<template lang="pug">
  tr()
    td
      div
        .th-in {{ (index + 1) }}
    //miejsce montazu/krotki opis
    //td {{ item.shortDescription }}
    // nazwa grupy
    td {{ property(item.fabricShadeDayNightGroup, "position") }}
    // nazwa wersji
    td {{ property(item.fabricShadeDayNight, "name") }}
    // nazwa kolekcji (tkanina)
    td {{ property(item.fabricDayNight, "name") }}
    // nazwa tkaniny (kolor tkaniny)
    td {{ property(item.fabricColorDayNight, "name") }}
    // szerokość całkowita
    td(v-if="printType === 'full'") {{ formatLength(item.width) }}
    // szerokość materiału
    td(v-if="printType === 'full'") {{ formatLength(item.fabricWidth) }}
    // szerokość szyby (opcjonalnie)
    td(v-if="printType === 'full'") {{ formatLength(item.glassWidth) ? formatLength(item.glassWidth) : "-" }}
    // wysokość
    td(v-if="printType === 'full'") {{ formatLength(item.height) }}
    // szerokość szyby (opcjonalnie)
    td(v-if="printType === 'full'") {{ formatLength(item.glassHeight) ? formatLength(item.glassHeight) : "-" }}
    // kasetka
    td {{ property(item.fabricShadeDayNightGlobalAccessoryEntry, "name", "globalAccessory") }}
    // belka dolna
    td {{ property(item.fabricShadeDayNightGlobalBalkEntry, "name", "globalBalk") }}
    // strona napędu
    td {{ item.driveSide }}
    // prowadnice
    td {{ property(item.fabricShadeDayNightGlobalRunnerEntry, "name", "globalRunner") }}
    // mocowanie
    td {{ property(item.fabricShadeDayNightGlobalFixingEntry, "name", "globalFixing") }}
    // napęd
    td {{ property(item.globalDrive, "name") }}
    // obrócenie tkaniny
    td {{ formatBoolean(item.hasTurnableFabric) }}
    // Dodatki
    td
      p(v-if="!item.fabricShadeDayNightFabricShadeDayNightAdditionalEntries || !item.fabricShadeDayNightFabricShadeDayNightAdditionalEntries.length") {{$t('empty')}}
      p(v-for="(itemAdditional) in item.fabricShadeDayNightFabricShadeDayNightAdditionalEntries") {{ property(itemAdditional, "name", "fabricShadeDayNightAdditional") }}

    // ilość szt.
    td {{ item.quantity }}
    // cena zakupu
    td.has-text-right(v-if="priceType !== 'sales' && printType === 'full'") {{ formatPriceValue(formatPrice(item.netPurchaseValue)) }}
    // cena sprzedaży
    td.has-text-right(v-if="priceType !== 'purchase' && printType === 'full'") {{ formatPriceValue(formatPrice(item.netSalesValue)) }}

</template>
<script>
import { mapGetters } from "vuex";
import priceFormatter from "../../../../../helpers/priceFormatter";
import decimal from "../../../../../helpers/decimal";

export default {
  props: {
    index: Number,
    item: Object,
    priceType: { default: null, required: true },
    printType: { default: null, required: true }
  },
  methods: {
    getDecimalValue(value) {
      return decimal(value);
    },
    formatPriceValue(value) {
      return priceFormatter(value);
    },
    versionNumber() {
      return this.checkVirtualVersionNumberAfterSum(this.item)
    },
    trueFalseToText(value) {
      return (value) ? this.$t("yes") : this.$t("no")
    },
    property(object, field, extraField = null) {
      if (extraField) {
        return object && object[extraField][field];
      }
      return object && object[field];
    },
  },
  computed: {
    ...mapGetters("common", [
      "formatLength",
      "formatNumber",
      "formatBoolean",
      "formatPrice",
      "formatWeight"
    ])
  }
};
</script>
