<template lang="pug">
  b-modal(v-model="isActive" width="80%" @close="onClose" )
    app-view-loadable(:loading="loading").columns.is-multiline
      app-card(style="height:99%" :title="$t('automation.title_choose_automation')").column.is-3
        .columns.is-multiline
          .column.is-full
            .sidebar-page
              p.has-font-size-15.menu-label {{$t("automation.header_filter")}}
              .menu-list
                .columns
                  //.column.is-2-desktop
                  //  app-form-field(:label="$t('automation.filters_elements')" field="manufactures" horizontal ).mt-5
                  //    b-field
                  //      v-select.has-pointer.w100(
                  //        :options="defaultAutomationElements"
                  //        label="manufactures"
                  //        v-model="filters.elements"
                  //        :clearable="false"
                  //        :placeholder="$t('empty')"
                  //        :getOptionLabel="option => {return this.$t('automation.' +option['value'])}"
                  //        @input=""
                  //      )
                  //.column.is-2-desktop
                  //  app-form-field(:label="$t('automation.filters_manufacture')" field="manufactures" horizontal ).mt-5
                  //    b-field
                  //        v-select.has-pointer.w100(
                  //          :options="itemGlobalDriveManufactures"
                  //          label="manufactures"
                  //          v-model="filters.manufactures"
                  //          :clearable="false"
                  //          :loading="loading"
                  //          :placeholder="$t('empty')"
                  //          :getOptionLabel="option => {if (typeof option === 'object'){ if(option.hasOwnProperty('name')){ return option['name'] }} return option;}"
                  //          @input=""
                  //  )
                  .column.is-2-desktop.is-2-tablet.is-offset-2
                    app-table-filter-text(
                      :label="$t('automation.filter_text')"
                      field="number"
                      v-bind="props"
                      :time="300"
                    )
                  .column.is-3-desktop.is-3-tablet
                    app-form-field(:label="$t('automation.filters_drive_control_type')" field="drive_control_type" horizontal ).mt-5
                      b-field
                        v-select.has-pointer.w100(
                          :options="itemGlobalDriveControlType"
                          label="drive_control_type"
                          v-model="filters.driveControlType"
                          :clearable="false"
                          :loading="loading"
                          :placeholder="$t('empty')"
                          :getOptionLabel="option => {if (typeof option === 'object'){ if(option.hasOwnProperty('name')){ return option['name'] }} return option;}"
                          @input=""
                        )

                    //app-form-field(:label="$t('automation.filters_remote_control_type')" field="drive_remote_control_type" horizontal ).mt-5
                    //  b-field
                    //    v-select.has-pointer.w100(
                    //      :options="itemGlobalRemoteControlType"
                    //      label="remote_control_type"
                    //      v-model="filters.remoteControlType"
                    //      :clearable="false"
                    //      :loading="loading"
                    //      :placeholder="$t('empty')"
                    //      :getOptionLabel="option => {if (typeof option === 'object'){ if(option.hasOwnProperty('name')){ return option['name'] }} return option;}"
                    //      @input=""
                    //    )
                  .column.is-2-desktop.is-3-tablet
                    app-form-field(:label="$t('automation.fitters_remote_control_channel')" field="drive_remote_control_channel" horizontal ).mt-5
                      b-field
                        v-select.has-pointer.w100(
                          :options="itemGlobalRemoteControlChannel"
                          label="remote_control_type"
                          v-model="filters.remoteControlChannel"
                          :clearable="false"
                          :loading="loading"
                          :placeholder="$t('empty')"
                          :getOptionLabel="option => {if (typeof option === 'object'){ if(option.hasOwnProperty('name')){ return option['name'] }} return option;}"
                          @input=""
                        )
                  .columnis-2-desktop.is-2-tablet.is-vcentered.pt-5
                    b-button(
                      type="is-primary"
                      @click="resetFilters"
                    ) {{ $t('automation.reset_filters') }}
          .column.is-12(style="height:60vh;overflow-y:auto;")
              hr.mb-5.mt-5
              AutomationItem(v-for="item in itemProductElements" :item="item")
          .column.mt-5.is-full.is-vcentered
            automation-order-items-external-shutters(v-if="productType === 'externalShutters'")
            automation-order-items-fabric-shade(v-if="productType === 'fabricShades'")
            automation-order-items-fabric-shade-day-night(v-if="productType === 'fabricShadeDayNights'")

</template>
<script>

import { mapActions, mapGetters} from "vuex";
import priceFormatter from "../../../helpers/priceFormatter";
import AutomationItem from "./AutomationItem";
import AutomationOrderItemsExternalShutters from "../../external_shutters/views/components/AutomationOrderItems";
import AutomationOrderItemsFabricShade from "../../fabric_shades/views/components/AutomationOrderItems";
import AutomationOrderItemsFabricShadeDayNight from "../../fabric_shade_day_nights/views/components/AutomationOrderItems";
import AppTableFilterText from "../../../plugins/app/table/components/AppTableFilterText";

export default {
  components: {
    AutomationItem,
    AutomationOrderItemsExternalShutters,
    AutomationOrderItemsFabricShade,
    AutomationOrderItemsFabricShadeDayNight,
    AppTableFilterText
  },
  props: {
    automationModalShow: { type: Boolean, default: false, required: true },
    productType: { type: String},
    value: {default: null},
    globalDriveControls: {type: Array},
    placeholder: {type: String},
    nullable: Boolean,
    expanded: Boolean
  },
  data() {
    return {
      props: {
        label: "Wyszukiwanie",
        filter: this.setFilterSearchText,
        field: "symbol",
        filters: {}
      },
      isActive:this.automationModalShow,
      priceLoading:false,
    };
  },
  beforeMount() {
    this.setProductType(this.productType)
  },
  methods: {
    ...mapActions("automation",
        ["init",
          "addElementToItemOrder",
          "addItemToOrder",
          "setFilterSearchText",
          "setItemProductElements",
          "setProductType",
          "clearItem",
          "resetFilters"
        ]),
    onClose() {
      this.isActive = false;
      this.$emit("close");
    },
    formatPriceValue(value) {
      return priceFormatter(value);
    },
  },

  watch:{
    globalDriveControls(val) {
        this.setItemProductElements(val)
    },
    automationModalShow(val) {
      this.isActive = !!val;
    },
    productType(val) {
      this.setProductType(val)
    },
  },

  computed: {
    ...mapGetters("common", [
      "formatPrice",
    ]),
    ...mapGetters("automation",
        ["loading",
         "item",
         "image",
         "filters",
         "defaultAutomationElements",
         "itemGlobalDriveManufactures",
         "itemGlobalDriveControlType",
         "itemGlobalRemoteControlType",
         "itemGlobalRemoteControlChannel",
         "itemProductElements",
        ]),


  }
};
</script>
