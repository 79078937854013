import Vue from "vue";
import Vuex from "vuex";
import router from "./router";
import http from "@/http";

import { sync } from "vuex-router-sync";
import i18n from "./i18n";

Vue.use(Vuex);

const store = new Vuex.Store({
  state: {
    themeBackgroundColor: null,
    hideImage:false,
    hideWholeSalePrice:false,
    pageLoginBackgroundImage:null,
  },
  getters: {
    themeBackgroundColor(state) {
      return state.themeBackgroundColor;
    },
    pageLoginBackgroundImage(state) {
      return state.pageLoginBackgroundImage;
    },
    hideImage(state) {
      return state.hideImage;
    },
    hideWholeSalePrice(state) {
      return state.hideWholeSalePrice;
    },
    driveSides: (state, getters) => {
      let sides = [
        { label: i18n.t("drive_sides.left"), value: "L" },
        { label: i18n.t("drive_sides.right"), value: "P" },
        { label: i18n.t("drive_sides.left_right"), value: "L+P" }
      ];
      return sides.filter(side => {
        if (
            side.value === "L+P" &&
            getters["fabricShades/item"].quantity % 2 !== 0
        ) {
          return false;
        }
        return true;
      });
    },
    fabricShadeDriveSides: (state, getters) => {
      let sides = [
        { label: i18n.t("drive_sides.left"), value: "L" },
        { label: i18n.t("drive_sides.right"), value: "P" },
        { label: i18n.t("drive_sides.left_right"), value: "L+P" }
      ];
      return sides.filter(side => {
        if (
          side.value === "L+P" &&
          getters["fabricShades/item"].quantity % 2 !== 0
        ) {
          return false;
        }
        return true;
      });
    },
    fabricShadeDayNightDriveSides: (state, getters) => {
      let sides = [
        { label: i18n.t("drive_sides.left"), value: "L" },
        { label: i18n.t("drive_sides.right"), value: "P" },
        { label: i18n.t("drive_sides.left_right"), value: "L+P" }
      ];
      return sides.filter(side => {
        if (
            side.value === "L+P" &&
            getters["fabricShadeDayNights/item"].quantity % 2 !== 0
        ) {
          return false;
        }
        return true;
      });
    },
    locales: () => {
      return ["PL", "CZ", "EN", "DE", "FR", "SE", "IT", "SI", "RU", "ES", "NL"];
    },
    lengthUnits: () => {
      return [
        { label: "Milimetr", value: "mm" },
        { label: "Centymetr", value: "cm" }
      ];
    },
    externalShutterCombinationsGraphics: () => {
      return [
        {
          file: "/external_shutter_combinations/K_single.jpg",
          itemsCount: 1,
          text:"single"
        },
        {
          file: "/external_shutter_combinations/K_2H.jpg",
          itemsCount: 2,
          text:"2H"
        },
        {
          file: "/external_shutter_combinations/K_WN.jpg",
          itemsCount: 2,
          text:"WN"
        },
        {
          file: "/external_shutter_combinations/K_NW.jpg",
          itemsCount: 2,
          text:"NW"
        },
        {
          file: "/external_shutter_combinations/K_WWN.jpg",
          itemsCount: 3,
          text:"WWN"
        },
        {
          file: "/external_shutter_combinations/K_NNW.jpg",
          itemsCount: 3,
          text:"NNW"
        },
        {
          file: "/external_shutter_combinations/K_3H.jpg",
          itemsCount: 3,
          text:"3H"
        },
        {
          file: "/external_shutter_combinations/K_NWW.jpg",
          itemsCount: 3,
          text:"NWW"
        },
        {
          file: "/external_shutter_combinations/K_NWN.jpg",
          itemsCount: 3,
          text:"NWN"
        },
        {
          file: "/external_shutter_combinations/K_WNN.jpg",
          itemsCount: 3,
          text:"WNN"
        }
      ];
    }
  },
  mutations: {
    setThemeBackgroundColor(state, payload) {
      state.themeBackgroundColor = payload;
    },
    setHideImage(state, payload) {
      state.hideImage = payload;
    },
    setHideWholeSalePrice(state, payload) {
      state.hideWholeSalePrice = payload;
    },
    setPageLoginBackgroundImage(state, payload) {
      state.pageLoginBackgroundImage = payload;
    },
  },
  actions: {
    async getBackground({ commit }) {
      let { data } = await http.get(
          `/background_image`
      );
      commit("setPageLoginBackgroundImage", data.backgroundUrl);

    },
    async init({ commit }, id) {
      let { data } = await http.get(
        `/users/${id}?properties[]=backgroundColor&properties[]=hideImage&properties[]=hideWholeSalePrice`
      );
      commit("setThemeBackgroundColor", data.backgroundColor);
      commit("setHideImage", data.hideImage);
      commit("setHideWholeSalePrice", data.hideWholeSalePrice)
    },
    setThemeBackgroundColor({ commit }, payload) {
      commit("setThemeBackgroundColor", payload);
    },
    setHideImage({ commit }, payload) {
      commit("setHideImage", payload);
    },
    setHideWholeSalePrice({ commit }, payload) {
      commit("setHideWholeSalePrice", payload);
    },
    setPageLoginBackgroundImage({ commit }, payload) {
      commit("setPageLoginBackgroundImage", payload);
    },
  }
});

sync(store, router);

export default store;
