import router from "./../../router";
import CreateOrder from "./views/CreateOrder";
import store from "../../store";
import fabricShadeDayNights from "../fabric_shade_day_nights/store";

store.registerModule("fabricShadeDayNights", fabricShadeDayNights);

router.addRoutes([
  {
    path: "/fabric_shade_day_nights/create_order/:copyId?",
    name: "fabric_shade_day_nights_create_order",
    component: CreateOrder,
    props:true
  }
]);

router.addRoutes([
  {
    path: "/fabric_shade_day_nights/edit_order/:id",
    name: "fabric_shade_day_nights_edit_order",
    component: CreateOrder,
    props: true
  }
]);
