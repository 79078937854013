<template lang="pug">
  app-form(v-on="listeners" v-bind="props" resource="bug_reports" :transform-form-data-before-save="transformToSubmit" :transform-to-model="transformToModel")
    template(#default="{ data, errors }")
      .columns(v-if="!isCreating")
        .column.is-two-fifths
          app-form-field(:label="$t('status')" field="status")
            b-input(:value="$t(data.status)" disabled)
      b-tabs(type="is-boxed")
        b-tab-item(:label="$t('general')")
          div(v-if="isCreating")
            .columns
              .column
                app-form-field(:label="$t('name')" field="name" :errors="errors" required)
                  b-input(v-model="data.name")
              .column
                app-form-field(label="PLik" field="file" :errors="errors")
                  app-form-file(v-model="files.file" :image="data.file" @removed="data.file = {}")
          .columns(v-if=" object && object.bugReportMessages && object.bugReportMessages.length > 0")
            .divider
            .column
              .columns(v-for="report in data.bugReportMessages")
                .column
                  .is-right(v-if="report.author.id !== userId")
                    b-message(
                      type="is-success"
                      :title="`${report.author.firstName} ${report.author.lastName}  ${report.createdAt}`"
                      :closable="false"
                    )
                      div(v-html="report.message")

                .column
                  .is-left(v-if="report.author.id === userId")
                    b-message(
                      type="is-info"
                      :title="`${report.author.firstName} ${report.author.lastName} ${report.createdAt}`"
                      :closable="false"
                    )
                      div(v-html="report.message")
            .divider
          .columns
            .column
              app-form-field(:label="$t('message')" field="bugReportMessages" :errors="errors" )
                app-form-editor(v-model="data.newMessage")

</template>
<script>
import form from "../../../plugins/app/form/mixins/form";
import { mapGetters } from "vuex";

export default {
  mixins: [form],
  data() {
    return {
      model: {
        name: "",
        bugReportMessages: [],
        status: "new", //invisible
        file: null,
        isMobile: navigator.userAgentData.mobile, //invisible
        userAgent: navigator.userAgent, //invisible
        platform: navigator.platform, //invisible
        newMessage: "",
        createdAt: "", //not procesible
        updatedAt: "" //not procesible
      },
      files: {
        file: {}
      }
    };
  },
  methods: {
    transformToSubmit(formData) {
      delete formData.createdAt;
      delete formData.updatedAt;

      return formData;
    },
    transformToModel(data) {
      data.newMessage = "";

      return data;
    }
  },
  computed: {
    ...mapGetters("auth", ["userId"]),
    isCreating() {
      return this && this.$route.path.includes("create");
    }
  }
};
</script>
