import http from "../../http";
import collectionToObject from "../../helpers/collectionToObject";
import Vue from "vue";
import notify from "@/helpers/notify";

export default {
  namespaced: true,
  state: {
    id: null,
    lengthUnit: "cm",
    productTypes: [],
    wholesalePriceList: {},
    retailPriceList: {},
    currencyCode: null,
    currencyRate: "1.0000",
    fitterTravelCosts: {},
    companyVatRates: {},
    companyInstallationValues: {},
    vatRate: {},
    paymentMethods: {},
    deliveryMethods: {},
    defaultDeliveryMethod: {},
    companyPriceListEntries: {},
    regulationReadAt: -1,
    fitters: [],
    minimalMargin: 1000,
    buyType: "retail",
    needSupportWithAddLogo: false,
    logo: null,
    companyData: {
      id: null,
      mobilePhone: "",
      name: "",
      phone: "",
      streetAndNumber: "",
      taxId: "",
      website: "",
      zipCode: "",
      bankAccountNumber: "",
      bankName: "",
      owner: "",
      email: "",
      city: "",
      country: null
    },
    isAllowedToCreateSubCompany: false,
    regulationContent: null
  },
  getters: {
    companyData: state => state.companyData,
    logo: state => state.logo,
    needSupportWithAddLogo: state => state.needSupportWithAddLogo,
    buyType: state => state.buyType,
    companyId: state => state.id,
    defaultDeliveryMethod: state => state.defaultDeliveryMethod,
    fitterTravelCosts: state => state.fitterTravelCosts,
    companyVatRates: state => state.companyVatRates,
    companyInstallationValues: state => state.companyInstallationValues,
    regulationReadAt: state => state.regulationReadAt,
    companyPriceListEntries: state => state.companyPriceListEntries,
    paymentMethods: state => state.paymentMethods,
    deliveryMethods: state => state.deliveryMethods,
    currencyCode: state => state.currencyCode,
    currencyRate: state => state.currencyRate,
    vatRate: state => state.vatRate,
    minimalMargin: state => state.minimalMargin,
    hasProductType: state => code => {
      let found = state.productTypes.find(
        productType => productType.code === code
      );

      return !!found;
    },
    lengthUnit: state => state.lengthUnit,
    retailPriceListByCode: state => code => state.retailPriceList[code],
    wholesalePriceListByCode: state => code => state.wholesalePriceList[code],
    fitters: state => state.fitters,
    isAllowedToCreateSubCompany(state) {
      return state.isAllowedToCreateSubCompany;
    },
    regulationContent: state => state.regulationContent
  },
  mutations: {
    setIsAllowedToCreateSubCompany(state, value) {
      state.isAllowedToCreateSubCompany = value;
    },
    setCompanyData(state, object) {
      state.companyData = object;
    },
    setLogo(state, object) {
      state.logo = object;
    },
    setNeedSupportWithAddLogo(state, object) {
      state.needSupportWithAddLogo = object;
    },
    setBuyType(state, object) {
      state.buyType = object;
    },
    updateFitterTravelCost(state, object) {
      Vue.set(state.fitterTravelCosts, object.id, object);
    },
    updateCompanyVatRate(state, object) {
      Vue.set(state.companyVatRates, object.id, object);
    },
    updateCompanyInstallationValue(state, object) {
      Vue.set(state.companyInstallationValues, object.id, object);
    },
    addCompanyInstallationValue(state, object) {
      Vue.set(state.companyInstallationValues, object.id, object);
    },
    addFitter(state, object) {
      state.fitters.push(object);
    },
    updateFitter(state, object) {
      Vue.set(state.fitters, state.fitters.indexOf(object), object);
    },
    setId(state, object) {
      state.id = object;
    },
    setFitterTravelCosts(state, object) {
      state.fitterTravelCosts = object;
    },
    setCompanyVatRates(state, object) {
      state.companyVatRates = object;
    },
    setCompanyInstallationValues(state, object) {
      state.companyInstallationValues = object;
    },
    setMinimalMargin(state, margin) {
      state.minimalMargin = margin;
    },
    setFitters(state, object) {
      state.fitters = object;
    },
    setCompanyPriceListEntries(state, object) {
      state.companyPriceListEntries = object;
    },
    setLengthUnit(state, lengthUnit) {
      state.lengthUnit = lengthUnit;
    },
    setRetailPriceList(state, data) {
      state.retailPriceList = data;
    },
    setProductTypes(state, data) {
      state.productTypes = data;
    },
    setWholesalePriceList(state, data) {
      state.wholesalePriceList = data;
    },
    setCurrencyCode(state, code) {
      state.currencyCode = code;
    },
    setCurrencyRate(state, rate) {
      state.currencyRate = rate;
    },
    setVatRate(state, vatRate) {
      state.vatRate = vatRate;
    },
    setPaymentMethods(state, paymentMethods) {
      state.paymentMethods = paymentMethods;
    },
    setDeliveryMethods(state, deliveryMethods) {
      state.deliveryMethods = deliveryMethods;
    },
    setDefaultDeliveryMethod(state, defaultDeliveryMethod) {
      state.defaultDeliveryMethod = defaultDeliveryMethod;
    },
    removeFitterTravelCost(state, id) {
      Vue.delete(state.fitterTravelCosts, id);
    },
    removeCompanyVatRate(state, id) {
      Vue.delete(state.companyVatRates, id);
    },
    removeCompanyInstallationValue(state, object) {
      Vue.delete(state.companyInstallationValues, object.id);
    },
    removeFitter(state, idx) {
      Vue.delete(state.fitters, idx);
    },
    setCompanyRegulationReadAt(state, date) {
      state.regulationReadAt = date;
    },
    setRegulationContent(state, content) {
      state.regulationContent = content;
    }
  },
  actions: {
    async setCompanyData({ commit }, object) {
      commit("setCompanyData", object);
    },
    async removeFitterTravelCost({ commit }, id) {
      commit("removeFitterTravelCost", id);
      await http.delete(`/fitter_travel_costs/${id}`);
    },
    async removeCompanyVatRate({ commit }, id) {
      commit("removeCompanyVatRate", id);
      await http.delete(`/company_vat_rates/${id}`);
    },
    async removeCompanyInstallationValue({ commit }, obj) {
      commit("removeCompanyInstallationValue", obj);
    },
    async addCompanyInstallationValue({ commit }, obj) {
      commit("addCompanyInstallationValue", obj);
    },
    async updateCompanyInstallationValue({ commit }, obj) {
      commit("updateCompanyInstallationValue", obj);
    },
    async removeFitter({ commit }, obj) {
      commit("removeFitter", obj.idx);
      await http.delete(`/fitters/${obj.id}`);
      notify("Usunięto pozycję");
    },
    async init({ commit, rootState, rootGetters }) {
      let [companyResponse, deliveryMethodsResponse] = await Promise.all([
        http.get(
          `/${rootGetters["auth/companyUrl"]}/${rootState.auth.companyId}`
        ),
        http.get("/delivery_methods?properties[]=id&properties[]=name"),
        // http.get(`/companies/${rootState.auth.companyId}/company_price_list_entries`)
      ]);
      const company = companyResponse.data;
      const deliveryMethods = deliveryMethodsResponse.data;


      commit("setCompanyRegulationReadAt", company.regulationReadAt);
      commit("setId", `${rootState.auth.companyId}`);
      commit("setMinimalMargin", company.minimalMargin);
      commit("setProductTypes", company.productTypes);
      commit("setLengthUnit", company.lengthUnit);
      commit("setCurrencyCode", company.currency.code);
      commit("setCurrencyRate", company.currencyRate);
      commit("setVatRate", company.vatRate);
      commit("setRegulationContent", company.regulationContent)
      commit("setCompanyVatRates", collectionToObject(company.companyVatRates));
      commit(
        "setCompanyInstallationValues",
        collectionToObject(company.companyInstallationValues)
      );
      // commit(
      //   "setFitterTravelCosts",
      //   collectionToObject(company.fitterTravelCosts)
      // );

      commit("setPaymentMethods", collectionToObject(company.paymentMethods));
      commit(
        "setDeliveryMethods",
        collectionToObject(deliveryMethods["hydra:member"])
      );
      commit("setDefaultDeliveryMethod", company.defaultDeliveryMethod);
      // commit("setFitters", company.fitters);
      commit("setBuyType", company.buyType);
      commit("setNeedSupportWithAddLogo", company.needSupportWithAddLogo);
      commit("setLogo", company.logo);
      commit(
        "setIsAllowedToCreateSubCompany",
        company.isAllowedToCreateSubCompany
      );
      const companyObject = {
        id: company.id,
        mobilePhone: company.mobilePhone,
        name: company.name,
        phone: company.phone,
        streetAndNumber: company.streetAndNumber,
        taxId: company.taxId,
        website: company.website,
        zipCode: company.zipCode,
        bankAccountNumber: company.bankAccountNumber,
        bankName: company.bankName,
        owner: company.owner,
        email: company.email,
        city: company.city,
        country: company.country
      };
      commit("setCompanyData", companyObject);
      // let wholesalePriceList = {};
      // company.companyPriceListEntries.forEach(entry => {
      //   wholesalePriceList[entry.productType.code] = {
      //     percentMargin: entry.wholesalePriceList.percentMargin,
      //     amountMargin: entry.wholesalePriceList.amountMargin,
      //     percentDiscount: entry.wholesalePriceList.percentDiscount,
      //     amountDiscount: entry.wholesalePriceList.amountDiscount
      //   };
      // });
      // commit("setWholesalePriceList", wholesalePriceList);
    },
    async setNeedSupportWithAddLogo({ commit }, payload) {
      commit("setNeedSupportWithAddLogo", payload);
    },
    async setLogo(
      { commit, rootState, rootGetters },
      payload,
      isDelete = false
    ) {
      const isString = "string" === typeof payload;
      if (!isDelete) {
        await http
          .put(
            `/${rootGetters["auth/companyUrl"]}/${
              rootState.auth.companyId
            }?properties[]=logo`,
            {
              logo: isString ? payload : null
            }
          )
          .then(response => {
            const data = response.data;
            if (
              Object.prototype.hasOwnProperty.call(data, "logo") &&
              data.logo !== null
            ) {
              commit("setLogo", data.logo);
            } else {
              commit("setLogo", {});
            }
          });
      } else {
        commit("setLogo", payload);
      }
    },
    async fetchRetailPriceList({ commit }) {
      const { data } = await http.get(`/retail_price_lists`);
      let retailPriceList = {};
      data["hydra:member"].forEach(member => {
        retailPriceList[member.productType.code] = {
          percentMargin: member.percentMargin,
          amountMargin: member.amountMargin,
          percentDiscount: member.percentDiscount,
          amountDiscount: member.amountDiscount
        };
      });
      commit("setRetailPriceList", retailPriceList);
    },
    //dont blame me... i know this is bad, but i had no idea how to fix it fast
    async setMinimalMargin({ commit }, payload) {
      commit("setMinimalMargin", payload);
      if (payload > 10000) {
        payload = 10000;
      } else if (payload < 1000) {
        payload = 1000;
      }
      await Vue.nextTick();
      commit("setMinimalMargin", payload);
    },
    async setBuyType({ commit }, payload) {
      commit("setBuyType", payload);
    },
    async setLengthUnit({ commit }, payload) {
      commit("setLengthUnit", payload);
    },
    async acceptRegulation({ commit, rootState, rootGetters }) {
      commit("setCompanyRegulationReadAt", 1);
      let { data } = await http.put(
        `/${rootGetters["auth/companyUrl"]}/${
          rootState.auth.companyId
        }/mark_regulation_read`,
        {}
      );
      commit("setCompanyRegulationReadAt", data.regulationReadAt);
    }
  }
};
