<template lang="pug">
    tr(:class="classes")
        // liczba porządkowa
        td
          div
            .th-in {{ (index + 1 + iteratorAlphabet[indexCombination]) }}
            .is-red {{ item.hasOversize ? "*" : ""}}
        //nazwa wersji (z drugiej tabelki)
        td {{ versionNumber() + 1 }}
        // Moskitiera
        td {{ trueFalseToText(property(itemCombination.hasMosquitoNet)) }}
        //szerokość kombinacji
        td {{ formatLength(itemCombination.width) }}
        // symbol rury
        td {{ property(itemCombination.globalPipe, "symbol") }}
        // wysokość prowadnic
        td {{ formatLength(itemCombination.runnersHeight) }}
        // wysokość całkowita
        td {{ formatLength(itemCombination.fullHeight) }}
        // kolor skrzynki zewnetrzny
        //td {{ property(item.temporaryExternalBoxColor, "name",  "fabricShadeColor") }}
        // kolor skrzynki wewnetrzny
        //td {{ property(item.temporaryInternalBoxColor, "name",  "fabricShadeColor") }}
        // nazwa skrzynki
        td {{ property(itemCombination.externalShutterBox, "name") }}
        // profil pancerza
        //td {{ property(item.externalShutterPriceList, "name",  "externalShutterArmor") }}
        // kolor pancerza
        //td {{ property(item.externalShutterArmorExternalShutterArmorColorEntry, "name",  "externalShutterArmorColor") }}
        // kolor listwy dolnej
        //td {{ property(item.externalShutterArmorFabricShadeColorEntry, "name",  "fabricShadeColor") }}
        // Prowadnica lewa
        td {{ property(itemCombination.externalShutterExternalShutterRunnerEntryLeft, "name",  "externalShutterRunner") }}
        // Prowadnica prawa
        td {{ property(itemCombination.externalShutterExternalShutterRunnerEntryRight, "name",  "externalShutterRunner") }}
        // kolor prowadnic
        //td {{ property(item.externalShutterRunnerFabricShadeColorEntry, "name",  "fabricShadeColor") }}
        // Nawiert lewy
        td {{ property(itemCombination.externalShutterDrillHoleLeft, "name") }}
        // Nawiert prawy
        td {{ property(itemCombination.externalShutterDrillHoleRight, "name") }}
        // Zabezpieczenia
        td
          p(v-if="!itemCombination.externalShutterExternalShutterProtectionEntries.length") {{$t('empty')}}
          p(v-for="(itemProtection, indexProtection) in itemCombination.externalShutterExternalShutterProtectionEntries") {{ property(itemProtection, "name", "externalShutterProtection") }}
        //Adaptacja
        td {{ property(itemCombination.externalShutterAdaptation, "name") }}
        // typ napędu
        td {{ property(itemCombination.globalDrive, "name", "globalDriveType")? property(itemCombination.globalDrive, "name", "globalDriveType") : $t('empty')   }}
        // seria napędu
        td {{ property(itemCombination.globalDrive, "name", "globalDriveSeries")? property(itemCombination.globalDrive, "name", "globalDriveSeries") : $t('empty') }}
        // strona napędu
        td {{ property(itemCombination.externalShutterDriveExit, "name") }}
        // wybrana kombinacja
        td {{ (item.selectedCombination === "single") ? "-" : item.selectedCombination   }}
        //miejsce montazu/krotki opis
        td {{ item.shortDescription }}
        // masa zestawu
        td {{ formatWeight(itemCombination.weight) }}
        // ilość szt. przy kombinacji
        td( v-if="indexCombination === 0" :rowspan="item.externalShutterOrderItemCombinationsCount") {{ item.quantity }}
        // cena zakupu
        td.has-text-right {{ formatPriceValue(formatPrice(itemCombination.netPurchaseValue)) }}
        // cena sprzedaży
        td.has-text-right(v-if="!hideWholeSalePrice") {{ formatPriceValue(formatPrice(itemCombination.netSalesValue)) }}
        td
            .field.has-addons
                p.control
                    b-button(
                      @click.prevent="editItem({index, item})"
                      type="is-info"
                      size="is-small"
                      icon-left="pencil"
                    )
                p.control
                    b-button(
                      @click.prevent="remove"
                      type="is-danger"
                      icon-left="delete"
                      size="is-small"
                    )


</template>
<script>
import { mapGetters, mapActions, mapMutations } from "vuex";
import priceFormatter from "../../../../helpers/priceFormatter";

export default {
  props: {
    index: Number,
    indexCombination : Number,
    item: Object,
    itemCombination: Object
  },
  methods: {
    ...mapGetters("externalShutters", ["checkVirtualVersionNumberAfterSum"]),
    ...mapActions("externalShutters", ["removeItem"]),
    ...mapMutations("externalShutters", ["editItem"]),
    formatPriceValue(value) {
      return priceFormatter(value);
    },
    versionNumber(){
      return this.checkVirtualVersionNumberAfterSum()(this.item)
    },
    trueFalseToText(value){
      return (value)? this.$t("yes") : this.$t("no")
    },
    remove() {
      return this.$buefy.dialog.confirm({
        message: this.$t("remove_position_confirm"),
        onConfirm: () => {
          this.removeItem(this.index);
        }
      });
    },
    property(object, field, extraField = null) {
      if (extraField) {
        return object && object[extraField][field];
      }
      return object && object[field];
    }
  },
  computed: {

    classes() {
      return {
        "is-editing": this.editIndex === this.index
      };
    },
    iteratorAlphabet(){
      if(this.item.externalShutterOrderItemCombinationsCount === 1)return " "
      return ["a","b","c"]
    },
    ...mapGetters(["hideWholeSalePrice"]),
    ...mapGetters("externalShutters", ["editIndex"]),
    ...mapGetters("common", [
      "formatLength",
      "formatNumber",
      "formatBoolean",
      "formatPrice",
      "formatWeight"
    ])
  }
};
</script>
