<template lang="pug">
    tr(:class="classes")
        // liczba porządkowa
        td
          div
            .th-in {{ (index + 1 ) }}
        //nazwa wersji
        td {{ property(item.externalShutter, "name") }}
        // profil pancerza
        td {{ property(item.externalShutterPriceList, "name",  "externalShutterArmor") }}
        // kolor pancerza
        td {{ property(item.externalShutterArmorExternalShutterArmorColorEntry, "name",  "externalShutterArmorColor") }}
        // kolor skrzynki zewnetrzny
        td {{ property(item.temporaryExternalBoxColor, "name",  "fabricShadeColor") }}
        // kolor skrzynki wewnetrzny
        td {{ property(item.temporaryInternalBoxColor, "name",  "fabricShadeColor") }}
        // kolor prowadnic
        td {{ property(item.externalShutterRunnerFabricShadeColorEntry, "name",  "fabricShadeColor") }}
        // kolor listwy dolnej
        td {{ property(item.externalShutterArmorFabricShadeColorEntry, "name",  "fabricShadeColor") }}


</template>
<script>
import { mapGetters, mapActions, mapMutations } from "vuex";
import priceFormatter from "../../../../helpers/priceFormatter";

export default {
  props: {
    index: Number,
    indexCombination : Number,
    item: Object,
    itemCombination: Object
  },
  methods: {
    ...mapActions("externalShutters", ["removeItem"]),
    ...mapMutations("externalShutters", ["editItem"]),
    formatPriceValue(value) {
      return priceFormatter(value);
    },
    trueFalseToText(value){
      return (value)? this.$t("yes") : this.$t("no")
    },
    remove() {
      return this.$buefy.dialog.confirm({
        message: this.$t("remove_position_confirm"),
        onConfirm: () => {
          this.removeItem(this.index);
        }
      });
    },
    property(object, field, extraField = null) {
      if (extraField) {
        return object && object[extraField][field];
      }
      return object && object[field];
    }
  },
  computed: {

    classes() {
      if(this.editIndex !== null)return {
        "is-editing": this.checkVirtualVersionNumberAfterSum() === this.index
      };
      return ""
    },
    ...mapGetters("externalShutters", ["editIndex", "checkVirtualVersionNumberAfterSum"]),
    ...mapGetters("common", [
      "formatLength",
      "formatNumber",
      "formatBoolean",
      "formatPrice",
      "formatWeight"
    ])
  }
};
</script>
