<template lang="pug">
    app-form(v-bind="props" v-on="listeners" resource="additional_costs")
        template(#default="{ data, errors }")
            b-tabs
                b-tab-item(:label="$t('general')")
                    .columns
                        .column
                            app-form-field(:label="$t('name')" field="name" :errors="errors")
                                b-input(v-model="data.name")

                            app-form-field(:label="$t('cost')" field="header" :errors="errors")
                                app-form-decimal( v-model="data.cost")
                        .column

</template>

<script>
import form from "@/plugins/app/form/mixins/form";

export default {
  mixins: [form],
  computed: {
    model() {
      return {
        name: "",
        cost: 0
      };
    }
  }
};
</script>
