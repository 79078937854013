<template lang="pug">
  section.hero.is-fullheight
    .hero-body
      .container
        .columns.is-centered
          .column.is-10-tablet.is-10-desktop.is-10-widescreen
            b-button(
              tag="router-link"
              to="/login"
              type="is-primary"
            ) {{ $t("login") }}

            b-message(type="is-warning") {{ $t('registration.alert') }}

            app-form(v-bind="props" v-on="listeners" resource="registrations")
              template(#default="{ data, errors }")
                .columns
                  .column
                    app-view-card(:title="$t('registration.company_data')")
                      .columns
                        .column
                          app-form-field(:label="$t('registration.tax_id')" field="taxId" :errors="errors" required)
                            b-input(v-model="data.taxId" expanded )
                        .column
                          app-form-field(:label="$t('registration.name')" field="name" :errors="errors" required)
                            b-input(v-model="data.name" expanded)

                      .columns
                        .column
                          app-form-field(:label="$t('registration.owner')" field="owner" :errors="errors" required)
                            b-input(v-model="data.owner" expanded)
                        .column
                          app-form-field(:label="$t('registration.zip_code')" field="zipCode" :errors="errors" required)
                            b-input(v-model="data.zipCode" expanded)
                        .column
                          app-form-field(:label="$t('registration.city')" field="city" :errors="errors" required)
                            b-input(v-model="data.city" expanded)

                      .columns
                        .column
                          app-form-field(:label="$t('registration.street_and_number')" field="streetAndNumber" :errors="errors" required)
                            b-input(v-model="data.streetAndNumber" expanded)
                        .column
                          app-form-field(:label="$t('registration.country')" field="country" :errors="errors" required)
                            resource-select(
                              v-model="data.country"
                              resource="countries"
                              url="countries"
                              :placeholder="$t('registration.country')"
                              @fetched="selectDefaultCountry"
                              simple
                              expanded
                            )

                      .columns
                        .column
                          app-form-field(:label="$t('registration.email')" field="email" :errors="errors" required)
                            b-input(v-model="data.email" expanded)
                        .column
                          app-form-field(:label="$t('registration.phone')" field="phone" :errors="errors" required)
                            b-input(v-model="data.phone" expanded)
                        .column
                          app-form-field(:label="$t('registration.currency')" field="currency" :errors="errors")
                            resource-select(
                              v-model="data.currency"
                              resource="currencies"
                              url="currencies"
                              :placeholder="$t('registration.currency')"
                              @fetched="selectDefaultCurrency"
                              label="code"
                              extra-props="properties[]=code"
                              simple
                              expanded
                              required
                            )
                      .columns
                        .column
                          app-form-field(field="needHelpWithAddLogo" :errors="errors" :help="$t('registration.logo_help')")
                            b-checkbox(v-model="data.needSupportWithAddLogo") {{$t('registration.need_help_with_logo')}}

                  .column
                    app-view-card(:title="$t('registration.shipment_data')" has-margin-bottom)
                      .columns
                        .column
                          b-checkbox(v-model="sameAsCompany" @click.native="changeSameAsCompany(data)") {{$t('registration.same_as_company')}}
                      .columns
                        .column
                          app-form-field(:label="$t('registration.shipment_name')" field="shipmentName" :errors="errors" required)
                            b-input(v-model="data.shipmentName" :placeholder="$t('registration.shipment_name_placeholder')" expanded :disabled="sameAsCompany")
                        .column
                          app-form-field(:label="$t('registration.zip_code')" field="shipmentZipCode" :errors="errors" required )
                            b-input(v-model="data.shipmentZipCode" expanded :disabled="sameAsCompany")
                        .column
                          app-form-field(:label="$t('registration.city')" field="shipmentCity" :errors="errors" required )
                            b-input(v-model="data.shipmentCity" expanded :disabled="sameAsCompany")

                      .columns
                        .column
                          app-form-field(:label="$t('registration.street_and_number')" field="shipmentStreetAndNumber" :errors="errors" required )
                            b-input(v-model="data.shipmentStreetAndNumber" expanded :disabled="sameAsCompany")
                        .column
                          app-form-field(:label="$t('registration.country')" field="shipmentCountry" :errors="errors" required )
                            resource-select(
                              v-model="data.shipmentCountry"
                              resource="countries"
                              url="countries"
                              :placeholder="$t('registration.country')"
                              @fetched="selectDefaultCountry"
                              simple
                              expanded
                              :disabled="sameAsCompany"
                            )

                    app-view-card(:title="$t('registration.admin_data')")
                      .columns
                        .column
                          app-form-field(:label="$t('registration.admin_first_name')" field="adminFirstName" :errors="errors" required)
                            b-input(v-model="data.adminFirstName" expanded)
                        .column
                          app-form-field(:label="$t('registration.admin_last_name')" field="adminLastName" :errors="errors" required)
                            b-input(v-model="data.adminLastName" expanded)
                      .columns
                        .column
                          app-form-field(:label="$t('registration.admin_phone')" field="adminPhone" :errors="errors" required)
                            b-input(v-model="data.adminPhone" expanded)
                        .column
                          app-form-field(:label="$t('registration.admin_email')" field="adminEmail" :errors="errors" required)
                            b-input(v-model="data.adminEmail" expanded)
                      .columns
                        .column
                          app-form-field(:label="$t('registration.admin_login')" field="adminLogin" :errors="errors" required)
                            b-input(v-model="data.adminLogin" name="login" @focus="isReadOnly = false" :readonly="isReadOnly" autocomplete="off" type="text" expanded)
                        .column
                          app-form-field(:label="$t('registration.admin_plain_password')" field="adminPlainPassword" :errors="errors" required)
                            b-input(type="password" v-model="data.adminPlainPassword" id="password" name="password" @focus="isReadOnly = false" :readonly="isReadOnly" autocomplete="off" :expanded="true")
                        .column
                          app-form-field(:label="$t('registration.admin_plain_password_repeat')" field="adminPlainPassword2" :errors="errors" required)
                            b-input(type="password" v-model="data.adminPlainPassword2" id="password2" name="password2" @focus="isReadOnly = false" :readonly="isReadOnly" autocomplete="off" :expanded="true")

              template(#actions="{ submitting }")
                div.has-text-centered
                  b-button(native-type="submit" type="is-primary" :loading="submitting") Zapisz
</template>
<script>
import form from "@/plugins/app/form/mixins/form";
import ResourceSelect from "../../common/components/ResourceSelect";
import http from "../../../http";

export default {
  mixins: [form],
  components: {
    ResourceSelect
  },
  data() {
    return {
      isReadOnly: true,
      loading: false,
      error: null,
      sameAsCompany: false,
    };
  },
  computed: {
    model() {
      return {
        name: "",
        owner: "",
        zipCode: "",
        city: "",
        streetAndNumber: "",
        taxId: "",
        email: "",
        phone: "",
        language: "",
        currency: null,
        adminFirstName: "",
        adminLastName: "",
        adminPhone: "",
        adminEmail: "",
        adminLogin: "",
        adminPlainPassword: "",
        adminPlainPassword2: "",
        country: null,
        shipmentName: "",
        shipmentZipCode: "",
        shipmentStreetAndNumber: "",
        shipmentCity: "",
        shipmentCountry: "",
        needSupportWithAddLogo: false
      };
    }
  },
  methods: {
    selectDefaultCountry(list) {
      let poland = list.find(country => country.name === "Polska").id;
      this.model.country = poland;
      this.model.shipmentCountry = poland;
    },
    selectDefaultCurrency(list) {
      this.model.currency = list.find(currency => currency.code === "PLN").id;
    },
    async fetchGus(originalData) {
      const nip = originalData.taxId.replace(/^\D+/g, "").replaceAll("-", "");
      try {
        let {data} = await http.get(`/gus/nip/${nip}`);
        originalData.name = data.name;
        originalData.taxId = data.nip;
        originalData.zipCode = data.zipCode;
        originalData.city = data.city;
        originalData.streetAndNumber = `${data.street} ${data.propertyNumber}`;
      } catch (e) {
        window.alert(this.$t("error_over_download_nip_data"));
      }
    },
    changeSameAsCompany(data) {
      data.shipmentName = data.name
      data.shipmentZipCode = data.zipCode
      data.shipmentCity = data.city
      data.shipmentStreetAndNumber = data.streetAndNumber
      data.shipmentCountry = data.country
    }
  }
};
</script>
