<template>
  <table class="table is-bordered" :style="{ countedWidth }">
    <thead>
      <tr>
        <th
          v-for="(col, id) in columns"
          :key="`simple-table-header:${id}`"
          :style="{ width: col.width, maxWidth: col.width }"
        >
          {{ $t(col.label) }}
        </th>
        <th :style="buttonsWidth">
          {{ $t("actions") }}
        </th>
      </tr>
    </thead>
    <tbody v-if="data.length > 0">
      <simple-table-row
        v-for="(row, id) in data"
        :columns="columns"
        :row="row"
        :key="`simple-table-row:${row.name + id}`"
        :editable="editable"
        :resource="resource"
        @remove="remove(id, row)"
        @success="success(id, $event)"
        :canSave="canSave"
      >
      </simple-table-row>
    </tbody>
    <tbody v-else>
      <tr>
        <td :colspan="columns.length + 1">
          {{ $t("empty_data") }}
        </td>
      </tr>
    </tbody>
  </table>
</template>

<script>
import SimpleTableRow from "@/plugins/app/simple-table/SimpleTableRow";
//objects 'id' inside collection is required, same as {field(string), label(string) and width(integer+'px' as string) inside columns(collection of objects) }
export default {
  components: { SimpleTableRow },
  props: {
    data: {
      type: Array,
      default: () => [],
      required: true
    },
    columns: {
      type: Array,
      default: () => [],
      required: true
    },
    resource: {
      type: String,
      default: null
    },
    editable: {
      type: Boolean,
      default: false
    },
    canSave: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {};
  },
  methods: {
    remove(idx, row) {
      if (this.canSave && row["id"]) {
        this.$emit("remove", { id: row["id"], idx: idx });
      } else {
        this.$emit("remove", idx);
      }
    },
    success(id, object) {
      this.$emit("success", { id, object });
    }
  },
  computed: {
    countedWidth() {
      let width = 0;
      this.columns.forEach(val => {
        width += parseInt(val["width"].replace("px", ""));
      });
      return {
        maxWidth: width + this.editable ? 130 : 65
      };
    },
    buttonsWidth() {
      let widths = this.editable ? 130 : 65;
      return {
        width: `${widths}px`,
        maxWidth: `${widths}px`,
        minWidth: `${widths}px`
      };
    }
  }
};
</script>
