import http from "@/http";

export default {
  namespaced: true,
  state: {
    instructions: [],
    files: []
  },
  getters: {
    instructions: state => state.instructions,
    files: state => state.files
  },
  mutations: {
    setInstructions(state, payload) {
      state.instructions = payload;
    },
    setFiles(state, payload) {
      state.files = payload;
    }
  },
  actions: {
    async initInstructions({ commit }) {
      let { data } = await http.get("/support_instructions");
      commit("setInstructions", data["hydra:member"]);
    },
    async initDownloadableFiles({ commit }) {
      let { data } = await http.get("/download_sections");
      commit("setFiles", data["hydra:member"]);
    }
  }
};
