<template lang="pug">
  .container.has-background-grey-light.mt-3
    .columns
      .column.is-7.ml-4
        img(:src="combinationImage")
      .column.is-4
        .columns.is-multiline.is-centered.pt-3
          .column.p-0.is-5.has-background-white.is-offset-1.tile-square
            app-form-hover-image-preview-window(
              :image="image(orderItem.externalShutterOrderItemCombinations[0].externalShutterBox, 'sectionPicture')"
              @open="openImage($event)"
            )
          .column.p-0.is-5.has-background-white.is-offset-1.tile-square
            app-form-hover-image-preview-window(
              :image="image(orderItem.externalShutterPriceList, 'sectionPicture', 'externalShutterArmor')"
              @open="openImage($event)"
            )
          .column.p-0.is-5.has-background-white.is-offset-1.mt-5.tile-square
            app-form-hover-image-preview-window(
              :image="image(orderItem.externalShutterOrderItemCombinations[0].externalShutterExternalShutterRunnerEntryLeft, 'sectionLeftPicture', 'externalShutterRunner')"
              @open="openImage($event)"
            )
          .column.p-0.is-5.has-background-white.is-offset-1.mt-5.tile-square
            app-form-hover-image-preview-window(
              :image="image(orderItem.externalShutterOrderItemCombinations[0].externalShutterExternalShutterRunnerEntryRight, 'sectionRightPicture', 'externalShutterRunner')"
              @open="openImage($event)"
            )
          .column.p-0.is-5.has-background-white.is-offset-1.mt-5.tile-square
            app-form-hover-image-preview-window(
              :image="image(orderItem.externalShutterOrderItemCombinations[0].externalShutterDrillHoleLeft, 'sectionLeftPicture')"
              @open="openImage($event)"
            )
          .column.p-0.is-5.has-background-white.is-offset-1.mt-5.tile-square
            app-form-hover-image-preview-window(
              :image="image(orderItem.externalShutterOrderItemCombinations[0].externalShutterDrillHoleRight, 'sectionRightPicture')"
              @open="openImage($event)"
            )
          .column.p-0.is-5.has-background-white.is-offset-1.mt-5.tile-square
            app-form-hover-image-preview-window(
              :image="image(orderItem.externalShutterOrderItemCombinations[0].externalShutterDriveExit, 'sectionPicture')"
              @open="openImage($event)"
            )
          .column.p-0.is-5.has-background-white.is-offset-1.mt-5.tile-square
            app-form-hover-image-preview-window(
              :image="image(orderItem.externalShutterOrderItemCombinations[0].globalDrive, 'picture')"
              @open="openImage($event)"
            )
          //.column.is-5.has-background-white.is-offset-1.mt-2.emptyColumn
          //.column.is-5.has-background-white.is-offset-1.mt-2.emptyColumn
    b-modal(v-model="imageModal")
      .columns(style="width:99%").has-text-centered
        img(:src="imageForModal")
        .columns(v-if="centeredTextForModal")
          .column
          .column.is-half.has-text-centered
            .has-background-white.has-text-black(v-html="centeredTextForModal")
          .column
</template>
<script>
import { mapGetters} from "vuex";
import AppFormHoverImagePreviewWindow from "../../../../plugins/app/form/components/AppFormHoverImagePreviewWindow";

export default {
  props: {
    orderItem: {},
    combinationImage:String
  },
  data() {
    return {
      imageModal:false,
      imageForModal:null,
      centeredTextForModal:null
    }
  },
  components: {AppFormHoverImagePreviewWindow  },
  computed: {
    ...mapGetters("externalShutters", [
      "image"
    ])

  },
  methods: {
    openImage(image, text = null) {
      this.imageForModal = image;
      if (text) {
        this.centeredTextForModal = text;
      } else {
        this.centeredTextForModal = null;
      }
      this.imageModal = true;
    },

  }
};
</script>

<style>
.tile-square{
  width: 100%;
  height: auto;
}
.p-0{
  padding: 0px;
}
</style>
