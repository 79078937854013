<template lang="pug">
  .table-container
    table.table.is-bordered.has-cells-centered.is-fullwidth.is-narrow
      thead
        tr
          th(colspan=12) {{ orderName }}
        tr
          th(rowspan=2) {{ $t("ordinal_number") }}
          th.max-table-col-width-large(rowspan=2) {{ $t("order_list.header_name") }}
          th(rowspan=2) {{ $t("order_list.header_symbol") }}
          th(rowspan=2) {{ $t("order_list.header_unit") }}
          th(rowspan=2) {{ $t("order_list.header_unit_price") }}
          th.max-table-col-width-xsmall(rowspan=2) {{ $t("order_list.header_pieces") }}
          th.max-table-col-width-small(rowspan=2) {{ $t("order_list.header_quantity_in_package") }}
          th.max-table-col-width-xsmall(rowspan=2) {{ $t("order_list.header_unit_short") }}
          th(rowspan=2) {{ $t("order_list.header_sum") }}
          th.has-text-right(rowspan=2 v-if="priceType !== 'sales' && printType === 'full'") {{ $t("net_purchase_value", { currencyCode }) }}
          th.has-text-right(rowspan=2 v-if="priceType !== 'purchase' && printType === 'full'") {{ $t("net_sales_value", { currencyCode }) }}

      tbody
        template(v-for="(item, index) in this.detailOrderItems")
         detail-order-item(
              :item="item"
              :index="index"
              :price-type="priceType"
              :print-type="printType"
            )
      tr
        td(:colspan="printType === 'full' ? 8 : 6" style="border: 0;")
        td(v-if="printType === 'full'") {{ $t('sum_up') }}
        td.has-text-right(v-if="priceType !== 'sales' && printType === 'full'") {{ getDecimalValue(sumValueByParameterInOrderItems("netPurchaseValue"))}}
        td.has-text-right(v-if="priceType !== 'purchase' && printType === 'full'") {{ getDecimalValue(sumValueByParameterInOrderItems("netSalesValue")) }}
</template>
<script>

import { mapGetters } from "vuex";
import DetailOrderItem from "./DetailOrderItem.vue";
import priceFormatter from "../../../../../helpers/priceFormatter";
import decimal from "../../../../../helpers/decimal";

export default {
  components: {DetailOrderItem},
  props:{
    detailOrderItems: {type: Array, default: null, required: true},
    priceType: { default: null, required: true },
    printType: { default: null, required: true }
  },
  computed: {
    ...mapGetters("company", ["currencyCode", "lengthUnit"]),
    ...mapGetters("common", ["formatWeight"]),
    orderName() {
      if (!this.order) {
        return this.$t("order_positions");
      }
      return this.$t("order_positions_for", { name: this.order.name });
    },
  },
  methods: {
    sumValueByParameterInOrderItems: function(parameter)  {
      return this.detailOrderItems.reduce((total, externalShutterOrderItem) => (externalShutterOrderItem[parameter]) + total,0)
    },
    formatPriceValue(value) {
      return priceFormatter(value);
    },
    getDecimalValue(value) {
      return decimal(value);
    },
  }
};
</script>
<style>
.table {
  max-height: 200px;
  overflow-y: auto;
}
</style>
