<template lang="pug">
  div
    app-form(v-on="listeners" v-bind="props" resource="order_reclamations" :transform-form-data-before-save="transformToSubmit" :transform-to-model="transformToModel")
      template(#default="{ data, errors }")
        .columns(v-if="!isCreating")
          .column.is-two-fifths
            app-form-field(:label="$t('status')" field="status")
              b-input(:value="$t(data.status)" disabled)
          .column.is-one-fifth
            app-form-field(:label="$t('order_number')" field="order")
              b-input(:value="data.order" disabled)
              b-button(size="is-small" type="is-primary" @click="showOrder(data.order)" :disabled="disabled(data.order)")
                b-icon(size="is-small" icon="eye")
        b-tabs(type="is-boxed")
          b-tab-item(:label="$t('general')")
            div(v-if="isCreating")
              .columns
                .column
                  app-form-field(:label="$t('reclamation_title')" field="name" :errors="errors" required)
                    b-input(v-model="data.name" placeholder="np.: Uszkodzenie silnika")
                .column
                  app-form-field(:label="$t('order_number')" field="order" :errors="errors" required)
                    b-input(v-model="data.order")
                    b-button(size="is-small" type="is-primary" @click="showOrder(data.order)" :disabled="disabled(data.order)")
                      b-icon(size="is-small" icon="eye")
                .column
                  app-form-field(label="Pliki" field="file" :errors="errors")
                    app-form-file(v-model="files.file" :image="data.files" @removed="data.files = {}" :multiple='true' accept='image/jpeg,image/gif,image/png,application/pdf,image/x-eps')
            .columns(v-if=" object && object.orderReclamationMessages && object.orderReclamationMessages.length > 0")
              .divider
              .column
                .columns(v-for="report in data.orderReclamationMessages")
                  .column
                    .is-right(v-if="report.author.id !== userId")
                      b-message(
                        type="is-success"
                        :title="`${report.author.firstName} ${report.author.lastName}  ${report.createdAt}`"
                        :closable="false"
                      )
                        div(v-html="report.message")

                  .column
                    .is-left(v-if="report.author.id === userId")
                      b-message(
                        type="is-info"
                        :title="`${report.author.firstName} ${report.author.lastName} ${report.createdAt}`"
                        :closable="false"
                      )
                        div(v-html="report.message")
              .divider
            .columns
              .column
                app-form-field(:label="$t('message')" field="newMessage" :errors="errors" )
                  app-form-editor(v-model="data.newMessage")
    OrderPreview(:active="isModalActive" :order-id="orderIdentity" @close="isModalActive=false")
</template>
<script>
import form from "../../../plugins/app/form/mixins/form";
import OrderPreview from "@/modules/common/components/OrderPreview";
import { mapGetters } from "vuex";
import orderNumberCreator from "@/helpers/orderNumberCreator";
import orderNumberFlatter from "@/helpers/orderNumberFlatter";

export default {
  props: ["orderId"],
  components: { OrderPreview },
  mixins: [form],
  data() {
    return {
      isModalActive: false,
      orderIdentity: null,
      model: {
        name: "",
        orderReclamationMessages: [],
        status: "new", //invisible
        file: null,
        newMessage: "",
        createdAt: "", //not procesible
        updatedAt: "", //not procesible
        //TODO z jakiegoś powodu nie chce/może zbindować orderId - trzeba pobierać bezpośrednio z route - tu nie ma problemu
        order: orderNumberCreator(this.$route.params.orderId) || null
      },
      files: {
        file: []
      },

    };
  },
  methods: {
    disabled(id) {
      return id === null || isNaN(id);
    },
    showOrder(orderId) {
      this.orderIdentity = orderId;
      this.isModalActive = true;
    },
    transformToSubmit(formData) {
      delete formData.createdAt;
      delete formData.updatedAt;

      formData.order = `/orders/${orderNumberFlatter(formData.order)}`;

      return formData;
    },
    transformToModel(data) {
      data.newMessage = "";
      data.order = orderNumberCreator(data.order.number);
      return data;
    }
  },
  computed: {
    ...mapGetters("auth", ["userId"]),
    isCreating() {
      return this && this.$route.path.includes("create");
    }
  }
};
</script>
