<template lang="pug">
  .table-container
    table.table.is-bordered.has-cells-centered.is-fullwidth.is-narrow
            thead
                tr
                    th {{ $t("ordinal_number") }}
                    th {{ $t("version") }}
                    th {{ $t("external_shutter.has_mosquito_net") }}
                    th {{ $t("external_shutter.combination_width", {lengthUnit}) }}
                    th {{ $t("external_shutter.global_pipe") }}
                    th {{ $t("external_shutter.combination_runners_height", {lengthUnit}) }}
                    th {{ $t("external_shutter.combination_full_height", {lengthUnit}) }}
                    th {{ $t("external_shutter.external_shutter_box") }}
                    th {{ $t("external_shutter.external_shutter_external_shutter_runner_entry_left") }}
                    th {{ $t("external_shutter.external_shutter_external_shutter_runner_entry_right") }}
                    th {{ $t("external_shutter.external_shutter_external_shutter_runner_entry_drill_hole_left") }}
                    th {{ $t("external_shutter.external_shutter_external_shutter_runner_entry_drill_hole_right") }}
                    th {{ $t("external_shutter.external_shutter_protections") }}
                    th {{ $t("external_shutter.external_shutter_adaptations") }}
                    th {{ $t("drive_type") }}
                    th {{ $t("drive_series") }}
                    th {{ $t("drive_side") }}

                    th {{ $t("external_shutter.combinations") }}
                    th {{ $t('installation_place') }}

                    th {{ $t("external_shutter.weight") }}
                    th {{ $t("pieces") }}
                    th.has-text-right(v-if="!hideWholeSalePrice") {{ $t("net_purchase_value", { currencyCode }) }}
                    th.has-text-right {{ $t("net_sales_value", { currencyCode }) }}
                    th {{ $t("operations") }}
                tr

            tbody
              template(v-for="(item, index) in order.externalShutterOrderItems")
                template(v-for="(itemCombination, indexCombination) in item.externalShutterOrderItemCombinations")
                  external-shutter-order-item(
                      :item="item"
                      :index="index"
                      :itemCombination="itemCombination"
                      :indexCombination="indexCombination"
                  )

            tr
              td(colspan=18 style="border: 0;")
              td {{ $t('sum_up') }}
              td {{ formatWeight(sumValueByParameterInOrderItems("weight")) }}
              td {{ sumValueByParameterInOrderItems("quantity") }}
              td.has-text-right(v-if="!hideWholeSalePrice") {{ getDecimalValue(sumValueByParameterInOrderItems("netPurchaseValue"))}}
              td.has-text-right {{ getDecimalValue(sumValueByParameterInOrderItems("netSalesValue")) }}
</template>
<script>
import ExternalShutterOrderItem from "./ExternalShutterOrderItem";

import { mapGetters } from "vuex";
import decimal from "@/helpers/decimal";
import priceFormatter from "../../../../helpers/priceFormatter";

export default {
  components: { ExternalShutterOrderItem },
  computed: {
    ...mapGetters(["hideWholeSalePrice"]),
    ...mapGetters("company", ["currencyCode", "lengthUnit"]),
    ...mapGetters("externalShutters", ["order", "orderAfterSum"]),
    ...mapGetters("common", ["formatWeight"]),
  },
  methods: {
    sumValueByParameterInOrderItems: function(parameter)  {
      return this.order.externalShutterOrderItems.reduce((total, externalShutterOrderItem) => (externalShutterOrderItem[parameter]) + total,0)
    },
    formatPriceValue(value) {
      return priceFormatter(value);
    },
    getDecimalValue(value) {
      return decimal(value);
    }
  }
};
</script>
<style>
.table {
  max-height: 200px;
  overflow-y: auto;
}
</style>
