<template>
  <tr>
    <td
      v-for="(col, id) in columns"
      :style="getWidthAndPredefinedHeight(col.width)"
      :key="`simple-table-row-td:${id}`"
    >
      <b-field v-if="!isEditing && col.numeric">
        {{ formatPrice(editedObject[col.field]) }}
      </b-field>
      <b-field v-if="!isEditing && col.bool">
        {{ editedObject[col.field] ? $t("yes") : $t("no") }}
      </b-field>
      <b-field v-else-if="!isEditing">
        {{ editedObject[col.field] }}
      </b-field>
      <app-form-decimal
        v-else-if="col.numeric && isEditing"
        v-model="editedObject[col.field]"
      ></app-form-decimal>
      <b-checkbox
        v-else-if="col.bool && isEditing"
        v-model="editedObject[col.field]"
      ></b-checkbox>
      <b-input v-else-if="isEditing" v-model="editedObject[col.field]" />
    </td>
    <td v-if="!isEditing">
      <b-button
        @click="isEditing = true"
        v-if="editable"
        size="is-small"
        type="is-info"
      >
        {{ $t("edit") }}
      </b-button>
      <b-button @click="remove" size="is-small" type="is-danger">
        {{ $t("remove") }}
      </b-button>
    </td>
    <td v-else>
      <b-button
        @click="update"
        v-if="editable"
        size="is-small"
        type="is-primary"
      >
        {{ $t("save") }}
      </b-button>
      <b-button @click="reset" v-if="editable" size="is-small" type="is-danger">
        {{ $t("cancel") }}
      </b-button>
    </td>
  </tr>
</template>

<script>
import { mapGetters } from "vuex";
import http from "../../../http";
import notify from "@/helpers/notify";
import simpleClone from "@/helpers/simpleClone";

export default {
  props: {
    editable: Boolean,
    row: Object,
    columns: Array,
    resource: String,
    canSave: Boolean
  },
  data() {
    return {
      editedObject: simpleClone(this.row),
      isEditing: false
    };
  },
  computed: {
    ...mapGetters("common", ["formatPrice"])
  },
  methods: {
    reset() {
      this.isEditing = false;
      this.editedObject = simpleClone(this.row);
    },
    remove() {
      this.$emit("remove");
    },
    update() {
      const dataToSend = { ...this.editedObject };
      try {
        if (this.canSave) {
          http.put(`/${this.resource}/${this.editedObject["id"]}`, dataToSend);
        }
        this.isEditing = false;
        this.$emit("success", dataToSend);
        notify("Zmieniono element");
      } catch ({ response }) {
        notify("Nie udało się zmienić elementu, wystąpił błąd", "danger");
      }
    },
    getWidthAndPredefinedHeight(colWidth) {
      return {
        width: colWidth,
        maxWidth: colWidth,
        height: "45px"
      };
    }
  }
};
</script>
