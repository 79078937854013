<template lang="pug" functional>
  b-field(:label="props.label")
    b-select(
      @input="props.filter({ field: props.field, value: $event })"
      :value="props.filters[props.field]"
      :placeholder="internalPlaceholder"
    )
      slot
</template>
<script>
import filterMixin from "../mixins/filterMixin";

export default {
  mixins: [filterMixin],
  props: {
    placeholder: {type: String}
  },
  computed: {
    internalPlaceholder() {
      return this.placeholder || this.label.toLowerCase() + "...";
    }
  }
};
</script>
