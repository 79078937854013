import router from "./../../router";
import CreateOrder from "./views/CreateOrder";
import store from "../../store";
import fabricShades from "../fabric_shades/store";

store.registerModule("fabricShades", fabricShades);

router.addRoutes([
  {
    path: "/fabric_shades/create_order/:copyId?",
    name: "fabric_shades_create_order",
    component: CreateOrder,
    props:true
  }
]);

router.addRoutes([
  {
    path: "/fabric_shades/edit_order/:id",
    name: "fabric_shades_edit_order",
    component: CreateOrder,
    props: true
  }
]);
