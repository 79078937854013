<template lang="pug">
  .card.has-margin-bottom-small(:class="{'highlighted': displayMarkAsRead}")
    header.card-header
      p.card-header-title {{ title }}
      .card-header-icon {{ date }}
    .card-content
      .columns
        .column.is-narrow(v-if="image")
          img.has-pointer(:src="image" width="200" @click="$emit('image', image)")
        .column.is-narrow(v-if="second")
          img.has-pointer(:src="second" width="200" @click="$emit('image', second)")
        .column
          .content(v-html="content")
    footer.card-footer
      .card-footer-item(style="justify-content: flex-end")
          b-checkbox(v-if="displayCheckboxAsRead" v-model="checked" :loading="marking") {{ $t('news.agree_with_news') }}
          b-button(v-if="displayMarkAsRead" :disabled="!checked && displayCheckboxAsRead" @click.prevent="markAsRead" :loading="marking") {{ $t('news.mark_as_read') }}
          span(v-if="displayDate") {{ $t('news.read_at') }} {{ readDate }}
      //a.card-footer-item {{ $t('news.copy_as_mine') }}

</template>
<script>
import moment from "moment";
import http from "../../../http";
import { mapGetters } from "vuex";

export default {
  props: {
    news: Object
  },
  data() {
    return {
      marking: false,
      checked: false
    }
  },
  computed: {
    ...mapGetters("company", [
      "companyId",
    ]),
    displayCheckboxAsRead() {
      return this.news.priority === "zgoda"  && !this.read;
    },
    displayMarkAsRead() {
      return (this.news.priority === "sredni" || this.news.priority === "wysoki" || this.news.priority === "zgoda") && !this.read;
    },
    displayDate() {
      return !!this.read;
    },
    readDate() {
      return this.read.date
    },
    read() {
      if(!this.news.newsReads) return false;
      return this.news.newsReads.find(read => {
        return read.company?.id == this.companyId || read.subCompany?.id == this.companyId;
      })
    },
    image() {
      if (this.news.picture && this.news.picture.contentUrl) {
        return this.news.picture.contentUrl;
      }
      return null;
    },
    second() {
      if (this.news.secondPicture && this.news.secondPicture.contentUrl) {
        return this.news.secondPicture.contentUrl;
      }
      return null;
    },
    suffix() {
      return this.$i18n.locale.toUpperCase();
    },
    title() {
      return this.news[`title${this.suffix}`];
    },
    date() {
      return moment(this.news.createdAt, "DD-MM-YYYY h:mm:ss", "pl").format('LLLL');
    },
    content() {
      return this.news[`content${this.suffix}`];
    }
  },
  methods: {
    hasPriority(name) {
      return this.news.priority === name;
    },
    async markAsRead() {
      this.marking = true
      let { data } = await http.put(`/news/${this.news.id}/mark_as_read`, {});
      this.marking = false;
      this.$emit('input', data);
    }
  }
}
</script>
