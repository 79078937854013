import http from "../../../http";

export default {
  postMediaObject(media) {
    let fd = new FormData();
    fd.append("file", media);
    return http.post("/media_objects", fd, {
      headers: { "Content-Type": "multipart/form-data" }
    });
  }
};
