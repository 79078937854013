<template lang="pug">
    .table-container
        table.table.is-bordered.has-cells-centered.is-fullwidth.is-narrow
            thead
                tr
                    th(rowspan=2) {{ $t("ordinal_number") }}
                    th(rowspan=2) {{ $t('installation_place') }}
                    th(rowspan=2) {{ $t("version") }}
                    th(rowspan=2) {{ $t("accessory_frame_color") }}
                    th(rowspan=2) {{ $t("mosquito_net.global_net") }}
                    th(rowspan=2) {{ $t("mosquito_net.global_clip") }}
                    th(rowspan=2) {{ $t("mosquito_net.gasket") }}
                    th(rowspan=2) {{ $t("width", { lengthUnit }) }}
                    th(rowspan=2) {{ $t("height", { lengthUnit }) }}
                    th(rowspan=2) {{ $t("pieces") }}
                    th(rowspan=2) {{ $t("is_bar") }}
                    th(rowspan=2) {{ $t("additional") }}
                    th.has-text-right(rowspan=2 v-if="!hideWholeSalePrice") {{ $t("net_purchase_value", { currencyCode }) }}
                    th.has-text-right(rowspan=2) {{ $t("net_sales_value", { currencyCode }) }}
                    th(rowspan=2) {{ $t("operations") }}

            tbody
                frame-mosquito-net-order-item(
                    v-for="(item, index) in order.frameMosquitoNetOrderItems"
                    :item="item"
                    :index="index"
                    :key ="index"
                )
                tr
                  td(colspan=11 style="border: 0;")
                  td {{ $t('sum_up') }}
                  td.has-text-right(v-if="!hideWholeSalePrice") {{ formatPriceValue(getDecimalValue(order.netPurchaseValueBeforeDiscount)) }}
                  td.has-text-right {{ formatPriceValue(getDecimalValue(order.netSalesValue)) }}
</template>
<script>
import { mapGetters } from "vuex";
import decimal from "@/helpers/decimal";
import priceFormatter from "../../../../helpers/priceFormatter";
import FrameMosquitoNetOrderItem from "@/modules/frame_mosquito_net/views/components/FrameMosquitoNetOrderItem.vue";

export default {
  components: {FrameMosquitoNetOrderItem},
  computed: {
    ...mapGetters(["hideWholeSalePrice"]),
    ...mapGetters("company", ["currencyCode", "lengthUnit"]),
    ...mapGetters("frameMosquitoNets", [
      "order",
    ])
  },
  methods: {
    formatPriceValue(value) {
      return priceFormatter(value);
    },
    getDecimalValue(value) {
      return decimal(value);
    }
  }
};
</script>
