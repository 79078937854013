<template lang="pug">
  b-modal(v-model="isActive" @close="onClose")
    app-view-loadable(:loading="loading")
      app-view-card(:title="$t('order_preview')")
        template(slot="icon")
          b-button(
            @click="onClose"
          )
            b-icon(icon="close" size="is-small")
        .is-centered(v-if="!order || (order && orderItems.length < 1)") {{ $t('empty_data') }}
        template(v-else)
          table.table.is-fullwidth.is-bordered
            thead
              tr
                th(colspan=13) {{ orderName }}
              tr
                th(rowspan=2) {{ $t('order_preview_props.ordinal_number') }}
                th(rowspan=2) {{ $t('order_preview_props.group') }}
                th(rowspan=2) {{ $t('order_preview_props.version') }}
                th(rowspan=2) {{ $t('order_preview_props.fabric') }}
                th(rowspan=2) {{ $t('order_preview_props.color') }}
                th(rowspan=2) {{ $t('order_preview_props.accessory') }}
                th(rowspan=2) {{ $t('order_preview_props.balk') }}
                th(rowspan=2) {{ $t('order_preview_props.fixing') }}
                th(rowspan=2) {{ $t('order_preview_props.control') }}
                th(rowspan=2) {{ $t('order_preview_props.drive') }}
                th(rowspan=2) {{ $t('order_preview_props.runner') }}
                th(rowspan=2) {{ $t('order_preview_props.quantity') }}
                th(rowspan=2) {{ $t('order_preview_props.item_net_purchase_value') }}

            tbody
              tr(v-for="(item, index) in orderItems")
                td {{ index + 1 }}
                td(v-if="item.fabricShadeGroup") {{ prop(item.fabricShadeGroup, 'position') }}
                td(v-if="item.fabricShadeDayNightGroup") {{ prop(item.fabricShadeDayNightGroup, 'name') }}
                td(v-if="item.fabricShade") {{ prop(item.fabricShade, 'name') }}
                td(v-if="item.fabricShadeDayNight") {{ prop(item.fabricShadeDayNight, 'name') }}
                td(v-if="item.fabric") {{ prop(item.fabric, 'name') }}
                td(v-if="item.fabricDayNight") {{ prop(item.fabricDayNight, 'name') }}
                td(v-if="item.fabricColor") {{ prop(item.fabricColor, 'name') }}
                td(v-if="item.fabricColorDayNight") {{ prop(item.fabricColorDayNight, 'name') }}
                td(v-if="item.fabricShadeGlobalAccessoryEntry") {{ prop(item.fabricShadeGlobalAccessoryEntry, 'name', 'globalAccessory') }}
                td(v-if="item.fabricShadeDayNightGlobalAccessoryEntry") {{ prop(item.fabricShadeDayNightGlobalAccessoryEntry, 'name', 'globalAccessory') }}
                td(v-if="item.fabricShadeGlobalBalkEntry") {{ prop(item.fabricShadeGlobalBalkEntry, 'name', 'globalBalk') }}
                td(v-if="item.fabricShadeDayNightGlobalBalkEntry") {{ prop(item.fabricShadeDayNightGlobalBalkEntry, 'name', 'globalBalk') }}
                td(v-if="item.fabricShadeGlobalFixingEntry") {{ prop(item.fabricShadeGlobalFixingEntry, 'name', 'globalFixing') }}
                td(v-if="item.fabricShadeDayNightGlobalFixingEntry") {{ prop(item.fabricShadeDayNightGlobalFixingEntry, 'name', 'globalFixing') }}

                //td {{ prop(item.globalDriveControl, 'name') }}
                td {{ prop(item.globalDrive, 'name') }}
                td(v-if="item.fabricShadeGlobalRunnerEntry") {{ prop(item.fabricShadeGlobalRunnerEntry, 'name', 'globalRunner') }}
                td(v-if="item.fabricShadeDayNightGlobalRunnerEntry") {{ prop(item.fabricShadeDayNightGlobalRunnerEntry, 'name', 'globalRunner') }}
                td {{ prop(item, 'quantity') }}
                td {{ getDecimalValue(prop(item, 'netPurchaseValue')) }}
          br
          .columns
            .column
            .column
              table.table.is-bordered.is-fullwidth
                thead
                  tr
                    th(colspan=2) {{ $t('order_preview_props.order_info') }}
                tbody.print-width-250.real-width-tables-td-250
                  tr
                    th {{ $t('order_preview_props.order_number') }}
                    td {{ orderNumber }}
                  tr
                    th {{ $t('order_preview_props.purchaser') }} ({{ $t(order.customer.type) }})
                    td(v-if="order.customer.type === 'person'") {{`${prop(order.customer,"firstName")} ${prop(order.customer, "lastName")}` }}
                    td(v-else) {{ `${prop(order.customer, 'taxId')} ${prop(order.customer, 'companyName')}` }}
                  tr
                    th {{ $t('order_preview_props.net_purchase_value') }}
                    td {{ getDecimalValue(prop(order, "netPurchaseValue")) }}
            .column
</template>

<script>
import decimal from "@/helpers/decimal";
import http from "@/http";
import notify from "@/helpers/notify";
import generateProperties from "@/helpers/generateProperties";
import t from "@/i18n";
import orderNumberCreator from "@/helpers/orderNumberCreator";

function debounce(func, wait, immediate) {
  let timeout;
  return function () {
    const context = this,
        args = arguments;
    const later = function () {
      timeout = null;
      if (!immediate) func.apply(context, args);
    };
    const callNow = immediate && !timeout;
    clearTimeout(timeout);
    timeout = setTimeout(later, wait);
    if (callNow) func.apply(context, args);
  };
}

export default {
  props: {
    active: {type: Boolean, default: false, required: true},
    orderId: {default: null, required: true}
  },
  data() {
    return {
      order: null,
      isActive: this.active,
      loading: false
    };
  },
  methods: {
    downloadOrder: debounce(async function (activator, value) {
      if (
          !this.order ||
          (this.order && this.order.id !== parseInt(this.orderId))
      ) {
        try {
          this.loading = true;
          let {data} = await http.get(
              `/orders/${this.orderId}?properties[]=externalShutterOrderItems&
               properties[]=automationOrderItems&`
              +
              generateProperties(
                  [],
                  ["id", "@id", "name", "category", "number", "netPurchaseValue"]) +
              generateProperties(
                  ["customer"],
                  ["id", "type", "firstName", "lastName", "taxId", "companyName"],
                  true) +
              generateProperties(
                  ["fabricShadeOrderItems"],
                  ["id", "@id", "quantity", "netPurchaseValue"],
                  true) +
              generateProperties(
                  ["fabricShadeOrderItems", "fabricShadeGroup"],
                  ["id", "@id", "name"],
                  true) +
              generateProperties(
                  ["fabricShadeOrderItems", "fabricShade"],
                  ["id", "@id", "name"],
                  true) +
              generateProperties(
                  ["fabricShadeOrderItems", "fabric"],
                  ["id", "@id", "name"],
                  true) +
              generateProperties(
                  ["fabricShadeOrderItems", "fabricColor"],
                  ["id", "@id", "name"],
                  true) +
              generateProperties(
                  ["fabricShadeOrderItems", "fabricShadeGroup"],
                  ["id", "@id", "name", "position"],
                  true) +
              generateProperties(
                  ["fabricShadeOrderItems", "fabricShadeGlobalAccessoryEntry", "globalAccessory"],
                  ["id", "name"],
                  true) +
              generateProperties(
                  ["fabricShadeOrderItems", "fabricShadeGlobalBalkEntry",
                    "globalBalk"], ["id", "name"],
                  true) +
              generateProperties(
                  ["fabricShadeOrderItems", "fabricShadeGlobalFixingEntry",
                    "globalFixing"], ["id", "name"],
                  true) +
              generateProperties(
                  ["fabricShadeOrderItems", "fabricShadeGlobalRunnerEntry",
                    "globalRunner"], ["id", "name"],
                  true) +
              generateProperties(
                  ["fabricShadeOrderItems", "fabricShadeFabricShadeAdditionalEntries"],
                  ["isEnabled"],
                  true
              ) +
              generateProperties(
                  ["fabricShadeOrderItems", "fabricShadeFabricShadeAdditionalEntries", "fabricShadeAdditional"],
                  ["id", "name", "picture", "thumbnail", "message"],
                  true
              ) +
              generateProperties(
                  ["fabricShadeOrderItems", "globalDrive"],
                  ["id", "name"],
                  true) +
              generateProperties(
                  ["fabricShadeOrderItems", "globalDriveControl"],
                  ["id", "name"],
                  true) +
              generateProperties(
                  ["fabricShadeDayNightOrderItems"],
                  ["id", "quantity", "netPurchaseValue"],
                  true) +
              generateProperties(
                  ["fabricShadeDayNightOrderItems", "fabricShadeDayNightGroup"],
                  ["id", "name", "position"],
                  true) +
              generateProperties(
                  ["fabricShadeDayNightOrderItems", "fabricShadeDayNight"],
                  ["id", "name"],
                  true) +
              generateProperties(
                  ["fabricShadeDayNightOrderItems", "fabricDayNight"],
                  ["id", "name"],
                  true) +
              generateProperties(
                  ["fabricShadeDayNightOrderItems", "fabricColorDayNight"],
                  ["id", "name"],
                  true) +
              generateProperties(
                  ["fabricShadeDayNightOrderItems", "fabricShadeDayNightGroup"],
                  ["id", "name"],
                  true) +
              generateProperties(
                  ["fabricShadeDayNightOrderItems", "fabricShadeDayNightGlobalAccessoryEntry", "globalAccessory"],
                  ["id", "name"],
                  true) +
              generateProperties(
                  ["fabricShadeDayNightOrderItems", "fabricShadeDayNightGlobalBalkEntry",
                    "globalBalk"], ["id", "name"],
                  true) +
              generateProperties(
                  ["fabricShadeDayNightOrderItems", "fabricShadeDayNightGlobalFixingEntry",
                    "globalFixing"], ["id", "name"],
                  true) +
              generateProperties(
                  ["fabricShadeDayNightOrderItems", "fabricShadeDayNightGlobalRunnerEntry",
                    "globalRunner"], ["id", "name"],
                  true) +
              generateProperties(
                  ["fabricShadeDayNightOrderItems", "fabricShadeDayNightFabricShadeDayNightAdditionalEntries"],
                  ["isEnabled"],
                  true
              ) +
              generateProperties(
                  ["fabricShadeDayNightOrderItems", "fabricShadeDayNightFabricShadeDayNightAdditionalEntries", "fabricShadeDayNightAdditional"],
                  ["id", "name", "picture", "thumbnail", "message"],
                  true
              ) +
              generateProperties(
                  ["fabricShadeDayNightOrderItems", "globalDrive"],
                  ["id", "name"],
                  true) +
              generateProperties(
                  ["fabricShadeDayNightOrderItems", "globalDriveControl"],
                  ["id", "name"],
                  true)
          );
          this.order = data;
          if (activator === "active") {
            if (this.isActive !== !!value) {
              this.isActive = !!value;
            }
          }
        } catch (err) {
          this.order = null;
          notify(
              "Nie udało się załadować danych do podglądu zamówienia",
              "danger"
          );
          this.$emit("close");
        } finally {
          this.loading = false;
        }
      } else if (activator === "active") {
        if (this.isActive !== !!value) {
          this.isActive = !!value;
        }
      }
    }, 100),
    onClose() {
      this.isActive = false;
      this.$emit("close");
    },
    prop(object, field, extraField = null) {
      if (extraField) {
        return (object && object[extraField][field]) || t.t("empty");
      }
      return (object && object[field]) || t.t("empty");
    },
    getDecimalValue(value) {
      return decimal(value);
    }
  },
  computed: {
    orderItems() {
      if (!this.order) {
        return [];
      }
      if (this.order && this.order.category === "fabric_shade") {
        return this.order.fabricShadeOrderItems;
      }
      if (this.order && this.order.category === "fabric_shade_day_night") {
        return this.order.fabricShadeDayNightOrderItems;
      }
      if (this.order && this.order.category === "external_shutter") {
        return this.order.externalShutterOrderItem;
      }

      return [];
    },
    orderName() {
      if (!this.order) {
        return this.$t("order_positions");
      }
      return this.$t("order_positions_for", {name: this.order.name});
    },
    orderNumber() {
      if (this.order && this.order.number) {
        return orderNumberCreator(this.order.number);
      } else {
        return "";
      }
    }
  },
  watch: {
    orderId(value) {
      if (value && !isNaN(value) && this.active) {
        this.downloadOrder("id");
      }
    },
    active(value) {
      if (value && !isNaN(value) && this.active && value) {
        this.downloadOrder("active", value);
      }
    }
  }
};
</script>
