const localStorageFuncs = {
    supportsLocalStorage: function () {
        try {
            localStorage.setItem('_', '_');
            localStorage.removeItem('_');

            return true;
        } catch (e) {
            return false;
        }
    },

    /**
     * Check if key exists in local storage
     * @param  string key
     * @return boolean
     */
localStorageHas(key)
{
    var item = localStorage.getItem(key);

    return (item !== null);
},

/**
 * Retrive an object from local storage.
 * @param  string key
 * @return mixed
 */
localStorageGet(key) {
    var item = localStorage.getItem(key);

    if (!item) return;

    if (item[0] === '{' || item[0] === '[') return JSON.parse(item);

    return item;
},

/**
 * Save some value to local storage.
 * @param string key
 * @param string value
 */
localStorageSave(key, value) {
    if (value === undefined) return null

    if (!this.supportsLocalStorage() || typeof value === 'undefined' || key === null || value === null) {
        return false;
    }

    try {
        localStorage.setItem(key, JSON.stringify(value));
        return true;
    } catch (e) {
        return false;
    }
},

/**
 * Remove element from local storage.
 * @param string key
 */
localStorageRemove(key) {
    localStorage.removeItem(key);
}
}

export default localStorageFuncs;



