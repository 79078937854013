<template lang="pug">
    tr(:class="classes")
        // liczba porządkowa
        td
          div
            .th-in {{ (index + 1) }}
        //miejsce montazu/krotki opis
        td {{ item.shortDescription }}
        // nazwa grupy
        td
          p {{ property(item.pleatGroup, "position") }}
          p(v-if="item.pleatGroupBottom") {{ property(item.pleatGroupBottom, "position") }}
        // nazwa wersji
        td {{ property(item.pleat, "name") }}
        // nazwa kolekcji (tkanina)
        td
          p {{ property(item.pleatFabric, "name") }}
          p(v-if="item.pleatFabricBottom") {{ property(item.pleatFabricBottom, "name") }}
        // nazwa tkaniny (kolor tkaniny)
        td
          p {{ property(item.pleatFabricColor, "name") }}
          p(v-if="item.pleatFabricColorBottom") {{ property(item.pleatFabricColorBottom, "name") }}
        // szerokość całkowita
        td {{ formatLength(item.width) }}

        // wysokość        //// szerokość materiału
                           //td {{ formatLength(item.fabricWidth) }}
                           //// szerokość szyby (opcjonalnie)
                           //td {{ formatLength(item.glassWidth) ? formatLength(item.glassWidth) : "-" }}
        td {{ formatLength(item.height) }}
        //// szerokość szyby (opcjonalnie)
        //td {{ formatLength(item.glassHeight) ? formatLength(item.glassHeight) : "-" }}
        // ilość szt.
        td {{ item.quantity }}
        // kasetka
        td {{ property(item.pleatGlobalAccessoryEntry, "name", "globalAccessory") }}
        //// belka dolna
        //td {{ property(item.pleatGlobalBalkEntry, "name", "globalBalk") }}
        //// strona napędu
        //td {{ item.driveSide }}
        // prowadnice
        td {{ property(item.pleatGlobalRunnerEntry, "name", "globalRunner") }}
        // dodatkowy naciąg
        td {{ property(item.pleatAdditionalString, "symbol", "globalRunner") }}
        // mocowanie
        td {{ property(item.pleatPleatFixingTypeEntry, "name", "pleatFixingType") }}
        // sterowanie
        td {{ property(item.pleatPleatRemoteControlTypeEntry, "name", "pleatRemoteControlType") }}
        // obrócenie tkaniny
        //td {{ formatBoolean(item.hasTurnableFabric) }}
        // Dodatki
        td
          p(v-if="!item.pleatPleatAdditionalEntries || !item.pleatPleatAdditionalEntries.length") {{$t('empty')}}
          p(v-for="(itemAdditional) in item.pleatPleatAdditionalEntries") {{ property(itemAdditional, "name", "pleatAdditional") }}
        //// cena zakupu
        td.has-text-right(v-if="!hideWholeSalePrice") {{ formatPriceValue(formatPrice(item.netPurchaseValue)) }}
        // cena sprzedaży
        td.has-text-right {{ formatPriceValue(formatPrice(item.netSalesValue)) }}
        td
            .field.has-addons
                p.control
                    b-button(
                      @click.prevent="editItem({index, item})"
                      type="is-info"
                      size="is-small"
                      icon-left="pencil"
                    )
                p.control
                    b-button(
                      @click.prevent="remove"
                      type="is-danger"
                      icon-left="delete"
                      size="is-small"
                    )
</template>
<script>
import { mapGetters, mapActions, mapMutations } from "vuex";
import priceFormatter from "../../../../helpers/priceFormatter";

export default {
  props: {
    index: Number,
    item: Object
  },
  methods: {
    ...mapActions("pleats", ["removeItem"]),
    ...mapMutations("pleats", ["editItem"]),
    formatPriceValue(value) {
      return priceFormatter(value);
    },
    remove() {
      return this.$buefy.dialog.confirm({
        message: this.$t("remove_position_confirm"),
        onConfirm: () => {
          this.removeItem(this.index);
        }
      });
    },
    property(object, field, extraField = null) {
      if (extraField) {
        return object && object[extraField][field];
      }
      return object && object[field];
    }
  },
  computed: {
    classes() {
      return {
        "is-editing": this.editIndex === this.index
      };
    },
    ...mapGetters(["hideWholeSalePrice"]),
    ...mapGetters("pleats", ["editIndex"]),
    ...mapGetters("common", [
      "formatLength",
      "formatNumber",
      "formatBoolean",
      "formatPrice"
    ])
  }
};
</script>

<style scoped>
td {
  vertical-align: middle;
  text-align: center;
}
</style>
