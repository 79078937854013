<template lang="pug">
    .has-margin-top-small
        .panel
            .panel-heading
                span
                    b-icon(icon="filter" size="is-small")
                    | Filtry
                b-button(size="is-small" class="is-pulled-right" icon-left="filter-remove" @click.prevent="clearFilters")
                    | Usuń fltry
            .panel-block
                b-field(grouped)
                    slot(name="filters" :filter="filter" :filters="filters")
        .is-table-responsive
            table.table.is-fullwidth.is-striped.is-hoverable(:class="classes")
                thead
                    tr
                        th(v-if="sortable" title="Przeciągnij i upuść by zmienić kolejność")
                            b-icon(icon="reorder-horizontal")
                        slot(name="headers" :sort="sort" :is-sorted="isSorted" :is-asc="isAsc" :is-desc="isDesc")
                        th Operacje
                draggable(:value="list" tag="tbody" handle=".handle" :disabled="!sortable" @change="change" v-show="!loading")
                    tr(v-for="row in list" :key="row.id")
                        td(v-if="sortable" title="Przeciągnij by zmienić kolejność")
                            b-icon(class="handle" icon="reorder-horizontal" ref="handle" :style="{ cursor: 'move' }")
                        slot(:row="row" :reload="fetch")
                        td
                            .buttons.has-addons
                                slot(name="actions" :row="row" :reload="fetch")
            div(v-show="isEmpty && !loading" class="has-text-centered") Brak danych
            .is-relative(:class="classes")
                b-loading(:active="loading" :is-full-page="false")
            .is-clearfix
                .is-pulled-right
                    a.button(:disabled="!this.hasPrevPage" @click.prevent="prev") Poprzednia
                    a.button(:disabled="!this.hasNextPage" @click.prevent="next") Następna
</template>

<script>
import draggable from "vuedraggable";

export default {
  components: {
    draggable
  },
  props: {
    resource: { type: String },
    url: { type: String },
    sortable: Boolean,
    properties: { type: Array }
  },
  data() {
    return {
      sortField: "position",
      sortOrder: "asc",
      total: 0,
      list: [],
      perPage: 30,
      loading: false
    };
  },
  methods: {
    isSorted(field) {
      const queryField = this.$route.query.sortField;
      return queryField && queryField === field;
    },
    async fetch(actionAfterDelete = null, object = null) {
      this.loading = true;
      const url = this.url ? this.url : this.resource;
      let { data } = await this.$http.get(url, {
        params: this.params
      });
      this.list = data["hydra:member"];
      this.total = data["hydra:totalItems"];
      this.loading = false;
      if (actionAfterDelete && object) {
        const vm = this;
        actionAfterDelete(vm, object);
      }
    },
    async change({ moved }) {
      if (this.sortable) {
        await this.move({
          id: moved.element.id,
          position: moved.newIndex
        });
      }
    },
    sort({ sortable, field }) {
      if (sortable) {
        this.router.push({
          query: {
            ...this.$route.query,
            sortField: field,
            sortOrder: this.$route.query.sortOrder === "desc" ? "asc" : "desc"
          }
        });
      }
    },
    clearFilters() {
      const query = { ...this.$route.query };
      this.$router.push({
        query: {
          sortField: query.sortField || undefined,
          sortOrder: query.sortOrder || undefined
        }
      });
    },
    filter({ field, value }) {
      this.$router.push({
        query: {
          ...this.$route.query,
          [field]: value || undefined
        }
      });
    },
    async move({ id, position }) {
      try {
        this.loading = true;
        await this.$http.put(`${this.resource}/${id}`, {
          position
        });
        this.$notify("Zmieniono kolejność");
        this.fetch();
      } catch (e) {
        this.$notify(e.message, "danger");
      }
    },
    next() {
      if (this.hasNextPage) {
        this.$router.push({
          query: {
            ...this.$route.query,
            page: this.currentPage + 1
          }
        });
      }
    },
    prev() {
      if (this.hasPrevPage) {
        this.$router.push({
          query: {
            ...this.$route.query,
            page: this.currentPage - 1
          }
        });
      }
    }
  },
  computed: {
    params() {
      const query = { ...this.$route.query };
      let sortField = `order[${query.sortField || this.sortField}]`;
      return {
        ...query,
        [sortField]: query.sortOrder || this.sortOrder,
        page: query.page,
        properties: this.properties || undefined
      };
    },
    filters() {
      let query = { ...this.$route.query };
      delete query.page;
      delete query.sortField;
      delete query.sortOrder;
      return query;
    },
    classes() {
      return {
        "is-taller": this.loading
      };
    },
    isEmpty() {
      return this.list.length === 0;
    },
    isAsc() {
      return this.$route.query.sortOrder === "asc";
    },
    isDesc() {
      return this.$route.query.sortOrder === "desc";
    },
    currentPage() {
      return this.$route.query.page || 1;
    },
    hasPrevPage() {
      return this.currentPage > 1;
    },
    hasNextPage() {
      const lastPage = Math.ceil(this.total / this.perPage);
      return this.currentPage < lastPage;
    }
  },
  watch: {
    "$store.state.route.query": "fetch",
    sortable(sortable) {
      if (sortable) {
        if (!this.resource) {
          throw new Error("Dla sortowalnych list wymagany jest prop resource");
        }
      }
    }
  },
  created() {
    this.fetch();
  }
};
</script>
<style>
.sortable {
  cursor: pointer;
}
.is-taller {
  height: 100px;
}
.is-table-responsive {
  overflow-x: auto;
}
</style>
