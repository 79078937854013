import CompanyWorkerForm from "../components/CompanyWorkerForm";
import t from "@/i18n";

export default {
  list: {
    hasPagination: false,
    permissions: ["company_worker_read"],
    actions: [
      {
        label: "Nowy pracownik",
        type: "is-primary",
        route: () => ({
          name: "company_workers_create"
        }),
        permissions: ["fabric_shade_create"]
      }
    ],
    route: {
      path: "/company_workers/list",
      name: "company_workers_list"
    },
    breadcrumbs: [
      {
        label: () => "Pracownicy"
      }
    ],
    fields: [
      {
        field: "lastName",
        type: "text",
        label: "Nazwisko",
        filter: "text"
      },
      {
        field: "firstName",
        type: "text",
        label: "Imię",
        filter: "text"
      },
      { field: "username", type: "text", label: "Login", filter: "text" },
      { field: "phone", type: "text", label: "Telefon"},
      { field: "mobilePhone", type: "text", label: "Telefon komórkowy"},
      { field: "memberships", type: "text", label: "Role",
        format(value) {
          let rolesArray = "";
          if (value.length === 0) return '';

          rolesArray = value.map((it) => t.t(it.name)).join(', ')

          return rolesArray;
        }
      },
      { field: "email", type: "text", label: "E-mail", filter: "text" },
      {
        field: "isEnabled",
        type: "boolean",
        label: "Aktywny",
        filter: "boolean"
      }
    ],
    resource: "company_workers",
    url: () =>
      `/company_workers?properties[]=id&properties[]=firstName&properties[]=lastName&properties[]=username&properties[]=email&properties[]=isEnabled&properties[]=mobilePhone&properties[]=phone&properties[]=roles&properties[]=memberships`,
    rowActions: [
      {
        action: "edit",
        permissions: ["company_worker_update"]
      },
      {
        action: "remove",
        permissions: ["company_worker_delete"]
      }
    ]
  },
  create: {
    permissions: ["company_worker_create"],
    route: {
      path: "/company_workers/create",
      name: "company_workers_create"
    },
    breadcrumbs: [
      {
        route: () => ({ name: "company_workers_list" }),
        label: () => "Pracownicy"
      },
      {
        label: () => `Nowy pracownik`
      }
    ],
    formComponent: CompanyWorkerForm,
    listRoute: () => ({
      name: "company_workers_list"
    }),
    updateRoute: ({ object }) => ({
      name: "company_workers_update",
      params: { id: object.id }
    })
  },
  update: {
    permissions: ["company_worker_update"],
    route: {
      path: "/company-workers/:id/update",
      name: "company_workers_update"
    },
    resource: "company_workers",
    breadcrumbs: [
      {
        route: () => ({ name: "company_workers_list" }),
        label: () => "Pracownicy"
      },
      {
        label: ({ compData }) =>
          `Edycja pracownika "${compData.object.username}"`
      }
    ],
    formComponent: CompanyWorkerForm,
    listRoute: () => ({ name: "company_workers_list" })
  }
};
