<template lang="pug">
    tr(:class="classes")
        // liczba porządkowa
        td
          div
            .th-in {{ (index + 1) }}
            .is-red {{ item.hasOversize ? "*" : ""}}
        //miejsce montazu/krotki opis
        td {{ item.shortDescription }}
        // nazwa grupy
        td {{ property(item.fabricShadeGroup, "position") }}
        // nazwa wersji
        td {{ property(item.fabricShade, "name") }}
        // nazwa kolekcji (tkanina)
        td {{ property(item.fabric, "name") }}
        // nazwa tkaniny (kolor tkaniny)
        td {{ property(item.fabricColor, "name") }}
        // szerokość całkowita
        td {{ formatLength(item.width) }}
        // szerokość materiału
        td {{ formatLength(item.fabricWidth) }}
        // szerokość szyby (opcjonalnie)
        td {{ formatLength(item.glassWidth) ? formatLength(item.glassWidth) : "-" }}
        // wysokość
        td {{ formatLength(item.height) }}
        // szerokość szyby (opcjonalnie)
        td {{ formatLength(item.glassHeight) ? formatLength(item.glassHeight) : "-" }}
        // ilość szt.
        td {{ item.quantity }}
        // kasetka
        td {{ property(item.fabricShadeGlobalAccessoryEntry, "name", "globalAccessory") }}
        // belka dolna
        td {{ property(item.fabricShadeGlobalBalkEntry, "name", "globalBalk") }}
        // strona napędu
        td {{ item.driveSide }}
        // prowadnice
        td {{ property(item.fabricShadeGlobalRunnerEntry, "name", "globalRunner") }}
        // mocowanie
        td {{ property(item.fabricShadeGlobalFixingEntry, "name", "globalFixing") }}
        // napęd
        td {{ property(item.globalDrive, "name") }}
        // obrócenie tkaniny
        td {{ formatBoolean(item.hasTurnableFabric) }}
        // Dodatki
        td
          p(v-if="!item.fabricShadeFabricShadeAdditionalEntries.length") {{$t('empty')}}
          p(v-for="(itemAdditional) in item.fabricShadeFabricShadeAdditionalEntries") {{ property(itemAdditional, "name", "fabricShadeAdditional") }}
        // cena zakupu
        td.has-text-right(v-if="!hideWholeSalePrice") {{ formatPriceValue(formatPrice(item.netPurchaseValue)) }}
        // cena sprzedaży
        td.has-text-right {{ formatPriceValue(formatPrice(item.netSalesValue)) }}
        td
            .field.has-addons
                p.control
                    b-button(
                      @click.prevent="editItem({index, item})"
                      type="is-info"
                      size="is-small"
                      icon-left="pencil"
                    )
                p.control
                    b-button(
                      @click.prevent="remove"
                      type="is-danger"
                      icon-left="delete"
                      size="is-small"
                    )
</template>
<script>
import { mapGetters, mapActions, mapMutations } from "vuex";
import priceFormatter from "../../../../helpers/priceFormatter";

export default {
  props: {
    index: Number,
    item: Object
  },
  methods: {
    ...mapActions("fabricShades", ["removeItem"]),
    ...mapMutations("fabricShades", ["editItem"]),
    formatPriceValue(value) {
      return priceFormatter(value);
    },
    remove() {
      return this.$buefy.dialog.confirm({
        message: this.$t("remove_position_confirm"),
        onConfirm: () => {
          this.removeItem(this.index);
        }
      });
    },
    property(object, field, extraField = null) {
      if (extraField) {
        return object && object[extraField][field];
      }
      return object && object[field];
    }
  },
  computed: {
    classes() {
      return {
        "is-editing": this.editIndex === this.index
      };
    },
    ...mapGetters(["hideWholeSalePrice"]),
    ...mapGetters("fabricShades", ["editIndex"]),
    ...mapGetters("common", [
      "formatLength",
      "formatNumber",
      "formatBoolean",
      "formatPrice"
    ])
  }
};
</script>
