<template lang="pug">
  div
    b-checkbox(@input="onSelectAll" v-model="all") {{ selectAllLabel }}
    br
    br
    .columns.is-gapless(v-for="item in list" :key="item.id")
      .column
        b-checkbox(
          :native-value="item['@id']"
          v-model="model"
          :disabled="disabledFunction ?  disabledFunction(item) : false"
        ) {{ getTranslate(item.name) }}
      //TODO narazie zrobione jako link do listy.
      //Zrobione na potrzeby linka z ofert do wycen.
      //Gdy powstanie podglad konkretnej wyceny mozna przerobic na link do konkretnego itemu
      .column(v-if="resourceLink")
        router-link(
          :to="{name: resourceLink}"
        ) {{ getLabel(item) }}
    .columns
      .column
        button.button(@click.prevent="refresh" type="button") Odśwież
      .column.is-narrow
        div(v-if="addForm")
          button.button(@click.prevent="showForm" type="button") Dodaj
          .modal.is-active(v-show="showAddForm")
            .modal-background
            .modal-card
              header.modal-card-head
                p.modal-card-title {{ addFormTitle }}
                button.delete(aria-label='close' type="button" @click="closeForm")
              section.modal-card-body
                component(:is="addForm" @success="success")
</template>
<script>
import resourceSelectMixin from "../mixins/resourceSelectMixin";

export default {
  mixins: [resourceSelectMixin],
  props: {
    value: {type: Array, default: () => []},
    resourceLink: {type: String, default: null},
    resourceLinkLabels: {type: Array, default: () => ["name"]},
    resourceId: {type: String, default: null},
    separator: {type: String, default: "; "},
    translatable: {type: Boolean, default: false},
    disabledFunction: {type: Function, default: null}
  },
  computed: {
    selectAllLabel() {
      if (this.all) {
        return "Odznacz wszystkie";
      } else {
        return "Zaznacz wszystkie";
      }
    },
    all: {
      set() {
      },
      get() {
        return this.value.length > 0 && this.value.length === this.list.length;
      }
    },
    model: {
      set(value) {
        this.$emit("input", value);
      },
      get() {
        return this.value.map(i => (i["@id"] ? i["@id"] : i));
      }
    }
  },
  methods: {
    //funkcja przyjmuje item z listy, nastepnie iteruje po podanych w resourceLinkLabels elementach.
    //Jeśli to zwykly String i ten label istnieje w itemie, to dopisuje go do outputu.
    //Jeśli elementem jest tablica, wtedy probuje dostać się do ostatniego elementu tej tablicy.
    //np: ["name", ["drive", "name"]] najpierw wykona item["name"] i doda do tego item["drive"]["name"] (o ile istnieje)
    //jeśli elementem jest tablica i natknie się na problem z odczytem to pomija wyświetlenie labelu
    getLabel(item) {
      let output = "";
      this.resourceLinkLabels.forEach(label => {
        if (Array.isArray(label)) {
          let nestedLabel = null;
          for (let subLabel of label) {
            if (nestedLabel === null) {
              nestedLabel = item[subLabel];
            } else {
              nestedLabel = nestedLabel[subLabel];
            }
            if (!nestedLabel) {
              break;
            }
          }
          if (nestedLabel) {
            output += nestedLabel + this.separator;
          }
        } else if (item[label]) {
          output += item[label] + this.separator;
        }
      });
      return output;
    },
    getTranslate(text) {
      return this.translatable ? this.$t(text) : text
    },
    onSelectAll(value) {
      if (value) {
        let values = this.list.map(item => item["@id"]);
        if (this.disabledFunction !== null) {
          values = this.list.filter(item => !this.disabledFunction(item)).map(item => item["@id"]);
        }
        this.$emit("input", values);
      } else {
        this.$emit("input", []);
      }
    },
    async success(data) {
      this.closeForm();
      await this.fetchData();
      let copy = [...this.value, data];
      this.$emit("input", copy);
    }
  }
};
</script>
