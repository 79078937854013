export default function generateProperties(props, fields, andBegin = null) {
  let properties = andBegin ? "&" : "";

  fields.forEach((field, id) => {
    if (id > 0) {
      properties += "&";
    }

    properties += "properties";
    props.forEach(prop => {
      properties += `[${prop}]`;
    });

    properties += `[]=${field}`;
  });

  return properties;
}
