<template lang="pug" functional>
    b-button(
        title="Edycja"
        type="is-info"
        size="is-small"
        tag="router-link"
        :to="props.to"
        :icon-left="props.icon"
    )
</template>
<script>
export default {
  props: {
    to: { type: [Object, String], required: true },
    icon: { type: String, default: "pencil" }
  }
};
</script>
