import router from "./../../router";
import GlobalSettings from "./views/GlobalSettings";
import PricelistsSettings from "./views/PricelistsSettings";
import Fitters from "./views/Fitters";
import Company from "./views/Company";
import store from "../../store";
import management from "./store";
import crud from "@/crud";
import companyInstallationValues from "@/modules/management/crud/companyInstallationValues";
import subCompanies from "@/modules/management/crud/subCompanies";
import CompanyWorkers from "@/modules/management/crud/companyWorkers";

store.registerModule("management", management);

router.addRoutes([
  {
    path: "/management/settings",
    name: "management_settings",
    component: GlobalSettings
  },
  {
    path: "/management/pricelists",
    name: "management_pricelists",
    component: PricelistsSettings
  },
  {
    path: "/management/fitters",
    name: "management_fitters",
    component: Fitters
  },
  {
    path: "/management/company",
    name: "management_company",
    component: Company
  }
]);

crud(CompanyWorkers)
crud(companyInstallationValues);
crud(subCompanies);
