<template lang="pug">
  app-form(:model="companyModel" v-bind="props" v-on="listeners" :resource="companyUrl" :object="companyData" :form-data-should-be-object="true")
    template(#default="{ data, errors }")
      .columns
        .column
          app-view-card(:title="$t('registration.company_data')")
            .columns
              .column
                app-form-field(:label="$t('registration.tax_id')" field="taxId" :errors="errors" required)
                b-field
                  b-input(v-model="data.taxId" @keydown.enter.prevent.native="fetchGus(data)" expanded )
                  .control
                    b-button(type="is-primary" @click.prevent="fetchGus(data)" :loading="gusFetching") GUS
              .column
                app-form-field(:label="$t('registration.name')" field="name" :errors="errors" required)
                  b-input(v-model="data.name" expanded)
            .columns
              .column
                app-form-field(:label="$t('registration.owner')" field="owner" :errors="errors" required)
                  b-input(v-model="data.owner" expanded)
              .column
                app-form-field(:label="$t('registration.zip_code')" field="zipCode" :errors="errors" required)
                  b-input(v-model="data.zipCode" expanded)
              .column
                app-form-field(:label="$t('registration.city')" field="city" :errors="errors" required)
                  b-input(v-model="data.city" expanded)

            .columns
              .column
                app-form-field(:label="$t('registration.street_and_number')" field="streetAndNumber" :errors="errors" required)
                  b-input(v-model="data.streetAndNumber" expanded)
              .column
                app-form-field(:label="$t('registration.country')" field="country" :errors="errors" required)
                  resource-select(
                    v-model="data.country"
                    resource="countries"
                    url="countries"
                    :placeholder="$t('registration.country')"
                    expanded
                  )

            .columns
              .column
                app-form-field(:label="$t('registration.email')" field="email" :errors="errors" required)
                  b-input(v-model="data.email" expanded)
              .column
                app-form-field(:label="$t('registration.website')" field="website" :errors="errors" required)
                  b-input(v-model="data.website" expanded)

    template(#actions="{ submitting }")
      div.has-text-centered
        b-button(native-type="submit" type="is-primary" :loading="submitting") Zapisz
</template>

<script>
import { mapGetters } from "vuex";
import ResourceSelect from "@/modules/common/components/ResourceSelect";
import form from "@/plugins/app/form/mixins/form";
import http from "@/http";

export default {
  mixins: [form],
  components: {
    ResourceSelect
  },
  data() {
    return {
      companyModel: {
        mobilePhone: "",
        name: "",
        phone: "",
        streetAndNumber: "",
        taxId: "",
        website: "",
        zipCode: "",
        bankAccountNumber: "",
        bankName: "",
        owner: "",
        email: "",
        city: "",
        country: ""
      },
      gusFetching: false
    };
  },
  computed: {
    ...mapGetters("company", ["companyData"]),
    ...mapGetters("auth", ["companyUrl"])
  },
  methods: {
    async fetchGus(originalData) {
      const nip = originalData.taxId.replace(/^\D+/g, "").replaceAll("-", "");
      try {
        this.gusFetching = true;
        let { data } = await http.get(`/gus/nip/${nip}`);
        originalData.name = data.name;
        originalData.taxId = data.nip;
        originalData.zipCode = data.zipCode;
        originalData.city = data.city;
        originalData.streetAndNumber = `${data.street} ${data.propertyNumber}`;
      } catch (e) {
        window.alert(this.$t("error_over_download_nip_data"));
      } finally {
        this.gusFetching = false;
      }
    }
  }
};
</script>
