import router from "./../../router";
import Registration from "./views/Registration";
import auth from "./../auth/store";

router.addRoutes([
  {
    path: "/rejestracja",
    name: "registration",
    component: Registration,
    beforeEnter: (to, from, next) => {
      if (auth.state.isLoggedIn) {
        next("/");
      } else {
        next();
      }
    }
  }
]);
