<template lang="pug">
section.hero.is-fullheight
  .hero-body
    .container
      .columns.is-centered
        .column.is-5-tablet.is-4-desktop.is-4-widescreen
          form.box.is-radiusless(@submit.prevent="submit")
            b-message(v-show="error" type="is-danger")
              | {{ error }}
            b-message(v-show="info" type="is-info")
              | {{ info }}
            .field
              label.label {{ $t("user.email") }}
              .control.has-icons-left
                input.input(v-model="data.email" type="email" placeholder="email" required="" )
                span.icon.is-small.is-left
                  i.fa.fa-envelope

            .field.has-text-centered
              b-button(native-type="submit" type="is-primary" :loading="loading" :disabled="info") {{ $t("user.reset_password") }}

          b-message(type="is-warning") {{ $t('user.alert') }}

          .columns.has-text-centered.is-vcentered.is-centered
            .column.is-4.has-text-centered
              router-link.button.is-primary(:to="{ name: 'registration' }") Zarejestruj się
            .column.is-4.has-text-centered.has-text-centered
              router-link.button.is-info(:to="{ name: 'login' }") Logowanie
</template>
<script>
export default {
  data() {
    return {
      loading: false,
      error: null,
      info:null,
      data: {
        email: null,
      }
    };
  },
  methods: {
    async submit() {
      this.error = null;
      this.info = null;
      this.loading = true;
      try {
        await this.$store.dispatch("auth/resetPassword", this.data, true);
        this.info = "Wysłaliśmy do Ciebie e-mail z instrukcjami resetowania hasła. Prosimy, sprawdź swoją skrzynkę pocztową, w tym folder spamu, jeśli nie widzisz wiadomości w skrzynce odbiorczej."
      } catch (e) {
        this.error = e.toString();
      }
      this.loading = false;
    }
  }
};
</script>
