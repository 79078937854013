import moment from "moment";
import http from "../../http";

export default {
  namespaced: true,
  state: {
    loading: false,
    list: [],
  },
  getters: {
    loading: state => state.loading,
    list: state => state.list,
  },
  mutations: {
    setLoading(state, payload) {
      state.loading = payload;
    },
    setList(state, payload) {
      state.list = payload;
    },
    updateListItem(state, payload) {
      const index = state.list.findIndex(item => item.id === payload.id);
      if(index !== -1) {
        state.list.splice(index, 1, payload);
      }
    }
  },
  actions: {
    async init({dispatch}) {
      await dispatch("fetchList");
    },
    updateNews({commit}, news) {
      commit("updateListItem", news)
    },
    async fetchList({commit}) {
      commit("setLoading", true);
      const date = moment()
        .set({
          hour: 0,
          minute: 0,
          second: 0
        })
        .format("YYYY-MM-DD HH:mm:ss");
      let {data} = await http.get(
        `/news?order[createdAt]=desc&dateFrom[before]=${date}&dateTo[after]=${date}`
      );
      if(data["hydra:totalItems"]) {
        commit("setList", data["hydra:member"]);
      }
      commit("setLoading", false);
    },
  }
}