<template lang="pug">
  .is-12
    div(v-for="(field, index) in fields")
      b-field(grouped)
        app-form-field(:label="$t(field.label)" :field="field.name" expanded horizontal)
          b-field
            resource-select(
              label="name"
              :resource="field.resource"
              :url="field.url"
              :field="field.field"
              :extra-props="field.extraProps"
              :placeholder="$t('all')"
              @input="updateField({ index, value: $event })"
              :value="valueChangerToInput(filters[field.field])"
              nullable
              expanded
              style="width: 100px"
            )
            b-button(icon-left="filter-remove" @click.prevent="clearFilters(field.field)")

</template>

<script>
import { mapMutations } from "vuex";
import ResourceSelect from "../../../common/components/ResourceSelect.vue";

function debounce(func, wait, immediate) {
  let timeout;
  return function() {
    const context = this,
        args = arguments;
    const later = function() {
      timeout = null;
      if (!immediate) func.apply(context, args);
    };
    const callNow = immediate && !timeout;
    clearTimeout(timeout);
    timeout = setTimeout(later, wait);
    if (callNow) func.apply(context, args);
  };
}

export default {
  components: { ResourceSelect },
  props: {
    filter: { type: Function, required: true },
    filters: { type: Object, required: true },
    time: { type: Number, default: 350 },
    valueChangerToFilter: { type: Function, default: value => value },
    valueChangerToInput: { type: Function, default: value => value },
  },
  data() {
    return {
      fields: [
        { name: 'detailCategories.id', label: 'detail_category', resource: 'detail_categories', url: 'detail_categories', field: 'detailCategories.id', extraProps: 'order[position]=asc' },
        { name: 'detailSystems.id', label: 'detail_system', resource: 'detail_systems', url: 'detail_systems', field: 'detailSystems.id', extraProps: 'order[position]=asc' },
        { name: 'detailSystems.detailProducts.id', label: 'detail_product', resource: 'detail_products', url: 'detail_products', field: 'detailSystems.detailProducts.id', extraProps: 'order[position]=asc'},
        { name: 'detailColors.id', label: 'detail_color', resource: 'detail_colors', url: 'detail_colors', field: 'detailColors.id', extraProps: 'order[position]=asc'},
      ],
    };
  },
  computed: {
    update() {
      return debounce((index, value) => {
        this.filter({ field: Object.keys(value)[0] , value: this.valueChangerToFilter(Object.values(value)[0]) });
      }, this.time);
    },
  },
  methods: {
    clearFilters(field) {
      this.filter({ field, value: null });
    },
    updateField({ index, value }) {
      this.update(index, value);
    },
    ...mapMutations("details", ["setMessage", "setIsMessageBorderAnimating"]),
  },
};
</script>
