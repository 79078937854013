<template lang="pug">
    .table-container
        table.table.is-bordered.has-cells-centered.is-fullwidth.is-narrow
            thead
                tr
                    th(rowspan=2) {{ $t("ordinal_number") }}
                    th.max-table-col-width-large(rowspan=2) {{ $t("order_list.header_name") }}
                    th(rowspan=2) {{ $t("order_list.header_symbol") }}
                    th(rowspan=2) {{ $t("order_list.header_unit") }}
                    th.max-table-col-width-xsmall(rowspan=2) {{ $t("order_list.header_pieces") }}
                    th.max-table-col-width-small(rowspan=2) {{ $t("order_list.header_quantity_in_package") }}
                    th.max-table-col-width-xsmall(rowspan=2) {{ $t("order_list.header_unit_short") }}


                    th.has-text-right(rowspan=2 v-if="!hideWholeSalePrice") {{ $t("net_purchase_value", { currencyCode }) }}
                    th.has-text-right(rowspan=2) {{ $t("net_sales_value", { currencyCode }) }}
                    th(rowspan=2) {{ $t("operations") }}
            tbody
                detail-order-item(
                    v-for="(item, index) in order.detailOrderItems"
                    :item="item"
                    :index="index"
                    :key ="index"
                )
                tr
                  td(colspan=6 style="border: 0;")
                  td {{ $t('sum_up') }}
                  td.has-text-right(v-if="!hideWholeSalePrice") {{ formatPriceValue(getDecimalValue(order.netPurchaseValueBeforeDiscount)) }}
                  td.has-text-right {{ formatPriceValue(getDecimalValue(order.netSalesValue)) }}
</template>
<script>
import { mapGetters } from "vuex";
import decimal from "@/helpers/decimal";
import priceFormatter from "../../../../helpers/priceFormatter";
import detailOrderItem from "./DetailOrderItem.vue";

export default {
  components: { detailOrderItem },
  computed: {
    ...mapGetters(["hideWholeSalePrice"]),
    ...mapGetters("company", ["currencyCode", "lengthUnit"]),
    ...mapGetters("details", [
      "order",
      "pleats",
      "pleatGroups",
      "pleatGlobalAccessoryEntries",
      "pleatGlobalBalkEntries",
      "pleatGlobalRunnerEntries",
      "pleatGlobalFixingEntries",
      "editIndex"
    ])
  },
  methods: {
    formatPriceValue(value) {
      return priceFormatter(value);
    },
    getDecimalValue(value) {
      return decimal(value);
    }
  }
};
</script>
