<template lang="pug">
  tr
    // liczba porządkowa
    td
      div
        .th-in {{ (index + 1 ) }}
    //nazwa wersji
    td {{ property(item.externalShutter, "name") }}
    // profil pancerza
    td {{ property(item.externalShutterPriceList, "name",  "externalShutterArmor") }}
    // kolor pancerza
    td {{ property(item.externalShutterArmorExternalShutterArmorColorEntry, "name",  "externalShutterArmorColor") }}
    // kolor skrzynki zewnetrzny
    td {{ property(item.temporaryExternalBoxColor, "name",  "fabricShadeColor") }}
    // kolor skrzynki wewnetrzny
    td {{ property(item.temporaryInternalBoxColor, "name",  "fabricShadeColor") }}
    // kolor prowadnic
    td {{ property(item.externalShutterRunnerFabricShadeColorEntry, "name",  "fabricShadeColor") }}
    // kolor listwy dolnej
    td {{ property(item.externalShutterArmorFabricShadeColorEntry, "name",  "fabricShadeColor") }}

</template>
<script>

import { mapGetters } from "vuex";
import priceFormatter from "../../../../../helpers/priceFormatter";

export default {
  props: {
    index: Number,
    indexCombination : Number,
    item: Object,
    itemCombination: Object
  },
  methods: {
    formatPriceValue(value) {
      return priceFormatter(value);
    },
    trueFalseToText(value){
      return (value)? this.$t("yes") : this.$t("no")
    },
    property(object, field, extraField = null) {
      if (extraField) {
        return object && object[extraField][field];
      }
      return object && object[field];
    }
  },
  computed: {
  ...mapGetters("common", [
      "formatLength",
      "formatNumber",
      "formatBoolean",
      "formatPrice",
      "formatWeight"
    ])
  }
};
</script>
