<template lang="pug">
  app-form(v-bind="props" v-on="listeners" resource="sub_companies" :transform-form-data-before-save="transformToSubmit")
    template(#default="{ data, errors }")
      b-tabs(type="is-boxed")
        b-tab-item(:label="$t('general')")
          .columns
            .column
              app-card(:title="$t('company_data')")
                app-form-field(:label="$t('registration.tax_id')" field="taxId" :errors="errors" required)
                  b-field
                    b-input(v-model="data.taxId" @keydown.enter.prevent.native="fetchGus" expanded)
                    .control
                      b-button(type="is-primary" @click.prevent="fetchGus" :loading="gusFetching") {{ $t('fetch_gus') }}

                app-form-field(:label="$t('registration.country')" field="country" :errors="errors" required)
                  resource-select(
                    v-model="data.country"
                    resource="countries"
                    url="countries"
                    :placeholder="$t('registration.country')"
                    @fetched="onFetchedCountries($event, data.country)"
                    expanded
                  )

                app-form-field(:label="$t('registration.name')" field="name" :errors="errors" required)
                  b-input(v-model="data.name")
                // validate shortNaem without special characters
                app-form-field(:label="$t('registration.short_name')" field="shortName" :errors="errors" required)
                  b-input(v-model="data.shortName" v-on:input="data.shortName = data.shortName.replace(/[^a-zA-Z0-9]/g, '')")

                app-form-field(:label="$t('registration.zip_code')" field="zipCode" :errors="errors" required)
                  b-input(v-model="data.zipCode")

                app-form-field(:label="$t('registration.city')" field="city" :errors="errors" required)
                  b-input(v-model="data.city")

                app-form-field(:label="$t('registration.street_and_number')" field="streetAndNumber" :errors="errors" required)
                  b-input(v-model="data.streetAndNumber")

                app-form-field(:label="$t('registration.admin_email')" field="email" :errors="errors" required)
                  b-input(v-model="data.email" type="email" aria-label="email")

            .column
              app-card(:title="$t('settings')")
                app-form-field(:label="$t('currency')" field="currency" :errors="errors" required)
                  resource-select(
                    v-model="data.currency"
                    resource="currencies"
                    url="currencies"
                    :placeholder="$t('choose_currency')"
                    label="code"
                    extra-props="&properties[]=code"
                  )
                app-form-field(label="Sposoby płatności" field="paymentMethods" :errors="errors")
                  multiple-resource-select(
                    v-model="data.paymentMethods"
                    resource="payment_methods"
                    url="payment_methods"
                    :disabled-function="disabledPaymentMethod"
                    placeholder="Wybierz sposoby płatności"
                    add-form-title="Dodawanie metody płatności"
                  )

              br
              app-card(:title="$t('owner_data')")
                app-form-field(:label="$t('registration.owner')" field="owner" :errors="errors" required)
                  b-input(v-model="data.owner" type="text")

                app-form-field(:label="$t('owner_contact')" field="ownerPhone" :errors="errors" required)
                  b-input(v-model="data.ownerPhone")

              br
              app-card(:title="$t('settings')")
                app-form-field(field="hasShipmentToSubCompany" :errors="errors")
                  b-checkbox(v-model="data.hasShipmentToSubCompany") {{ $t('shipment_to_sub_company')}}

                app-form-field(field="hasInvoicingByProducer" :errors="errors")
                  b-checkbox(v-model="data.hasInvoicingByProducer" @input="invoiceByProducerChanged") {{ $t('has_invoicing_by_producer')}}

        b-tab-item(label="Administrator")
          .columns
            .column
              app-card(:title="$t('admin_data')")
                app-form-field(:label="$t('name')" field="companyOwner.firstName" :errors="errors" required)
                  b-input(v-model="data.companyOwner.firstName")

                app-form-field(:label="$t('surname')" field="companyOwner.lastName" :errors="errors" required)
                  b-input(v-model="data.companyOwner.lastName")

                app-form-field(:label="$t('phone')" field="companyOwner.phone" :errors="errors" required)
                  b-input(v-model="data.companyOwner.phone")

                app-form-field(label="Telefon komórkowy" field="companyOwner.mobilePhone" :errors="errors" required)
                  b-input(v-model="data.companyOwner.mobilePhone")

            .column
              app-card(:title="$t('user_data')")
                app-form-field(label="Login" field="companyOwner.username" :errors="errors" required)
                  b-input(v-model="data.companyOwner.username")

                app-form-field(label="E-mail" field="companyOwner.email" :errors="errors" required)
                  b-input(v-model="data.companyOwner.email" type="email" aria-label="email")

                app-form-field(:label="$t('user.password')" field="companyOwner.plainPassword" :errors="errors" required)
                  b-input(v-model="data.companyOwner.password" type="password" id="password" @focus="isReadOnly = false" :readonly="isReadOnly" autocomplete="off" aria-label="password")

              //
              //.column
              //  app-card(:title="$t('available_membership')")
              //    app-form-field(:label="$t('available_membership')" field="companyOwner.memberships" :errors="errors" required)
              //      multiple-resource-select(
              //        v-model="data.companyOwner.memberships"
              //        resource="member_ships"
              //        url="member_ships"
              //        :translatable='true'
              //      )

        b-tab-item(:label="$t('available_product_types')")
          .columns
            .column.is-one-fifth
              app-card(:title="$t('permissions')")
                app-form-field(:label="$t('available_modules')" field="productTypes" :errors="errors" required)
                  multiple-resource-select(
                    v-model="data.productTypes"
                    resource="product_types"
                    url="product_types"
                    :placeholder="$t('choose_available_modules')"
                    @fetched="onFetchedProductTypes"
                  )
            .column
              app-card(:title="$t('margins')")
                table.table.is-fullwidth.is-bordered
                  thead
                    th {{ $t('product_type') }}
                    th {{ $t('margin_in_percent') }}
                  tbody
                    tr(v-for="(margin, index) in model.productTypeMargins")
                      td {{ margin.productType.name }}
                      td
                        b-numberinput(v-model="margin.margin" min="0" max="100")

</template>
<script>
import ResourceSelect from "../../common/components/ResourceSelect";
import form from "../../../plugins/app/form/mixins/form";
import MultipleResourceSelect from "../../common/components/MultipleResourceSelect";
import http from "../../../http";

export default {
  mixins: [form],
  components: {
    ResourceSelect,
    MultipleResourceSelect
  },
  data() {
    return {
      isReadOnly: true,
      wholesalePriceLists: [],
      productTypes: [],
      countries: [],
      gusFetching: false,
      bankAccountNumberChecking: false,
      loading: false,
      model: {
        name: "",
        shortName: "",
        taxId: "",
        isEnabled: false,
        owner: "",
        country: null,
        productTypes: [],
        companyOwner: {
          firstName: "",
          lastName: "",
          phone: "",
          mobilePhone: "",
          username: "",
          email: "",
          password: "",
          isEnabled: false,
          memberships: []
        },
        ownerPhone: "",
        zipCode: "",
        city: "",
        streetAndNumber: "",
        email: "",
        currencyRate: "1.0000",
        currency: null,
        lengthUnit: "cm",
        productTypeMargins: [],
        hasShipmentToSubCompany: false,
        hasInvoicingByProducer: false,
        paymentMethods: []
      }
    };
  },
  computed: {
    lengthUnits() {
      return this.$store.getters.lengthUnits;
    }
  },
  methods: {
    invoiceByProducerChanged() {
      if (this.model.hasInvoicingByProducer) {
        this.model.paymentMethods = [];
      }
    },
    disabledPaymentMethod(item) {
      const regex = new RegExp("przedpłata", "i");
      if (item === null) return false;
      if (this.model.hasInvoicingByProducer) {
        if (!regex.test(item.name)) {
          return true
        }
      }
      return false;
    },
    transformToSubmit(data) {
      data.productTypeMargins.map(margin => {
        if (margin.productType) {
          margin.productType = margin.productType["@id"];
        }
        return margin;
      });
      if (!data.companyOwner.password) {
        delete data.companyOwner.password;
      }
      if (data.defaultDeliveryMethod && data.defaultDeliveryMethod["@id"]) {
        data.defaultDeliveryMethod = data.defaultDeliveryMethod["@id"];
      }
      if (data.currency && data.currency["@id"]) {
        data.currency = data.currency["@id"];
      }
      if (data.mainZone && data.mainZone["@id"]) {
        data.mainZone = data.mainZone["@id"];
      }
      if (data.additionalZone && data.additionalZone["@id"]) {
        data.additionalZone = data.additionalZone["@id"];
      }
      if (data.companyOwner.memberships && data.companyOwner.memberships.length > 0 && data.companyOwner.memberships[0]["@id"]) {
        data.companyOwner.memberships = data.companyOwner.memberships.map(membership => membership["@id"]);
      }
      if (data.paymentMethods && data.paymentMethods.length > 0 && data.paymentMethods[0]["@id"]) {
        data.paymentMethods = data.paymentMethods.map(method => method["@id"]);
      }
      // if (data.productTypes && data.productTypes.length > 0) {
      //   data.productTypes = data.productTypes.map(type => type["@id"]);
      // }
      return data;
    },
    // pobrano moduły - należy zbudować listę wpisów
    onFetchedProductTypes(data) {
      this.productTypes = data;

      // jeśli nie ma wpisu dla danego typu produktu to utwórz nowy
      this.productTypes.forEach(type => {
        let found = this.model.productTypeMargins.find(
            margin => margin.productType.id === type.id
        );
        if (!found) {
          this.model.productTypeMargins.push({
            productType: type,
            margin: 0
          });
        }
      });
    },
    async fetchGus() {
      if (!this.model.taxId) return;
      this.gusFetching = true;
      let nip = this.clear(this.model.taxId);
      try {
        let {data} = await http.get(`/gus/nip/${nip}`);
        this.model.name = data.name;
        this.model.shortName = data.name;
        this.model.taxId = data.nip;
        this.model.owner = data.name;
        this.model.zipCode = data.zipCode;
        this.model.city = data.city;
        this.model.street = data.street;
        this.model.addressNumber = data.propertyNumber;
      } catch (e) {
        window.alert(
            "Wystąpił błąd podczas pobierania danych - nie działa serwis lub nie odnaleziono takiego podmiotu"
        );
      }
      this.gusFetching = false;
    },
    clear(string) {
      let numb = string.match(/\d/g);
      return numb.join("");
    },
    onFetchedCountries(countries, dataCountry) {
      this.countries = countries;
      if (!dataCountry) {
        let poland =
            this.countries &&
            this.countries.find(country => country.name === "Polska");
        if (poland) {
          this.model.country = poland["@id"];
        }
      }
    }
  }
};
</script>
