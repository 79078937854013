import router from "./router";

export default config => {
  let routes = [];
  if (config.list) {
    let list = {
      path: config.list.route.path,
      name: config.list.route.name,
      props: config.list,
      component: () =>
        import(/* webpackChunkName: "dynamic" */ "./components/DynamicList")
    };

    if (config.list.route.beforeEnter) {
      list.beforeEnter = config.list.route.beforeEnter;
    }
    routes.push(list);
  }

  if (config.create) {
    let create = {
      path: config.create.route.path,
      name: config.create.route.name,
      props: config.create,
      component: () =>
        import(/* webpackChunkName: "dynamic" */ "./components/DynamicCreate")
    };
    if (config.create.route.beforeEnter) {
      create.beforeEnter = config.create.route.beforeEnter;
    }
    routes.push(create);
  }

  if (config.update) {
    let update = {
      path: config.update.route.path,
      name: config.update.route.name,
      props: config.update,
      component: () =>
        import(/* webpackChunkName: "dynamic" */ "./components/DynamicUpdate")
    };
    if (config.update.route.beforeEnter) {
      update.beforeEnter = config.update.route.beforeEnter;
    }
    routes.push(update);
  }
  router.addRoutes(routes);
};
