export default function ({detailUnit, metricUnit}) {

  if (!detailUnit || !detailUnit.symbol) {
    return;
  }
  if (metricUnit) return metricUnit;

  switch (detailUnit.symbol) {
    case 'rozpak':
      return this.$t('detail_list.unit_pieces');
    case 'opak':
      return this.$t('detail_list.unit_package');
    case 'mb':
      return this.$t('detail_list.unit_cut', {unit: this.lengthUnit});
    default:
      return metricUnit
  }
}