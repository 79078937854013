<template lang="pug">
    tr(:class="classes")
        // liczba porządkowa
        td
          div
            .th-in {{ (index + 1) }}
        // nazwa detalu
        td {{ property(item.detail, "name") }}
        // symbol
        td {{ property(item.detail, "symbol") }}
        // jednostka
        td {{ property(item.detailUnit, "name") }}
        // ilość
        td.max-table-col-width-xsmall {{ item.quantity }}
        // ilość sztuk
        td.max-table-col-width-small {{pieces}}
        // skrot jm.
        td.max-table-col-width-small {{ unitName({detailUnit:item.detailUnit, metricUnit:item.detail.metricUnit}) }}

        // kasetka
        //// cena zakupu
        td.max-table-col-width-small.has-text-right(v-if="!hideWholeSalePrice") {{ formatPriceValue(formatPrice(item.netPurchaseValue)) }}
        // cena sprzedaży
        td.max-table-col-width-small.has-text-right {{ formatPriceValue(formatPrice(item.netSalesValue)) }}
        td
            .field.has-addons
                p.control
                    b-button(
                      @click.prevent="remove"
                      type="is-danger"
                      icon-left="delete"
                      size="is-small"
                    )
</template>
<script>
import { mapGetters, mapActions, mapMutations } from "vuex";
import priceFormatter from "../../../../helpers/priceFormatter";
import setPieces from "../../helpers/calculatePieces";
import calculatePieces from "../../helpers/calculatePieces";
import unitName from "../../helpers/unitName";

export default {
  props: {
    index: Number,
    item: Object
  },
  methods: {
    unitName,
    calculatePieces,
    setPieces,
    ...mapActions("details", ["removeItem"]),
    ...mapMutations("details", ["editItem"]),
    formatPriceValue(value) {
      return priceFormatter(value);
    },
    remove() {
      return this.$buefy.dialog.confirm({
        message: this.$t("remove_position_confirm"),
        onConfirm: () => {
          this.removeItem(this.index);
        }
      });
    },
    property(object, field, extraField = null) {
      if (extraField) {
        return object && object[extraField][field];
      }
      return object && object[field];
    }
  },
  computed: {
    pieces() {
      return calculatePieces(this.item, this.lengthUnit);
    },
    classes() {
      return {
        "is-editing": this.editIndex === this.index
      };
    },
    ...mapGetters("company", ["lengthUnit"]),
    ...mapGetters(["hideWholeSalePrice"]),
    ...mapGetters("details", ["editIndex"]),
    ...mapGetters("common", [
      "formatLength",
      "formatNumber",
      "formatBoolean",
      "formatPrice"
    ])
  }
};
</script>

<style scoped>
td {
  vertical-align: middle;
  text-align: center;
}
</style>
