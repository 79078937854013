<template lang="pug">
    app-form(:model="object || model" :object="object" resource="fitter_travel_costs" @success="onSuccess" :has-save-and-close="false")
        template(#default="{ data, errors }")
            app-form-field(:label="$t('fitter_travel_cost.name')" field="name" :errors="errors")
                b-input(v-model="data.name")
            app-form-field(:label="$t('fitter_travel_cost.net_cost')" field="netCost" :errors="errors")
                app-form-decimal(v-model="data.netCost")
</template>
<script>
export default {
  props: {
    object: Object
  },
  data() {
    return {
      loading: false,
      model: {
        name: "",
        netCost: 0
      }
    };
  },
  methods: {
    onSuccess(object) {
      this.$store.commit("company/updateFitterTravelCost", object);
      this.$emit("success", object);
    }
  }
};
</script>
