<template lang="pug">
    b-button(
        title="Usuń"
        :disabled="active"
        :loading="active"
        :size="size"
        icon-left="delete"
        type="is-danger"
        @click.prevent="remove"
    )
</template>
<script>
import t from "@/i18n";

export default {
  props: {
    object: { type: Object, required: true },
    resource: { type: String, required: true },
    size: { type: String },
    errorCodes: Array
  },
  data() {
    return {
      active: false
    };
  },
  computed: {
    url() {
      return `${this.resource}/${this.object.id}`;
    }
  },
  methods: {
    async remove() {
      if (confirm(t.t("remove_confirm"))) {
        try {
          this.active = true;
          await this.$http.delete(this.url);
          this.$notify(t.t("removed"));
          this.active = false;
          this.$emit("removed", this.object);
        } catch (e) {
          this.active = false;
          let error = null;
          if (this.errorCodes) {
            error = this.errorCodes.find(
              error => error.code === e.response.status
            );
          }
          if (error) {
            this.$notify(t.t(error.message), "danger");
          }
          this.$notify(e.message, "danger");
        }
      }
    }
  }
};
</script>
