<template lang="pug">
  .columns.is-multiline
    .column.is-one-third-desktop.is-half-tablet(v-if="hasPermission('FITTER_TRAVEL_COST_READ')" )
      app-view-card(:title="$t('fitter_travel_cost.panel_title')")
        b-table(:data="fitterTravelCostsArray" bordered)
          b-table-column(field="name" :label="$t('fitter_travel_cost.name')" v-slot="props") {{ props.row.name }}
          b-table-column(field="netCost" :label="$t('fitter_travel_cost.net_cost')" v-slot="props") {{ formatPrice(props.row.netCost) }}
          b-table-column(:label="$t('actions')" v-slot="props")
            b-button(:disabled="!hasPermission('FITTER_TRAVEL_COST_UPDATE')" @click="edit('fitterTravelCostForm', props.row)" size="is-small" type="is-info") {{ $t('fitter_travel_cost.edit') }}
            b-button(:disabled="!hasPermission('FITTER_TRAVEL_COST_DELETE')" @click="remove(props.row.id, 'removeFitterTravelCost')" size="is-small" type="is-danger") {{ $t('fitter_travel_cost.remove') }}
          template(slot="empty") {{ $t('empty_data') }}
        b-button(:disabled="!hasPermission('FITTER_TRAVEL_COST_CREATE')" type="is-primary" @click="open('fitterTravelCostForm')" class="has-margin-top-small") {{ $t('fitter_travel_cost.add_button') }}

    .column.is-one-third-desktop.is-half-tablet
      app-view-card(:title="$t('company_vat_rates.panel_title')")
        b-table(:data="companyVatRatesArray" bordered)
          b-table-column(field="name" :label="$t('company_vat_rates.name')" v-slot="props") {{ props.row.name }}
          b-table-column(field="value" :label="$t('company_vat_rates.value')" v-slot="props") {{ formatPrice(props.row.value) }}
          b-table-column(:label="$t('actions')" v-slot="props")
            b-button(@click="edit('companyVatRateForm', props.row)" size="is-small" type="is-info") {{ $t('company_vat_rates.edit') }}
            b-button(@click="remove(props.row.id, 'removeCompanyVatRate')" size="is-small" type="is-danger") {{ $t('company_vat_rates.remove') }}
          template(slot="empty") {{ $t('empty_data') }}
        b-button(type="is-primary" @click="open('companyVatRateForm')" class="has-margin-top-small") {{ $t('company_vat_rates.add_button') }}

    .column.is-one-third-desktop.is-half-tablet.has-min-max-width-small
      app-view-card.has-max-width-small(:title="$t('background_color')")
        app-form-field(:label="$t('choose_color')" field="null")
          b-field
            input(type="color" :value="themeBackgroundColor" @input="setThemeBackgroundColor($event.target.value)" class="color-picker")
        app-form-field(:label="null" field="null")
          b-field
            b-button(:label="$t('save')" type="is-primary" @click="saveColor" :loading="loading")
    .column.is-one-third-desktop.is-half-tablet.has-min-max-width-small(v-if="userType === 'company_owner' && this.minimalMargin")
      app-view-card(:title="$t('minimal_percent_margin')")
        app-form-field(:label="$t('type_minimal_percent_margin')" field="null" :help="$t('minimal_percent_margin_help')")
          b-field
            app-form-decimal(
              :value="this.minimalMargin"
              @input="setMinimalMargin($event)"
              :min="1000"
              :max="10000"
            )
            p.control
              span.button.is-static %
        app-form-field(:label="null" field="null")
          b-field
            b-button(:label="$t('save')" @click="saveMinimalMargin" type="is-primary" :loading="loading")
    //.column.is-one-third-desktop.is-half-tablet.has-min-max-width-small(v-if="userType === 'company_owner'")
    //    app-view-card(:title="$t('buy_type')")
    //        app-form-field(:label="$t('type_buy_type')" field="null")
    //            b-field
    //                b-radio(
    //                  name="buyType"
    //                  native-value="retail"
    //                  v-model="computedBuyType"
    //                ) {{ $t('retail') }}
    //                b-radio(
    //                  name="buyType"
    //                  native-value="wholesale"
    //                  v-model="computedBuyType"
    //                ) {{ $t('wholesale') }}
    //        app-form-field(:label="null" field="null")
    //            b-field
    //                b-button(:label="$t('save')" @click="saveBuyType" type="is-primary" :loading="loading")
    .column.is-one-third-desktop.is-half-tablet.has-min-max-width-small
      app-view-card(:title="$t('length_unit_header')")
        app-form-field(:label="$t('length_unit')" field="null")
          b-field
            b-radio(
              name="lengthUnit"
              native-value="mm"
              v-model="computedLengthUnit"
            ) {{ $t('Milimetr') }}
            b-radio(
              name="lengthUnit"
              native-value="cm"
              v-model="computedLengthUnit"
            ) {{ $t('Centymetr') }}
        app-form-field(:label="null" field="null")
          b-field
            b-button(:label="$t('save')" @click="saveLengthUnit" type="is-primary" :loading="loading")
    .column.is-one-third-desktop.is-half-tablet.has-min-max-width-small(v-if="userType === 'company_owner'")
      app-view-card(:title="$t('company_logo')")
        app-form-field(:label="$t('need_support_with_logo')" field="null")
          app-button-toggle(
            :resource="companyUrl"
            :object="companyObject"
            field="needSupportWithAddLogo"
            extra-props="?properties[]=needSupportWithAddLogo"
            @success="setNeedSupportWithAddLogoAfterToggle"
          )
        app-form-file(
          :value="files.logo"
          @input="setLogoAfterInput"
          :image="logo"
          @removed="removeLogo({})"
        )

    .column.is-one-third-desktop.is-half-tablet.has-min-max-width-small
      app-view-card(:title="$t('hide_wholesale_price')")
        app-form-field(:label="$t('hide_wholesale_price_question')" field="null")
          app-button-toggle(
            :resource="userUrl"
            :object="userObject"
            field="hideWholeSalePrice"
            extra-props=""
            @success="setHideWholesalePriceAfterToggle"
          )
    .column.is-one-third-desktop.is-half-tablet
      app-view-card(:title="$t('hide_image')")
        app-form-field(:label="$t('hide_image_question')" field="null")
          app-button-toggle(
            :resource="userUrl"
            :object="userObject"
            field="hideImage"
            extra-props=""
            @success="setHideImageAfterToggle"
          )

    b-modal(:active.sync="isModalActive" has-modal-card)
      app-view-modal(:title="modalTitle")
        template(#body)
          fitter-travel-cost-form(v-if="form === 'fitterTravelCostForm' && hasPermission('FITTER_TRAVEL_COST_READ')" @success="isModalActive = false" :object="editObject")
          company-vat-rate-form(v-if="form === 'companyVatRateForm' " @success="isModalActive = false" :object="editObject")
</template>
<script>
import {mapActions, mapGetters, mapMutations} from "vuex";
import objectToCollection from "../../../helpers/objectToCollection";
import FitterTravelCostForm from "./components/FitterTravelCostForm";
import simpleClone from "../../../helpers/simpleClone";
import CompanyVatRateForm from "./components/CompanyVatRateForm";
import notify from "@/helpers/notify";
import http from "@/http";
import {uploadMedia} from "@/modules/media_objects/helpers";
import collectionToObject from "../../../helpers/collectionToObject";

export default {
  components: {
    FitterTravelCostForm,
    CompanyVatRateForm
  },
  data() {
    return {
      isModalActive: false,
      editObject: null,
      form: null,
      loading: false,
      files: {
        logo: {}
      },
    };
  },
  async mounted() {
    if (this.hasPermission('FITTER_TRAVEL_COST_READ')) {
      await this.getFitterTravelCost();
    }
  },
  methods: {
    async getFitterTravelCost() {
      try {
        const companyId = this.$store.state.auth.companyId;
        const fitterTravelCostResponse = await http.get(`/companies/${companyId}/fitter_travel_cost_entries`);

        const fitterTravelCostLists = fitterTravelCostResponse.data["hydra:member"];

        this.setFitterTravelCosts(
            collectionToObject(fitterTravelCostLists)
        );
      } catch (error) {
        // console.error("Error fetching company price list entries:", error);
      }
    },
    removeLogo(data) {
      this.files.logo = data;
      this.setLogo(data);
    },
    async setLogoAfterInput(data) {
      if (data && data.name && data.name.length > 0) {
        this.files.logo = data;
        let formData = {logo: simpleClone(this.logo)};
        await Promise.all(uploadMedia({...this.files}, formData));
        const logoHasKeys = this.logo && Object.keys(this.logo).length > 0;
        if (logoHasKeys) {
          await http.delete(`/media_objects/${this.logo.id}`);
        }
        await this.setLogo(formData.logo);
      } else {
        await this.setLogo(data, true);
      }
    },
    setNeedSupportWithAddLogoAfterToggle(data) {
      this.setNeedSupportWithAddLogo(data.needSupportWithAddLogo);
    },
    setHideImageAfterToggle(data) {
      this.setHideImage(data.hideImage);
    },
    setHideWholesalePriceAfterToggle(data) {
      this.setHideWholeSalePrice(data.hideWholeSalePrice)
    },
    open(form) {
      this.editObject = null;
      this.isModalActive = true;
      this.form = form;
    },
    remove(id, func) {
      this.$buefy.dialog.confirm({
        message: this.$t("remove_confirm"),
        onConfirm: () => {
          this[func](id);
        }
      });
    },
    edit(form, row) {
      this.form = form;
      this.editObject = simpleClone(row);
      this.isModalActive = true;
    },
    ...mapActions("company", [
      "removeFitterTravelCost",
      "removeCompanyVatRate",
      "setMinimalMargin",
      "setBuyType",
      "setLengthUnit",
      "setNeedSupportWithAddLogo",
      "setLogo"
    ]),
    ...mapMutations("company", ["setFitterTravelCosts"]),
    ...mapActions(["setThemeBackgroundColor", "setHideImage", "setHideWholeSalePrice"]),
    async saveColor() {
      this.loading = true;
      const bgColor = this.themeBackgroundColor;
      try {
        await http.put(`/users/${this.userId}?properties[]=backgroundColor`, {
          backgroundColor: bgColor
        });
        notify("Pomyślnie zaktualizowano kolor tła");
      } catch {
        notify(
            "Nie udało się zaktualizować koloru tła aplikacji, obecny kolor pozostanie tłem do czasu opuszczenia strony",
            "danger"
        );
      } finally {
        this.loading = false;
      }
    },
    async saveMinimalMargin() {
      this.loading = true;
      const margin = this.minimalMargin;
      try {
        await http.put(
            `/${this.companyUrl}/${this.companyId}?properties[]=minimalMargin`,
            {
              minimalMargin: margin
            }
        );
        notify("Pomyślnie zaktualizowano minimalną marże firmy");
      } catch {
        notify(
            "Nie udało się zaktualizować minimalnej marży firmy. Wystąpił błąd",
            "danger"
        );
      } finally {
        this.loading = false;
      }
    },
    async saveBuyType() {
      this.loading = true;
      const buyType = this.buyType;
      try {
        await http.put(
            `/${this.companyUrl}/${this.companyId}?properties[]=buyType`,
            {
              buyType: buyType
            }
        );
        notify("Pomyślnie zaktualizowano typ zakupu firmy");
      } catch {
        notify(
            "Nie udało się zaktualizować typu zakupu firmy. Wystąpił błąd",
            "danger"
        );
      } finally {
        this.loading = false;
      }
    },
    async saveLengthUnit() {
      this.loading = true;
      const lengthUnit = this.lengthUnit;
      try {
        await http.put(
            `/${this.companyUrl}/${this.companyId}?properties[]=lengthUnit`,
            {
              lengthUnit: lengthUnit
            }
        );
        notify("Pomyślnie zaktualizowano jednostkę miary");
      } catch {
        notify(
            "Nie udało się zaktualizować jednostki miary. Wystąpił błąd",
            "danger"
        );
      } finally {
        this.loading = false;
      }
    }
  },
  computed: {
    companyObject() {
      return {
        id: this.companyId,
        needSupportWithAddLogo: this.needSupportWithAddLogo
      };
    },
    userObject() {
      return {
        id: this.userId,
        hideImage: this.hideImage,
        hideWholeSalePrice: this.hideWholeSalePrice
      };
    },

    modalTitle() {
      if (this.form) {
        return this.$t(`${this.form}ModalTitle`);
      }
      return null;
    },
    ...mapGetters("company", [
      "fitterTravelCosts",
      "companyVatRates",
      "minimalMargin",
      "buyType",
      "needSupportWithAddLogo",
      "companyId",
      "lengthUnit",
      "logo"
    ]),
    ...mapGetters("common", ["formatPrice"]),
    ...mapGetters("auth", ["userId", "userType", "companyId", "companyUrl", "userUrl", "hasPermission"]),
    ...mapGetters(["themeBackgroundColor", "hideImage", "hideWholeSalePrice"]),
    computedBuyType: {
      get: function () {
        return this.buyType;
      },
      set: function (value) {
        this.setBuyType(value);
      }
    },
    computedLengthUnit: {
      get: function () {
        return this.lengthUnit;
      },
      set: function (value) {
        this.setLengthUnit(value);
      }
    },
    fitterTravelCostsArray() {
      return objectToCollection(this.fitterTravelCosts);
    },
    companyVatRatesArray() {
      return objectToCollection(this.companyVatRates);
    }
  }
};
</script>
