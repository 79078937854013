<template lang="pug">
  form(@submit.prevent="save" class="is-relative")
    b-loading(:is-full-page="false" :active="submitting")
    b-field.has-text-centered(v-if="communicate" :class="communicateClasses") {{ communicate }}
    slot(:errors="errors" :data="formData")
    b-field(class="has-margin-bottom" v-if="allowSave")
      slot(name="actions" :submitting="submitting")
        b-button(v-if="hasSave" native-type="submit" type="is-primary" :loading="submitting") Zapisz
        b-button(v-if="hasSaveAndClose" native-type="button" type="is-default" :loading="submitting" @click="saveAndClose") Zapisz i zamknij
</template>
<script>
import {uploadMedia} from "@/modules/media_objects/helpers";
import simpleClone from "@/helpers/simpleClone";
import t from "@/i18n";

export default {
  props: {
    resource: {type: String, required: true},
    transformFormDataBeforeSave: {type: Function},
    model: {type: Object, required: true},
    object: {type: Object},
    files: {type: [Object, Array]},
    transformToModel: {type: Function},
    transformToSubmit: {type: Function},
    actionAfterSave: {type: Function},
    allowSave: {type: Boolean, default: true},
    communicate: {type: String, default: null},
    communicateType: {type: String, default: "warning"},
    formDataShouldBeObject: {type: Boolean, default: false},
    hasSave: {type: Boolean, default: true},
    hasSaveAndClose: {type: Boolean, default: true},
    customValidation: {type: Function},
  },
  data() {
    return {
      submitting: false,
      errors: [],
      formData: this.formDataShouldBeObject ? this.object : this.model
    };
  },
  methods: {
    replaceObject(obj) {
      this.formData.id = obj.id;
      for (let field in this.model) {
        this.formData[field] = obj[field];
      }
      if (this.transformToModel) {
        this.transformToModel(this.formData);
      }
      this.$emit("object-changed", this.formData);
    },
    flat(formData) {
      Object.keys(formData).forEach(key => {
        if (Array.isArray(formData[key])) {
          formData[key].forEach(obj => {
            this.flat(obj);
          });
        }
        if (this.isObject(formData[key]) && formData[key]["@id"]) {
          formData[key] = formData[key]["@id"];
        }
      });
    },
    isObject(obj) {
      return obj === Object(obj);
    },
    async submit(callback) {
      this.submitting = true;
      this.errors = [];
      let formData = this.transformFormDataBeforeSave
          ? this.transformFormDataBeforeSave(simpleClone(this.formData))
          : simpleClone(this.formData);

      formData = this.transformToSubmit
          ? this.transformToSubmit(simpleClone(this.formData))
          : formData;

      if (this.customValidation) {
        await this.customValidation(formData, this.errors);
        if (this.errors.length > 0) {
          this.submitting = false;
          this.$notify("Formularz zawiera błędy", "danger");
          return;
        }
      }

      for (let field in this.files) {
        delete formData[field];
      }

      // normalize relations
      // this.flat(formData);
      if (this.files && this.files.file instanceof Array) await Promise.all(uploadMedia({...this.files}, formData, true));
      else {
        await Promise.all(uploadMedia({...this.files}, formData));
      }

      try {
        let {data} = await this.$http[this.method](this.url, formData);
        this.submitting = false;
        this.$notify(t.t("saved"));
        callback(data);
        this.replaceObject(data);
        if (this.actionAfterSave) {
          this.actionAfterSave(data, this.method);
        }
      } catch ({response}) {
        if (response.status === 400) {
          this.errors = response.data.violations;
          this.$notify(t.t("form_has_errors"), "danger");
        }
        this.submitting = false;
      }
    },
    async saveAndClose() {
      if (this.allowSave) {
        await this.submit(data => {
          this.$emit("close", data);
        });
      }
    },
    async save() {
      if (this.allowSave) {
        await this.submit(data => {
          this.$emit("success", data);
        });
      }
    }
  },
  computed: {
    communicateClasses() {
      switch (this.communicateType) {
        case "warning":
          return {
            "has-text-danger": true,
            "has-font-size-medium": true
          };
        case "info":
          return {
            "has-text-info": true,
            "has-font-size-small": true
          };
        default:
          return {
            "has-text-danger": true,
            "has-font-size-medium": true
          };
      }
    },
    method() {
      return this.isUpdate ? "put" : "post";
    },
    isUpdate() {
      return !!this.formData.id;
    },
    url() {
      let url = `${this.resource}`;
      if (this.isUpdate) {
        url += `/${this.formData.id}`;
      }
      return url;
    }
  },
  watch: {
    object(val) {
      this.replaceObject(val);
    }
  }
};
</script>
