import auth from "../store";
import http from "../../../http";
import store from "../../../store";
import router from "../../../router";
import notify from "../../../helpers/notify";

export const onRequest = config => {
  if (auth.state.token) {
    config.headers["Authorization"] = `Bearer ${auth.state.token}`;
  }
  return config;
};

export const onRequestError = error => {
  return Promise.reject(error);
};

export const onResponse = response => {
  return response;
};

export const onResponseError = async error => {
  if (error.response.status === 403) {
    notify("Brak dostępu", "danger");
    return Promise.reject(error);
  }

  if (error.response.status !== 401) {
    return new Promise((resolve, reject) => {
      reject(error);
    });
  }

  // Logout user if token refresh didn't work or user is disabled
  if (error.config.url.includes("token/refresh")) {
    store.commit("auth/removeData");
    router.push({name: "login"});
    return Promise.reject("Auth refresh failed");
  }

  // Try request again with new token
  try {
    let {data} = await http.post("/token/refresh", {
      refresh_token: auth.state.refresh_token
    });
    store.commit("auth/setRefreshedTokens", data);
    return new Promise((resolve, reject) => {
      http
          .request(error.config)
          .then(response => {
            resolve(response);
          })
          .catch(error => {
            reject(error);
          });
    });
  } catch (e) {
    Promise.reject(error);
  }
};

export const refreshAuthLogic = async () => {
  if (auth.state.refresh_token) {
    try {
      let {data} = await http.post("/token/refresh", {
        refresh_token: auth.state.refresh_token
      });
      store.commit("auth/setRefreshedTokens", data);
      return Promise.resolve();
    } catch (e) {
      store.commit("auth/removeData");
      router.push({name: "login"});
      return Promise.reject("Auth refresh failed");
    }
  } else {
    if (store.state.route.name !== "login") {
      router.push({name: "login"});
    }
  }
};
