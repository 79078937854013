<template lang="pug" functional>
  th(:class="{ sortable: props.sortable }" @click.prevent="props.sort(props)")
    b-tooltip(v-if="props.labelTooltip" tag="span" :label="props.labelTooltip" position="is-bottom"  class="has-pointer") {{ props.label }}
    span(v-else) {{ props.label }}
    template(v-show="props.sortable && props.isSorted(props.field)")
      span(class="icon is-small" v-show="props.sortable && props.isDesc")
        i(class="mdi mdi-arrow-down")
      span(class="icon is-small" v-show="props.sortable && props.isAsc")
        i(class="mdi mdi-arrow-up")
</template>
<script>
export default {
  props: {
    label: {type: String, required: true},
    field: {type: String},
    isSorted: {type: Function, required: true},
    sort: {type: Function, required: true},
    sortable: Boolean,
    isDesc: Boolean,
    isAsc: Boolean,
    labelTooltip: {type: String}
  }
};
</script>
