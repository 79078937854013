<template lang="pug">
    .table-container
        table.table.is-bordered.has-cells-centered.is-narrow
            thead
                tr
                    th {{ $t("ordinal_number") }}
                    th {{ $t("version") }}
                    th {{ $t("external_shutter.external_shutter_armor") }}
                    th {{ $t("external_shutter.external_shutter_armor_color") }}
                    th {{ $t("external_shutter.external_box_color") }}
                    th {{ $t("external_shutter.internal_box_color") }}
                    th {{ $t("external_shutter.runners_color") }}


                    th {{ $t("external_shutter.external_shutter_armor_fabric_shade_color") }}
                tr

            tbody
              template(v-for="(item, index) in orderAfterSum")

                  external-shutter-order-item-after-sum(
                      :item="item"
                      :index="index"
                  )

            tr

</template>
<script>
import ExternalShutterOrderItem from "./ExternalShutterOrderItem";

import { mapGetters } from "vuex";
import decimal from "@/helpers/decimal";
import priceFormatter from "../../../../helpers/priceFormatter";
import ExternalShutterOrderItemAfterSum from "./ExternalShutterOrderItemAfterSum";

export default {
  components: {ExternalShutterOrderItemAfterSum, ExternalShutterOrderItem },
  computed: {
    ...mapGetters("company", ["currencyCode", "lengthUnit"]),
    ...mapGetters("externalShutters", ["orderAfterSum"]),
    ...mapGetters("common", ["formatWeight"])
  },
  methods: {
    formatPriceValue(value) {
      return priceFormatter(value);
    },
    getDecimalValue(value) {
      return decimal(value);
    }
  }
};
</script>
