import http from "../../../http";

export default {
  props: {
    url: { type: String },
    resource: { type: String, required: true },
    placeholder: { type: String },
    addForm: { type: Object },
    addFormTitle: { type: String },
    extraProps: { type: String, default: null },
    fullObject: Boolean
  },
  data() {
    return {
      loading: false,
      list: [],
      showAddForm: false
    };
  },
  methods: {
    refresh() {
      this.fetchData();
    },
    showForm() {
      this.showAddForm = true;
    },
    closeForm() {
      this.showAddForm = false;
    },
    async fetchData() {
      this.loading = true;
      try {
        const url = this.url || this.resource;
        let simpleProperties = null;
        if (!this.fullObject) {
          simpleProperties = "properties[]=id&properties[]=name";
        }
        let { data } = await http.get(
          `${url}?${simpleProperties || ""}${
            simpleProperties && this.extraProps ? "&" : ""
          }${this.extraProps || ""}`,
          {
            params: {
              pagination: false
            }
          }
        );
        this.list = data["hydra:member"];
        this.$emit("fetched", data["hydra:member"]);
        this.loading = false;
      } catch (e) {
        this.loading = false;
        throw e;
      }
    }
  },
  created() {
    this.fetchData();
  }
};
