import http from "../../../../http";

export default function example_image_upload_handler (blobInfo, success, failure) {

    let fd = new FormData();

    fd.append("file", blobInfo.blob(), blobInfo.filename());

    http
        .post("/private/upload", fd, {
            headers: { "Content-Type": "multipart/form-data" }
        })
        .then(({data}) => success(data.location))
        .catch(error => failure(error));
};
