<template lang="pug">
    tr
        // liczba porządkowa
        td
          div
            .th-in {{ (index + 1 ) }}
        // pilot
        td {{ property(item.globalRemoteControl, "name") ? property(item.globalRemoteControl, "name") : property(item.globalDrive, "name") ? property(item.globalDrive, "name") : property(item.globalDriveControl, "name") }}
        // ilość
        td {{ item.quantity }}
        // cena zakupu
        td.has-text-right(v-if="!hideWholeSalePrice") {{ formatPriceValue(formatPrice(item.netPurchaseValue)) }}
        // cena sprzedaży
        td.has-text-right {{ formatPriceValue(formatPrice(item.netSalesValue)) }}
        td
            .field.has-addons
                p.control
                    b-button(
                      @click.prevent="remove"
                      type="is-danger"
                      icon-left="delete"
                      size="is-small"
                    )


</template>
<script>
import { mapGetters, mapActions } from "vuex";
import priceFormatter from "../../../../helpers/priceFormatter";

export default {
  props: {
    index: Number,
    item: Object,
  },
  methods: {
    ...mapActions("fabricShades", ["removeAutomationItem"]),
    formatPriceValue(value) {
      return priceFormatter(value);
    },
    trueFalseToText(value){
      return (value)? this.$t("yes") : this.$t("no")
    },
    remove() {
      return this.$buefy.dialog.confirm({
        message: this.$t("remove_position_confirm"),
        onConfirm: () => {
          this.removeAutomationItem(this.index);
        }
      });
    },
    property(object, field, extraField = null) {
      if (extraField) {
        return object && object[extraField][field];
      }
      return object && object[field];
    }
  },
  computed: {
    iteratorAlphabet(){
      if(this.item.externalShutterOrderItemCombinationsCount === 1)return " "
      return ["a","b","c"]
    },
    ...mapGetters(["hideWholeSalePrice"]),
    ...mapGetters("fabricShades", ["editIndex"]),
    ...mapGetters("common", [
      "formatLength",
      "formatNumber",
      "formatBoolean",
      "formatPrice",
      "formatWeight"
    ])
  }
};
</script>
