<template lang="pug">
  tr()
    td
      div
        .th-in {{ (index + 1) }}
    // nazwa grupy
    td(v-if="printType === 'full'")
      p {{ property(item.pleatGroup, "position") }}
      p(v-if="item.pleatGroupBottom") {{ property(item.pleatGroupBottom, "position") }}
    // nazwa wersji
    td {{ property(item.pleat, "name") }}
    // nazwa kolekcji (tkanina)
    td
      p {{ property(item.pleatFabric, "name") }}
      p(v-if="item.pleatFabricBottom") {{ property(item.pleatFabricBottom, "name") }}
    // nazwa tkaniny (kolor tkaniny)
    td
      p {{ property(item.pleatFabricColor, "name") }}
      p(v-if="item.pleatFabricColorBottom") {{ property(item.pleatFabricColorBottom, "name") }}
    // szerokość całkowita
    td(v-if="printType === 'full'") {{ formatLength(item.width) }}
    // wysokość
    td(v-if="printType === 'full'") {{ formatLength(item.height) }}
    // kasetka
    td {{ property(item.pleatGlobalAccessoryEntry, "name", "globalAccessory") }}
    // prowadnice
    td {{ property(item.pleatGlobalRunnerEntry, "name", "globalRunner") }}
    // Dodatkowy naciag
    td {{ property(item.pleatAdditionalString, "symbol", "globalRunner") }}
    // mocowanie
    td {{ property(item.pleatPleatFixingTypeEntry, "name", "pleatFixingType") }}
    // sterowanie
    td {{ property(item.pleatPleatRemoteControlTypeEntry, "name", "pleatRemoteControlType") }}
    // Dodatki
    td
      p(v-if="!item.pleatPleatAdditionalEntries || !item.pleatPleatAdditionalEntries.length") {{$t('empty')}}
      p(v-for="(itemAdditional) in item.pleatPleatAdditionalEntries") {{ property(itemAdditional, "name", "pleatAdditional") }}
    // ilość szt.
    td {{ item.quantity }}
    // cena zakupu
    td.has-text-right(v-if="priceType !== 'sales' && printType === 'full'") {{ formatPriceValue(formatPrice(item.netPurchaseValue)) }}
    // cena sprzedaży
    td.has-text-right(v-if="priceType !== 'purchase' && printType === 'full'") {{ formatPriceValue(formatPrice(item.netSalesValue)) }}

</template>
<script>
import { mapGetters } from "vuex";
import priceFormatter from "../../../../../helpers/priceFormatter";
import decimal from "../../../../../helpers/decimal";

export default {
  props: {
    index: Number,
    item: Object,
    priceType: { default: null, required: true },
    printType: { default: null, required: true }
  },
  methods: {
    getDecimalValue(value) {
      return decimal(value);
    },
    formatPriceValue(value) {
      return priceFormatter(value);
    },
    versionNumber() {
      return this.checkVirtualVersionNumberAfterSum(this.item)
    },
    trueFalseToText(value) {
      return (value) ? this.$t("yes") : this.$t("no")
    },
    property(object, field, extraField = null) {
      if (extraField) {
        return object && object[extraField][field];
      }
      return object && object[field];
    },
  },
  computed: {
    ...mapGetters("common", [
      "formatLength",
      "formatNumber",
      "formatBoolean",
      "formatPrice",
      "formatWeight"
    ])
  }
};
</script>
<style scoped>
td {
  vertical-align: middle;
  text-align: center;
}
</style>