<template lang="pug">
  b-tabs(type="is-boxed")
    b-tab-item(v-for="section in files" :label="section.name" v-if="section.downloadSubsections.length > 0")
      b-tabs(type="is-boxed")
        b-tab-item(v-for="subsection in section.downloadSubsections" v-if="subsection.isEnabled && subsection.downloadfiles.length > 0" :label="subsection.name")
          a(
            v-for="download in subsection.downloadfiles"
            v-if="download.isEnabled"
            class="button is-info has-margin-right-medium has-margin-bottom"
            :href="download.file.contentUrl"
            target="_blank"
            download
          )
            b-icon(
              icon="download"
              size="is-small"
              pack="fas"
            )
            div {{ download.name }}

</template>

<script>
import { mapActions, mapGetters } from "vuex";

export default {
  data() {
    return {
      selectedInstruction: null
    };
  },
  methods: {
    ...mapActions("support", ["initDownloadableFiles"])
  },
  computed: {
    ...mapGetters("support", ["files"])
  },
  created() {
    this.initDownloadableFiles();
  }
};
</script>
