import CreateOrder from "./views/CreateOrder";
import pleats from "../pleats/store";
import store from "../../store";
import router from "../../router";

store.registerModule("pleats", pleats);

router.addRoutes([
  {
    path: "/pleats/create_order/:copyId?",
    name: "pleats_create_order",
    component: CreateOrder,
    props: true
  }
]);

router.addRoutes([
  {
    path: "/pleats/edit_order/:id",
    name: "pleats_edit_order",
    component: CreateOrder,
    props: true
  }
]);
