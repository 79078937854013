<template lang="pug">
  .hover-image(v-if="image")
      img(:src="image" class="thumbnail-image" @mouseover="hoverImage = image" @mouseout="hoverImage = null" @click="$emit('open', image)")
      img(:src="image" width="500" class="thumbnail-image-large is-4by3" v-if="hoverImage === image")
</template>
<script>
export default {
  props: {
    image: String
  },
  data() {
    return {
      hoverImage: null
    };
  }
};
</script>
<style>
.thumbnail-image-large{
  position: absolute;
}
</style>