// table
import AppTable from "./table/components/AppTable";
import AppTableStore from "./table/components/AppTableStore";
import AppTableHead from "./table/components/AppTableHead";
import AppTableFilterText from "./table/components/AppTableFilterText";
import AppTableFilterBoolean from "./table/components/AppTableFilterBoolean";
import AppTableFilterSelect from "./table/components/AppTableFilterSelect";

// form
import AppForm from "./form/components/AppForm";
import AppFormField from "./form/components/AppFormField";
import AppFormFile from "./form/components/AppFormFile";
import AppFormDecimal from "./form/components/AppFormDecimal";
import AppFormLength from "./form/components/AppFormLength";
import AppFormRichselect from "./form/components/AppFormRichselect";
import AppFormHoverImage from "./form/components/AppFormHoverImage";
import AppFormEditor from "./form/components/AppFormEditor";

// button
import AppButtonRemove from "./button/components/AppButtonRemove";
import AppButtonEdit from "./button/components/AppButtonEdit";
import AppButtonToggle from "./button/components/AppButtonToggle";

// view
import AppViewCreate from "./view/components/AppViewCreate";
import AppViewUpdate from "./view/components/AppViewUpdate";
import AppViewList from "./view/components/AppViewList";
import AppViewLoadable from "./view/components/AppViewLoadable";
import AppViewBreadcrumbs from "./view/components/AppViewBreadcrumbs";
import AppViewCard from "./view/components/AppViewCard";
import AppViewMoney from "./view/components/AppViewMoney";
import AppViewModal from "./view/components/AppViewModal";
import SimpleTable from "@/plugins/app/simple-table/SimpleTable";
import SimpleTableRow from "@/plugins/app/simple-table/SimpleTableRow";

// layout
import AppCard from "./layout/components/AppCard";
import AppPanel from "./layout/components/AppPanel";
import AppPanelBlock from "./layout/components/AppPanelBlock";

export default {
  install(Vue) {
    // table
    Vue.component("app-table", AppTable);
    Vue.component("app-table-store", AppTableStore);
    Vue.component("app-table-head", AppTableHead);
    Vue.component("app-table-filter-text", AppTableFilterText);
    Vue.component("app-table-filter-boolean", AppTableFilterBoolean);
    Vue.component("app-table-filter-select", AppTableFilterSelect);
    // form
    Vue.component("app-form", AppForm);
    Vue.component("app-form-field", AppFormField);
    Vue.component("app-form-file", AppFormFile);
    Vue.component("app-form-decimal", AppFormDecimal);
    Vue.component("app-form-length", AppFormLength);
    Vue.component("app-form-richselect", AppFormRichselect);
    Vue.component("app-form-hover-image", AppFormHoverImage);
    Vue.component("app-form-editor", AppFormEditor);
    // button
    Vue.component("app-button-remove", AppButtonRemove);
    Vue.component("app-button-edit", AppButtonEdit);
    Vue.component("app-button-toggle", AppButtonToggle);
    // view
    Vue.component("app-view-create", AppViewCreate);
    Vue.component("app-view-update", AppViewUpdate);
    Vue.component("app-view-list", AppViewList);
    Vue.component("app-view-loadable", AppViewLoadable);
    Vue.component("app-view-breadcrumbs", AppViewBreadcrumbs);
    Vue.component("app-view-card", AppViewCard);
    Vue.component("app-view-money", AppViewMoney);
    Vue.component("app-view-modal", AppViewModal);
    //simple-table
    Vue.component("app-simple-table", SimpleTable);
    Vue.component("app-simple-table-row", SimpleTableRow);
    // layout
    Vue.component("app-card", AppCard);
    Vue.component("app-panel", AppPanel);
    Vue.component("app-panel-block", AppPanelBlock);
  }
};
