<template lang="pug">
  .columns(style="width:50vw; margin-left:auto; margin-right:auto").is-multiline
    .column.is-1
      app-form-hover-image(
        :image="image(item, 'picture')"
      )
    .column.is-8
      p.has-font-size-small {{item.symbol}}
      p.has-text-left {{item.description}}
    .column.is-3.has-text-centered
      // cena zakupu
      p(v-if="item.pieceSurcharge").has-font-size-small {{ formatPriceValue(formatPrice(item.pieceSurcharge)) }}
      // cena sprzedaży
      p(v-if="item.netWholesalePrice") {{ formatPriceValue(formatPrice(item.netWholesalePrice)) }}
      .columns.mt-1
        .column.is-4-desktop.is-6-tablet.has-text-centered.is-offset-2-desktop.is-offset-1-tablet
          b-select(v-model="quantity" expanded )
            option(v-for="side in generateQuantity()" :value="side") {{ side }}

        .column.is-2-desktop.is-6-tablet
            b-button(
              type="is-primary"
              @click="calculateItemAndAdd(item)"
            ) {{ $t('automation.add_item') }}
    .column.is-full.m-0.p-0
      hr
</template>

<script>
import { mapActions, mapGetters} from "vuex";
import priceFormatter from "../../../helpers/priceFormatter";
import AppFormHoverImage from "../../../plugins/app/form/components/AppFormHoverImagePreviewWindow";
import simpleClone from "../../../helpers/simpleClone";
import prepareItemToSent from "../../../helpers/prepareItemToSent";
import http from "../../../http";
import notify from "../../../helpers/notify";

function debounce(func, wait, immediate) {
  let timeout;
  return function() {
    const context = this,
        args = arguments;
    const later = function() {
      timeout = null;
      if (!immediate) func.apply(context, args);
    };
    const callNow = immediate && !timeout;
    clearTimeout(timeout);
    timeout = setTimeout(later, wait);
    if (callNow) func.apply(context, args);
  };
}
export default {
  components:{AppFormHoverImage},
  props: {
    index: Number,
    item: Object,
    productType: String
  },
  data() {
    return {
      quantity: 1
    };
  },
  methods: {
    removeAutomationItem(index){
      this.$store.dispatch(this.productType, {
        removeAutomationItem: this.removeAutomationItem(index)
      });
    },

    ...mapActions("automation",
        ["init",
          "addElementToItemOrder",
          "setQuantity",
          "addItemToOrder",
          "clearItem",
          "resetFilters",
        ]),

    formatPriceValue(value) {
      return priceFormatter(value);
    },
    trueFalseToText(value){
      return (value)? this.$t("yes") : this.$t("no")
    },
    remove() {
      return this.$buefy.dialog.confirm({
        message: this.$t("remove_position_confirm"),
        onConfirm: () => {
          this.removeAutomationItem(this.index);
        }
      });
    },
    property(object, field, extraField = null) {
      if (extraField) {
        return object && object[extraField][field];
      }
      return object && object[field];
    },
    openImage(image, text = null) {
      this.imageForModal = image;
      if (text) {
        this.centeredTextForModal = text;
      } else {
        this.centeredTextForModal = null;
      }
      this.imageModal = true;
    },
    async calculateItemAndAdd(item){
      await this.addElementToItemOrder(item)
      await this.setQuantity(this.quantity)
      await this.calculatePrice()

      const {name : automationItemName } = item

      return this.$buefy.dialog.confirm({
        message: this.$t("automation.add_automation_item_confirm", {automation:automationItemName, quantity:this.quantity}),
        onConfirm: () => {
          this.addItemToOrder()
        },
        onCancel: ()=>{
          this.clearItem()
        }
      });
    },
    generateQuantity(){
      return Array.from(Array(10).keys())
    },


    calculatePrice: debounce(async function() {
      let calculatorPriceAvailable = true;

      if(!calculatorPriceAvailable){
        return null;
      }else {
        this.priceLoading = true;
        let item = simpleClone(this.item);
        item = prepareItemToSent(item);
        delete item["@id"];
        delete item.id;
        await http
            .post(`/calculate/automation_order_item`, item)
            .then(({data}) => {
              this.item.netPurchaseValue = data.netPurchaseValue;
              this.item.netSalesValue = data.netSalesValue;
              this.isCalculated = true;
              this.removeError(null,null,true)
            })
            .catch(error => {
              this.isCalculated = false;
              notify(error.response.data["hydra:description"], "danger");
            });
        this.priceLoading = false;
      }
    }, 400),

  },
  computed: {
    ...mapGetters("common", [
      "formatLength",
      "formatNumber",
      "formatBoolean",
      "formatPrice",
      "formatWeight"
    ]),
    ...mapGetters("automation",
        ["loading",
          "image",
          "filters",
          "defaultAutomationElements",
          "itemGlobalDriveManufactures",
          "itemGlobalDriveControlType",
          "itemGlobalRemoteControlType",
          "itemGlobalRemoteControlChannel",
          "itemProductElements",
        ]),
  }
}

</script>
