<template lang="pug" functional>
.card(:class="{'has-margin-bottom-small': props.hasMarginBottom}")
    .card-header
        .card-header-title {{ props.title }}
        .card-header-icon
            slot(name="icon")
    slot(name="color-picker-selector")
    .card-content(:class="{ 'is-paddingless': props.paddingless }")
        slot
</template>
<script>
export default {
  props: {
    title: String,
    paddingless: Boolean,
    hasMarginBottom: Boolean
  }
};
</script>
