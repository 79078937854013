import http from "../../http";
import store from "../../store";

export default {
    namespaced: true,
    state: {
        yourDataIsProtected: null,
        regulationContent: null,
    },
    getters: {
        yourDataIsProtected: state => state.yourDataIsProtected,
        regulationContent: state => state.regulationContent,
    },
    mutations: {
        setYourDataIsProtected(state, content) {
            state.yourDataIsProtected = content;
        },
        setRegulationContent(state, content) {
            state.regulationContent = content;
        },
    },
    actions: {
        async init({ commit }) {
            const companyRegulationContent = store.getters["company/regulationContent"];
            if (companyRegulationContent) {
                commit("setRegulationContent", companyRegulationContent);
            } else {
                let {data} = await http.get("/configurations/1");
                commit("setYourDataIsProtected", data.yourDataIsProtected);
                commit("setRegulationContent", data.regulationContent);
            }
        }
    }
};
