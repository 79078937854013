<template lang="pug">
    span(title="Kliknij by przełączyć")
        a(
            class="button is-small"
            :class="classes"
            href=""
            @click.prevent="toggle"
        ) {{ value ? "TAK" : "NIE" }}
</template>
<script>
export default {
  props: {
    resource: { type: String, required: true },
    object: { type: Object, required: true },
    field: { type: String, required: true },
    extraProps: { type: String, default: null }
  },
  computed: {
    value() {
      return this.object[this.field];
    },
    classes() {
      return { "is-success": this.value, "is-danger": !this.value };
    },
    url() {
      let url = `${this.resource}/${this.object.id}`;
      if (this.extraProps) {
        url += this.extraProps;
      }
      return url;
    }
  },
  methods: {
    async toggle() {
      const value = !this.object[this.field];
      this.object[this.field] = value;
      try {
        let { data } = await this.$http.put(this.url, {
          [this.field]: value
        });
        this.$emit("success", data);
        this.$notify(this.$t("element_switched"));
      } catch (e) {
        this.$notify(this.$t("element_switch_failure"), "danger");
        this.object[this.field] = !this.object[this.field];
      }
    }
  }
};
</script>
