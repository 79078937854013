<template lang="pug">
b-modal(:active.sync="active" has-modal-card :can-cancel="false")
  app-view-modal( :title="$t('regulation')" v-if="regulationContent"  actions-centered )
    template(#body id="content" @scroll="onScroll")
      span( v-html="regulationContent" )
    template(#actions)
      b-button(type="is-primary" @click.prevent="accept" :disabled="disabled") {{ $t('accept') }}
</template>
<script>
import {mapGetters} from "vuex";

export default {
  data: () => ({
    active: true,
    disabled: true
  }),
  computed: {
    ...mapGetters("configuration", ["regulationContent"])
  },
  mounted() {
    const element = document.getElementsByClassName("modal-card-body");
    element[0].addEventListener('scroll', this.onScroll);

  },
  updated() {
    const element = document.getElementsByClassName("modal-card-body");
    element[0].addEventListener('scroll', this.onScroll);
  },
  methods: {
    accept() {
      this.$store.dispatch("company/acceptRegulation");
    },
    onScroll({target: {scrollTop, clientHeight, scrollHeight}}) {
      if (scrollTop + clientHeight >= scrollHeight - 2) {
        this.disabled = false
      }
    }
  }
};
</script>
