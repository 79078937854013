<template lang="pug">
    b-table(:data="model" bordered)
        b-table-column(field="name" :label="$t('fitters.name')" v-slot="props" width="180")
            b-input(v-model="props.row.name" expanded)
        b-table-column(field="surname" :label="$t('fitters.surname')" v-slot="props" width="180")
            b-input(v-model="props.row.surname" expanded)
        b-table-column(field="phone" :label="$t('fitters.phone')" v-slot="props" width="60")
            b-input(v-model="props.row.phone" expanded)
        b-table-column(field="isEnabled" :label="$t('fitters.isEnabled')" v-slot="props" width="60")
            b-checkbox(v-model="props.row.isEnabled" expanded)
        b-table-column(:label="$t('action')" v-slot="props" width="40")
            b-button(@click="add" size="is-small" type="is-primary" :loading="loading") {{ $t('add') }}
</template>
<script>
import simpleClone from "@/helpers/simpleClone";
import http from "@/http";
import { mapGetters } from "vuex";

export default {
  props: {
    companyId: { required: true }
  },
  data() {
    return {
      model: [
        {
          name: null,
          surname: null,
          phone: null,
          isEnabled: false,
          company: `/${this.companyUrl}/${this.companyId}`
        }
      ],
      loading: false
    };
  },
  computed: {
    ...mapGetters("auth", ["companyUrl", "companyType"])
  },
  methods: {
    async add() {
      try {
        this.loading = true;
        let val = simpleClone(this.model[0]);
        if ("sub_company" === this.companyType) {
          val.subCompany = `/${this.companyUrl}/${this.companyId}`;
          delete val.company;
        }
        let { data } = await http.post("/fitters", val);
        this.$notify("Dodano nowy element");
        this.$emit("success", { ...data });
      } catch ({ response }) {
        if (response.status !== 200) {
          this.$notify("Wystąpił problem przy dodawaniu montera", "danger");
        }
      } finally {
        this.loading = false;
      }
    }
  }
};
</script>
