<template lang="pug">
  div
    .columns.is-desktop
      .column.is-6-desktop
        // Nowa pozycja zamówienia
        app-view-card(:title="$t(itemDataTitle)")
          b-notification(type="is-danger" v-if="globalError" :closable="false") {{ globalError }}
          form
          .columns
            .column
              b-field(grouped)
                app-form-field(:label="$t('version')" field="fabricShade" horizontal expanded)
                  b-field
                    v-select.w100.has-pointer(
                      :options="fabricShades"
                      label="name"
                      v-model="item.fabricShade"
                      :clearable="false"
                      @input="onFabricShadeChanged"
                      :placeholder="$t('empty')"
                    )
                      template(v-slot:no-options='') {{$t('empty')}}
                    app-form-hover-image(
                      :image="image(item.fabricShade, 'picture')"
                      @open="openImage($event)"
                    )

              b-field(grouped)
                app-form-field(:label="$t('case')" field="case" horizontal expanded)
                  b-field
                    v-select.w100.has-pointer(
                      :options="itemFabricShadeGlobalAccessoryEntries"
                      label="name"
                      v-model="item.fabricShadeGlobalAccessoryEntry"
                      :clearable="false"
                      :placeholder="$t('empty')"
                      :getOptionLabel="option => {if (typeof option === 'object'){ if(option.hasOwnProperty('globalAccessory')){ return option.globalAccessory['name'] }} return option;}"
                      @input="onFabricShadeGlobalAccessoryEntryChanged"
                    )
                      template(v-slot:no-options='') {{$t('empty')}}
                    app-form-hover-image(
                      :image="image(item.fabricShadeGlobalAccessoryEntry, 'thumbnail', 'globalAccessory')"
                      @open="openImage($event)"
                    )

              b-field(grouped)
                app-form-field(:label="$t('balk')" field="fabricShadeBalk" horizontal expanded)
                  b-field
                    v-select.w100.has-pointer(
                      :options="itemFabricShadeGlobalBalkEntries"
                      label="name"
                      v-model="item.fabricShadeGlobalBalkEntry"
                      :clearable="false"
                      :placeholder="$t('empty')"
                      @input="onFabricShadeGlobalBalkChanged"
                      :getOptionLabel="option => {if (typeof option === 'object'){ if(option.hasOwnProperty('globalBalk')){ return option.globalBalk['name'] }} return option;}"
                    )
                      template(v-slot:no-options='') {{$t('empty')}}
                    app-form-hover-image(
                      :image="image(item.fabricShadeGlobalBalkEntry, 'thumbnail', 'globalBalk')"
                      @open="openImage($event)"
                    )

              b-field(grouped)
                app-form-field(:label="$t('runners')" field="runner" horizontal expanded)
                  b-field
                    v-select.w100.has-pointer(
                      :options="itemFabricShadeGlobalRunnerEntries"
                      label="name"
                      v-model="item.fabricShadeGlobalRunnerEntry"
                      :clearable="false"
                      :placeholder="$t('empty')"
                      @input="onFabricShadeGlobalRunnerEntryChanged"
                      :getOptionLabel="option => {if (typeof option === 'object'){ if(option.hasOwnProperty('globalRunner')){ return option.globalRunner['name'] }} return option;}"
                    )
                      template(v-slot:no-options='') {{$t('empty')}}
                    app-form-hover-image(
                      :image="image(item.fabricShadeGlobalRunnerEntry, 'thumbnail', 'globalRunner')"
                      @open="openImage($event)"
                    )

              app-form-field(:label="$t('group')" field="fabricShadeGroup" horizontal expanded)
                v-select.w100.has-pointer(
                  :options="itemFabricShadeGroups"
                  label="position"
                  v-model="item.fabricShadeGroup"
                  :clearable="false"
                  :placeholder="$t('empty')"
                  @input="onGroupChanged"
                  class="style-chooser"
                )
                  template(v-slot:no-options='') {{$t('empty')}}
                  template(#option="{ name }") {{ name }}

              app-form-field(:label="$t('collection')" field="fabric" horizontal expanded)
                v-select.w100.has-pointer(
                  :options="itemFabrics"
                  label="name"
                  v-model="item.fabric"
                  :clearable="false"
                  placeholder="Bez tkaniny"
                  @input="onFabricChanged"
                )

              b-field(grouped)
                app-form-field(:label="$t('fabric')" field="fabricColor" horizontal expanded)
                  b-field
                    v-select.w100.has-pointer(
                      :options="itemFabricColors"
                      label="name"
                      v-model="item.fabricColor"
                      :clearable="false"
                      :placeholder="$t('empty')"
                      @input="onFabricColorChanged"
                      :map-keydown="fabricColorHandlers"
                    )
                      template(v-slot:no-options='') {{$t('empty')}}
                    app-form-hover-image(
                      :image="image(item.fabricColor, 'thumbnail')"
                      @open="openImage($event)"
                    )

              b-field(grouped)
                app-form-field(:label="$t('fixing')" field="fabricShadeFixing" horizontal expanded)
                  b-field
                    v-select.w100.has-pointer(
                      :options="asArray(item.fabricShade, 'fabricShadeGlobalFixingEntries').filter(entry => entry.isEnabled)"
                      label="name"
                      v-model="item.fabricShadeGlobalFixingEntry"
                      :clearable="false"
                      :placeholder="$t('empty')"
                      @input="onFabricShadeGlobalFixingEntryChanged"
                      :getOptionLabel="option => {if (typeof option === 'object'){ if(option.hasOwnProperty('globalFixing')){ return option.globalFixing['name'] }} return option;}"
                    )
                      template(v-slot:no-options='') {{$t('empty')}}
                    app-form-hover-image(
                      :image="image(item.fabricShadeGlobalFixingEntry, 'thumbnail', 'globalFixing')"
                      @open="openImage($event)"
                    )
              b-field(grouped)
                app-form-field(:label="$t('additional')" field="fabricShadeAdditional" horizontal expanded)
                  v-select.has-pointer.w100(
                    :options="asArray(item.fabricShade, 'fabricShadeFabricShadeAdditionalEntries').filter(entry => entry.isEnabled)"
                    label="name"
                    v-model="item.fabricShadeFabricShadeAdditionalEntries"
                    :clearable="false"
                    :placeholder="$t('empty')"
                    :getOptionLabel="option => {if (typeof option === 'object'){ if(option.hasOwnProperty('fabricShadeAdditional')){ return option.fabricShadeAdditional['name'] }} return option;}"
                    multiple
                    @input="onFabricShadeFabricShadeAdditonalEntriesChanged"
                  )
                    template(v-slot:no-options='') {{$t('empty')}}

            .column
              app-form-field(
                :label="$t('order.width', { lengthUnit })"
                field="width"
                :help="widthHelp"
                :showHelp="showHelpForOrderDimensions"
                :errors="errors"
                horizontal
                expanded
              )
                app-form-length(
                  v-model="item.width"
                  :unit="lengthUnit"
                  @blur="onWidthChange"
                  @focus="showHelpForOrderDimensions = true"
                  customClass="pl-1"
                )

              app-form-field(
                v-if="!isWithGlassSizes"
                :label="$t('order.fabric_width', { lengthUnit })"
                field="fabricWidth"
                horizontal
                expanded
              )
                app-form-length(
                  v-model="item.fabricWidth"
                  :unit="lengthUnit"
                  @blur="onFabricWidthChange"
                  customClass="pl-1"
                )

              app-form-field(
                v-if="isWithGlassSizes"
                :label="$t('order.glass_width', { lengthUnit })"
                field="glassWidth"
                :errors="errors"
                horizontal
                expanded
              )
                app-form-length(
                  v-model="item.glassWidth"
                  :unit="lengthUnit"
                  @blur="onGlassWidthChange"
                  customClass="pl-1"
                )

              app-form-field(
                :label="$t('order.height', { lengthUnit })"
                field="height"
                :help="heightHelp"
                :showHelp="showHelpForOrderDimensions"
                :errors="errors"
                horizontal
                expanded
              )
                app-form-length(
                  v-model="item.height"
                  :unit="lengthUnit"
                  @focus="showHelpForOrderDimensions = true"
                  @blur="onHeightChange"
                  customClass="pl-1"
                )

              app-form-field(
                v-if="isWithGlassSizes"
                :label="$t('order.glass_height', { lengthUnit })"
                field="glassHeight"
                :errors="errors"
                horizontal
                expanded
              )
                app-form-length(
                  v-model="item.glassHeight"
                  :unit="lengthUnit"
                  @blur="onGlassHeightChange"
                  customClass="pl-1"
                )

              app-form-field(v-if="isOversized() || item.hasConfirmedOversize" field="hasTurnableFabric" horizontal expanded)
                b-checkbox(
                  v-model="item.hasConfirmedOversize"
                  @input="confirmOversize"
                ) {{ $t('oversize_confirm') }}

              app-form-field(field="hasTurnableFabric" horizontal expanded)
                b-checkbox(
                  v-model="item.hasTurnableFabric"
                  @input="calculatePrice"
                  @click.native.prevent.stop="confirmTurnableFabric"
                ) {{ $t('turnable') }}

              app-form-field(:label="$t('order.pieces')" field="quantity" horizontal expanded)
                b-numberinput(v-model="item.quantity" min="1" max="100" @input="onQuantityChange")

              b-field(grouped)
                app-form-field(:label="$t('drive_type')" field="itemDriveType" horizontal expanded)
                  b-field
                    v-select.w100.has-pointer(
                      :options="itemDriveTypes"
                      label="name"
                      v-model="driveTypeFilterComp"
                      :clearable="false"
                      :placeholder="$t('empty')"
                      @input="onGlobalDriveTypeChanged"
                    )
                      template(v-slot:no-options='') {{$t('empty')}}
              b-field(grouped)
                app-form-field(:label="$t('drive_series')" field="itemDriveSeries" horizontal expanded)
                  b-field
                    v-select.w100.has-pointer(
                      :options="itemDriveSeries"
                      label="name"
                      v-model="driveSeriesFilterComp"
                      :clearable="false"
                      :placeholder="$t('empty')"
                      @input="onGlobalDriveSeriesChanged"
                    )
                      template(v-slot:no-options='') {{$t('empty')}}
              app-form-field(
                :label="$t('drive_side')"
                field="driveSide"
                :help="driveSideHelp"
                horizontal
                expanded
              )
                b-select(v-model="item.driveSide" expanded)
                  option(v-for="side in fabricShadeDriveSides" :value="side.value") {{ side.label }}

              //b-field(v-if="asArray(item.globalDrive, 'globalDriveControls').length > 0" grouped)
              //  app-form-field(
              //    :label="$t('drive_control')"
              //    field="globalDriveControl"
              //    horizontal
              //    expanded
              //  )
              //    b-field
              //      v-select.w100.has-pointer(
              //        :options="asArray(item.globalDrive, 'globalDriveControls')"
              //        label="name"
              //        v-model="item.globalDriveControl"
              //        :clearable="false"
              //        :placeholder="$t('empty')"
              //        @input="onGlobalDriveControlChanged"
              //      )
              //        template(v-slot:no-options='') {{$t('empty')}}
              //      app-form-hover-image(
              //        :image="image(item.globalDriveControl, 'picture')"
              //        @open="openImage($event)"
              //      )

              //b-field(v-if="itemGlobalRemoteControls.length > 0" grouped)
              //  app-form-field(
              //    :label="$t('remote_control')"
              //    field="globalRemoteControl"
              //    horizontal
              //    expanded
              //  )
              //    b-field
              //      v-select.w100.has-pointer(
              //        :options="itemGlobalRemoteControls"
              //        label="name"
              //        v-model="item.globalRemoteControl"
              //        :clearable="false"
              //        :placeholder="$t('empty')"
              //        @input="onGlobalRemoteControlChanged"
              //      )
              //        template(v-slot:no-options='') {{$t('empty')}}
              //      app-form-hover-image(
              //        :image="image(item.globalRemoteControl, 'picture')"
              //        @open="openImage($event)"
              //      )
              //app-form-field(v-if="item.globalRemoteControl" :label="$t('remote_control_quantity')" field="globalRemoteControlQuantity" horizontal expanded)
              //  b-numberinput(v-model="item.globalRemoteControlQuantity" min="0" max="100" @input="onGlobalRemoteQuantityChange")

              app-form-field(:label="$t('installation_place')" field="shortDescription" horizontal expanded)
                b-input(v-model="item.shortDescription" maxlength="20" :placeholder="$t('installation_place_example')")
              b-field(grouped)
                app-form-field(
                  :label="$t('company_installation_value')"
                  field="companyInstallationValue"
                  horizontal
                  expanded
                )
                  b-field
                    v-select.w100.has-pointer(
                      :options="companyInstallationValuesBySelectedFabricShade"
                      label="name"
                      v-model="item.companyInstallationValue"
                      :clearable="false"
                      :placeholder="$t('empty')"
                    )
                      template(v-slot:no-options='') {{$t('empty')}}
          .columns(v-if="message")
            .column
            .column.is-four-fifths
              .border
                .has-font-size-15.communicate(:class="{'communicate-animation': isMessageBorderAnimating}") {{ message }}
            .column
          table.table.is-fullwidth.is-bordered.is-narrow
            b-loading(:active.sync="priceLoading")
            thead
              tr
                th.has-text-centered.has-border-radius-top(colspan=2) {{ $t('order.total_net_value') }}
              tr
                th(v-if="!hideWholeSalePrice").has-text-centered {{ $t('order.purchase') }}
                th.has-text-centered {{ $t('order.sale') }}
            tbody
              tr
                td(v-if="!hideWholeSalePrice").has-text-right {{ formatPriceValue(formatPrice(item.netPurchaseValue)) }}
                td.has-text-right {{ formatPriceValue(formatPrice(item.netSalesValue)) }}
          b-field
            .buttons
              b-button(
                native-type="submit"
                type="is-primary"
                :loading="submitting"
                @click="saveItem"
                :disabled="cantSave"
              ) {{ $t(addButtonLabel) }}
              //toggle show form
              b-button(type="is-warning" @click="toggleShowForm") {{ $t(`${showForm?'hide':'show'}_form`) }}
              b-button(v-if="isEdit" :loading="submitting" @click.prevent="cancelEdit") {{ $t('cancel') }}
              b-button(type="is-danger" v-if="orderHasAtLeastOneItem" @click.prevent="clearValuation") {{ $t('clear_order') }}
              b-button(@click="calculatePrice") {{ $t('refresh') }}
              b-button(type="is-primary" @click="automationFormModal = true") {{ $t('automation.title_choose_automation') }}
      .column.is-narrow
        .columns.is-centered
          .column.card-preview.has-min-width-large
            // Podgląd
            .columns
              .column.is-three-quarters-desktop.is-four-fifths-tablet(v-if="!getHideImage")
                app-view-card(:title="pictureTitle" class="has-margin-bottom" paddingless)
                  template(slot="color-picker-selector" v-if="!loading")
                    .columns.is-multiline.has-margin-top-very-small
                      .column
                        b-field(grouped)
                          app-form-field(:label="$t('window_color')" field="window_frame" expanded horizontal)
                            b-field
                              resource-select(
                                :value="windowFrame"
                                resource="window_frames"
                                url="window_frames"
                                extra-props="order[position]=asc"
                                :placeholder="$t('default_frame')"
                                @input="setWindowFrame"
                                nullable
                                expanded
                                full-object
                              )
                              b-button(icon-left="filter-remove" @click.prevent="resetWindowFrame")
                      .column
                        b-field(grouped)
                          app-form-field(:label="$t('wall_color')" field="wall_color" expanded horizontal)
                            b-field
                              input(type="color" v-model="backgroundColor" class="color-picker")

                  div
                    .card-div-preview(:style="{'backgroundColor': backgroundColor}")
                      .shutter(v-if="!loading && item && item.fabricShade " :style="windowFrameBackgroundImage")
                        .preview
                          div.shade-top(v-if="item.fabricShade && item.fabricShade.previewType !== 'free'" :style="getBackgroundPictureWithContain(item.fabricShadeGlobalAccessoryEntry, 'globalAccessory')")
                          div.shade-top-free(v-else)
                            div.shade-top-free-left-fixing
                            div.shade-top-free-left-pipe-up
                            div.shade-top-free-left-pipe-down
                            div.shade-top-free-mechanism(:style="getBackgroundPictureWithContain(item.fabricColor)")
                            div.shade-top-free-mechanism-gradient
                            div.shade-top-free-right-pipe-up
                            div.shade-top-free-right-pipe-down
                            div.shade-top-free-right-fixing
                          div.shade-left(v-if="item.fabricShade && item.fabricShade.previewType === 'with_runners'" :style="{'--bg': getPictureUrl(item.fabricShadeGlobalAccessoryEntry, 'globalAccessory')}")
                          div.shade-center.has-pointer(@click="openImage(image(item.fabricColor, 'picture'))" :style="getBackgroundPictureWithContain(item.fabricColor)")
                          div.shade-center-gradient(v-if="item.fabricShade && item.fabricShade.previewType === 'free'")
                          div.shade.shade-center-extra-gradient-free(v-if="item.fabricShade && item.fabricShade.previewType === 'free'")
                          div.shade-center-gradient-no-free(v-if="item.fabricShade && item.fabricShade.previewType !== 'free'")
                          div.shade-right(v-if="item.fabricShade && item.fabricShade.previewType === 'with_runners'" :style="{'--bg': getPictureUrl(item.fabricShadeGlobalAccessoryEntry, 'globalAccessory')}")
                          div.shade-bottom(:style="balkBackgroundPictureWithContain")
                          div.chain-left(v-if="item.driveSide === 'L+P' || item.driveSide === 'L'")
                            div.chain-fixing
                            div.chain
                            div.chain-handle
                          div.chain-right(v-if="item.driveSide === 'L+P' || item.driveSide === 'P'")
                            div.chain-fixing
                            div.chain
                            div.chain-handle
                    .picture-review-help {{ $t('picture_review_help') }}
              br
              .column
                .columns.is-multiline.is-mobile
                  .column.is-12-desktop.is-12-tablet
                    .columns
                      .column
                        app-view-card(:title="$t('version')" class="has-margin-bottom")
                          figure
                            .level
                              .level-item
                                img.has-pointer(
                                  v-if="image(item.fabricShade, 'mechanismPicture')"
                                  :src="image(item.fabricShade, 'mechanismPicture')"
                                  width="76"
                                  @click="openImage(image(item.fabricShade, 'mechanismPicture'))"
                                )
                  .column.is-12-desktop.is-12-tablet
                    .columns
                      .column
                        app-view-card(:title="$t('survey')" class="has-margin-bottom")
                          figure
                            .level
                              .level-item
                                img.has-pointer(
                                  v-if="image(item.fabricShade, 'surveyPicture')"
                                  :src="image(item.fabricShade, 'surveyPicture')"
                                  width="100"
                                  @click="openImage(image(item.fabricShade, 'surveyPicture'), item.fabricShade['surveyDescription'+getLocale])"
                                )
                  .column.is-12-desktop.is-12-tablet
                    .columns
                      .column
                        app-view-card(:title="$t('files')")
                          a(
                            v-if="item.fabricShade && item.fabricShade.measurementInstruction"
                            class="button is-primary"
                            :href="item.fabricShade.measurementInstruction.contentUrl"
                            target="_blank"
                            download
                          ) {{ $t('measurementInstruction') }}
    br
    b-modal(v-model="imageModal")
      .columns(style="width:99%").has-text-centered
        img(:src="imageForModal")
        .columns(v-if="centeredTextForModal")
          .column
          .column.is-half.has-text-centered
            .has-background-white.has-text-black(v-html="centeredTextForModal")
          .column
    automation-form-modal( productType="fabricShades" :globalDriveControls="itemGlobalDriveControls()" :automationModalShow="automationFormModal" @close="automationFormModal=false")
</template>

<script>
import http from "../../../../http";
import {mapActions, mapGetters, mapMutations} from "vuex";
import notify from "../../../../helpers/notify";
import simpleClone from "../../../../helpers/simpleClone";
import ResourceSelect from "@/modules/common/components/ResourceSelect";
import prepareItemToSent from "@/helpers/prepareItemToSent";
import objectToCollection from "@/helpers/objectToCollection";
import priceFormatter from "../../../../helpers/priceFormatter";
import AutomationFormModal from "../../../automation/components/AutomationFormModal";

function debounce(func, wait, immediate) {
  let timeout;
  return function () {
    const context = this,
        args = arguments;
    const later = function () {
      timeout = null;
      if (!immediate) func.apply(context, args);
    };
    const callNow = immediate && !timeout;
    clearTimeout(timeout);
    timeout = setTimeout(later, wait);
    if (callNow) func.apply(context, args);
  };
}

export default {
  components: {ResourceSelect, AutomationFormModal},
  data() {
    return {
      globalError: null,
      errors: [],
      submitting: false,
      showWidthError: false,
      priceLoading: false,
      imageModal: false,
      imageForModal: "",
      centeredTextForModal: null,
      hoverImage: null,
      backgroundColor: "#E6E6E6",
      windowFrame: null,
      globalOrderRemoteControlIsPosting: false,
      showHelpForOrderDimensions: false,
      isCalculated: false,
      automationFormModal: false,
    };
  },
  methods: {
    formatPriceValue(value) {
      return priceFormatter(value);
    },
    ...mapActions("fabricShades", [
      "setMessage",
      "setIsMessageBorderAnimating",
      "toggleShowForm",
      "saveItem",
      "cancelEdit",
      "setDriveTypeFilter",
      "setDriveSeriesFilter",
      "saveItemAndClose",
      "updateOrder",
      "updateClearOrder",
      "clearOrder",
    ]),
    ...mapMutations("fabricShades", ["setItem"]),
    setMessageFromAccessory(message) {
      if (message && message.length > 0) {
        this.setMessage(message);
        this.setIsMessageBorderAnimating(true);
        setTimeout(() => {
          this.setIsMessageBorderAnimating(false);
        }, 1000);
      }
    },
    //nadpisuje eventy keyup i keydown, preventDefault jest defaultowy, oryginalnie zwraca fora, który jest tu wykonywany
    //emit jest customowy
    //up
    fabricColorHandlers: (map, vm) => ({
      ...map,
      38: e => {
        e.preventDefault();

        for (let i = vm.typeAheadPointer - 1; i >= 0; i--) {
          if (vm.selectable(vm.filteredOptions[i])) {
            vm.typeAheadPointer = i;
            break;
          }
        }
        vm.$emit("input", vm.filteredOptions[vm.typeAheadPointer]);
      },
      //down
      40: e => {
        e.preventDefault();
        for (
            let i = vm.typeAheadPointer + 1;
            i < vm.filteredOptions.length;
            i++
        ) {
          if (vm.selectable(vm.filteredOptions[i])) {
            vm.typeAheadPointer = i;
            break;
          }
        }
        vm.$emit("input", vm.filteredOptions[vm.typeAheadPointer]);
      }
    }),
    getPictureFromNestedElement(src, nestedElement) {
      const item = src && src[nestedElement];
      const picture = item && item["picture"];

      return picture || null;
    },
    //zwraca prosty url
    getPictureUrl(src, nestedField = null) {
      let picture = null;

      if (nestedField) {
        picture = this.getPictureFromNestedElement(src, nestedField);
      } else {
        picture = src && src["picture"];
      }

      if (picture) {
        return "url(" + picture.contentUrl + ")";
      }

      return null;
    },
    //zwraca obiekt stylu z tłem i background-size: contain
    getBackgroundPictureWithContain(src, nestedField = null) {
      let picture = null;

      if (nestedField) {
        picture = this.getPictureFromNestedElement(src, nestedField);
      } else {
        picture = src && src["thumbnail"];
      }

      if (picture) {
        return {
          background: "url(" + picture.contentUrl + ") 0% 0% / contain"
        };
      }
      return null;
    },
    //zwraca obiekt stylu z tłem
    getBackgroundPicture(src, nestedField = null) {
      let picture = null;

      if (nestedField) {
        picture = this.getPictureFromNestedElement(src, nestedField);
      } else {
        picture = src && src["picture"];
      }

      if (picture) {
        return {
          background: "url(" + picture.contentUrl + ")"
        };
      }

      return null;
    },
    openImage(image, text = null) {
      this.imageForModal = image;
      if (text) {
        this.centeredTextForModal = text;
      } else {
        this.centeredTextForModal = null;
      }
      this.imageModal = true;
    },
    asArray(object, field) {
      return (object && object[field]) || [];
    },
    /**
     * Zmieniono grupę, więc zresetuj tkaninę i kolor
     */
    onGroupChanged() {
      this.setMessageFromAccessory(this.item.fabricShadeGroup.message);
      this.item.fabric = this.item.fabricShadeGroup.fabrics[0];
      this.item.fabricColor = this.item.fabricShadeGroup.fabrics[0].defaultFabricColor;
      this.calculatePrice();
    },
    /**
     * Zmieniono napęd, więc zresetuj sterowania
     */
    onGlobalDriveTypeChanged() {
      this.item.globalDrive = null;
      this.driveSeriesFilterComp = this.itemDriveSeries[0] || null;
      //this.setDriveSeriesFilter(this.itemDriveSeries[0] || null);

      this.setMessageFromAccessory(this.driveTypeFilterComp.message);
      this.onGlobalDriveSeriesChanged();
    },
    onGlobalDriveSeriesChanged() {
      this.item.globalDrive =
          this.itemGlobalDrives.sort((first, next) => {
            if (first.liftingCapacityNm - next.liftingCapacityNm < 0) {
              return -1;
            }
            if (first.liftingCapacityNm - next.liftingCapacityNm > 0) {
              return 1;
            }
            if (first.pieceSurcharge - next.pieceSurchare < 0) {
              return -1;
            }
            if (first.pieceSurcharge - next.pieceSurchare > 0) {
              return 1;
            }
            return 0;
          })[0] || null;

      if (this.item.globalDrive) {
        this.item.globalPipe =
            this.item.fabricShade.globalPipes.find(
                pipe =>
                    pipe.globalDrives.findIndex(
                        drive => drive.id === this.item.globalDrive.id
                    ) > -1
            ) || null;
      }
      this.onGlobalDriveChanged(true);
    },
    onFabricShadeGlobalAccessoryEntryChanged() {
      this.setMessageFromAccessory(
          this.item.fabricShadeGlobalAccessoryEntry.globalAccessory.message
      );

      if (
          this.item.fabricShadeGlobalAccessoryEntry.fabricShadeGlobalBalkEntry
      ) {
        const balk = this.itemFabricShadeGlobalBalkEntries.findIndex(
            search =>
                search.id ===
                this.item.fabricShadeGlobalAccessoryEntry.fabricShadeGlobalBalkEntry
                    .id
        );
        if (balk > -1) {
          this.item.fabricShadeGlobalBalkEntry = this.itemFabricShadeGlobalBalkEntries[
              balk
              ];
        }
      }
      this.calculatePrice();
    },
    onFabricShadeGlobalBalkChanged() {
      this.setMessageFromAccessory(
          this.item.fabricShadeGlobalBalkEntry.globalBalk.message
      );

      this.calculatePrice();
    },
    onFabricShadeFabricShadeAdditonalEntriesChanged() {
      this.calculatePrice();
    },
    onFabricShadeGlobalRunnerEntryChanged() {
      this.setMessageFromAccessory(
          this.item.fabricShadeGlobalRunnerEntry.globalRunner.message
      );
      this.calculatePrice();
    },
    onGlobalDriveChanged(shouldCalculateAndChangeMessage = true) {
      if (shouldCalculateAndChangeMessage) {
        this.setMessageFromAccessory(this.item.globalDrive.message);
        this.calculatePrice();
      }
    },
    onQuantityChange() {
      const side = this.item.driveSide;
      if (side !== "P" || side !== "L" || side !== "L+P") {
        this.item.driveSide = "L";
      }
      this.calculatePrice();
    },
    onGlobalRemoteQuantityChange() {
      if (!this.globalOrderRemoteControlIsPosting) {
        this.globalOrderRemoteControlIsPosting = true;
        this.calculatePrice();
        this.globalOrderRemoteControlIsPosting = false;
      }
    },
    /**
     * Zmieniono wersję, więc zresetuj osprzęt
     */
    //TODO please verify default settings, if something is unnecessary just delete it
    onFabricShadeChanged() {
      this.setMessageFromAccessory(this.item.fabricShade.message);
      this.item.width = 0;
      this.item.height = 0;
      this.item.fabricWidth = 0;
      this.driveTypeFilterComp = null;
      this.driveSeriesFilterComp = null;
      this.item.globalDrive = null;

      this.onGlobalDriveChanged(false);

      this.item.fabricShadeGlobalAccessoryEntry = this.itemFabricShadeGlobalAccessoryEntries[0];
      this.item.fabricShadeGlobalBalkEntry = this.itemFabricShadeGlobalBalkEntries[0];
      this.item.quantity = 1;
      this.item.driveSide = "L";
      this.item.fabricShadeGlobalFixingEntry = this.asArray(
          this.item.fabricShade,
          "fabricShadeGlobalFixingEntries"
      )[0];
      this.item.fabricShadeGlobalRunnerEntry = this.itemFabricShadeGlobalRunnerEntries[0];
      this.calculatePrice();
    },
    /**
     * Zmieniono kolekcję, więc ustaw kolor domyslny lub pierwszy
     */
    onFabricChanged() {
      this.setMessageFromAccessory(this.item.fabric.message);
      const defaultColor = this.item.fabric.defaultFabricColor;
      if (defaultColor) {
        this.item.fabricColor = defaultColor;
      } else {
        this.item.fabricColor = this.item.fabric.fabricColors[0] || null;
      }

      this.calculatePrice();
    },
    onFabricColorChanged() {
      this.setMessageFromAccessory(this.item.fabricColor.message);
    },
    onFabricShadeGlobalFixingEntryChanged() {
      this.setMessageFromAccessory(
          this.item.fabricShadeGlobalFixingEntry.globalFixing.message
      );
    },
    confirmTurnableFabric() {
      const value = this.item.hasTurnableFabric;
      const self = this;
      if (!value) {
        return this.$buefy.dialog.confirm({
          message: self.$t("turnable_warning"),
          onConfirm() {
            self.item.hasTurnableFabric = true;
          }
        });
      }
      this.item.hasTurnableFabric = !value;
    },
    onFabricWidthChange(value) {
      if (this.item.fabricShade.fabricWidthOperator === "nie_mniejsza_niz" &&
          (!this.item.width ||
              Math.abs(this.item.fabricWidth - this.item.width) < this.item.fabricShade.fabricWidth)
      ) {
        this.item.width = value + this.item.fabricShade.fabricWidth;
        this.removeError("width");
      }

      if (this.item.fabricShade.fabricWidthOperator === "nie_mniejsza_niz" && this.item.width < this.item.fabricWidth) {
        this.item.width = value + this.item.fabricShade.fabricWidth;
        this.removeError("width");
      }

      if (this.item.fabricShade.fabricWidthOperator === "mniejsza_o" &&
          (this.item.width > this.item.fabricWidth && Math.abs(this.item.fabricWidth - this.item.width) > this.item.fabricShade.fabricWidth)
          || this.item.fabricWidth > this.item.width) {

        this.item.width = value + this.item.fabricShade.fabricWidth;
        this.removeError("width");
      }
      if (this.item.fabricShade.fabricWidthOperator === "mniejsza_o" &&
          (this.item.width > this.item.fabricWidth && Math.abs(this.item.fabricWidth - this.item.width) <= this.item.fabricShade.fabricWidth)
          || this.item.fabricWidth > this.item.width) {
        this.removeError("width");
      }

    },
    isWidthValid() {
      this.removeError("width");

      if (this.item.width > this.maxWidth) {
        this.addError(
            "width",
            this.$t("max_width_error", {
              width: this.formatLength(this.maxWidth),
              lengthUnit: this.lengthUnit
            })
        );
        return false;
      }

      if (this.item.width < this.minWidth) {
        this.addError(
            "width",
            this.$t("min_width_error", {
              width: this.formatLength(this.minWidth),
              lengthUnit: this.lengthUnit
            })
        );
        return false;
      }

      if (this.item.fabricShade.fabricWidthOperator === "nie_mniejsza_niz") {
        if (Math.abs(this.item.width - this.item.fabricWidth) < this.item.fabricShade.fabricWidth && this.item.fabricWidth > 0) {
          this.addError(
              "width",
              this.$t("not_smaller_than", {
                width: this.formatLength(this.item.fabricShade.fabricWidth),
                lengthUnit: this.lengthUnit
              })
          );
          return false;
        }
      }

      if (this.item.fabricShade.fabricWidthOperator === "mniejsza_o") {
        if (Math.abs(this.item.width - this.item.fabricWidth) > this.item.fabricShade.fabricWidth && this.item.fabricWidth > 0) {
          this.addError(
              "width",
              this.$t("smaller_than", {
                width: this.formatLength(this.item.fabricShade.fabricWidth),
                lengthUnit: this.lengthUnit
              })
          );
          return false;
        }
      }

      return true;
    },

    isGlassWidthValid() {
      this.removeError("glassWidth");

      if (this.item.width < this.item.glassWidth) {
        this.addError(
            "glassWidth",
            this.$t("max_glass_width_error", {
              width: this.formatLength(this.item.width),
              lengthUnit: this.lengthUnit
            })
        );
        return false;
      }

      return true;
    },
    onWidthChange() {
      this.showHelpForOrderDimensions = false;
      if (this.isWidthValid()) {
        this.setFabricWidth();
      }
      if (this.isWithGlassSizes) this.isGlassWidthValid()
    },
    onGlassWidthChange() {
      this.showHelpForOrderDimensions = false;
      this.isGlassWidthValid()
    },
    isHeightValid() {
      this.removeError("height");

      if (this.item.height > this.maxHeight) {
        this.addError(
            "height",
            this.$t("max_height_error", {
              height: this.formatLength(this.maxHeight),
              lengthUnit: this.lengthUnit
            })
        );
        return false;
      }

      if (this.item.height < this.minHeight) {
        this.addError(
            "height",
            this.$t("min_height_error", {
              height: this.formatLength(this.minHeight),
              lengthUnit: this.lengthUnit
            })
        );
        return false;
      }

      return true;
    },
    isGlassHeightValid() {
      this.removeError("glassHeight");

      if (this.item.height < this.item.glassHeight) {
        this.addError(
            "glassHeight",
            this.$t("max_glass_height_error", {
              height: this.formatLength(this.item.height),
              lengthUnit: this.lengthUnit
            })
        );
        return false;
      }

      return true;
    },
    onHeightChange() {
      this.showHelpForOrderDimensions = false;
      this.isHeightValid();
      if (this.isWithGlassSizes) this.isGlassHeightValid()
    },
    onGlassHeightChange() {
      this.showHelpForOrderDimensions = false;
      this.isGlassHeightValid()
    },
    setWindowFrame(newWindowFrame) {
      this.windowFrame = newWindowFrame;
    },
    resetWindowFrame() {
      this.windowFrame = null;
    },
    setFabricWidth() {
      if (this.item.fabricShade.fabricWidthOperator === "nie_mniejsza_niz") {
        if (this.item.width && this.item.width > 0 && (!this.item.fabricWidth || (this.item.fabricWidth && Math.abs(this.item.fabricWidth - this.item.width) < this.item.fabricShade.fabricWidth))) {
          this.item.fabricWidth =
              this.item.width - this.item.fabricShade.fabricWidth;
        }
        if (this.item.fabricWidth > this.item.width) {
          this.item.fabricWidth = this.item.width - this.item.fabricShade.fabricWidth;
        }
      }
      if (this.item.fabricShade.fabricWidthOperator === "mniejsza_o") {
        this.item.fabricWidth = this.item.width - this.item.fabricShade.fabricWidth;
      }
    },
    calculatePrice: debounce(async function () {
      if (this.item.width > this.maxWidth) {
        const self = this;
        return this.$buefy.dialog.confirm({
          message: self.$t("set_turnable_suggestion"),
          onConfirm() {
            self.item.hasTurnableFabric = true;
            self.calculatePrice();
          }
        });
      } else if (
          this.item.width >= this.minWidth &&
          this.item.height >= this.minHeight &&
          (!this.isWithGlassSizes || (this.isWithGlassSizes && ((this.item.width >= this.item.glassWidth) && (this.item.height >= this.item.glassHeight))))
      ) {
        this.priceLoading = true;
        let item = simpleClone(this.item);
        item = prepareItemToSent(item);
        delete item["@id"];
        delete item.id;
        await http
            .post(`/calculate/fabric_shade_order_item`, item)
            .then(({data}) => {
              this.item.netPurchaseValue = data.netPurchaseValue;
              this.item.netSalesValue = data.netSalesValue;
              this.isCalculated = true;
            })
            .catch(error => {
              this.isCalculated = false;
              notify(error.response.data["hydra:description"], "danger");
            });
        this.priceLoading = false;
      }
    }, 400),
    addError(field, message) {
      let existsError = this.errors.find(
          ({propertyPath}) => propertyPath === field
      );
      if (existsError) {
        existsError.message = message;
      } else {
        this.errors = this.errors.concat({propertyPath: field, message});
      }
    },
    removeError(field) {
      this.errors = this.errors.filter(
          ({propertyPath}) => field !== propertyPath
      );
    },
    setFirst(value) {
      if (value === "itemFabricShadeGlobalAccessoryEntries") {
        if (this.isEdit && !this.changeStatusesForEditItem.accessory) {
          this.changeStatusesForEditItem.accessory = true;
        } else {
          this.item.fabricShadeGlobalAccessoryEntry =
              this.itemFabricShadeGlobalAccessoryEntries[0] ?? null;
        }
      } else if (value === "itemFabricShadeGlobalBalkEntries") {
        if (this.isEdit && !this.changeStatusesForEditItem.balk) {
          this.changeStatusesForEditItem.balk = true;
        } else {
          this.item.fabricShadeGlobalBalkEntry =
              this.itemFabricShadeGlobalBalkEntries[0] ?? null;
        }
      } else if (value === "itemFabricShadeGroups") {
        if (this.isEdit && !this.changeStatusesForEditItem.group) {
          this.changeStatusesForEditItem.group = true;
        } else {
          this.item.fabricShadeGroup = this.itemFabricShadeGroups[0] ?? null;
          this.item.fabric = this.itemFabrics[0] ?? null;
          this.item.fabricColor =
              this.item.fabric && this.item.fabric.defaultFabricColor
                  ? this.item.fabric.defaultFabricColor
                  : null;
        }
      } else if (value === "itemFabricColors") {
        if (this.isEdit && !this.changeStatusesForEditItem.fabricColor) {
          this.changeStatusesForEditItem.fabricColor = true;
        } else {
          this.item.fabricColor =
              this.item.fabric && this.item.fabric.defaultFabricColor
                  ? this.item.fabric.defaultFabricColor
                  : null;
        }
      } else if (value === "itemFabrics") {
        if (this.isEdit && !this.changeStatusesForEditItem.fabric) {
          this.changeStatusesForEditItem.fabric = true;
        } else {
          this.item.fabric = this.itemFabrics[0] ?? null;
          this.item.fabricColor =
              this.item.fabric && this.item.fabric.defaultFabricColor
                  ? this.item.fabric.defaultFabricColor
                  : null;
        }
      } else if (value === "itemFabricShadeGlobalRunnerEntries") {
        if (this.isEdit && !this.changeStatusesForEditItem.runner) {
          this.changeStatusesForEditItem.runner = true;
        } else {
          this.item.fabricShadeGlobalRunnerEntry =
              this.itemFabricShadeGlobalRunnerEntries[0] ?? null;
        }
      }
    },
    setOversize(value = true) {
      this.item.hasOversize = value;
      if (!value) {
        this.item.hasConfirmedOversize = value;
      }
    },
    confirmOversize: debounce(function () {
      const value = this.isHeightOversized || this.isWidthOversized;
      const confirmed = this.item.hasConfirmedOversize;
      const self = this;

      if (value && !confirmed) {
        this.item.hasOversize = true;
        return this.$buefy.dialog.confirm({
          message: self.$t("oversize_warning"),
          canCancel: false,
          onConfirm() {
            self.item.hasConfirmedOversize = true;
            return true;
          }
        });
      }
    }, 400),
    checkDrive() {
      if (
          (this.driveTypeFilterComp &&
              this.itemDriveTypes.findIndex(
                  type => type.id === this.driveTypeFilterComp.id
              ) < 0) ||
          (this.driveSeriesFilterComp &&
              this.itemDriveSeries.findIndex(
                  series => series.id === this.driveSeriesFilterComp.id
              ) < 0) ||
          (this.item.globalDrive &&
              this.itemGlobalDrives.findIndex(
                  drive => drive.id === this.item.globalDrive.id
              ) < 0)
      ) {
        this.driveTypeFilterComp = null;
        this.driveSeriesFilterComp = null;
        this.item.globalDrive = null;
      }
    },
    handleItemWidthChange() {
      this.confirmOversize();
      this.checkDrive();
      this.calculatePrice();
    },
    handleItemHeightChange() {
      this.confirmOversize();
      this.checkDrive();
      this.calculatePrice();
    },
    handleItemGlassHeightChange() {
      this.checkDrive();
      this.calculatePrice();
    },
    handleItemGlassWidthChange() {
      this.checkDrive();
      this.calculatePrice();
    },
    isOversized: debounce(function () {
      const oversized = this.isHeightOversized || this.isWidthOversized;
      if (oversized) {
        this.setOversize();
      } else {
        this.setOversize(false);
      }
      return oversized;
    }, 400),

    clearValuation() {
      return this.$buefy.dialog.confirm({
        message: this.$t("remove_valuation_confirm"),
        onConfirm: () => {
          this.clearOrder();
        }
      });
    },
  },
  /**
   * Computed properties
   */
  computed: {
    orderHasAtLeastOneItem() {
      return this.order.fabricShadeOrderItems.length > 0;
    },
    companyInstallationValuesBySelectedFabricShade() {
      if (!this.item.fabricShade) {
        return [];
      }

      const installationArray = objectToCollection(
          this.companyInstallationValues
      );

      return installationArray.filter(
          civ =>
              civ.fabricShades &&
              civ.fabricShades.findIndex(
                  fs => fs === this.item.fabricShade["@id"]
              ) > -1
      );
    },
    balkBackgroundPictureWithContain() {
      let picture = null;
      const src = this.item.fabricShadeGlobalBalkEntry;
      const nestedField = "globalBalk";
      picture = this.getPictureFromNestedElement(src, nestedField);
      if (picture) {
        return {
          background: "url(" + picture.contentUrl + ") 0% 0% / contain"
        };
      }
      return null;
    },
    getLocale() {
      const local = this.$i18n.locale.toUpperCase();
      if (this.locales.find(locale => local === locale)) {
        return local;
      }
      return "EN";
    },
    windowFrameBackgroundImage() {
      if (this.windowFrame && this.windowFrame.picture) {
        return {
          backgroundImage: "url(" + this.windowFrame.picture.contentUrl + ")"
        };
      }
      return {
        backgroundColor: "white"
      };
    },
    traitBlackout() {
      return this.$store.getters["fabricShades/traitBlackout"];
    },
    loading() {
      return this.$store.getters["common/loading"];
    },
    itemDataTitle() {
      if (this.isEdit) {
        return "item_edit_data_title";
      }
      return "item_data_title";
    },
    completeButtonLabel() {
      if (this.isEdit) {
        return "save_complete";
      }
      return "add_complete";
    },
    addButtonLabel() {
      if (this.isEdit) {
        return "save";
      }
      return "add";
    },
    driveSideHelp() {
      if (this.item.driveSide === "L+P") {
        return this.$t("l_plus_p_warning");
      }
      return "";
    },
    cantSave() {
      if (!this.isCalculated) {
        return true;
      } else if (this.item.width === 0 || this.item.height === 0) {
        return true;
      } else if (this.isWithGlassSizes && (this.item.glassHeight === 0 || this.item.glassWidth === 0 || (this.item.glassHeight > this.item.height) || (this.item.glassWidth > this.item.width))) {
        return true;
      } else if (!this.item.hasConfirmedOversize && this.isOversized()) {
        return true;
      } else if (
          this.item.width > this.maxWidth ||
          this.item.height > this.maxHeight
      ) {
        return true;
      } else if (!this.item.globalDrive) {
        return true;
      } else if (this.errors.length > 0) {
        return true
      }
      return false;
    },
    getHideImage() {
      return this.hideImage
    },
    ...mapGetters("fabricShades", [
      "order",
      "showForm",
      "image",
      "fabricShades",
      "fabricShadeGroups",
      "fabricShadeGlobalAccessoryEntries",
      "fabricShadeGlobalBalkEntries",
      "fabricShadeGlobalRunnerEntries",
      "fabricShadeGlobalFixingEntries",
      "fabricShadeFabricShadeAdditionalEntries",
      "driveTypeFilter",
      "driveSeriesFilter",
      "itemDriveTypes",
      "itemDriveSeries",
      "itemGlobalDrives",
      "itemGlobalRemoteControls",
      "item",
      "isEdit",
      "itemFabricShadeGroups",
      "itemFabricShadeGlobalAccessoryEntries",
      "itemFabricShadeGlobalBalkEntries",
      "itemFabricColors",
      "itemFabrics",
      "itemFabricShadeGlobalRunnerEntries",
      "message",
      "isMessageBorderAnimating",
      "isWithGlassSizes",
      "changeStatusesForEditItem",
      "itemGlobalDriveControls"
    ]),
    ...mapGetters(["fabricShadeDriveSides", "locales", "hideImage", "hideWholeSalePrice"]),
    ...mapGetters("company", ["lengthUnit", "companyInstallationValues"]),
    ...mapGetters("common", ["formatLength", "formatPrice"]),
    driveTypeFilterComp: {
      get() {
        return this.driveTypeFilter;
      },
      set(newVal) {
        this.setDriveTypeFilter(newVal);
      }
    },
    driveSeriesFilterComp: {
      get() {
        return this.driveSeriesFilter;
      },
      set(newVal) {
        this.setDriveSeriesFilter(newVal);
      }
    },
    pictureTitle() {
      if (this.item.fabricShade) {
        return this.$t("picture_review", {
          fabricShadeName: this.item.fabricShade.name
        });
      }
      return " ";
    },
    widthHelp() {
      const showMax = this.maxWidth !== Number.MAX_SAFE_INTEGER;

      let message = `min: ${this.formatLength(this.minWidth)} ${
          this.lengthUnit
      }`;

      message += showMax
          ? `, max: ${this.formatLength(this.maxWidth)} ${this.lengthUnit}`
          : ``;

      return message;
    },
    heightHelp() {
      const showMax = this.maxHeight !== Number.MAX_SAFE_INTEGER;

      let message = `min: ${this.formatLength(this.minHeight)} ${
          this.lengthUnit
      }`;

      message += showMax
          ? `, max: ${this.formatLength(this.maxHeight)} ${this.lengthUnit}`
          : ``;

      return message;
    },
    minWidth() {
      return this.item.fabricShade && this.item.fabricShade.minWidth;
    },
    maxWidth() {
      const maxWidth = this.item.fabricShade && this.item.fabricShade.maxWidth;
      if (!maxWidth) {
        return maxWidth;
      }
      if (!this.item.hasTurnableFabric) {
        const fabric = this.item.fabric;
        return fabric && fabric.rollWidth / 10;
      } else {
        return Number.MAX_SAFE_INTEGER;
      }
    },
    maxHeight() {
      const fabricShade = this.item.fabricShade;
      const fabric = this.item.fabric;
      const rollWidth = fabric && fabric.rollWidth / 10;
      const fabricHeights = fabric && fabric.fabricHeights;
      const fabricMaxHeightForSelectedFabricShade =
          fabricHeights &&
          fabricHeights.find(
              height => height.fabricShade["@id"] === fabricShade["@id"]
          );

      if (!this.item.hasTurnableFabric) {
        return (
            fabricMaxHeightForSelectedFabricShade &&
            fabricMaxHeightForSelectedFabricShade.height
        );
      } else {
        return rollWidth - fabricShade.productionFabricHeight;
      }
    },
    minHeight() {
      return this.item.fabricShade && this.item.fabricShade.minHeight;
    },
    isWidthOversized() {
      const turnable = this.item.hasTurnableFabric;
      const fabricShade = this.item.fabricShade;
      const fabric = this.item.fabric;
      const rollWidth = fabric && fabric.rollWidth / 10;

      const shadeMaxWidth = fabricShade && fabricShade.maxWidth;

      if (!turnable) {
        return rollWidth > this.item.width && shadeMaxWidth < this.item.width;
      } else {
        return shadeMaxWidth < this.item.width;
      }
    },
    isHeightOversized() {
      const turnable = this.item.hasTurnableFabric;
      const fabricShade = this.item.fabricShade;
      const fabric = this.item.fabric;
      const rollWidth = fabric && fabric.rollWidth / 10;
      const fabricHeights = fabric && fabric.fabricHeights;
      const fabricMaxHeightForSelectedFabricShade =
          fabricHeights &&
          fabricHeights.find(
              height => height.fabricShade["@id"] === fabricShade["@id"]
          );

      if (!turnable) {
        return (
            (fabricMaxHeightForSelectedFabricShade &&
                fabricMaxHeightForSelectedFabricShade.height) < this.item.height
        );
      } else {
        return (
            rollWidth - fabricShade.productionFabricHeight < this.item.height
        );
      }
    }
  },
  /**
   * Watchers
   */
  watch: {
    "item.globalDrive": "onGlobalDriveChanged",
    "item.width": "handleItemWidthChange",
    "item.height": "handleItemHeightChange",
    "item.glassHeight": "handleItemGlassHeightChange",
    "item.glassWidth": "handleItemGlassWidthChange",
    isOversized() {
      this.confirmOversize();
    },
    itemFabricShadeGroups() {
      this.setFirst("itemFabricShadeGroups");
    },
    itemFabricShadeGlobalBalkEntries() {
      this.setFirst("itemFabricShadeGlobalBalkEntries");
    },
    itemFabricShadeGlobalAccessoryEntries() {
      this.setFirst("itemFabricShadeGlobalAccessoryEntries");
    },
    itemFabricColors() {
      this.setFirst("itemFabricColors");
    },
    itemFabricShadeGlobalRunnerEntries() {
      this.setFirst("itemFabricShadeGlobalRunnerEntries");
    },
    itemFabrics() {
      this.setFirst("itemFabrics");
    },
    companyInstallationValuesBySelectedFabricShade(values) {
      if (values.length > 0) {
        this.item.companyInstallationValue = values[0];
      }
    },
    isWithGlassSizes() {
      this.item.fabricWidth = 0;
      this.item.glassHeight = 0;
      this.item.glassWidth = 0;
    }
  }
};
</script>
