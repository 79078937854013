import http from "../../http";
import { onResponseError, onRequest } from "./interceptors";
import store from "../../store";

http.interceptors.request.use(onRequest);
http.interceptors.response.use(response => response, onResponseError);

import common from "../common/store";

store.registerModule("common", common);
