<template lang="pug">
  div
    .columns.is-desktop
      .column.is-6-desktop
        // Nowa pozycja zamówienia
        app-view-card(:title="$t(itemDataTitle)")
          b-notification(type="is-danger" v-if="globalError" :closable="false") {{ globalError }}
          form
          .columns
            .column
              b-field(grouped)
                app-form-field(:label="$t('version')" field="pleat" horizontal expanded)
                  b-field
                    v-select.w100.has-pointer(
                      :options="pleats"
                      label="name"
                      v-model="item.pleat"
                      :clearable="false"
                      @input="onPleatChanged"
                      :placeholder="$t('empty')"
                    )
                      template(v-slot:no-options='') {{$t('empty')}}
                    app-form-hover-image(
                      :image="image(item.pleat, 'picture')"
                      @open="openImage($event)"
                    )

              b-field(grouped)
                app-form-field(:label="$t('case')" field="case" horizontal expanded)
                  b-field
                    v-select.w100.has-pointer(
                      :options="itemPleatGlobalAccessoryEntries"
                      label="name"
                      v-model="item.pleatGlobalAccessoryEntry"
                      :clearable="false"
                      :placeholder="$t('empty')"
                      :getOptionLabel="option => {if (typeof option === 'object'){ if(option.hasOwnProperty('globalAccessory')){ return option.globalAccessory['name'] }} return option;}"
                      @input="onPleatGlobalAccessoryEntryChanged"
                    )
                      template(v-slot:no-options='') {{$t('empty')}}
                    app-form-hover-image(
                      :image="image(item.pleatGlobalAccessoryEntry, 'thumbnail', 'globalAccessory')"
                      @open="openImage($event)"
                    )

              b-field(grouped)
                app-form-field(:label="$t('runners')" field="runner" horizontal expanded)
                  b-field
                    v-select.w100.has-pointer(
                      :options="itemPleatGlobalRunnerEntries"
                      label="name"
                      v-model="item.pleatGlobalRunnerEntry"
                      :clearable="false"
                      :placeholder="$t('empty')"
                      @input="onPleatGlobalRunnerEntryChanged"
                      :getOptionLabel="option => {if (typeof option === 'object'){ if(option.hasOwnProperty('globalRunner')){ return option.globalRunner['name'] }} return option;}"
                    )
                      template(v-slot:no-options='') {{$t('empty')}}
                    app-form-hover-image(
                      :image="image(item.pleatGlobalRunnerEntry, 'thumbnail', 'globalRunner')"
                      @open="openImage($event)"
                    )

              b-field(grouped )
                app-form-field(:label="$t('additional_string')" field="pleatAdditionalString" horizontal expanded :errors="errors")
                  b-field
                    v-select.w100.has-pointer(
                      :options="itemPleatAdditionalStrings.filter(entry => entry.isEnabled)"
                      label="symbol"
                      v-model="item.pleatAdditionalString"
                      :clearable="false"
                      :placeholder="$t('empty')"
                      @input="onPleatAddtionalStringChanged"
                      :getOptionLabel="option => {if (typeof option === 'object'){ if(option.hasOwnProperty('globalRunner')){ return option.globalRunner['name'] }} return option;}"

                    )
                      template(v-slot:no-options='') {{$t('empty')}}
                    app-form-hover-image(
                      :image="image(item.pleatAdditionalString, 'picture')"
                      @open="openImage($event)"
                    )

              b-field(grouped)
                app-form-field(:label="$t('fixing')" field="pleatFixing" horizontal expanded)
                  b-field
                    v-select.w100.has-pointer(
                      :options="asArray(item.pleat, 'pleatPleatFixingTypeEntries').filter(entry => entry.isEnabled)"
                      label="name"
                      v-model="item.pleatPleatFixingTypeEntry"
                      :clearable="false"
                      :placeholder="$t('empty')"
                      @input="onPleatPleatFixingTypeEntryChanged"
                      :getOptionLabel="option => {if (typeof option === 'object'){ if(option.hasOwnProperty('pleatFixingType')){ return option.pleatFixingType['name'] }} return option;}"
                    )
                      template(v-slot:no-options='') {{$t('empty')}}
                    app-form-hover-image(
                      :image="image(item.pleatPleatFixingTypeEntry, 'picture', 'pleatFixingType')"
                      @open="openImage($event)"
                    )

              b-field(grouped)
                app-form-field(:label="$t('drive_control')" field="driveControl" horizontal expanded)
                  b-field
                    v-select.w100.has-pointer(
                      :options="itemPleatPleatRemoteControlTypeEntries.filter(entry => entry.isEnabled)"
                      label="name"
                      v-model="item.pleatPleatRemoteControlTypeEntry"
                      :clearable="false"
                      :placeholder="$t('empty')"
                      @input="onPleatPleatRemoteControlTypeEntryChanged"
                      :getOptionLabel="option => {if (typeof option === 'object'){ if(option.hasOwnProperty('pleatRemoteControlType')){ return option.pleatRemoteControlType['name'] }} return option;}"
                    )
                      template(v-slot:no-options='') {{$t('empty')}}
                    app-form-hover-image(
                      :image="image(item.pleatPleatRemoteControlTypeEntry, 'picture')"
                      @open="openImage($event)"
                    )


              app-form-field(:label="$t('group')" field="pleatGroup" horizontal expanded)
                v-select.w100.has-pointer(
                  :options="itemPleatGroups"
                  label="position"
                  v-model="item.pleatGroup"
                  :clearable="false"
                  :placeholder="$t('empty')"
                  @input="onGroupChanged"
                  class="style-chooser"
                )
                  template(v-slot:no-options='') {{$t('empty')}}
                  template(#option="{ name }") {{ name }}

              app-form-field(:label="$t('collection')" field="pleatFabric" horizontal expanded)
                v-select.w100.has-pointer(
                  :options="itemPleatFabrics"
                  label="name"
                  v-model="item.pleatFabric"
                  :clearable="false"
                  placeholder="Bez tkaniny"
                  @input="onPleatFabricChanged"
                )

              b-field(grouped)
                app-form-field(:label="$t('fabric')" field="pleatFabricColor" horizontal expanded)
                  b-field
                    v-select.w100.has-pointer(
                      :options="itemPleatFabricColors"
                      label="name"
                      v-model="item.pleatFabricColor"
                      :clearable="false"
                      :placeholder="$t('empty')"
                      @input="onFabricColorChanged"
                      :map-keydown="fabricColorHandlers"
                    )
                      template(v-slot:no-options='') {{$t('empty')}}
                    app-form-hover-image(
                      :image="image(item.pleatFabricColor, 'thumbnail')"
                      @open="openImage($event)"
                    )

              b-field(group-multiline )
                app-form-field(:label="$t('additional')" field="" horizontal expanded)
                  b-field()
                    v-select.has-pointer.w100(
                      :options="pleatPleatAdditionalEntries.filter(entry => entry.isEnabled)"
                      label="name"
                      v-model="item.pleatPleatAdditionalEntries"
                      :clearable="false"
                      :placeholder="$t('empty')"
                      :getOptionLabel="option => {if (typeof option === 'object'){ if(option.hasOwnProperty('pleatAdditional')){ return option.pleatAdditional['name'] }} return option;}"
                      @input="onPleatPleatAdditionalEntriesChanged"
                      multiple
                    )
                      template(v-slot:no-options='') {{$t('empty')}}

            .column
              app-form-field(
                :label="$t('order.width', { lengthUnit })"
                field="width"
                :help="widthHelp"
                :showHelp="showHelpForOrderDimensions"
                :errors="errors"
                horizontal
                expanded
              )
                app-form-length(
                  v-model="item.width"
                  :unit="lengthUnit"
                  @blur="onWidthChange"
                  @focus="showHelpForOrderDimensions = true"
                  customClass="pl-1"
                )

              app-form-field(
                :label="$t('order.height', { lengthUnit })"
                field="height"
                :help="heightHelp"
                :showHelp="showHelpForOrderDimensions"
                :errors="errors"
                horizontal
                expanded
              )
                app-form-length(
                  v-model="item.height"
                  :unit="lengthUnit"
                  @focus="showHelpForOrderDimensions = true"
                  @blur="onHeightChange"
                  customClass="pl-1"
                )

              app-form-field(:label="$t('order.pieces')" field="quantity" horizontal expanded)
                b-numberinput(v-model="item.quantity" min="1" max="100" @input="onQuantityChange")

              app-form-field(:label="$t('installation_place')" field="shortDescription" horizontal expanded)
                b-input(v-model="item.shortDescription" maxlength="20" :placeholder="$t('installation_place_example')")

              div(v-if="item.pleat && item.pleat.fabricCount == 2").mt-3.has-highlight
                app-form-field(:label="$t('group_bottom')" field="pleatGroupBottom" horizontal expanded)
                  v-select.w100.has-pointer(
                    :options="itemPleatGroupBottoms"
                    label="position"
                    v-model="item.pleatGroupBottom"
                    :clearable="false"
                    :placeholder="$t('empty')"
                    @input="onGroupBottomChanged"
                    class="style-chooser"
                  )
                    template(v-slot:no-options='') {{$t('empty')}}
                    template(#option="{ name }") {{ name }}

                app-form-field(:label="$t('collection_bottom')" field="pleatFabricBottom" horizontal expanded)
                  v-select.w100.has-pointer(
                    :options="itemPleatFabricBottoms"
                    label="name"
                    v-model="item.pleatFabricBottom"
                    :clearable="false"
                    placeholder="Bez tkaniny"
                    @input="onPleatFabricBottomChanged"
                  )

                b-field(grouped)
                  app-form-field(:label="$t('fabric_bottom')" field="pleatFabricColorBottom" horizontal expanded)
                    b-field
                      v-select.w100.has-pointer(
                        :options="itemPleatFabricColorBottoms"
                        label="name"
                        v-model="item.pleatFabricColorBottom"
                        :clearable="false"
                        :placeholder="$t('empty')"
                        @input="onFabricColorBottomChanged"
                        :map-keydown="fabricColorHandlers"
                      )
                        template(v-slot:no-options='') {{$t('empty')}}
                      app-form-hover-image(
                        :image="image(item.pleatFabricColorBottom, 'thumbnail')"
                        @open="openImage($event)"
                      )

              b-field(grouped).mt-5
                app-form-field(
                  :label="$t('company_installation_value')"
                  field="companyInstallationValue"
                  horizontal
                  expanded
                )
                  b-field
                    v-select.w100.has-pointer(
                      :options="companyInstallationValuesBySelectedPleat"
                      label="name"
                      v-model="item.companyInstallationValue"
                      :clearable="false"
                      :placeholder="$t('empty')"
                    )
                      template(v-slot:no-options='') {{$t('empty')}}

          .columns(v-if="message")
            .column
            .column.is-four-fifths
              .border
                .has-font-size-15.communicate(:class="{'communicate-animation': isMessageBorderAnimating}") {{ message }}
            .column
          table.table.is-fullwidth.is-bordered.is-narrow
            b-loading(:active.sync="priceLoading")
            thead
              tr
                th.has-text-centered.has-border-radius-top(colspan=2) {{ $t('order.total_net_value') }}
              tr
                th(v-if="!hideWholeSalePrice").has-text-centered {{ $t('order.purchase') }}
                th.has-text-centered {{ $t('order.sale') }}
            tbody
              tr
                td(v-if="!hideWholeSalePrice").has-text-right {{ formatPriceValue(formatPrice(item.netPurchaseValue)) }}
                td.has-text-right {{ formatPriceValue(formatPrice(item.netSalesValue)) }}
          b-field
            .buttons
              b-button(
                native-type="submit"
                type="is-primary"
                :loading="submitting"
                @click="saveItem"
                :disabled="cantSave"
              ) {{ $t(addButtonLabel) }}
              //toggle show form
              b-button(type="is-warning" @click="toggleShowForm") {{ $t(`${showForm?'hide':'show'}_form`) }}
              b-button(v-if="isEdit" :loading="submitting" @click.prevent="cancelEdit") {{ $t('cancel') }}
              b-button(type="is-danger" v-if="orderHasAtLeastOneItem" @click.prevent="clearValuation") {{ $t('clear_order') }}
              b-button(@click="calculatePrice") {{ $t('refresh') }}
              //b-button(type="is-primary" @click="automationFormModal = true") {{ $t('automation.title_choose_automation') }}
      .column.is-narrow
        .columns.is-centered
          .column.card-preview.has-min-width-large
            // Podgląd
            .columns
              .column.is-three-quarters-desktop.is-four-fifths-tablet(v-if="!getHideImage")
                app-view-card(:title="pictureTitle" class="has-margin-bottom" paddingless)
                  template(slot="color-picker-selector" v-if="!loading")
                    .columns.is-multiline.has-margin-top-very-small
                      .column
                        b-field(grouped)
                          app-form-field(:label="$t('window_color')" field="window_frame" expanded horizontal)
                            b-field
                              resource-select(
                                :value="windowFrame"
                                resource="window_frames"
                                url="window_frames"
                                extra-props="order[position]=asc"
                                :placeholder="$t('default_frame')"
                                @input="setWindowFrame"
                                nullable
                                expanded
                                full-object
                              )
                              b-button(icon-left="filter-remove" @click.prevent="resetWindowFrame")
                      .column
                        b-field(grouped)
                          app-form-field(:label="$t('wall_color')" field="wall_color" expanded horizontal)
                            b-field
                              input(type="color" v-model="backgroundColor" class="color-picker")

                  div
                    .card-div-preview(:style="{'backgroundColor': backgroundColor}")
                      .shutter(v-if="!loading && item && item.pleat " :style="windowFrameBackgroundImage")
                        .preview
                          //div.shade-top(v-if="item.pleat && item.pleat.previewType !== 'free'" :style="getBackgroundPictureWithContain(item.pleatGlobalAccessoryEntry, 'globalAccessory')")
                          //div.shade-top-free(v-else)
                            div.shade-top-free-left-fixing
                            div.shade-top-free-left-pipe-up
                            div.shade-top-free-left-pipe-down
                            div.shade-top-free-mechanism(:style="getBackgroundPictureWithContain(item.pleatFabricColor)")
                            div.shade-top-free-mechanism-gradient
                            div.shade-top-free-right-pipe-up
                            div.shade-top-free-right-pipe-down
                            div.shade-top-free-right-fixing
                          //div.shade-left(v-if="item.pleat && item.pleat.previewType === 'with_runners'" :style="{'--bg': getPictureUrl(item.pleatGlobalAccessoryEntry, 'globalAccessory')}")

                          div.shade-center.has-pointer(@click="openImage(image(item.pleatFabricColor, 'picture'))" :style="{...getBackgroundPictureWithContain(item.pleatFabricColor), ...{height: (item.pleat.fabricCount === 2 && item.pleat.balkCount == 3) ? '172px' : (item.pleat.fabricCount === 2 && item.pleat.balkCount == 4) ? '132px' :  '343px'}, width:'265px'}" )
                          div.shade-center-bottom.has-pointer(v-if="item.pleat.fabricCount === 2" @click="openImage(image(item.pleatFabricColorBottom, 'picture'))" :style="{...getBackgroundPictureWithContain(item.pleatFabricColorBottom), ...{height: (item.pleat.fabricCount === 2 && item.pleat.balkCount === 4) ? '132px' : '172px', top: (item.pleat.fabricCount === 2 && item.pleat.balkCount === 4) ? '246px' : '206px',  width:'265px'}}")
                          //div.shade-center-gradient(v-if="item.pleat && item.pleat.previewType === 'free'")
                          //div.shade.shade-center-extra-gradient-free(v-if="item.pleat && item.pleat.previewType === 'free'")
                          //div.shade-center-gradient-no-free(v-if="item.pleat && item.pleat.previewType !== 'free'")
                          div.shade-right(v-if="item.pleat && item.pleat.previewType === 'with_runners'" :style="{'--bg': getPictureUrl(item.pleatGlobalAccessoryEntry, 'globalAccessory')}")
                          div.shade-bottom(:style="{'background': getPictureUrl(item.pleatGlobalAccessoryEntry, 'globalAccessory')}")
                            div.balk-handle
                          div.shade-middle-center(v-if="item.pleat.fabricCount === 2 && item.pleat.balkCount === 3" :style="{'background': getPictureUrl(item.pleatGlobalAccessoryEntry, 'globalAccessory')}")
                            div.balk-handle
                          div.shade-middle-top(v-if="item.pleat.fabricCount === 2 && item.pleat.balkCount === 4" :style="{'background': getPictureUrl(item.pleatGlobalAccessoryEntry, 'globalAccessory')}")
                            div.balk-handle
                          div.shade-middle-bottom(v-if="item.pleat.fabricCount === 2 && item.pleat.balkCount === 4" :style="{'background': getPictureUrl(item.pleatGlobalAccessoryEntry, 'globalAccessory')}")
                            div.balk-handle

                          div.shade-top-pleat( :style="{'background': getPictureUrl(item.pleatGlobalAccessoryEntry, 'globalAccessory')}")
                            div.balk-handle
                          //div.chain-left(v-if="item.driveSide === 'L+P' || item.driveSide === 'L'")
                          //  div.chain-fixing
                          //  div.chain
                          //  div.chain-handle
                          div.chain-right(v-if="item.pleat.isRequiredAdditionalStrain === true")
                            div.chain-fixing
                            div.chain
                            div.chain-handle
                    .picture-review-help {{ $t('picture_review_help') }}
              br
              .column
                .columns.is-multiline.is-mobile
                  .column.is-12-desktop.is-12-tablet
                    .columns
                      .column
                        app-view-card(:title="$t('version')" class="has-margin-bottom")
                          figure
                            .level
                              .level-item
                                img.has-pointer(
                                  v-if="image(item.pleat, 'mechanismPicture')"
                                  :src="image(item.pleat, 'mechanismPicture')"
                                  width="76"
                                  @click="openImage(image(item.pleat, 'mechanismPicture'))"
                                )
                  .column.is-12-desktop.is-12-tablet
                    .columns
                      .column
                        app-view-card(:title="$t('survey')" class="has-margin-bottom")
                          figure
                            .level
                              .level-item
                                img.has-pointer(
                                  v-if="image(item.pleat, 'surveyPicture')"
                                  :src="image(item.pleat, 'surveyPicture')"
                                  width="100"
                                  @click="openImage(image(item.pleat, 'surveyPicture'), item.pleat['surveyDescription'+getLocale])"
                                )
                  .column.is-12-desktop.is-12-tablet
                    .columns
                      .column
                        app-view-card(:title="$t('files')")
                          a(
                            v-if="item.pleat && item.pleat.measurementInstruction"
                            class="button is-primary"
                            :href="item.pleat.measurementInstruction.contentUrl"
                            target="_blank"
                            download
                          ) {{ $t('measurementInstruction') }}
    br
    b-modal(v-model="imageModal")
      .columns(style="width:99%").has-text-centered
        img(:src="imageForModal")
        .columns(v-if="centeredTextForModal")
          .column
          .column.is-half.has-text-centered
            .has-background-white.has-text-black(v-html="centeredTextForModal")
          .column
    //automation-form-modal( productType="pleats" :globalDriveControls="itemGlobalDriveControls()" :automationModalShow="automationFormModal" @close="automationFormModal=false")
</template>

<script>
import http from "../../../../http";
import {mapActions, mapGetters, mapMutations} from "vuex";
import notify from "../../../../helpers/notify";
import simpleClone from "../../../../helpers/simpleClone";
import ResourceSelect from "@/modules/common/components/ResourceSelect";
import prepareItemToSent from "@/helpers/prepareItemToSent";
import objectToCollection from "@/helpers/objectToCollection";
import priceFormatter from "../../../../helpers/priceFormatter";
import AutomationFormModal from "../../../automation/components/AutomationFormModal";

function debounce(func, wait, immediate) {
  let timeout;
  return function () {
    const context = this,
        args = arguments;
    const later = function () {
      timeout = null;
      if (!immediate) func.apply(context, args);
    };
    const callNow = immediate && !timeout;
    clearTimeout(timeout);
    timeout = setTimeout(later, wait);
    if (callNow) func.apply(context, args);
  };
}

export default {
  components: {ResourceSelect, AutomationFormModal},
  data() {
    return {
      globalError: null,
      errors: [],
      submitting: false,
      showWidthError: false,
      priceLoading: false,
      imageModal: false,
      imageForModal: "",
      centeredTextForModal: null,
      hoverImage: null,
      backgroundColor: "#E6E6E6",
      windowFrame: null,
      globalOrderRemoteControlIsPosting: false,
      showHelpForOrderDimensions: false,
      isCalculated: false,
      automationFormModal: false,
    };
  },
  methods: {
    formatPriceValue(value) {
      return priceFormatter(value);
    },
    ...mapActions("pleats", [
      "setMessage",
      "setIsMessageBorderAnimating",
      "toggleShowForm",
      "saveItem",
      "cancelEdit",
      "setDriveTypeFilter",
      "setDriveSeriesFilter",
      "saveItemAndClose",
      "updateOrder",
      "updateClearOrder",
      "clearOrder"
    ]),
    ...mapMutations("pleats", ["setItem"]),
    setMessageFromAccessory(message) {
      if (message && message.length > 0) {
        this.setMessage(message);
        this.setIsMessageBorderAnimating(true);
        setTimeout(() => {
          this.setIsMessageBorderAnimating(false);
        }, 1000);
      }else{
        this.setMessage('');
      }
    },
    //nadpisuje eventy keyup i keydown, preventDefault jest defaultowy, oryginalnie zwraca fora, który jest tu wykonywany
    //emit jest customowy
    //up
    fabricColorHandlers: (map, vm) => ({
      ...map,
      38: e => {
        e.preventDefault();

        for (let i = vm.typeAheadPointer - 1; i >= 0; i--) {
          if (vm.selectable(vm.filteredOptions[i])) {
            vm.typeAheadPointer = i;
            break;
          }
        }
        vm.$emit("input", vm.filteredOptions[vm.typeAheadPointer]);
      },
      //down
      40: e => {
        e.preventDefault();
        for (
            let i = vm.typeAheadPointer + 1;
            i < vm.filteredOptions.length;
            i++
        ) {
          if (vm.selectable(vm.filteredOptions[i])) {
            vm.typeAheadPointer = i;
            break;
          }
        }
        vm.$emit("input", vm.filteredOptions[vm.typeAheadPointer]);
      }
    }),
    getPictureFromNestedElement(src, nestedElement) {
      const item = src && src[nestedElement];
      const picture = item && item["picture"];

      return picture || null;
    },
    //zwraca prosty url
    getPictureUrl(src, nestedField = null) {
      let picture = null;

      if (nestedField) {
        picture = this.getPictureFromNestedElement(src, nestedField);
      } else {
        picture = src && src["picture"];
      }

      if (picture) {
        return "url(" + picture.contentUrl + ")";
      }

      return null;
    },
    //zwraca obiekt stylu z tłem i background-size: contain
    getBackgroundPictureWithContain(src, nestedField = null) {
      let picture = null;

      if (nestedField) {
        picture = this.getPictureFromNestedElement(src, nestedField);
      } else {
        picture = src && src["thumbnail"];
      }

      if (picture) {
        return {
          background: "url(" + picture.contentUrl + ") 0% 0% / contain"
        };
      }
      return null;
    },
    //zwraca obiekt stylu z tłem
    getBackgroundPicture(src, nestedField = null) {
      let picture = null;

      if (nestedField) {
        picture = this.getPictureFromNestedElement(src, nestedField);
      } else {
        picture = src && src["picture"];
      }

      if (picture) {
        return {
          background: "url(" + picture.contentUrl + ")"
        };
      }

      return null;
    },
    openImage(image, text = null) {
      this.imageForModal = image;
      if (text) {
        this.centeredTextForModal = text;
      } else {
        this.centeredTextForModal = null;
      }
      this.imageModal = true;
    },
    asArray(object, field) {
      return (object && object[field]) || [];
    },
    /**
     * Zmieniono grupę, więc zresetuj tkaninę i kolor i przelicz jeśli możliwe
     */
    onGroupChanged() {
      this.setMessageFromAccessory(this.item.pleatGroup.message);
      this.item.pleatFabric = this.item.pleatGroup.pleatFabrics[0];
      if(this.item.pleatGroup.pleatFabrics[0].defaultPleatFabricColor !== null){
        this.item.pleatFabricColor = this.item.pleatFabric.defaultPleatFabricColor;
      }else{
        this.item.pleatFabricColor = this.item.pleatFabric.pleatFabricColors[0]
      }

      this.calculatePrice();
    },
    /**
     * Zmieniono kolor tkaniny, więc zresetuj kolor i przelicz jeśli możliwe
     */
    onGroupBottomChanged() {
      this.setMessageFromAccessory(this.item.pleatGroupBottom.message);
      this.item.pleatFabricBottom = this.item.pleatGroupBottom.pleatFabrics[0];
      if(this.item.pleatGroupBottom.pleatFabrics[0].defaultPleatFabricColor !== null){
        this.item.pleatFabricColorBottom = this.item.pleatFabricBottom.defaultPleatFabricColor;
      }else{
        this.item.pleatFabricColorBottom = this.item.pleatFabricBottom.pleatFabricColors[0]
      }

      this.calculatePrice();
    },
    onPleatGlobalAccessoryEntryChanged() {
      this.setMessageFromAccessory(
          this.item.pleatGlobalAccessoryEntry.globalAccessory.message
      );

      this.calculatePrice();
    },
    onPleatPleatAdditonalEntriesChanged() {
      this.calculatePrice();
    },
    onPleatGlobalRunnerEntryChanged() {
      this.setMessageFromAccessory(
          this.item.pleatGlobalRunnerEntry.globalRunner.message
      );
      this.calculatePrice();
    },
    onQuantityChange() {
      this.calculatePrice();
    },
    /**
     * Zmieniono wersję, więc zresetuj osprzęt
     */
    onPleatChanged() {
      this.setMessageFromAccessory(this.item.pleat.message);
      this.item.width = 0;
      this.item.height = 0;

      this.item.pleatGlobalAccessoryEntry = this.itemPleatGlobalAccessoryEntries.length > 0 ? this.itemPleatGlobalAccessoryEntries[0] : null;
      this.item.quantity = 1;
      this.item.pleatPleatFixingTypeEntry = this.itemPleatPleatFixingTypeEntries.length > 0 ? this.itemPleatPleatFixingTypeEntries[0] : null;
      this.item.pleatGlobalRunnerEntry = this.itemPleatGlobalRunnerEntries.length > 0 ? this.itemPleatGlobalRunnerEntries[0] : null;
      this.item.pleatAdditionalString = this.itemPleatAdditionalStrings.length > 0 ? this.itemPleatAdditionalStrings[0] : null;
      this.item.pleatPleatRemoteControlTypeEntry = this.itemPleatPleatRemoteControlTypeEntries.length > 0 ? this.itemPleatPleatRemoteControlTypeEntries[0] : null;

      if (this.item && this.item.pleat && this.item.pleat.fabricCount === 2) {
        this.item.pleatGroupBottom = this.pleatGroups && this.pleatGroups.length > 0 ? this.pleatGroups[0] : null;
        this.item.pleatFabricBottom = this.itemPleatFabrics && this.itemPleatFabrics.length > 0 ? this.itemPleatFabrics[0] : null;
        this.item.pleatFabricColorBottom = this.itemPleatFabrics.length > 0 ? this.itemPleatFabrics[0].pleatFabricColors[0] : null;
      }else if(this.item && this.item.pleat && this.item.pleat.fabricCount === 1){
        this.item.pleatGroupBottom = null;
        this.item.pleatFabricBottom = null;
        this.item.pleatFabricColorBottom = null;
      }

      this.calculatePrice();
    },

    /**
     * Zmieniono kolekcję, więc ustaw kolor domyslny lub pierwszy
     */
    onPleatFabricChanged() {
      this.setMessageFromAccessory(this.item.pleatFabric.message);
      const defaultColor = this.item.pleatFabric.defaultPleatFabricColor;
      if (defaultColor !== null) {
        this.item.pleatFabricColor = defaultColor;
      } else {
        this.item.pleatFabricColor = this.item.pleatFabric.pleatFabricColors[0] || null;
      }

      this.calculatePrice();
    },
    /**
     * Zmieniono kolekcję, więc ustaw kolor domyslny lub pierwszy
     */
    onPleatFabricBottomChanged() {
      this.setMessageFromAccessory(this.item.pleatFabricBottom.message);
      const defaultColor = this.item.pleatFabricBottom.defaultPleatFabricColor;
      if (defaultColor !== null) {
        this.item.pleatFabricColorBottom = defaultColor;
      } else {
        this.item.pleatFabricColorBottom = this.item.pleatFabricBottom.pleatFabricColors[0] || null;
      }

      this.calculatePrice();
    },
    onFabricColorChanged() {
      this.setMessageFromAccessory(this.item.pleatFabricColor.message);
    },
    onFabricColorBottomChanged() {
      this.setMessageFromAccessory(this.item.pleatFabricColorBottom.message);
    },
    onPleatPleatFixingTypeEntryChanged() {
      this.calculatePrice()
    },
    onPleatPleatRemoteControlTypeEntryChanged() {
      this.calculatePrice()
    },
    onPleatPleatAdditionalEntriesChanged(){
      this.calculatePrice()
    },
    onPleatAddtionalStringChanged(){
      this.removeError('pleatAdditionalString')
      this.calculatePrice()
    },
    onFabricWidthChange(value) {
      if (this.item.pleat.fabricWidthOperator === "nie_mniejsza_niz" &&
          (!this.item.width ||
              Math.abs(this.item.fabricWidth - this.item.width) < this.item.pleat.fabricWidth)
      ) {
        this.item.width = value + this.item.pleat.fabricWidth;
        this.removeError("width");
      }

      if (this.item.pleat.fabricWidthOperator === "nie_mniejsza_niz" && this.item.width < this.item.fabricWidth) {
        this.item.width = value + this.item.pleat.fabricWidth;
        this.removeError("width");
      }

      if (this.item.pleat.fabricWidthOperator === "mniejsza_o" &&
          (this.item.width > this.item.fabricWidth && Math.abs(this.item.fabricWidth - this.item.width) > this.item.pleat.fabricWidth)
          || this.item.fabricWidth > this.item.width) {

        this.item.width = value + this.item.pleat.fabricWidth;
        this.removeError("width");
      }
      if (this.item.pleat.fabricWidthOperator === "mniejsza_o" &&
          (this.item.width > this.item.fabricWidth && Math.abs(this.item.fabricWidth - this.item.width) <= this.item.pleat.fabricWidth)
          || this.item.fabricWidth > this.item.width) {
        this.removeError("width");
      }

    },
    isWidthValid() {
      this.removeError("width");

      if (this.item.width > this.maxWidth) {
        this.addError(
            "width",
            this.$t("max_width_error", {
              width: this.formatLength(this.maxWidth),
              lengthUnit: this.lengthUnit
            })
        );
        return false;
      }

      if (this.item.width < this.minWidth) {
        this.addError(
            "width",
            this.$t("min_width_error", {
              width: this.formatLength(this.minWidth),
              lengthUnit: this.lengthUnit
            })
        );
        return false;
      }

      if (this.item.pleat.fabricWidthOperator === "nie_mniejsza_niz") {
        if (Math.abs(this.item.width - this.item.fabricWidth) < this.item.pleat.fabricWidth && this.item.fabricWidth > 0) {
          this.addError(
              "width",
              this.$t("not_smaller_than", {
                width: this.formatLength(this.item.pleat.fabricWidth),
                lengthUnit: this.lengthUnit
              })
          );
          return false;
        }
      }

      if (this.item.pleat.fabricWidthOperator === "mniejsza_o") {
        if (Math.abs(this.item.width - this.item.fabricWidth) > this.item.pleat.fabricWidth && this.item.fabricWidth > 0) {
          this.addError(
              "width",
              this.$t("smaller_than", {
                width: this.formatLength(this.item.pleat.fabricWidth),
                lengthUnit: this.lengthUnit
              })
          );
          return false;
        }
      }

      return true;
    },

    onWidthChange() {
      this.showHelpForOrderDimensions = false;
      if (this.isWidthValid()) {
        this.setFabricWidth();
      }
    },

    isHeightValid() {
      this.removeError("height");

      if (this.item.height > this.maxHeight) {
        this.addError(
            "height",
            this.$t("max_height_error", {
              height: this.formatLength(this.maxHeight),
              lengthUnit: this.lengthUnit
            })
        );
        return false;
      }

      if (this.item.height < this.minHeight) {
        this.addError(
            "height",
            this.$t("min_height_error", {
              height: this.formatLength(this.minHeight),
              lengthUnit: this.lengthUnit
            })
        );
        return false;
      }

      return true;
    },
    onHeightChange() {
      this.showHelpForOrderDimensions = false;
      this.isHeightValid();
    },
    setWindowFrame(newWindowFrame) {
      this.windowFrame = newWindowFrame;
    },
    resetWindowFrame() {
      this.windowFrame = null;
    },
    setFabricWidth() {
      if (this.item.pleat.fabricWidthOperator === "nie_mniejsza_niz") {
        if (this.item.width && this.item.width > 0 && (!this.item.fabricWidth || (this.item.fabricWidth && Math.abs(this.item.fabricWidth - this.item.width) < this.item.pleat.fabricWidth))) {
          this.item.fabricWidth =
              this.item.width - this.item.pleat.fabricWidth;
        }
        if (this.item.fabricWidth > this.item.width) {
          this.item.fabricWidth = this.item.width - this.item.pleat.fabricWidth;
        }
      }
      if (this.item.pleat.fabricWidthOperator === "mniejsza_o") {
        this.item.fabricWidth = this.item.width - this.item.pleat.fabricWidth;
      }
    },
    calculatePrice: debounce(async function () {
     if(!this.item.pleatAdditionalString){
        this.addError(
            "pleatAdditionalString",
            this.$t("default_pleat_additional_string_required")
        );
      }else if(this.item.pleat.fabricCount === 2 && !this.item.pleatGroupBottom){
       this.addError(
           "pleatGroupBottom",
           this.$t("default_pleat_group_bottom_required")
       );
     }else if (
          this.item.width >= this.minWidth &&
          this.item.height >= this.minHeight
       ) {
        this.priceLoading = true;
        let item = simpleClone(this.item);
        item = prepareItemToSent(item);
        delete item["@id"];
        delete item.id;
        await http
            .post(`/calculate/pleat_order_item`, item)
            .then(({data}) => {
              this.item.netPurchaseValue = data.netPurchaseValue;
              this.item.netSalesValue = data.netSalesValue;
              this.isCalculated = true;
            })
            .catch(error => {
              this.isCalculated = false;
              notify(error.response.data["hydra:description"], "danger");
            });
        this.priceLoading = false;
      }
    }, 400),
    addError(field, message) {
      let existsError = this.errors.find(
          ({propertyPath}) => propertyPath === field
      );
      if (existsError) {
        existsError.message = message;
      } else {
        this.errors = this.errors.concat({propertyPath: field, message});
      }
    },
    removeError(field) {
      this.errors = this.errors.filter(
          ({propertyPath}) => field !== propertyPath
      );
    },
    setFirst(value) {
      try {
        if (value === "itemPleatGlobalAccessoryEntries") {
          if (this.isEdit && !this.changeStatusesForEditItem.accessory) {
            this.changeStatusesForEditItem.accessory = true;
          } else {
            this.item.pleatGlobalAccessoryEntry =
                this.itemPleatGlobalAccessoryEntries[0] ?? null;
          }
        } else if (value === "itemPleatGroups") {
          if (this.isEdit && !this.changeStatusesForEditItem.group) {
            this.changeStatusesForEditItem.group = true;
          } else {
            this.item.pleatGroup = this.itemPleatGroups[0] ?? null;
            this.item.pleatFabric = this.itemPleatFabrics[0] ?? null;
            this.item.pleatFabricColor =
                this.item.pleatFabric && this.item.pleatFabric.defaultPleatFabricColor !== null
                    ? this.item.pleatFabric.defaultPleatFabricColor
                    : this.item.pleatFabric?.pleatFabricColors[0];
          }
        } else if (value === "itemPleatGroupBottoms") {
          if (this.isEdit && !this.changeStatusesForEditItem.groupBottom) {
            this.changeStatusesForEditItem.groupBottom = true;
          } else {
            this.item.pleatGroupBottom = this.itemPleatGroupBottoms[0] ?? null;
            this.item.pleatFabricBottom = this.itemPleatFabricBottoms[0] ?? null;
            this.item.pleatFabricColorBottom =
                this.item.pleatFabricBottom && this.item.pleatFabricBottom.defaultPleatFabricColor !== null
                    ? this.item.pleatFabricBottom.defaultPleatFabricColor
                    : this.item.pleatFabricBottom?.pleatFabricColors[0];
          }
        } else if (value === "itemPleatFabricColors") {
          if (this.isEdit && !this.changeStatusesForEditItem.pleatFabricColor) {
            this.changeStatusesForEditItem.pleatFabricColor = true;
          } else {
            this.item.pleatFabricColor = (this.item.pleatFabric && this.item.pleatFabric.defaultPleatFabricColor !== null)
                ? this.item.pleatFabric.defaultPleatFabricColor
                : this.item.pleatFabric?.pleatFabricColors[0];
          }
        } else if (value === "itemPleatFabricColorBottoms") {
          if (this.isEdit && !this.changeStatusesForEditItem.pleatFabricColorBottom) {
            this.changeStatusesForEditItem.pleatFabricColorBottom = true;
          } else {
            this.item.pleatFabricColorBottom =
                (this.item.pleatFabricBottom && this.item.pleatFabricBottom.defaultPleatFabricColor !== null)
                    ? this.item.pleatFabricBottom.defaultPleatFabricColor
                    : this.item.pleatFabricBottom?.pleatFabricColors[0];
          }
        } else if (value === "itemPleatFabrics") {
          if (this.isEdit && !this.changeStatusesForEditItem.pleatFabricBottom) {
            this.changeStatusesForEditItem.pleatFabric = true;
          } else {
            this.item.pleatFabric = this.itemPleatFabrics[0] ?? null;
            this.item.pleatFabricColor =
                (this.item.pleatFabric && this.item.pleatFabric.defaultPleatFabricColor !== null)
                    ? this.item.pleatFabric.defaultPleatFabricColor
                    : this.item.pleatFabric?.pleatFabaricColors[0]
          }
        } else if (value === "itemPleatFabricBottoms") {
          if (this.isEdit && !this.changeStatusesForEditItem.pleatFabricBottom) {
            this.changeStatusesForEditItem.pleatFabricBottom = true;
          } else {
            this.item.pleatFabricBottom = this.itemPleatFabricBottoms[0] ?? null;
            this.item.pleatFabricColorBottom =
                this.item.pleatFabricBottom && this.item.pleatFabricBottom.defaultPleatFabricColor !== null
                    ? this.item.pleatFabricBottom.defaultPleatFabricColor
                    : null;
          }
        } else if (value === "itemPleatGlobalRunnerEntries") {
          if (this.isEdit && !this.changeStatusesForEditItem.runner) {
            this.changeStatusesForEditItem.runner = true;
          } else {
            this.item.pleatGlobalRunnerEntry =
                this.itemPleatGlobalRunnerEntries[0] ?? null;
          }
        } else if (value === "itemPleatAdditionalStrings") {
          if (this.isEdit && !this.changeStatusesForEditItem.additionalString) {
            this.changeStatusesForEditItem.additionalString = true;
          } else {
            this.item.pleatAdditionalString =
                this.itemPleatAdditionalStrings[0] ?? null;
          }
        }
      }catch(err){
        return 0
      }
    },
    setOversize(value = true) {
      this.item.hasOversize = value;
      if (!value) {
        this.item.hasConfirmedOversize = value;
      }
    },
    confirmOversize: debounce(function () {
      const value = this.isHeightOversized || this.isWidthOversized;
      const confirmed = this.item.hasConfirmedOversize;
      const self = this;

      if (value && !confirmed) {
        this.item.hasOversize = true;
        return this.$buefy.dialog.confirm({
          message: self.$t("oversize_warning"),
          canCancel: false,
          onConfirm() {
            self.item.hasConfirmedOversize = true;
            return true;
          }
        });
      }
    }, 400),
    checkDrive() {
      if (
          (this.driveTypeFilterComp &&
              this.itemDriveTypes.findIndex(
                  type => type.id === this.driveTypeFilterComp.id
              ) < 0) ||
          (this.driveSeriesFilterComp &&
              this.itemDriveSeries.findIndex(
                  series => series.id === this.driveSeriesFilterComp.id
              ) < 0) ||
          (this.item.globalDrive &&
              this.itemGlobalDrives.findIndex(
                  drive => drive.id === this.item.globalDrive.id
              ) < 0)
      ) {
        this.driveTypeFilterComp = null;
        this.driveSeriesFilterComp = null;
        this.item.globalDrive = null;
      }
    },
    handleItemWidthChange() {
      this.confirmOversize();
      this.checkDrive();
      this.calculatePrice();
    },
    handleItemHeightChange() {
      this.confirmOversize();
      this.checkDrive();
      this.calculatePrice();
    },
    handleItemGlassHeightChange() {
      this.checkDrive();
      this.calculatePrice();
    },
    handleItemGlassWidthChange() {
      this.checkDrive();
      this.calculatePrice();
    },
    isOversized: debounce(function () {
      const oversized = this.isHeightOversized || this.isWidthOversized;
      if (oversized) {
        this.setOversize();
      } else {
        this.setOversize(false);
      }
      return oversized;
    }, 400),

    clearValuation() {
      return this.$buefy.dialog.confirm({
        message: this.$t("remove_valuation_confirm"),
        onConfirm: () => {
          this.clearOrder();
        }
      });
    },
  },
  /**
   * Computed properties
   */
  computed: {
    orderHasAtLeastOneItem() {
      return this.order.pleatOrderItems.length > 0;
    },
    companyInstallationValuesBySelectedPleat() {
      if (!this.item.pleat) {
        return [];
      }

      const installationArray = objectToCollection(
          this.companyInstallationValues
      );

      return installationArray.filter(
          civ =>
              civ.pleats &&
              civ.pleats.findIndex(
                  fs => fs === this.item.pleat["@id"]
              ) > -1
      );
    },
    balkBackgroundPictureWithContain() {
      let picture = null;
      const src = this.item.pleatGlobalBalkEntry;
      const nestedField = "globalBalk";
      picture = this.getPictureFromNestedElement(src, nestedField);
      if (picture) {
        return {
          background: "url(" + picture.contentUrl + ") 0% 0% / contain"
        };
      }
      return null;
    },
    getLocale() {
      const local = this.$i18n.locale.toUpperCase();
      if (this.locales.find(locale => local === locale)) {
        return local;
      }
      return "EN";
    },
    windowFrameBackgroundImage() {
      if (this.windowFrame && this.windowFrame.picture) {
        return {
          backgroundImage: "url(" + this.windowFrame.picture.contentUrl + ")"
        };
      }
      return {
        backgroundColor: "white"
      };
    },
    traitBlackout() {
      return this.$store.getters["pleats/traitBlackout"];
    },
    loading() {
      return this.$store.getters["common/loading"];
    },
    itemDataTitle() {
      if (this.isEdit) {
        return "item_edit_data_title";
      }
      return "item_data_title";
    },
    completeButtonLabel() {
      if (this.isEdit) {
        return "save_complete";
      }
      return "add_complete";
    },
    addButtonLabel() {
      if (this.isEdit) {
        return "save";
      }
      return "add";
    },
    driveSideHelp() {
      if (this.item.driveSide === "L+P") {
        return this.$t("l_plus_p_warning");
      }
      return "";
    },
    cantSave() {
      if (!this.isCalculated) {
        return true;
      } else if (this.item.width === 0 || this.item.height === 0) {
        return true;
      } else if (
          this.item.width > this.maxWidth ||
          this.item.height > this.maxHeight
      ) {
        return true;
      } else if (
          !this.item.pleatPleatFixingTypeEntry
      ) {
        return true;
      }else if (this.errors.length > 0) {
        return true
      }
      return false;
    },
    getHideImage() {
      return this.hideImage
    },
    ...mapGetters("pleats", [
      "order",
      "showForm",
      "image",
      "pleats",
      "pleatGroups",
      "pleatGlobalAccessoryEntries",
      "pleatGlobalBalkEntries",
      "pleatGlobalRunnerEntries",
      "pleatAdditionalStrings",
      "pleatPleatAdditionalEntries",
      "itemPleatPleatFixingTypeEntries",
      "itemPleatPleatRemoteControlTypeEntries",
      "itemPleatAdditionalStrings",
      "item",
      "isEdit",
      "itemPleatGroups",
      "itemPleatGroupBottoms",
      "itemPleatGlobalAccessoryEntries",
      "itemPleatFabricColors",
      "itemPleatFabricColorBottoms",
      "itemPleatFabrics",
      "itemPleatFabricBottoms",
      "itemPleatGlobalRunnerEntries",
      "message",
      "isMessageBorderAnimating",
      "isRequiredAdditionalStrain",
      "changeStatusesForEditItem",
      "itemGlobalDriveControls"
    ]),
    ...mapGetters(["driveSides", "locales", "hideImage", "hideWholeSalePrice"]),
    ...mapGetters("company", ["lengthUnit", "companyInstallationValues"]),
    ...mapGetters("common", ["formatLength", "formatPrice"]),
    driveTypeFilterComp: {
      get() {
        return this.driveTypeFilter;
      },
      set(newVal) {
        this.setDriveTypeFilter(newVal);
      }
    },
    driveSeriesFilterComp: {
      get() {
        return this.driveSeriesFilter;
      },
      set(newVal) {
        this.setDriveSeriesFilter(newVal);
      }
    },
    pictureTitle() {
      if (this.item.pleat) {
        return this.$t("picture_review", {
          pleatName: this.item.pleat.name
        });
      }
      return " ";
    },
    widthHelp() {
      const showMax = this.maxWidth !== Number.MAX_SAFE_INTEGER;

      let message = `min: ${this.formatLength(this.minWidth)} ${
          this.lengthUnit
      }`;

      message += showMax
          ? `, max: ${this.formatLength(this.maxWidth)} ${this.lengthUnit}`
          : ``;

      return message;
    },
    heightHelp() {
      const showMax = this.maxHeight !== Number.MAX_SAFE_INTEGER;

      let message = `min: ${this.formatLength(this.minHeight)} ${
          this.lengthUnit
      }`;

      message += showMax
          ? `, max: ${this.formatLength(this.maxHeight)} ${this.lengthUnit}`
          : ``;

      return message;
    },
    minWidth() {
      return this.item.pleat && this.item.pleat.minWidth;
    },
    maxWidth() {
      const maxWidth = this.item.pleat && this.item.pleat.maxWidth;
      if (!maxWidth) {
        return maxWidth;
      }
        return Number.MAX_SAFE_INTEGER;

    },
    maxHeight() {
      const pleat = this.item.pleat;
      const fabric = this.item.pleatFabric;

      const fabricHeights = fabric && fabric.pleatFabricHeights;
      const fabricMaxHeightForSelectedPleat =
          fabricHeights &&
          fabricHeights.length > 0 &&
          fabricHeights.find(
              height => height.pleat["@id"] === pleat["@id"]
          );


      if(fabricMaxHeightForSelectedPleat && fabricMaxHeightForSelectedPleat.height !== 0)return fabricMaxHeightForSelectedPleat.height / 10

      return fabric && fabric.rollWidth

    },
    minHeight() {
      return this.item.pleat && this.item.pleat.minHeight;
    },
  },
  /**
   * Watchers
   */
  watch: {
    "item.width": "handleItemWidthChange",
    "item.height": "handleItemHeightChange",
    // itemPleatGroups() {
    //   this.setFirst("itemPleatGroups");
    // },
    // itemPleatGroupBottoms() {
    //   this.setFirst("itemPleatGroupBottoms");
    // },
    itemPleatGlobalAccessoryEntries() {
      this.setFirst("itemPleatGlobalAccessoryEntries");
    },
    // itemPleatFabricColors() {
    //   this.setFirst("itemPleatFabricColors");
    // },
    // itemPleatFabricColorBottoms() {
    //   this.setFirst("itemPleatFabricColorBottoms");
    // },
    itemPleatGlobalRunnerEntries() {
      this.setFirst("itemPleatGlobalRunnerEntries");
    },
    // itemPleatFabrics() {
    //   this.setFirst("itemPleatFabrics");
    // },
    // itemPleatFabricBottoms() {
    //   this.setFirst("itemPleatFabricBottoms");
    // },
    companyInstallationValuesBySelectedPleat(values) {
      if (values.length > 0) {
        this.item.companyInstallationValue = values[0];
      }
    },
  }
};
</script>
