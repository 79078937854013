<template lang="pug">
  .columns.is-multiline
    .column.is-four-fifths
      div
        div
          a(
            v-if="selectedInstruction && selectedInstruction.file"
            class="button is-info has-margin-right-medium has-margin-bottom"
            :href="selectedInstruction.file.contentUrl"
            target="_blank"
            download
          )
            b-icon(
              icon="download"
              size="is-small"
              pack="fas"
            )
            div {{ $t('download_instruction_file') }}
        div(v-html="selectedInstruction && selectedInstruction.instruction")
    .column
      b-menu(label="Wybierz instrukcje")
        b-menu-list(label="Wybierz instrukcje")
          b-menu-item(v-for="ins in instructions" @click="setInstruction(ins)" :label="ins.name")
</template>

<script>
import { mapActions, mapGetters } from "vuex";

export default {
  data() {
    return {
      selectedInstruction: null
    };
  },
  methods: {
    ...mapActions("support", ["initInstructions"]),
    setInstruction(instruction) {
      this.selectedInstruction = instruction;
    }
  },
  computed: {
    ...mapGetters("support", ["instructions"])
  },
  created() {
    this.initInstructions();
  }
};
</script>
