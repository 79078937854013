<template lang="pug">
    .modal-card(style='width: auto')
        header.modal-card-head
            p.modal-card-title {{ title }}
        section.modal-card-body(@scroll="onScroll" ref="body")
            slot(name="body")
        footer.modal-card-foot(:style="style")
            slot(name="actions")
</template>
<script>
export default {
  props: {
    title: String,
    actionsCentered: Boolean
  },
  methods: {
    onScroll() {
      let body = this.$refs.body;
      if (body.offsetHeight + body.scrollTop >= body.scrollHeight) {
        this.$emit("scrolled");
      }
    }
  },
  computed: {
    style() {
      if (this.actionsCentered) {
        return {
          "justify-content": "center"
        }
      }
      return {}
    }
  }
};
</script>
