import CompanyInstallationValueForm from "@/modules/management/components/CompanyInstallationValueForm";
import generateProperties from "@/helpers/generateProperties";
import t from "@/i18n";
import decimal from "@/helpers/decimal";

export default {
  list: {
    permissions: ["company_installation_value_read"],
    actions: [
      {
        label: t.t("new_company_installation_value"),
        type: "is-primary",
        route: () => ({
          name: "company_installation_values_create"
        }),
        permissions: ["company_installation_value_create"]
      }
    ],
    route: {
      path: "/company_installation_values/list",
      name: "company_installation_values_list"
    },
    breadcrumbs: [
      {
        label: () => t.t("company_installation_values_list")
      }
    ],
    fields: [
      { field: "name", type: "text", label: t.t("name"), filter: "text" },
      {
        field: "value",
        type: "text",
        label: t.t("value"),
        filter: "text",
        format: decimal
      }
    ],
    resource: "company_installation_values",
    url: () =>
      `/company_installation_values?` +
      generateProperties([], ["id", "name", "value"]),
    rowActions: [
      {
        action: "edit",
        permissions: ["company_installation_value_update"]
      },
      {
        action: "remove",
        permissions: ["company_installation_value_delete"]
      }
    ],
    actionAfterDelete: (vm, object) => {
      vm.$store.dispatch("company/removeCompanyInstallationValue", object);
    }
  },
  create: {
    permissions: ["company_installation_value_create"],
    route: {
      path: "/company_installation_values/create",
      name: "company_installation_values_create"
    },
    breadcrumbs: [
      {
        route: () => ({ name: "company_installation_values_list" }),
        label: () => t.t("company_installation_values_list")
      },
      {
        label: () => t.t("new_company_installation_value")
      }
    ],
    formComponent: CompanyInstallationValueForm,
    listRoute: () => ({ name: "company_installation_values_list" }),
    updateRoute: ({ object }) => ({
      name: "company_installation_values_update",
      params: { id: object.id }
    })
  },
  update: {
    permissions: ["company_installation_value_update"],
    route: {
      path: "/company_installation_values/:id/update",
      name: "company_installation_values_update"
    },
    resource: "company_installation_values",
    breadcrumbs: [
      {
        route: () => ({ name: "company_installation_values_list" }),
        label: () => t.t("company_installation_values_list")
      },
      {
        label: ({ compData }) =>
          `${t.t("company_installation_value_edit")}: "${compData.object.name}"`
      }
    ],
    formComponent: CompanyInstallationValueForm,
    listRoute: () => ({ name: "company_installation_values_list" })
  }
};
