import notify from "../../helpers/notify";

export const onResponseError = error => {
  if (error.response && error.response.status === 404) {
    let message = "";
    if ("development" === process.env.NODE_ENV) {
      message = `Wystąpił błąd, nieodnaleziono obiektu pod adresem ${error.response.request.responseURL}`;
    } else {
      message = `Wystąpił błąd, nieodnaleziono obiektu pod adresem ${
        error.response.request.responseURL.split("?")[0]
      }`;
    }
    notify(message, "danger");
  }
  if (error.response && error.response.status === 500) {
    notify(
      `Wystąpił nieoczekiwany błąd aplikacji, administrator został już o nim powiadomiony`,
      "danger"
    );
  }
  return Promise.reject(error);
};

// export const onRequest = config => {
//   if (config.url.includes("?")) {
//     config.url += "&";
//   } else {
//     config.url += "?";
//   }
//   config.url += "XDEBUG_SESSION_START=PHPSTORM";
//   return config;
// };
