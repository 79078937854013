<template>
  <ckeditor :editor="editor" :config="editorConfig" :value="editorData" @input="onChangeInput"></ckeditor>
</template>

<script>
import ClassicEditor from '@nickdobson/ckeditor5-build-full-free'
import '@nickdobson/ckeditor5-build-full-free/build/translations/pl'

import upload from "./upload";

export default {
  props: {
    value: String,
  },
  computed: {
    editorData: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      },
    },
  },

  data() {
    return {
      editor: ClassicEditor,
      editorConfig: {
        plugins: [
          "Alignment",
          "AutoImage",
          "Autoformat",
          "BlockQuote",
          "Bold",
          "CloudServices",
          "Code",
          "CodeBlock",
          "Essentials",
          "FontBackgroundColor",
          "FontColor",
          "FontFamily",
          "FontSize",
          "Heading",
          "Highlight",
          "HorizontalLine",
          "Image",
          "ImageCaption",
          "ImageInsert",
          "ImageResize",
          "ImageStyle",
          "ImageToolbar",
          "ImageUpload",
          "Indent",
          "IndentBlock",
          "Italic",
          "Link",
          "List",
          "MediaEmbed",
          "MediaEmbedToolbar",
          "PageBreak",
          "Paragraph",
          "PasteFromOffice",
          "RemoveFormat",
          "SpecialCharacters",
          "SpecialCharactersArrows",
          "SpecialCharactersCurrency",
          "SpecialCharactersEssentials",
          "SpecialCharactersLatin",
          "SpecialCharactersMathematical",
          "SpecialCharactersText",
          "Strikethrough",
          "Subscript",
          "Superscript",
          "Table",
          "TableCellProperties",
          "TableProperties",
          "TableToolbar",
          "TodoList",
          "Underline",
          "WordCount"
        ],
        toolbar: [
          "undo", "redo", "|", 'fontFamily', 'fontsize', 'fontColor', 'fontBackgroundColor', "|",
          "alignment", "imageInsert", "|",
          "highlight", "code", "subscript", "superscript", "|",
          "bold", "italic", "|",
          "outdent", "indent", "|",
          "link", "imageUpload", "|",
          "mediaEmbed",
          "removeFormat", "insertTable", "blockQuote", "horizontalLine", "|",
        ],
        language: "pl",
        title: {
          isEnabled: false
        },
        extraPlugins: [this.customUploadAdapter],
      },
    };
  },

  methods: {
    onChangeInput($event) {
      this.$emit("input", $event);
    },
    customUploadAdapter(editor) {
      editor.plugins.get("FileRepository").createUploadAdapter = (loader) => {
        return new MyUploadAdapter(loader);
      };
    },
  },
};

// Custom Upload Adapter
class MyUploadAdapter {
  constructor(loader) {
    this.loader = loader;
  }

  upload() {
    return this.loader.file.then(
        (file) =>
            new Promise((resolve, reject) => {
              const blobInfo = {
                blob: () => file,
                filename: () => file.name,
              };

              // Use the provided upload handler
              upload(
                  blobInfo,
                  (url) => resolve({default: url}),
                  (error) => reject(error)
              );
            })
    );
  }

  abort() {
    // Reject the promise returned from the upload() method.
  }
}
</script>
