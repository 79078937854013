<template lang="pug">
    app-view-card(:title="$t('filters')")
        template(slot="icon")
            b-button(icon-left="filter-remove" @click.prevent="clearFilters" size="is-small") Wyczyść filtry
        form
          .columns.is-multiline
              .column.is-half-tablet.is-one-quarter-desktop
                  b-field(grouped)
                    app-form-field(:label="$t('fabric_color')" field="traitColor" expanded horizontal)
                      b-field
                        resource-select(
                          :value="traitColor"
                          label="name"
                          resource="trait_colors"
                          url="trait_colors"
                          extra-props="order[position]=asc"
                          :placeholder="$t('all')"
                          @input="onFilterChanged($event, 'TraitColor')"
                          nullable
                          expanded
                          full-object
                        )
                        b-button(icon-left="filter-remove" @click.prevent="resetTraitColor")
              .column.is-half-tablet.is-one-quarter-desktop
                  b-field(grouped)
                      app-form-field(:label="$t('fabric')" field="traitFabric" expanded horizontal)
                        b-field
                          resource-select(
                            :value="traitFabric"
                            resource="trait_fabrics"
                            url="trait_fabrics"
                            extra-props="order[position]=asc"
                            :placeholder="$t('all')"
                            @input="onFilterChanged($event, 'TraitFabric')"
                            nullable
                            expanded
                            full-object
                          )
                          b-button(icon-left="filter-remove" @click.prevent="resetTraitFabric")
</template>
<script>
import { mapGetters, mapMutations } from "vuex";
import ResourceSelect from "@/modules/common/components/ResourceSelect";

export default {
  components: { ResourceSelect },
  computed: {
    ...mapGetters("fabricShades", [
      "traitFabric",
      "traitColor",
      "fabricShadeColor"
    ])
  },
  methods: {
    ...mapMutations("fabricShades", [
      "setTraitFabric",
      "setTraitColor",
      "setFabricShadeColor",
      "clearFilters",
      "resetFabricShadeColor",
      "resetTraitColor",
      "resetTraitFabric",
      "setMessage",
      "setIsMessageBorderAnimating"
    ]),
    onFilterChanged(filter, type) {
      switch (type) {
        case "TraitFabric":
          if (filter) {
            this.setTraitFabric(filter["@id"]);
          } else {
            this.setTraitFabric(null);
          }
          break;
        case "TraitColor":
          if (filter) {
            this.setTraitColor(filter["@id"]);
          } else {
            this.setTraitColor(null);
          }
          break;
      }

      if (filter) {
        const message = filter.message;
        if (message && message.length > 0) {
          this.setMessage(message);
          this.setIsMessageBorderAnimating(true);
          setTimeout(() => {
            this.setIsMessageBorderAnimating(false);
          }, 1000);
        }
      }
    }
  }
};
</script>
