<template lang="pug">
  table.table.is-bordered.is-fullwidth
    thead
      tr
        th(colspan=2) {{ order && order.status === 'valuation' ? $t('order_preview_props.valuation_info') : $t('order_preview_props.order_info') }}
    tbody.print-width-250.real-width-tables-td-250
      tr
        th {{ order && order.status === 'valuation' ? $t('order_preview_props.valuation_number') : $t('order_preview_props.order_number') }}
        td {{ orderNumber }}
      tr
        th {{ $t('order_preview_props.purchaser') }} ({{ $t(order.customer.type) }})
        td(v-if="order.customer.type === 'person'") {{`${prop(order.customer,"firstName")} ${prop(order.customer, "lastName")}` }}
        td(v-else) {{ `${prop(order.customer, 'taxId')} ${prop(order.customer, 'companyName')}` }}

</template>
<script>

import { mapGetters } from "vuex";
import decimal from "@/helpers/decimal";
import priceFormatter from "../../../../helpers/priceFormatter";
import t from "@/i18n";
import orderNumberCreator from "../../../../helpers/orderNumberCreator";

export default {
  name: "OrderInformation",
  props:{
    order: {type: Object, default: null, required: true},
    priceType: { default: null, required: true }
  },
  computed: {
    ...mapGetters("company", ["currencyCode", "lengthUnit"]),
    ...mapGetters("common", ["formatWeight"]),
    orderNumber() {
      if (this.order && this.order.number) {
        return orderNumberCreator(this.order.number);
      } else {
        return "";
      }
    }
  },

  methods: {
    prop(object, field, extraField = null) {
      if (extraField) {
        return (object && object[extraField][field]) || t.t("empty");
      }
      return (object && object[field]) || t.t("empty");
    },
    formatPriceValue(value) {
      return priceFormatter(value);
    },
    getDecimalValue(value) {
      return decimal(value);
    }
  }
}
</script>
