<template lang="pug">
  tr(:class="classes")
    // liczba porządkowa
    td
      div
        .th-in {{ (index + 1) }}
    //miejsce montazu/krotki opis
    td {{ item.shortDescription }}
    // nazwa wersji
    td {{ property(item.frameMosquitoNet, "name") }}
    // kolor ramy
    td {{ property(item.frameMosquitoNetGlobalAccessoryEntry, "name", "globalAccessory") }}
    // siatka
    td {{ property(item.frameMosquitoNetGlobalNetEntry, "name", "globalNet") }}
    // zaczep
    td {{ property(item.frameMosquitoNetGlobalClipEntry, "name", "globalClip") }}
    //usczelka
    td {{ property(item.frameMosquitoNetGasket, "name") }}
    // szerokość
    td {{ formatLength(item.width) }}
    // wysokość
    td {{ formatLength(item.height) }}
    //// szerokość szyby (opcjonalnie)
    //td {{ formatLength(item.glassHeight) ? formatLength(item.glassHeight) : "-" }}
    // ilość szt.
    td {{ item.quantity }}
    // poprzeczka
    td {{ item.isBar ? $t("yes") : '-' }}
    //// prowadnice
    //td {{ property(item.fabricShadeGlobalRunnerEntry, "name", "globalRunner") }}
    //// mocowanie
    //td {{ property(item.fabricShadeGlobalFixingEntry, "name", "globalFixing") }}
    //// napęd
    //td {{ property(item.globalDrive, "name") }}
    //// obrócenie tkaniny
    //td {{ formatBoolean(item.hasTurnableFabric) }}
    // Dodatki
    td
      p(v-if="!item.frameMosquitoNetAdditionalEntries.length") {{$t('empty')}}
      p(v-for="(itemAdditional) in item.frameMosquitoNetAdditionalEntries") {{ property(itemAdditional, "name", "frameMosquitoNetAdditional") }}
    //// cena zakupu
    td.has-text-right(v-if="!hideWholeSalePrice") {{ formatPriceValue(formatPrice(item.netPurchaseValue)) }}
    // cena sprzedaży
    td.has-text-right {{ formatPriceValue(formatPrice(item.netSalesValue)) }}
    td
      .field.has-addons
        p.control
          b-button(
            @click.prevent="editItem({index, item})"
            type="is-info"
            size="is-small"
            icon-left="pencil"
          )
        p.control
          b-button(
            @click.prevent="remove"
            type="is-danger"
            icon-left="delete"
            size="is-small"
          )
</template>
<script>
import {mapGetters, mapActions, mapMutations} from "vuex";
import priceFormatter from "../../../../helpers/priceFormatter";

export default {
  props: {
    index: Number,
    item: Object
  },
  methods: {
    ...mapActions("frameMosquitoNets", ["removeItem"]),
    ...mapMutations("frameMosquitoNets", ["editItem"]),
    formatPriceValue(value) {
      return priceFormatter(value);
    },
    remove() {
      return this.$buefy.dialog.confirm({
        message: this.$t("remove_position_confirm"),
        onConfirm: () => {
          this.removeItem(this.index);
        }
      });
    },
    property(object, field, extraField = null) {
      if (extraField) {
        return object && object[extraField][field];
      }
      return object && object[field];
    }
  },
  computed: {
    classes() {
      return {
        "is-editing": this.editIndex === this.index
      };
    },
    ...mapGetters(["hideWholeSalePrice"]),
    ...mapGetters("frameMosquitoNets", ["editIndex"]),
    ...mapGetters("common", [
      "formatLength",
      "formatNumber",
      "formatBoolean",
      "formatPrice"
    ])
  }
};
</script>
