<template lang="pug">
  b-modal(:active.sync="active" has-modal-card can-cancel="['escape', 'outside', 'x']" )
    app-view-modal(:loading="loading" actions-centered :title="value.name" :icon="image(value, 'picture')" style="min-width: 1024px; min-height: 360px;")
      template(#actions)
        b-button(type="is-primary" @click.prevent="onClose") {{ $t('close') }}
      template(#body id="content")
        table.table.is-bordered.has-cells-centered.is-fullwidth
          thead
            tr
              th {{$t('detail_list.header_picture')}}
              th {{$t('detail_list.header_name')}}
              th {{$t('detail_list.header_symbol')}}
              th {{$t('detail_list.header_description')}}
              th {{$t('detail_list.header_files')}}
          tbody
            tr
              td(style="width: 200px").has-text-left
                app-form-hover-image-preview-window(
                  v-for='picture in [...value.pictures, ...[value.picture]]'
                  :image="image(picture)"
                  @open="openImage($event)"
                )
              td {{value.name}}
              td {{value.symbol}}
              td {{value.description}}
              td.has-text-right
                a(
                  v-if="value.measurementInstructions.length > 0"
                  v-for="download in value.measurementInstructions"
                  class="button is-info has-margin-right-medium has-margin-bottom"
                  :href="download.contentUrl"
                  target="_blank"
                  download
                )
                  b-icon(
                    icon="download"
                    size="is-small"
                    pack="fas"
                  )
                  div {{ $t("download") }}
        //.columns.mx-5.py-2
        //  .column.is-1.has-min-height-200
        //    p.has-text-weight-medium {{$t('detail_list.header_picture') + ':'}}
        //    app-form-hover-image-preview-window(
        //      v-for='picture in [...value.pictures, ...[value.picture]]'
        //      :image="image(picture)"
        //      @open="openImage($event)"
        //    )
        //  .column.is-2
        //    p.has-text-weight-medium {{$t('detail_list.header_name') + ':'}}
        //    p {{value.name}}
        //  .column.is-1
        //    p.has-text-weight-medium {{$t('detail_list.header_symbol') + ':'}}
        //    p {{value.symbol}}
        //  .column.is-6
        //    p.has-text-weight-medium {{$t('detail_list.header_description') + ':'}}
        //    p {{value.description}}
        //  .column.is-2
        //    p.has-text-weight-medium {{$t('detail_list.header_files') + ':'}}
        //    a(
        //      v-if="value.measurementInstructions.length > 0"
        //      v-for="download in value.measurementInstructions"
        //      class="button is-info has-margin-right-medium has-margin-bottom"
        //      :href="download.contentUrl"
        //      target="_blank"
        //      download
        //    )
        //      b-icon(
        //        icon="download"
        //        size="is-small"
        //        pack="fas"
        //      )
        //      div {{ $t("download") }}
    b-modal(v-model="imageModal")
      .columns(style="width:99%").has-text-centered
        img(:src="imageForModal")

</template>

<script>
import { mapGetters} from "vuex";
import AppFormHoverImagePreviewWindow from "../../../../plugins/app/form/components/AppFormHoverImagePreviewWindow.vue";

export default {
  components: {AppFormHoverImagePreviewWindow},
  props: {
    value: {
      type: Object,
      default: null,
      required: false,
    },
    active: {
      type: Boolean,
      default: false,
      required: true,
    }
  },
  data() {
    return {
      isActive: this.active,
      loading: false,
      imageModal: false,
      imageForModal: null,
    };
  },

  methods: {
    openImage(image) {
      this.imageForModal = image;
      this.imageModal = true;
    },
    onClose() {
      this.$emit("input",null);
    },
  },
  computed: {
    ...mapGetters("details", ["image"]),
  },
};
</script>
