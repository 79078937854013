export default function(val) {
  if (val.toString().countRepeatsOnBegin("0") === val.toString().length) {
    return val;
  }
  return Array.from(val)
    .splice(
      val.toString().countRepeatsOnBegin("0"),
      val.toString().length - val.toString().countRepeatsOnBegin("0")
    )
    .join("");
}
