import prepareItemToSent from "@/helpers/prepareItemToSent";
import simpleClone from "@/helpers/simpleClone";

export default function(originalOrder) {
  let order = simpleClone(originalOrder);
  for (let orderItem in order.fabricShadeOrderItems) {
    order.fabricShadeOrderItems[orderItem] = prepareItemToSent(
      order.fabricShadeOrderItems[orderItem]
    );
  }

  for (let orderItem in order.fabricShadeDayNightOrderItems) {
    order.fabricShadeDayNightOrderItems[orderItem] = prepareItemToSent(
        order.fabricShadeDayNightOrderItems[orderItem]
    );
  }


  for (let orderItem in order.externalShutterOrderItems) {
    order.externalShutterOrderItems[orderItem] = prepareItemToSent(
      order.externalShutterOrderItems[orderItem]
    );
  }
  return order;
}
