import customerForm from "@/modules/customers/components/CustomerForm";
import generateProperties from "@/helpers/generateProperties";
import t from "@/i18n";

function getType(header, row) {
  if (row["type"] === "person") {
    return t.t("customer_types.person");
  }
  if (row["type"] === "company") {
    return t.t("customer_types.company");
  }
  return "";
}

export default {
  list: {
    hasPagination: true,
    permissions: ["customer_read"],
    actions: [
      {
        label: t.t("new_customer"),
        type: "is-primary",
        route: () => ({
          name: "customers_create"
        }),
        permissions: ["customer_create"]
      }
    ],
    route: {
      path: "/customers/list",
      name: "customers_list"
    },
    countable: true,
    breadcrumbs: [
      {
        label: () => t.t("customers_list")
      }
    ],
    fields: [
      {
        field: "type",
        type: "virtual",
        label: t.t("customer_type"),
        filter: "text",
        value: getType
      },
      {
        field: "lastName",
        type: "text",
        label: t.t("last_name"),
        filter: "text"
      },
      {
        field: "firstName",
        type: "text",
        label: t.t("first_name"),
        filter: "text"
      },
      {
        field: "companyName",
        type: "text",
        label: t.t("company_name"),
        filter: "text"
      },
      {
        field: "zipCode",
        type: "text",
        label: t.t("zip_code"),
        filter: "text"
      },
      {
        field: "streetAndNumber",
        type: "text",
        label: t.t("street"),
        filter: "text"
      },
      { field: "phone", type: "text", label: t.t("phone"), filter: "text" },
      { field: "email", type: "text", label: t.t("email"), filter: "text" },
      { field: "valuationsCount", type: "text", label: t.t("valuations") },
      { field: "ordersCount", type: "text", label: t.t("orders") }
    ],
    resource: "customers",
    url: () =>
      `/customers?` +
      generateProperties(
        [],
        [
          "id",
          "type",
          "firstName",
          "phone",
          "zipCode",
          "streetAndNumber",
          "companyName",
          "companyName",
          "lastName",
          "email"
        ]
      ),
    rowActions: [
      {
        action: "edit",
        permissions: ["customer_update"]
      },
      {
        action: "remove",
        permissions: ["customer_delete"]
      }
    ]
  },
  create: {
    permissions: ["customer_create"],
    route: {
      path: "/customers/create",
      name: "customers_create"
    },
    breadcrumbs: [
      {
        route: () => ({ name: "customers_list" }),
        label: () => t.t("customers")
      },
      {
        label: () => t.t("new_customer")
      }
    ],
    formComponent: customerForm,
    listRoute: () => ({ name: "customers_list" }),
    updateRoute: ({ object }) => ({
      name: "customers_update",
      params: { id: object.id }
    })
  },
  update: {
    permissions: ["customer_update"],
    route: {
      path: "/customers/:id/update",
      name: "customers_update"
    },
    resource: "customers",
    resourceProperties: generateProperties(
      [],
      [
        "id",
        "firstName",
        "lastName",
        "phone",
        "email",
        "companyName",
        "streetAndNumber",
        "city",
        "zipCode",
        "type",
        "taxId"
      ]
    ),
    breadcrumbs: [
      {
        route: () => ({ name: "customers_list" }),
        label: () => t.t("customers")
      },
      {
        label: ({ compData }) =>
          `${t.t("customer_edit")}: "${compData.object.type === "person" ? compData.object.lastName + " ("+compData.object.city+")" : compData.object.companyName + " ("+compData.object.taxId+")"}"`
      }
    ],
    formComponent: customerForm,
    listRoute: () => ({ name: "customers_list" })
  }
};
