import AdditionalCostForm from "@/modules/offer/components/AdditionalCostForm";
import generateProperties from "@/helpers/generateProperties";
import t from "@/i18n";
import decimal from "@/helpers/decimal";

export default {
  list: {
    permissions: ["additional_cost_read"],
    actions: [
      {
        label: t.t("new_additional_cost"),
        type: "is-primary",
        route: () => ({
          name: "additional_costs_create"
        }),
        permissions: ["additional_cost_create"]
      }
    ],
    route: {
      path: "/additional_costs/list",
      name: "additional_costs_list"
    },
    countable: true,
    breadcrumbs: [
      {
        label: () => t.t("additional_costs")
      }
    ],
    fields: [
      { field: "name", type: "text", label: t.t("name"), filter: "text" },
      { field: "cost", type: "text", label: t.t("cost"), format: decimal }
    ],
    resource: "additional_costs",
    url: () =>
      `/additional_costs?properties[]=id&properties[]=cost&properties[]=name`,
    rowActions: [
      {
        action: "edit",
        permissions: ["additional_cost_update"]
      },
      {
        action: "remove",
        permissions: ["additional_cost_delete"]
      }
    ]
  },
  create: {
    permissions: ["additional_cost_create"],
    route: {
      path: "/additional_costs/create",
      name: "additional_costs_create"
    },
    breadcrumbs: [
      {
        route: () => ({ name: "additional_costs_list" }),
        label: () => t.t("additional_costs")
      },
      {
        label: () => t.t("new_additional_cost")
      }
    ],
    formComponent: AdditionalCostForm,
    listRoute: () => ({ name: "additional_costs_list" }),
    updateRoute: ({ object }) => ({
      name: "additional_costs_update",
      params: { id: object.id }
    })
  },
  update: {
    permissions: ["additional_cost_update"],
    route: {
      path: "/additional_costs/:id/update",
      name: "additional_costs_update"
    },
    resource: "additional_costs",
    resourceProperties: generateProperties([], ["id", "name", "cost"]),
    breadcrumbs: [
      {
        route: () => ({ name: "additional_costs_list" }),
        label: () => t.t("additional_costs")
      },
      {
        label: ({ compData }) =>
          `${t.t("additional_cost_edit")}: "${compData.object.name}"`
      }
    ],
    formComponent: AdditionalCostForm,
    listRoute: () => ({ name: "additional_costs_list" })
  }
};
