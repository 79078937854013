import router from "./../../router";
import store from "../../store";
import support from "./store";
import Instructions from "@/modules/support/views/Instructions";
import Files from "@/modules/support/views/Files";
import crud from "@/crud";
import bugReports from "@/modules/support/crud/bugReports";
import orderReclamations from "@/modules/support/crud/orderReclamations";

store.registerModule("support", support);

router.addRoutes([
  {
    path: "/support/instructions",
    name: "instructions",
    component: Instructions
  },
  {
    path: "/support/downloads",
    name: "downloads",
    component: Files
  }
]);

crud(bugReports);
crud(orderReclamations);
