import BugReportForm from "@/modules/support/components/BugReportForm";
import generateProperties from "@/helpers/generateProperties";
import t from "@/i18n";

export default {
  list: {
    permissions: ["bug_report_read"],
    actions: [
      {
        label: t.t("new_bug_report"),
        type: "is-primary",
        route: () => ({
          name: "bug_reports_create"
        }),
        permissions: ["bug_report_create"]
      }
    ],
    route: {
      path: "/bug_reports/list",
      name: "bug_reports_list"
    },
    breadcrumbs: [
      {
        label: () => t.t("bug_reports_list")
      }
    ],
    fields: [
      { field: "name", type: "text", label: t.t("name"), filter: "text" },
      {
        field: "createdAt",
        type: "text",
        label: t.t("createdAt")
      },
      {
        field: "updatedAt",
        type: "text",
        label: t.t("updatedAt")
      }
    ],
    resource: "bug_reports",
    url: () =>
      `/bug_reports?` +
      generateProperties(
        [],
        ["id", "name", "createdAt", "updatedAt", "status"]
      ),
    rowActions: [
      {
        action: "edit",
        icon: "eye",
        permissions: ["bug_report_update"]
      }
    ]
  },
  create: {
    permissions: ["bug_report_create"],
    route: {
      path: "/bug_reports/create",
      name: "bug_reports_create"
    },
    breadcrumbs: [
      {
        route: () => ({ name: "bug_reports_list" }),
        label: () => t.t("bug_reports_list")
      },
      {
        label: () => t.t("new_bug_report")
      }
    ],
    formComponent: BugReportForm,
    listRoute: () => ({ name: "bug_reports_list" }),
    updateRoute: ({ object }) => ({
      name: "bug_reports_update",
      params: { id: object.id }
    })
  },
  update: {
    permissions: ["bug_report_update"],
    route: {
      path: "/bug_reports/:id/update",
      name: "bug_reports_update"
    },
    resource: "bug_reports",
    breadcrumbs: [
      {
        route: () => ({ name: "bug_reports_list" }),
        label: () => t.t("bug_reports_list")
      },
      {
        label: ({ compData }) =>
          `${t.t("bug_report_edit")}: "${compData.object.name}"`
      }
    ],
    formComponent: BugReportForm,
    listRoute: () => ({ name: "bug_reports_list" })
  }
};
