import offerForm from "@/modules/offer/components/OfferForm";
import store from "@/modules/auth/store";
import http from "@/http";
import generateProperties from "@/helpers/generateProperties";
import t from "@/i18n";

export default {
  list: {
    permissions: ["offer_read"],
    actions: [
      {
        label: t.t("new_offer"),
        type: "is-primary",
        route: () => ({
          name: "offers_create"
        }),
        permissions: ["offer_create"]
      }
    ],
    route: {
      path: "/offers/list",
      name: "offers_list"
    },
    countable: true,
    breadcrumbs: [
      {
        label: () => t.t("offers")
      }
    ],
    fields: [
      { field: "name", type: "text", label: t.t("name"), filter: "text" },
      {
        type: "buttons",
        label: t.t("operations"),
        buttons: [
          {
            label: t.t("export_to_pdf"),
            isLink: true,
            route: ({ row }) =>
              `${process.env.VUE_APP_API_URL}api/offers/${row.id}/pdf`,
            download: ({ row }) => {
              http
                .get(`api/offers/${row.id}/pdf`, {
                  responseType: "blob",
                  headers: {
                    Authorization: `Bearer ${store.token}`
                  }
                })
                .then(response => {
                  let file = new File([response.data], "oferta.pdf", {
                    type: "application/pdf"
                  });
                  let url = URL.createObjectURL(file);
                  window.open(url);
                });
            }
          },
          {
            label: t.t("export_to_doc"),
            isLink: true,
            route: ({ row }) =>
              `${process.env.VUE_APP_API_URL}api/offers/${row.id}/doc`,
            download: ({ row }) => {
              http
                .get(`api/offers/${row.id}/doc`, {
                  responseType: "blob",
                  headers: {
                    Authorization: `Bearer ${store.token}`
                  }
                })
                .then(response => {
                  const url = window.URL.createObjectURL(response.data);
                  const a = document.createElement("a");
                  a.style.display = "none";
                  a.href = url;
                  a.download = `${t.t("offer")}.docx`;
                  document.body.appendChild(a);
                  a.click();
                  window.URL.revokeObjectURL(url);
                });
            }
          }
        ]
      }
    ],
    resource: "offers",
    url: () => `/offers?properties[]=id&properties[]=name`,
    rowActions: [
      {
        action: "edit",
        permissions: ["offer_update"]
      },
      {
        action: "remove",
        permissions: ["offer_delete"]
      }
    ]
  },
  create: {
    permissions: ["offer_create"],
    route: {
      path: "/offers/create",
      name: "offers_create"
    },
    breadcrumbs: [
      {
        route: () => ({ name: "offers_list" }),
        label: () => t.t("offers")
      },
      {
        label: () => t.t("new_offer")
      }
    ],
    formComponent: offerForm,
    listRoute: () => ({ name: "offers_list" }),
    updateRoute: ({ object }) => ({
      name: "offers_update",
      params: { id: object.id }
    })
  },
  update: {
    permissions: ["offer_update"],
    route: {
      path: "/offers/:id/update",
      name: "offers_update"
    },
    resource: "offers",
    resourceProperties:
      generateProperties(
        [],
        [
          "id",
          "name",
          "orders",
          "offerTemplate",
          "customContent",
          "additionalCostIsVisible"
        ]
      ) + generateProperties(["additionalCosts"], ["id", "cost", "name"], true),
    breadcrumbs: [
      {
        route: () => ({ name: "offers_list" }),
        label: () => t.t("offers")
      },
      {
        label: ({ compData }) =>
          `${t.t("offer_edit")}: "${compData.object.name}"`
      }
    ],
    formComponent: offerForm,
    listRoute: () => ({ name: "offers_list" })
  }
};
