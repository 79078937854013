<template lang="pug">
    app-form(:model="object || model" :object="object" resource="company_vat_rates" @success="onSuccess" :has-save-and-close="false")
        template(#default="{ data, errors }")
            app-form-field(:label="$t('company_vat_rates.name')" field="name" :errors="errors")
                b-input(v-model="data.name")

            app-form-field(:label="$t('company_vat_rates.value')" field="value" :errors="errors")
                app-form-decimal(v-model="data.value")
</template>
<script>
export default {
  props: {
    object: Object
  },
  data() {
    return {
      loading: false,
      model: {
        name: "",
        value: 0
      }
    };
  },
  methods: {
    onSuccess(object) {
      this.$store.commit("company/updateCompanyVatRate", object);
      this.$emit("success", object);
    }
  }
};
</script>
