<template lang="pug">
    app-form(v-bind="props" v-on="listeners" resource="customers" :transform-to-submit="transformToSubmit")
        template(#default="{ data, errors }")
            b-tabs
                b-tab-item(label="Ogólne")
                    .columns
                        .column.is-half
                            app-form-field(:label="$t('customer_type')" field="type")
                              div
                                b-radio(v-model="data.type" native-value="person") {{ $t('customer_types.person') }}
                                b-radio(v-model="data.type" native-value="company") {{ $t('customer_types.company') }}

                            div(v-show="data.type === 'company'")
                                app-form-field(:label="$t('tax_id')" field="taxId" :errors="errors")
                                    b-input(v-model="data.taxId" expanded)

                                app-form-field(:label="$t('company_name')" field="companyName" :errors="errors")
                                    b-input(v-model="data.companyName" expanded)

                            app-form-field(:label="$t('first_name')" field="firstName" :errors="errors")
                                b-input(v-model="data.firstName" expanded)

                            app-form-field(:label="$t('last_name')" field="lastName" :errors="errors")
                                b-input(v-model="data.lastName" expanded)


                            app-form-field(:label="$t('zip_code')" field="zipCode" :errors="errors")
                                b-input(v-model="data.zipCode" expanded)

                            app-form-field(:label="$t('city')" field="city" :errors="errors")
                                b-input(v-model="data.city" expanded)

                            app-form-field(:label="$t('street')" field="streetAndNumber" :errors="errors")
                                b-input(v-model="data.streetAndNumber" expanded)

                            app-form-field(:label="$t('phone')" field="phone" :errors="errors")
                                b-input(v-model="data.phone" expanded)

                            app-form-field(:label="$t('email')" field="email" :errors="errors")
                                b-input(v-model="data.email" expanded)
</template>

<script>
import form from "@/plugins/app/form/mixins/form";

export default {
  mixins: [form],
  data() {
    return {
      model: {
        type: "person",
        firstName: "",
        lastName: "",
        companyName: "",
        zipCode: "",
        city: "",
        streetAndNumber: "",
        phone: "",
        taxId: null,
        email: ""
      }
    };
  },
  methods: {
    transformToSubmit(data) {
      if (data.type === "company") {
        if (!data.taxId || data.taxId.trim().length === 0) {
          data.taxId = null;
        }
      }
      return data;
    }
  }
};
</script>
