import { render, staticRenderFns } from "./FrameMosquitoNetOrderItem.vue?vue&type=template&id=6a73d4e6&lang=pug"
import script from "./FrameMosquitoNetOrderItem.vue?vue&type=script&lang=js"
export * from "./FrameMosquitoNetOrderItem.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports