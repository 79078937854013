<template lang="pug">
    table.table.is-bordered.has-cells-centered.is-narrow
      thead
        tr
          th(colspan=3) {{$t("summary") }}
        tr
          th {{ $t("pieces") }}
          th(v-if="!hideWholeSalePrice") {{ $t("net_purchase_value", { currencyCode }) }}
          th {{ $t("net_sales_value", { currencyCode }) }}
        tr

      tbody
      tr
        td {{ order.quantity }}
        td.has-text-right(v-if="!hideWholeSalePrice") {{ formatPriceValue(getDecimalValue(order.netPurchaseValueBeforeDiscount + order.netPurchaseAutomationValueBeforeDiscount))}}
        td.has-text-right {{ formatPriceValue(getDecimalValue(order.netSalesValue + order.netSalesAutomationValue)) }}
      tr
        td(colspan='3' style="border-top:0px;border-bottom:0px;").has-font-size-15
      tr
        th(colspan='3').has-background-light Po rabacie
      tr
        th {{ $t("pieces") }}
        th(v-if="!hideWholeSalePrice") {{ $t("net_purchase_value", { currencyCode }) }}
        th {{ $t("net_sales_value", { currencyCode }) }}
      tr
        td {{ order.quantity }}
        td.has-text-right(v-if="!hideWholeSalePrice") {{ formatPriceValue(getDecimalValue(order.netPurchaseValue + order.netPurchaseAutomationValue))}}
        td.has-text-right {{ formatPriceValue(getDecimalValue(order.netSalesValueAfterDiscount + order.netSalesAutomationValueAfterDiscount  )) }}

</template>

<script>

import {mapGetters} from "vuex";
import decimal from "@/helpers/decimal";
import priceFormatter from "../../../../helpers/priceFormatter";


export default {
  name: "SummaryPricesInOrder",
  computed: {
    ...mapGetters(["hideWholeSalePrice"]),
    ...mapGetters("company", ["currencyCode", "lengthUnit"]),
    ...mapGetters("frameMosquitoNets", ["order"]),
    ...mapGetters("common", ["formatWeight"])
  },

  methods: {
    formatPriceValue(value) {
      return priceFormatter(value);
    },
    getDecimalValue(value) {
      return decimal(value);
    }
  }
}
</script>
