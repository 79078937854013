<template lang="pug">
    app-form(v-bind="props" v-on="listeners" resource="offer_templates")
      template(#default="{ data, errors }")
        b-tabs
          b-tab-item(:label="$t('general')")
            .columns
              .column
                app-form-field(:label="$t('name')" field="name" :errors="errors")
                  b-input(v-model="data.name")

                app-form-field(:label="$t('footer')" field="footer" :errors="errors")
                  app-form-editor(v-model="data.footer")

</template>

<script>
import form from "@/plugins/app/form/mixins/form";

export default {
  mixins: [form],
  computed: {
    model() {
      return {
        name: "",
        footer: ""
      };
    }
  }
};
</script>
