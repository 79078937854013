import {SnackbarProgrammatic as Snackbar} from "buefy";

export default function (message, type = "success", position = "is-bottom-right", duration = 3000) {
    Snackbar.open({
        message,
        type: `is-${type}`,
        position: position,
        queue: true,
        duration: duration
    });
}
