import router from "./../../router";
import Dashboard from "./views/Dashboard";

router.addRoutes([
  {
    path: "/",
    redirect: "/news",
    name: "dashboard",
    component: Dashboard
  }
]);
