<template lang="pug">
  .table-container
    table.table.is-bordered.has-cells-centered.is-narrow
      thead
        tr
          th {{ $t("ordinal_number") }}
          th {{ $t("automation.element_item") }}

          th {{ $t("pieces") }}
          th(v-if="!hideWholeSalePrice") {{ $t("net_purchase_value", { currencyCode }) }}
          th {{ $t("net_sales_value", { currencyCode }) }}
          th {{ $t("operations") }}
        tr
      tbody
        template(v-for="(item, index) in order.automationOrderItems")
          automation-order-item(
            :item="item"
            :index="index")
      tr
        td(colspan=1 style="border: 0;")
        td {{ $t('sum_up') }}
        td.has-text-right {{ sumParameterInAutomationOrderItems("quantity") }}
        td.has-text-right(v-if="!hideWholeSalePrice") {{ getDecimalValue(sumParameterInAutomationOrderItems("netPurchaseValue"))}}
        td.has-text-right {{ getDecimalValue(sumParameterInAutomationOrderItems("netSalesValue")) }}

</template>
<script>

import {mapGetters} from "vuex";
import decimal from "@/helpers/decimal";
import priceFormatter from "../../../../helpers/priceFormatter";
import AutomationOrderItem from "./AutomationOrderItem";

export default {
  components: {AutomationOrderItem},
  computed: {
    ...mapGetters(["hideWholeSalePrice"]),
    ...mapGetters("company", ["currencyCode", "lengthUnit"]),
    ...mapGetters("fabricShades", ["order"]),
    ...mapGetters("common", ["formatWeight"])
  },
  methods: {
    sumParameterInAutomationOrderItems: function (parameter) {
      return this.order.automationOrderItems.reduce((total, automationOrderItem) => automationOrderItem[parameter] + total, 0)
    },
    formatPriceValue(value) {
      return priceFormatter(value);
    },
    getDecimalValue(value) {
      return decimal(value);
    }
  }
};
</script>
