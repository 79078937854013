export default {
  props: {
    object: { type: Object },
    toList: { type: Function }
  },
  computed: {
    props() {
      return {
        model: this.model,
        files: this.files,
        object: this.object,
        toList: this.toList
      };
    },
    listeners() {
      return {
        success: data => {
          this.$emit("success", data);
        },
        close: () => {
          this.toList();
        }
      };
    }
  }
};
