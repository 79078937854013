import OrderReclamationForm from "@/modules/support/components/OrderReclamationForm";
import generateProperties from "@/helpers/generateProperties";
import t from "@/i18n";

export default {
  list: {
    permissions: ["order_reclamation_read"],
    actions: [
      {
        label: t.t("new_order_reclamation"),
        type: "is-primary",
        route: () => ({
          name: "order_reclamations_create"
        }),
        permissions: ["order_reclamation_create"]
      }
    ],
    route: {
      path: "/order_reclamations/list",
      name: "order_reclamations_list"
    },
    breadcrumbs: [
      {
        label: () => t.t("order_reclamations_list")
      }
    ],
    fields: [
      {
        field: "name",
        type: "text",
        label: t.t("reclamation_title"),
        filter: "text"
      },
      {
        field: "createdAt",
        type: "text",
        label: t.t("createdAt")
      },
      {
        field: "updatedAt",
        type: "text",
        label: t.t("updatedAt")
      },
      {
        field: "status",
        type: "text",
        label: "Status",
        filter: "select",
        filterOptions: () => [
          { label: t.t("all"), value: null },
          { label: t.t("new"), value: "new" },
          { label: t.t("accepted"), value: "accepted" },
          { label: t.t("closed"), value: "closed" },
          { label: t.t("transferred"), value: "transferred" }
        ],
        format: val => {
          switch (val) {
            case "new":
              return t.t("new");
            case "accepted":
              return t.t("accepted");
            case "closed":
              return t.t("closed");
            case "transferred":
              return t.t("transferred");
          }
        }
      }
    ],
    resource: "order_reclamations",
    url: () =>
      `/order_reclamations?` +
      generateProperties(
        [],
        ["id", "name", "createdAt", "updatedAt", "status"]
      ),
    rowActions: [
      {
        action: "edit",
        icon: "eye",
        permissions: ["order_reclamation_update"]
      }
    ]
  },
  create: {
    permissions: ["order_reclamation_create"],
    route: {
      path: "/order_reclamations/create/:orderId?",
      name: "order_reclamations_create",
      props: true
    },
    breadcrumbs: [
      {
        route: () => ({ name: "order_reclamations_list" }),
        label: () => t.t("order_reclamations_list")
      },
      {
        label: () => t.t("new_order_reclamation")
      }
    ],
    formComponent: OrderReclamationForm,
    listRoute: () => ({ name: "order_reclamations_list" }),
    updateRoute: ({ object }) => ({
      name: "order_reclamations_update",
      params: { id: object.id }
    })
  },
  update: {
    permissions: ["order_reclamation_update"],
    route: {
      path: "/order_reclamations/:id/update",
      name: "order_reclamations_update"
    },
    resource: "order_reclamations",
    breadcrumbs: [
      {
        route: () => ({ name: "order_reclamations_list" }),
        label: () => t.t("order_reclamations_list")
      },
      {
        label: ({ compData }) =>
          `${t.t("order_reclamation_edit")}: "${compData.object.name}"`
      }
    ],
    formComponent: OrderReclamationForm,
    listRoute: () => ({ name: "order_reclamations_list" })
  }
};
