<template lang="pug">
  tr()
    td
      div
        .th-in {{ (index + 1) }}
    // nazwa detalu
    td {{ property(item.detail, "name") }}
    // symbol
    td {{ property(item.detail, "symbol") }}
    // jednostka
    td {{ property(item.detailUnit, "name") }}
    //cena jednostkowa
    td {{ decimal(selectedUnitPrice) }}
    // ilość
    td.max-table-col-width-xsmall {{ item.quantity }}
    // ilość sztuk
    td.max-table-col-width-small {{pieces}}
    // skrot jm.
    td.max-table-col-width-small {{ unitName(item) }}
    // suma szt
    td {{ sumPieces }}
    // cena zakupu
    td.has-text-right(v-if="priceType !== 'sales' && printType === 'full'") {{ formatPriceValue(formatPrice(item.netPurchaseValue)) }}
    // cena sprzedaży
    td.has-text-right(v-if="priceType !== 'purchase' && printType === 'full'") {{ formatPriceValue(formatPrice(item.netSalesValue)) }}

</template>
<script>
import {mapGetters} from "vuex";

import calculatePieces from "../../../../details/helpers/calculatePieces";
import unitName from "../../../../details/helpers/unitName";
import priceFormatter from "../../../../../helpers/priceFormatter";
import decimal from "../../../../../helpers/decimal";

export default {
  props: {
    index: Number,
    item: Object,
    priceType: {default: null, required: true},
    printType: {default: null, required: true}
  },
  methods: {
    decimal,
    unitName,
    getDecimalValue(value) {
      return decimal(value);
    },
    formatPriceValue(value) {
      return priceFormatter(value);
    },
    versionNumber() {
      return this.checkVirtualVersionNumberAfterSum(this.item)
    },
    trueFalseToText(value) {
      return (value) ? this.$t("yes") : this.$t("no")
    },
    property(object, field, extraField = null) {
      if (extraField) {
        return object && object[extraField][field];
      }
      return object && object[field];
    },
  },
  computed: {
    ...mapGetters("company", ["lengthUnit", "currencyCode"]),
    ...mapGetters("common", [
      "formatLength",
      "formatNumber",
      "formatBoolean",
      "formatPrice",
      "formatWeight"
    ]),
    pieces() {
      return calculatePieces(this.item, this.lengthUnit);
    },
    sumPieces() {
      if (this.item.detailUnit && this.item.detailUnit.symbol === 'mb') {
        return (
            (this.item.pieces / 1000 * this.item.quantity).toFixed(2) + ' mb'
        );
      }
      return this.item.pieces / 100 * this.item.quantity;
    },
    selectedUnitPrice() {
      const {detailUnit, netSalesValue, netPurchaseValue} = this.item;

      if (!detailUnit?.symbol) {
        return;
      }

      const sumPrice = this.priceType === 'purchase' ? netPurchaseValue : netSalesValue;

      return sumPrice / parseInt(this.sumPieces);
    },
  }
};
</script>
<style scoped>
td {
  vertical-align: middle;
  text-align: center;
}
</style>