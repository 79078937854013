<template lang="pug">
  div
    .columns.is-desktop
      .column.is-12
        app-table-store(store-namespace="detailsList" sidebar=true)
          template(#sidebar-filter="props")
            detail-filters(v-bind="props")
          template(#filters="props")
            .columns
              .column.is-12
                app-table-filter-text(
                  :label="$t('name_or_symbol')"
                  field="search"
                  v-bind="props"
                  :time="1000"
                )

          template(#headers="props")
            app-table-head(v-bind="props" :label="$t('order_list.header_picture')")
            app-table-head(v-bind="props" :label="$t('order_list.header_name')")
            app-table-head(v-bind="props" :label="$t('order_list.header_symbol')")
            app-table-head(v-bind="props" :label="$t('order_list.header_unit')")
            app-table-head(v-bind="props" :label="$t('order_list.header_unit_price')")
            app-table-head(v-bind="props" :label="$t('order_list.header_pieces')").max-table-col-width-xsmall
            app-table-head(v-bind="props" :label="$t('order_list.header_quantity_in_package')").max-table-col-width-xsmall
            app-table-head(v-bind="props" :label="$t('order_list.header_unit_short')").max-table-col-width-xsmall
            app-table-head(v-bind="props" :label="$t('order_list.header_sum')")
            app-table-head(v-if="!hideWholeSalePrice" v-bind="props" :label="$t('order_list.header_value')")

          template(#default="{ row, perPage, total, index }")
            detail-item(:row="row" :index="index" :key="index" v-model="selectedDetail")

        detail-info-modal(
          v-if="selectedDetail !== null"
          v-model="selectedDetail"
          :active="selectedDetail !== null"
        )
</template>

<script>
import {mapGetters} from "vuex";
import ResourceSelect from "@/modules/common/components/ResourceSelect";
import DetailItem from "./DetailItem.vue";
import DetailFilters from "./DetailFilters.vue";
import DetailInfoModal from "./DetailInfoModal.vue";

export default {
  components: {DetailInfoModal, DetailFilters, DetailItem, ResourceSelect},
  data() {
    return {
      globalError: null,
      errors: [],
      selectedDetail: null
    }
  },
  created() {
    this.$store.dispatch("detailsList/fetch");
  },

  computed: {
    ...mapGetters("company", ["lengthUnit", "currencyCode"]),
    ...mapGetters(["hideWholeSalePrice"]),
    ...mapGetters("details", [
      "image",
      "item",
      "order"
    ]),
  },
};
</script>
