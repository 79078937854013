import api from "./../api";

export function uploadMedia(files, formData, multiple) {
  let promises = [];

  if(multiple){
    formData['files'] = []
    files.file.forEach(async (value) => {
      if (value instanceof File) {
        promises.push(
            new Promise(function(resolve, reject) {
              api.postMediaObject(value).then(function({ data }) {
                formData['files'].push(data["@id"])
                resolve(formData);
              }, reject);
            })
        );
      }
    });
  }else{
    Object.entries(files).forEach(async ([key, value]) => {
      if (value instanceof File) {
        promises.push(
            new Promise(function(resolve, reject) {
              api.postMediaObject(value).then(function({ data }) {
                formData[key] = data["@id"];
                resolve(formData);
              }, reject);
            })
        );
      }
    });

  }
  return promises;

}
