<template lang="pug">
  .table-container
    table.table.is-bordered.has-cells-centered.is-fullwidth.is-narrow
            thead
                tr
                    th {{ $t("ordinal_number") }}
                    th {{ $t("version") }}
                    th {{ $t("external_shutter.has_mosquito_net") }}
                    th(v-if="printType === 'full'") {{ $t("external_shutter.global_pipe") }}
                    th(v-if="printType === 'full'") {{ $t("external_shutter.combination_width", {lengthUnit}) }}
                    th(v-if="printType === 'full'") {{ $t("external_shutter.combination_runners_height", {lengthUnit}) }}
                    th(v-if="printType === 'full'") {{ $t("external_shutter.combination_full_height", {lengthUnit}) }}
                    th(v-if="printType === 'full'") {{ $t("external_shutter.external_shutter_box") }}
                    th {{ $t("external_shutter.external_shutter_external_shutter_runner_entry_left") }}
                    th {{ $t("external_shutter.external_shutter_external_shutter_runner_entry_right") }}
                    th(v-if="printType === 'full'") {{ $t("external_shutter.external_shutter_external_shutter_runner_entry_drill_hole_left") }}
                    th(v-if="printType === 'full'") {{ $t("external_shutter.external_shutter_external_shutter_runner_entry_drill_hole_right") }}
                    th {{ $t("external_shutter.external_shutter_protections") }}
                    th(v-if="printType === 'full'") {{ $t("external_shutter.external_shutter_adaptations") }}
                    th {{ $t("drive") }}
                    //th {{ $t("drive_series") }}
                    th(v-if="printType === 'full'") {{ $t("drive_side") }}

                    th(v-if="printType === 'full'") {{ $t("external_shutter.combinations") }}
                    //th {{ $t('installation_place') }}

                    th(v-if="printType === 'full'") {{ $t("external_shutter.weight") }}
                    th(v-if="printType === 'full'") {{ $t("external_shutter.surface") }}
                    th {{ $t("pieces") }}
                    th.has-text-right(v-if="priceType !== 'sales' && printType === 'full'") {{ $t("net_purchase_value", { currencyCode }) }}
                    th.has-text-right(v-if="priceType !== 'purchase' && printType === 'full'") {{ $t("net_sales_value", { currencyCode }) }}
                tr

            tbody
              template(v-for="(item, index) in this.externalShutterOrderItems")
                template(v-for="(itemCombination, indexCombination) in item.externalShutterOrderItemCombinations")
                  external-shutter-order-item(
                      :item="item"
                      :index="index"
                      :itemCombination="itemCombination"
                      :indexCombination="indexCombination"
                      :external-shutter-order-items="externalShutterOrderItems"
                      :price-type="priceType"
                      :print-type="printType"
                  )

            tr
              td(:colspan="printType === 'full' ? 16 : 6 " style="border: 0;")
              td {{ $t('sum_up') }}
              td(v-if="printType === 'full'") {{ formatWeight(sumValueByParameterInOrderItems("weight")) }}
              td(v-if="printType === 'full'") {{ formatSurface(sumValueByParameterInOrderItems("sumSurfacesAllCombinations", true)) }}
              td {{ sumValueByParameterInOrderItems("quantity") }}
              td.has-text-right(v-if="priceType !== 'sales' && printType === 'full'") {{ getDecimalValue(sumValueByParameterInOrderItems("netPurchaseValue"))}}
              td.has-text-right(v-if="priceType !== 'purchase' && printType === 'full'") {{ getDecimalValue(sumValueByParameterInOrderItems("netSalesValue")) }}


</template>
<script>
import ExternalShutterOrderItem from "./ExternalShutterOrderItem.vue";

import { mapGetters } from "vuex";
import decimal from "@/helpers/decimal";
import priceFormatter from "../../../../../helpers/priceFormatter";

export default {
  components: { ExternalShutterOrderItem },
  props:{
    externalShutterOrderItems: {type: Array, default: null, required: true},
    priceType: { default: null, required: true },
    printType: { default: null, required: true }
  },
  computed: {
    ...mapGetters("company", ["currencyCode", "lengthUnit"]),
    ...mapGetters("common", ["formatWeight", "formatSurface"]),

  },
  methods: {
    sumValueByParameterInOrderItems: function(parameter, multipleByQuantity = false)  {
      return this.externalShutterOrderItems.reduce((total, externalShutterOrderItem) =>
          (externalShutterOrderItem[parameter] * (multipleByQuantity ? externalShutterOrderItem.quantity : 1)) + total,0)
    },
    formatPriceValue(value) {
      return priceFormatter(value);
    },
    getDecimalValue(value) {
      return decimal(value);
    },
    property(object, field, extraField = null) {
      if (extraField) {
        return object && object[extraField][field];
      }
      return object && object[field];
    },
  }
};
</script>
<style>
.table {
  max-height: 200px;
  overflow-y: auto;
}
</style>
