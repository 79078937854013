<template lang="pug">
  div
    b-input(:value="value" readonly)
    b-modal(:active.sync="isModalActive" has-modal-card)
      app-view-modal(title="Wybierz element")
        template(#body)
          table
            thead
              tr
                th Pierwsza kolumna
                th Druga kolumna
                th Trzecia kolumna
          tbody
            tr(v-for="item in list")
              td {{ item.value }}
    //b-select(:loading="loading" :value="internalValue" @input="$emit('input', $event)" :placeholder="placeholder")
      option(v-for="item in list" :key="item.id" :value="`/${resource}/${item.id}`") {{ item.name }}
</template>
<script>
import http from "@/http";

export default {
  props: {
    url: { type: String },
    resource: { type: String, required: true },
    value: { default: null },
    placeholder: { type: String }
  },
  data() {
    return {
      loading: false,
      isModalActive: false,
      list: []
    };
  },
  computed: {
    internalValue() {
      if (this.value["@id"]) {
        return this.value["@id"];
      }
      return this.value;
    }
  },
  methods: {
    open() {}
  },
  async created() {
    this.loading = true;
    try {
      const url = this.url || this.resource;
      let { data } = await http.get(url, {
        params: {
          pagination: false
        }
      });
      this.list = data["hydra:member"];
      this.loading = false;
    } catch (e) {
      this.loading = false;
      throw e;
    }
  }
};
</script>
