import router from "./../../router";
import CreateOrder from "./views/CreateOrder";
import store from "../../store";
import externalShutters from "../external_shutters/store";

store.registerModule("externalShutters", externalShutters);

router.addRoutes([
  {
    path: "/external_shutters/create_order/:copyId?",
    name: "external_shutters_create_order",
    component: CreateOrder,
    props:true
  }
]);

router.addRoutes([
  {
    path: "/external_shutters/edit_order/:id",
    name: "external_shutters_edit_order",
    component: CreateOrder,
    props: true
  }
]);
