<template lang="pug">
    nav.breadcrumb
        ul
            li(
                v-for="({route, label}, i) in breadcrumbs"
                :class="classes(i)"
            )
                router-link(
                    v-if="route"
                    :to="getLink(route)"
                ) {{ getLabel(label) }}
                a(v-if="!route") {{ getLabel(label) }}
</template>
<script>
export default {
  props: {
    breadcrumbs: Array,
    data: Object
  },
  methods: {
    getLink(route) {
      return route({
        compData: this.data,
        router: this.$router,
        route: this.$route
      });
    },
    getLabel(label) {
      return label({ compData: this.data });
    },
    isLast(i) {
      return i === this.breadcrumbs.length - 1;
    },
    classes(i) {
      return { "is-active": this.isLast(i) };
    }
  }
};
</script>
