import router from "./../../router";
import OrderList from "./views/OrderList";
import SubCompanyOrdersList from "./views/SubCompanyOrderList.vue";
import store from "../../store";
import orders from "../order/store";
import createListStore from "../../helpers/createListStore";
import ValuationList from "@/modules/order/views/ValuationList";
import generateProperties from "../../helpers/generateProperties";
import subCompanyValuationList from "./views/SubCompanyValuationList.vue";
import OrderListSubCompanies from "./views/OrderListSubCompanies.vue";
import ValuationListSubCompanies from "./views/ValuationListSubCompanies.vue";


store.registerModule(
  "ordersList",
    createListStore(
    ()=>
      `/orders?exists[${store.getters["auth/companyTypeForUrlProp"]}]=true&status[]=complete&status[]=production&status[]=accepted&status[]=new&order[orderDate]=desc&properties[]=${
        store.getters["auth/companyTypeForUrlProp"]
      }` +
    generateProperties(['subCompany'], ["receptionDays","hasInvoicingByProducer", "hasShipmentToSubCompany"], true) +
    generateProperties(["customer"], ["firstName", "lastName", "city", "companyName", "type", "taxId",], true) +
    generateProperties([], ["id", "name", "number", "orderDate", "productionStatus", "category", "status", "receptionDate", "productionDate", "shipmentDate", "totalPurchaseGrossValue", "customPurchaseGrossValue", "deliveryMethod", "orderInvoice", "isSettled"], true) +
    generateProperties(["orderInvoice"], ["invoiceNumber", "invoiceType", "createdAt", "updatedAt"], true)
  )
);

store.registerModule(
  "ordersListSubCompanies",
    createListStore(
    ()=>
    `/orders?exists[subCompany]=true&status[]=complete&status[]=production&status[]=accepted&status[]=new&order[orderDate]=desc&properties[]=${
      store.getters["auth/companyTypeForUrlProp"]
    }` +
    generateProperties(['subCompany'], ["receptionDays","hasInvoicingByProducer","shortName", "hasShipmentToSubCompany"], true) +
    generateProperties(["customer"], ["firstName", "lastName", "city", "companyName", "type", "taxId",], true) +
    generateProperties([], ["id", "name", "number", "orderDate", "productionStatus", "category", "status", "receptionDate", "productionDate", "shipmentDate", "totalPurchaseGrossValue", "customPurchaseGrossValue", "deliveryMethod", "orderInvoice"], true) +
    generateProperties(["orderInvoice"], ["invoiceNumber", "invoiceType", "createdAt", "updatedAt"], true)
  )
);

store.registerModule(
  "valuationsList",
    createListStore(
    ()=>
    `/orders?status[]=valuation&properties[]=${
      store.getters["auth/companyTypeForUrlProp"]
    }&order[isRetraction]=desc&properties[]=category&properties[]=valuationDate&properties[]=retraction&order[valuationDate]=desc` +
    generateProperties([], ["id", "name"], true) +
    generateProperties(["customer"], ["firstName", "lastName", "city", "companyName", "type", "taxId",], true)
  )
);

store.registerModule(
  "subCompanyOrdersList",
    createListStore(
    ()=>
    `/orders?status[]=complete&status[]=production&status[]=new&status[]=accepted&status[]=new&order[orderDate]=desc&properties[]=${
      store.getters["auth/companyTypeForUrlProp"]
    }` +
    generateProperties(['subCompany'], ["receptionDays","hasInvoicingByProducer", "hasShipmentToSubCompany"], true) +
    generateProperties(["customer"], ["firstName", "lastName", "city", "companyName", "type", "taxId",], true) +
    generateProperties([], ["id", "name", "number", "orderDate", "productionStatus", "category", "status", "receptionDate", "productionDate", "shipmentDate", "totalPurchaseGrossValue", "customPurchaseGrossValue", "deliveryMethod", "companyMarginProfit", "totalNetPurchaseValueBeforeDiscount", "orderInvoice", "isSettled"], true)
  )
);

store.registerModule(
  "subCompanyValuationsList",
    createListStore(
    ()=>
    `/orders?status[]=valuation&properties[]=${
      store.getters["auth/companyTypeForUrlProp"]
    }&order[isRetraction]=desc&properties[]=valuationDate&properties[]=retraction&order[valuationDate]=desc` +
    generateProperties(['subCompany'], ["hasInvoicingByProducer", "hasShipmentToSubCompany"], true) +
    generateProperties(["customer"], ["firstName", "lastName", "city", "companyName", "type", "taxId",], true) +
    generateProperties([], ["id", "name", "number", "orderDate", "productionStatus", "category", "status", "receptionDate", "productionDate", "shipmentDate", "totalPurchaseGrossValue", "customPurchaseGrossValue", "deliveryMethod", "companyMarginProfit", "totalNetPurchaseValueBeforeDiscount","orderInvoice"], true)
  )
);

store.registerModule(
  "valuationsListSubCompanies",
    createListStore(
    ()=>
    `/orders?exists[subCompany]=true&status[]=valuation&properties[]=${
      store.getters["auth/companyTypeForUrlProp"]
    }&order[isRetraction]=desc&properties[]=category&properties[]=valuationDate&properties[]=retraction&order[valuationDate]=desc` +
    generateProperties(['subCompany'], ["shortName"], true) +
    generateProperties([], ["id", "name"], true) +
    generateProperties(["customer"], ["firstName", "lastName", "city", "companyName", "type", "taxId",], true)
  )
);

store.registerModule("orders", orders);


router.addRoutes([
  {
    path: "/orders/list",
    name: "orders_list",
    component: OrderList
  },
  {
    path: "/valuations/list",
    name: "valuations_list",
    component: ValuationList
  },
  {
    path: "/sub_companies/orders",
    name: "sub_company_orders_list",
    component: SubCompanyOrdersList
  },
  {
    path: "/sub_companies/valuations",
    name: "sub_company_valuations_list",
    component: subCompanyValuationList
  },
  {
    path: "/orders/sub_companies",
    name: "orders_list_subcompanies",
    component: OrderListSubCompanies
  },
  {
    path: "/valuations/sub_companies",
    name: "valuations_list_subcompanies",
    component: ValuationListSubCompanies
  }
]);

// router.addRoutes([
//   {
//     path: "/orders/list",
//     name: "orders_list",
//     component: OrderList
//   }
// ]);
//
// router.addRoutes([
//   {
//     path: "/valuations/list",
//     name: "valuations_list",
//     component: ValuationList
//   }
// ]);
//
// router.addRoutes([
//   {
//     path: "/sub_companies/orders",
//     name: "sub_company_orders_list",
//     component: SubCompanyOrdersList
//   }]);
//
// router.addRoutes([
//   {
//     path: "/sub_companies/valuations",
//     name: "sub_company_valuations_list",
//     component: ValuationList
//   }
// ]);


