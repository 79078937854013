<template lang="pug">
    tr()
        // liczba porządkowa
        td
          div
            .th-in {{ (index + 1 + iteratorAlphabet[indexCombination]) }}
        //nazwa wersji (z drugiej tabelki)
        td {{ versionNumber() + 1 }}
        // Moskitiera
        td {{ trueFalseToText(property(itemCombination.hasMosquitoNet)) }}
        // symbol rury
        td(v-if="printType === 'full'") {{ property(itemCombination.globalPipe, "symbol") }}
        //szerokość kombinacji
        td(v-if="printType === 'full'") {{ formatLength(itemCombination.width) }}
        // wysokość prowadnic
        td(v-if="printType === 'full'") {{ formatLength(itemCombination.runnersHeight) }}
        // wysokość całkowita
        td(v-if="printType === 'full'") {{ formatLength(itemCombination.fullHeight) }}
        // nazwa skrzynki
        td(v-if="printType === 'full'") {{ property(itemCombination.externalShutterBox, "name") }}
        // Prowadnica lewa
        td {{ property(itemCombination.externalShutterExternalShutterRunnerEntryLeft, "name",  "externalShutterRunner") }}
        // Prowadnica prawa
        td {{ property(itemCombination.externalShutterExternalShutterRunnerEntryRight, "name",  "externalShutterRunner") }}
        // kolor prowadnic
        //td {{ property(item.externalShutterRunnerFabricShadeColorEntry, "name",  "fabricShadeColor") }}
        // Nawiert lewy
        td(v-if="printType === 'full'") {{ property(itemCombination.externalShutterDrillHoleLeft, "name") }}
        // Nawiert prawy
        td(v-if="printType === 'full'") {{ property(itemCombination.externalShutterDrillHoleRight, "name") }}
        // Zabezpieczenia
        td
          p(v-if="!itemCombination.externalShutterExternalShutterProtectionEntries.length") {{$t('empty')}}
          p(v-for="(itemProtection, indexProtection) in itemCombination.externalShutterExternalShutterProtectionEntries") {{ property(itemProtection, "name", "externalShutterProtection") }}
        //Adaptacja
        td(v-if="printType === 'full'") {{ property(itemCombination.externalShutterAdaptation, "name") }}
        // typ napędu
        td {{ property(itemCombination.globalDrive, "name")? property(itemCombination.globalDrive, "name") : $t('empty')   }}
        // seria napędu
        //td {{ property(itemCombination.globalDrive, "name", "globalDriveSeries")? property(itemCombination.globalDrive, "name", "globalDriveSeries") : $t('empty') }}
        // strona napędu
        td(v-if="printType === 'full'") {{ property(itemCombination.externalShutterDriveExit, "name") }}
        // wybrana kombinacja
        td(v-if="printType === 'full'") {{ (item.selectedCombination === "single") ? "-" : item.selectedCombination   }}
        //miejsce montazu/krotki opis
        //td {{ item.shortDescription }}
        // masa zestawu
        td(v-if="printType === 'full'") {{ formatWeight(itemCombination.weight) }}
        //Powierzchnia zestawu
        td(v-if="printType === 'full'") {{ formatSurface(item.sumSurfacesAllCombinations) }}
        // ilość szt. przy kombinacji
        td(v-if="indexCombination === 0" :rowspan="item.externalShutterOrderItemCombinationsCount") {{ item.quantity }}
        // cena zakupu
        td.has-text-right(v-if="priceType !== 'sales' && printType === 'full'") {{ formatPriceValue(formatPrice(itemCombination.netPurchaseValue)) }}
        // cena sprzedaży
        td.has-text-right(v-if="priceType !== 'purchase' && printType === 'full'") {{ formatPriceValue(formatPrice(itemCombination.netSalesValue)) }}

</template>
<script>
import { mapGetters } from "vuex";
import priceFormatter from "../../../../../helpers/priceFormatter";
import externalShutterOrderItemsMixinJs from "../../../mixins/externalShutterOrderItemsMixin";

export default {
  mixins: [externalShutterOrderItemsMixinJs],
  props: {
    index: Number,
    indexCombination : Number,
    item: Object,
    itemCombination: Object,
    externalShutterOrderItems: {type: Array, default: null, required: true},
    priceType: { default: null, required: true },
    printType: { default: null, required: true }

  },
  methods: {
    formatPriceValue(value) {
      return priceFormatter(value);
    },
    versionNumber() {
      return this.checkVirtualVersionNumberAfterSum(this.item)
    },
    trueFalseToText(value) {
      return (value) ? this.$t("yes") : this.$t("no")
    },
    property(object, field, extraField = null) {
      if (extraField) {
        return object && object[extraField][field];
      }
      return object && object[field];
    },
  },
  computed: {
    iteratorAlphabet(){
      if(this.item.externalShutterOrderItemCombinationsCount === 1)return " "
      return ["a","b","c"]
    },
    ...mapGetters("common", [
      "formatLength",
      "formatNumber",
      "formatBoolean",
      "formatPrice",
      "formatWeight",
      "formatSurface"
    ])
  }
};
</script>
