import CreateOrder from "./views/CreateOrder";
import details from "../details/store";
import store from "../../store";
import router from "../../router";
import createListStore from "../../helpers/createListStore";

store.registerModule("details", details);

store.registerModule(
    "detailsList",
      createListStore(
    ()=>`details?`)
)

router.addRoutes([
  {
    path: "/details/create_order/:copyId?",
    name: "details_create_order",
    component: CreateOrder,
    props: true
  }
]);

router.addRoutes([
  {
    path: "/details/edit_order/:id",
    name: "details_edit_order",
    component: CreateOrder,
    props: true
  }
]);
