<template lang="pug">
  .table-container
    table.table.is-bordered.has-cells-centered.is-narrow
      thead
        tr
          th {{ $t("ordinal_number") }}
          th {{ $t("version") }}
          th {{ $t("external_shutter.external_shutter_armor") }}
          th {{ $t("external_shutter.external_shutter_armor_color") }}
          th {{ $t("external_shutter.external_box_color") }}
          th {{ $t("external_shutter.internal_box_color") }}
          th {{ $t("external_shutter.runners_color") }}
          th {{ $t("external_shutter.external_shutter_armor_fabric_shade_color") }}
        tr

      tbody
        template(v-for="(item, index) in orderAfterSum")
          external-shutter-order-item-after-sum(
            :item="item"
            :index="index"
          )
      tr
</template>
<script>
import { mapGetters } from "vuex";
import priceFormatter from "../../../../../helpers/priceFormatter";
import ExternalShutterOrderItemAfterSum from "./ExternalShutterOrderItemAfterSum.vue";
import externalShutterOrderItemsMixinJs from "../../../mixins/externalShutterOrderItemsMixin.js";

export default {
  components: {ExternalShutterOrderItemAfterSum},
  mixins: [externalShutterOrderItemsMixinJs],
  props:{
    externalShutterOrderItems: {type: Array, default: null, required: true}
  },
  computed: {
    ...mapGetters("company", ["currencyCode", "lengthUnit"]),
    ...mapGetters("common", ["formatWeight"]),
  },
  methods: {
    formatPriceValue(value) {
      return priceFormatter(value);
    },
  }

};
</script>
