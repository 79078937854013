<template lang="pug">
    .table-container
        table.table.is-bordered.has-cells-centered.is-narrow
            thead
                tr
                    th {{ $t("ordinal_number") }}
                    th {{ $t("automation.element_item") }}

                    th {{ $t("pieces") }}
                    th(v-if="priceType !== 'sales'") {{ $t("net_purchase_value", { currencyCode }) }}
                    th(v-if="priceType !== 'purchase'") {{ $t("net_sales_value", { currencyCode }) }}
                tr
            tbody
              template(v-for="(item, index) in automationOrderItems")
               automation-order-item(
                      :item="item"
                      :index="index"
                      :price-type="priceType"
                  )
            tr(v-if="automationOrderItems && automationOrderItems.length > 0")
              td(:colspan="priceType ? 0 : 1" style="border: 0;")
              td {{ $t('sum_up') }}
              td {{ sumParameterInAutomationOrderItems("quantity") }}
              td(v-if="priceType !== 'sales'") {{ getDecimalValue(sumParameterInAutomationOrderItems("netPurchaseValue"))}}
              td(v-if="priceType !== 'purchase'") {{ getDecimalValue(sumParameterInAutomationOrderItems("netSalesValue")) }}
            tr(v-else)
              td(:colspan="priceType ? 4 : 5") {{$t('empty')}}
</template>
<script>

import { mapGetters } from "vuex";
import decimal from "@/helpers/decimal";
import priceFormatter from "../../../../../helpers/priceFormatter";
import AutomationOrderItem from "./AutomationOrderItem.vue";

export default {
  props:{
    automationOrderItems: {type: Array, default: null, required: true},
    priceType: { default: null, required: true }
  },
  components: {AutomationOrderItem },
  computed: {
    ...mapGetters("company", ["currencyCode", "lengthUnit"]),
    ...mapGetters("common", ["formatWeight"])
  },
  methods: {
    sumParameterInAutomationOrderItems: function(parameter)  {
      return this.automationOrderItems.reduce((total, automationOrderItem) => automationOrderItem[parameter] + total,0)
    },
    formatPriceValue(value) {
      return priceFormatter(value);
    },
    getDecimalValue(value) {
      return decimal(value);
    }
  }
};
</script>
