import router from "./../../router";
import CreateOrder from "./views/CreateOrder";
import store from "../../store";
import frameMosquitoNet from "./store";

store.registerModule("frameMosquitoNets", frameMosquitoNet);
router.addRoutes([
  {
    path: "/frame_mosquito_net/create_order/:copyId?",
    name: "frame_mosquito_net_create_order",
    component: CreateOrder,
    props: true
  }
]);

router.addRoutes([
  {
    path: "/frame_mosquito_net/edit_order/:id",
    name: "frame_mosquito_net_edit_order",
    component: CreateOrder,
    props: true
  }
]);
