import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import http from "./http";

import CKEditor from "@ckeditor/ckeditor5-vue2";
import vSelect from "vue-select";
import { Plugin } from 'vue-fragment'
Vue.component("v-select", vSelect);

import VueCurrencyInput from "vue-currency-input";
Vue.use(VueCurrencyInput);

import VueHtmlToPaper from "vue-html-to-paper";

const location = window.location;
const printCssUrl =
  location.protocol + "//" + location.host + "/" + "print.css";

const options = {
  name: "_blank",
  specs: ["fullscreen=yes", "titlebar=no", "scrollbars=no"],
  styles: [
    printCssUrl,
    "https://cdn.jsdelivr.net/npm/bulma@0.9.0/css/bulma.css"
  ]
};

Vue.use(VueHtmlToPaper, options);
Vue.use(CKEditor);

Vue.use(Plugin) // vue-fragment package
import "./registerServiceWorker";

import Buefy from "buefy";
import "./scss/main.scss";
Vue.use(Buefy);

import AppComponents from "./plugins/app";
Vue.use(AppComponents);

import "./modules/auth";
import "./modules/company";
import "./modules/automation";
import "./modules/common";
import "./modules/dashboard";
import "./modules/fabric_shades";
import "./modules/fabric_shade_day_nights";
import "./modules/external_shutters";
import "./modules/pleats";
import "./modules/details";
import "./modules/frame_mosquito_net";
import "./modules/management";
import "./modules/order";
import "./modules/offer";
import "./modules/customers";
import "./modules/news";
import "./modules/registration";
import "./modules/configuration";
import "./modules/support";

import notify from "./helpers/notify";
import i18n from "./i18n";

if (!Array.isArray) {
  Array.isArray = function(arg) {
    return Object.prototype.toString.call(arg) === "[object Array]";
  };
}

String.prototype.countRepeatsOnBegin = function(search) {
  let counter = 0;
  for (let i = 0; i < this.length; i++) {
    if (this[i] === search) {
      counter++;
    } else {
      break;
    }
  }

  return counter;
};

Date.prototype.getFullDatePL = function() {
  const day = this.getDate();
  const month = this.getMonth() + 1;
  const year = this.getFullYear();
  return `${day < 10 ? "0" : ""}${day}-${month < 10 ? "0" : ""}${month}-${year}`;
};

Vue.config.productionTip = false;
Vue.prototype.$notify = notify;
Vue.prototype.$http = http;

new Vue({
  router,
  store,
  i18n,
  render: h => h(App)
}).$mount("#app");
