<template lang="pug">
    b-field
        b-upload(:value="value" @input="$emit('input', $event)" :multiple="multiple" :accept="accept" )
            a(class="button is-primary")
                b-icon(icon="upload")
                span Wskaż plik
        span(class="file-name" v-if="multiple" v-for="file in value") {{ file.name }}
        span(class="file-name" v-if="!multiple && !image && value.name") {{ value.name }}
        span(class="" v-if="!removed && image && image.contentUrl")
            .card
                .card-image
                    figure.image
                        img(:src="image.contentUrl")
                .card-footer
                    p.card-footer-item
                        b-button(
                            type="is-danger"
                            icon-left="mdi mdi-delete"
                            @click.prevent="remove"
                            :loading="removing"
                            ) Usuń
</template>
<script>
export default {
  props: {
    value: { type: [File, Object, Array] },
    image: { type: [Object, String, Array] },
    multiple: Boolean,
    accept: {type:String, default: 'image/*'}
  },
  data() {
    return {
      removing: false,
      removed: false
    };
  },
  methods: {
    async remove() {
      try {
        this.removed = false;
        this.removing = true;
        await this.$http.delete(`media_objects/${this.image.id}`);
        this.$emit("input", {});
        this.removing = false;
      } catch (e) {
        this.removing = false;
      }
    }
  }
};
</script>
