<template lang="pug">
    tr
        // liczba porządkowa
        td
          div
            .th-in {{ (index + 1 ) }}
        // pilot
        td {{ property(item.globalRemoteControl, "name") ? property(item.globalRemoteControl, "name") : property(item.globalDrive, "name") ? property(item.globalDrive, "name") : property(item.globalDriveControl, "name") }}
        // quantity
        td {{ item.quantity }}
        // cena zakupu
        td(v-if="priceType !== 'sales'") {{ formatPriceValue(formatPrice(item.netPurchaseValue)) }}
        // cena sprzedaży
        td(v-if="priceType !== 'purchase'") {{ formatPriceValue(formatPrice(item.netSalesValue)) }}

</template>
<script>
import { mapGetters} from "vuex";
import priceFormatter from "../../../../../helpers/priceFormatter";

export default {
  props: {
    index: Number,
    item: Object,
    priceType: { default: null, required: true }
  },
  methods: {
    formatPriceValue(value) {
      return priceFormatter(value);
    },
    trueFalseToText(value){
      return (value)? this.$t("yes") : this.$t("no")
    },
    property(object, field, extraField = null) {
      if (extraField) {
        return object && object[extraField][field];
      }
      return object && object[field];
    }
  },
  computed: {
    ...mapGetters("common", [
      "formatLength",
      "formatNumber",
      "formatBoolean",
      "formatPrice",
      "formatWeight"
    ])
  }
};
</script>
