import SubCompanyForm from "@/modules/management/components/SubCompanyForm";
import auth from "@/modules/auth/store";
import company from "@/modules/company/store";
import router from "../../../router";
import generateProperties from "../../../helpers/generateProperties";

const checkIfUserIsAllowedToCreateSubCompany = () => {
  if (
    auth.state.companyType === "sub_company" ||
    auth.state.type !== "company_owner" ||
    !company.state.isAllowedToCreateSubCompany
  ) {
    router.push("/");
  }
}
export default {
  list: {
    permissions: ["sub_company_read"],
    actions: [
      {
        label: "Nowy odbiorca",
        type: "is-primary",
        route: () => ({
          name: "sub_companies_create"
        }),
        permissions: ["sub_company_create"]
      }
    ],
    onCreated: checkIfUserIsAllowedToCreateSubCompany,
    route: {
      path: "/sub_companies/list",
      name: "sub_companies_list",

    },
    sortable: false,
    breadcrumbs: [
      {
        route: () => ({name: "sub_companies_list"}),
        label: () => "Pododbiorcy"
      }
    ],
    fields: [
      {field: "shortName", type: "text", label: "Nazwa", filter: "text"},
      {field: "isEnabled", type: "boolean", label: "Aktywny", filter: "boolean"},
      {
        type: "link",
        label: "Zamówienia",
        link: {
          label: "Zobacz",
          route: ({row}) => ({
            name: "sub_company_orders_list",
            params: {'subCompany': row},
            query: {'subCompany.id': row.id}
          })
        },
        permissions: ["sub_company_order_read"]
      },
      {
        type: "link",
        label: "Wyceny",
        link: {
          label: "Zobacz",
          route: ({row}) => ({
            name: "sub_company_valuations_list",
            params: {'subCompany': row},
            query: {'subCompany.id': row.id}
          })
        },
      }

    ],
    resource: "sub_companies",
    url: () =>
      `/sub_companies?` +
      generateProperties([], ["isEnabled", "shortName", "id", "isVisible"]),
    rowActions: [
      {
        action: "edit",
        permissions: ["sub_company_update"]
      },
      {
        action: "remove",
        permissions: ["sub_company_delete"]
      }
    ]
  },
  create: {
    permissions: ["sub_company_create"],
    onCreated: checkIfUserIsAllowedToCreateSubCompany,
    route: {
      path: "/sub_companies/create",
      name: "sub_companies_create",
    },
    breadcrumbs: [
      {
        route: () => ({name: "sub_companies_list"}),
        label: () => "Odbiorcy"
      },
      {
        label: () => `Nowy odbiorca`
      }
    ],
    listRoute: () => ({
      name: "sub_companies_list"
    }),
    updateRoute: ({object}) => ({
      name: "sub_companies_update",
      params: {
        id: object.id
      }
    }),
    formComponent: SubCompanyForm,
    redirectToListAfterCreate: true
  },
  update: {
    permissions: ["sub_company_update"],
    onCreated: checkIfUserIsAllowedToCreateSubCompany,
    route: {
      path: "/sub_companies/:id/update",
      name: "sub_companies_update",
    },
    resource: "sub_companies",
    breadcrumbs: [
      {
        route: () => ({name: "sub_companies_list"}),
        label: () => "Odbiorcy"
      },
      {
        label: ({compData}) => `Edycja odbiorcy "${compData.object.name}"`
      }
    ],
    formComponent: SubCompanyForm,
    listRoute: () => ({
      name: "sub_companies_list"
    })
  }
};
