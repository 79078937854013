<template lang="pug">
    table.table.is-bordered.has-cells-centered.is-narrow
      thead
        tr
          th(:colspan="!priceType ? 3 : 2") {{$t("summary") }}
        tr
          th {{ $t("pieces") }}
          th.has-text-right(v-if="priceType !== 'sales'") {{ $t("net_purchase_value", { currencyCode }) }}
          th.has-text-right(v-if="priceType !== 'purchase'")    {{ $t("net_sales_value", { currencyCode }) }}
        tr

      tbody
      tr
        td {{ order.quantity }}
        td.has-text-right(v-if="priceType !== 'sales'") {{ formatPriceValue(getDecimalValue(order.netPurchaseValueBeforeDiscount + order.netPurchaseAutomationValueBeforeDiscount))}}
        td.has-text-right(v-if="priceType !== 'purchase' ") {{ formatPriceValue(getDecimalValue(order.netSalesValue + order.netSalesAutomationValue)) }}

</template>
<script>

import { mapGetters } from "vuex";
import decimal from "@/helpers/decimal";
import priceFormatter from "../../../../helpers/priceFormatter";

export default {
  name: "SummaryPricesInOrder",
  props:{
    order: {type: Object, default: null, required: true},
    priceType: { default: null, required: true }
  },
  computed: {
    ...mapGetters("company", ["currencyCode", "lengthUnit"]),
    ...mapGetters("common", ["formatWeight"])
  },

  methods: {
    formatPriceValue(value) {
      return priceFormatter(value);
    },
    getDecimalValue(value) {
      return decimal(value);
    }
  }
}
</script>
