export default function checkKeyInDeepObject(array, key, valueToCompare, deep = false) {
    if (!array || array.length === 0) return false;

    if (!valueToCompare) return false;

    if (valueToCompare instanceof Array) {

        const isSameArray = (a, b) => {
            return a[deep][key] === b[deep][key];
        }

        const onlyInLeft = (left, right, compareFunction) =>
            left.filter(leftValue =>
                right.some(rightValue =>
                    compareFunction(leftValue, rightValue)));

        return onlyInLeft(array, valueToCompare, isSameArray); // return the same object
    }

    //helper functions
    const arrayToDeepString = (deep) => {
        return deep.map(el => `[${el}]`)
    }

    const result = array.findIndex(it => {
        if (deep instanceof Array) { // when deep is array
            if (`${it}[${arrayToDeepString(deep)}]`[key] === `${valueToCompare}[${arrayToDeepString(deep)}]`[key]) return true;
            return false;
        }
        if (deep) {
            if (it[deep][key] === valueToCompare[deep][key]) return true;
            return false;
        }
        //wichout deep
        if (it[key] === valueToCompare[key]) return true;

        return false
    });

    return result !== -1
}