<template lang="pug">
section.hero.is-fullheight
  .hero-body
    .container
      .columns.is-centered
        .column.is-5-tablet.is-4-desktop.is-3-widescreen
          form.box.is-radiusless(@submit.prevent="submit")
            b-message(v-show="error" type="is-danger")
              | {{ error }}
            .field
              label.label {{ $t("user.login") }}
              .control.has-icons-left
                input.input(v-model="data.username" type="text" placeholder="username" required="")
                span.icon.is-small.is-left
                  i.fa.fa-envelope
            .field
              label.label {{ $t("user.password") }}
              .control.has-icons-left
                input.input(v-model="data.password" type="password" placeholder="*******" required="")
                span.icon.is-small.is-left
                  i.fa.fa-lock
            .field.has-text-right
              router-link.is-info(:to="{ name: 'resetPassword' }").is-small Nie pamiętam hasła
            .field.has-text-centered
              b-button(native-type="submit" type="is-primary" :loading="loading") {{ $t("user.log_me_in") }}


          b-message(type="is-warning") {{ $t('user.alert') }}
          div.has-text-centered
            router-link.button.is-primary(:to="{ name: 'registration' }") Zarejestruj się

</template>
<script>
export default {
  data() {
    return {
      loading: false,
      error: null,
      data: {
        username: null,
        password: null
      }
    };
  },
  methods: {
    async submit() {
      this.error = null;
      this.loading = true;
      try {
        await this.$store.dispatch("auth/postLogin", this.data);
        await this.$router.push("/");
      } catch (e) {
        if (e.message === "must_change_password") {
          this.error = "To jest Twoje pierwsze logowanie. Prosimy zmienić hasło za pomocą opcji przypomnij hasło.";
        }else {
          this.error = "Nie udało się zalogować";
        }
      }
      this.loading = false;
    }
  }
};
</script>
