<template lang="pug">
    b-field(
        :custom-class="customClass"
        :horizontal="horizontal"
        :label="label"
        :type="{ 'is-danger': hasError , 'is-warning' : hasWarning }"
        :message="message"
        :class="classes"
        :grouped="grouped"
        :expanded="expanded"
        :addons="addons"
        :position="position"
        :required="required"
    )
      template(#label v-if="required") {{ label }} *
      slot
</template>
<script>
export default {
  props: {
    position: String,
    addons: Boolean,
    required: Boolean,
    horizontal: Boolean,
    disabled: Boolean,
    grouped: Boolean,
    expanded: Boolean,
    classes: { type: String },
    label: { type: String },
    customClass: { type: String },
    field: { type: String, required: true },
    errors: { type: Array, default: () => [] },
    warnings: { type: Array, default: () => [] },
    customErrors: { type: Array, default: () => [] },
    customWarnings: { type: Array, default: () => [] },
    help: { type: String, default: "" },
    showHelp: { type: Boolean, default: true },
    translatable: { type: Boolean, default: false }
  },
  computed: {
    hasError() {
      const error = this.errors.find(({ propertyPath }) => {
        return propertyPath === this.field;
      });

      const customError = this.customErrors.find(({ propertyPath }) => {
        return propertyPath === this.field;
      });

      if (error) return error;
      if (customError) return customError;
      return undefined;
    },

    hasWarning() {
      const warning = this.warnings.find(({ propertyPath }) => {
        return propertyPath === this.field;
      });

      const customWarning = this.customWarnings.find(({ propertyPath }) => {
        return propertyPath === this.field;
      });

      if (warning) return warning;
      if (customWarning) return customWarning;
      return undefined;
    },
    message() {
      if (this.hasError) {
        let error = this.hasError.message;
        if (this.translatable) {
          error = this.$t(error);
        }
        return error;
      }
      if (this.showHelp) {
        return this.help;
      }
      return null;
    }
  }
};
</script>
