<template lang="pug">
    .table-container
        table.table.is-bordered.has-cells-centered.is-fullwidth.is-narrow
            thead
                tr
                    th(rowspan=2) {{ $t("ordinal_number") }}
                    th(rowspan=2) {{ $t('installation_place') }}
                    th(rowspan=2) {{ $t("group") }}
                    th(rowspan=2) {{ $t("version") }}
                    th(rowspan=2) {{ $t("collection") }}
                    th(rowspan=2) {{ $t("fabric") }}
                    th(colspan=3) {{ $t("whole_width") }}
                    th(rowspan=2) {{ $t("height", { lengthUnit }) }}
                    th(rowspan=2) {{ $t("height_glass", { lengthUnit }) }}
                    th(rowspan=2) {{ $t("pieces") }}
                    th(rowspan=2) {{ $t("case") }}
                    th(rowspan=2) {{ $t("balk") }}
                    th(rowspan=2) {{ $t("drive_side") }}
                    th(rowspan=2) {{ $t("runners") }}
                    th(rowspan=2) {{ $t("fixing") }}
                    th(rowspan=2) {{ $t("drive") }}
                    th(rowspan=2) {{ $t("turn") }}
                    th(rowspan=2) {{ $t("additional") }}
                    th.has-text-right(rowspan=2 v-if="!hideWholeSalePrice") {{ $t("net_purchase_value", { currencyCode }) }}
                    th.has-text-right(rowspan=2) {{ $t("net_sales_value", { currencyCode }) }}
                    th(rowspan=2) {{ $t("operations") }}
                tr
                    th {{ $t("fabric_shade", { lengthUnit }) }}
                    th {{ $t("fabric_width", { lengthUnit }) }}
                    th {{ $t("width_glass", { lengthUnit }) }}
            tbody
                fabric-shade-day-night-order-item(
                    v-for="(item, index) in order.fabricShadeDayNightOrderItems"
                    :item="item"
                    :index="index"
                    :key ="index"
                )
                tr
                  td(colspan=19 style="border: 0;")
                  td {{ $t('sum_up') }}
                  td.has-text-right(v-if="!hideWholeSalePrice") {{ formatPriceValue(getDecimalValue(order.netPurchaseValueBeforeDiscount)) }}
                  td.has-text-right {{ formatPriceValue(getDecimalValue(order.netSalesValue)) }}
</template>
<script>
import FabricShadeDayNightOrderItem from "./FabricShadeDayNightOrderItem";
import { mapGetters } from "vuex";
import decimal from "@/helpers/decimal";
import priceFormatter from "../../../../helpers/priceFormatter";

export default {
  components: { FabricShadeDayNightOrderItem },
  computed: {
    ...mapGetters(["hideWholeSalePrice"]),
    ...mapGetters("company", ["currencyCode", "lengthUnit"]),
    ...mapGetters("fabricShadeDayNights", [
      "order",
      "fabricShadeDayNights",
      "fabricShadeDayNightGroups",
      "fabricShadeDayNightGlobalAccessoryEntries",
      "fabricShadeDayNightGlobalBalkEntries",
      "fabricShadeDayNightGlobalRunnerEntries",
      "fabricShadeDayNightGlobalFixingEntries"
    ])
  },
  methods: {
    formatPriceValue(value) {
      return priceFormatter(value);
    },
    getDecimalValue(value) {
      return decimal(value);
    }
  }
};
</script>
