import router from "./../../router";
import store from "./../../store";
import auth from "./store";
import http from "../../http";
import {
  onRequest,
  onRequestError,
  onResponse,
  onResponseError
} from "./http/interceptors";
import Login from "./views/Login";
import ResetPassword from "./views/ResetPassword";
import ChangePassword from "./views/ChangePassword";

router.addRoutes([
  {
    path: "/login",
    name: "login",
    component: Login,
    beforeEnter: (to, from, next) => {
      if (auth.state.isLoggedIn) {
        next("/");
      } else {
        next();
      }
    }
  },
  {
    path: "/reset-password",
    name: "resetPassword",
    component: ResetPassword,
    beforeEnter: (to, from, next) => {
      if (auth.state.isLoggedIn) {
        next("/");
      } else {
        next();
      }
    }
  },
  {
    path: "/change-password",
    name: "changePassword",
    component: ChangePassword,
    beforeEnter: (to, from, next) => {
      if (auth.state.isLoggedIn) {
        next("/");
      } else {
        next();
      }
    }
  }
]);
store.registerModule("auth", auth);

router.beforeEach((to, from, next) => {
  if (false === auth.state.isLoggedIn && to.name !== "login" && to.name !== "registration" && to.name !== "resetPassword" && to.name !== "changePassword") {
    next({name: "login"});
  }

  next();
});

http.interceptors.request.use(onRequest, onRequestError);
http.interceptors.response.use(onResponse, onResponseError);
