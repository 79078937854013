<template lang="pug">
    app-form(v-bind="props" v-on="listeners" resource="company_workers")
        template(#default="{ data, errors }")
            b-tabs
                b-tab-item(label="Ogólne")
                    .subtitle Pracownik firmy
                    .columns
                        .column
                          app-form-field(:label="$t('company_worker.first_name')" field="firstName" :errors="errors")
                              b-input(v-model="data.firstName")

                          app-form-field(:label="$t('company_worker.last_name')" field="lastName" :errors="errors")
                            b-input(v-model="data.lastName")

                          app-form-field(:label="$t('company_worker.email')" field="email" :errors="errors")
                            b-input(v-model="data.email")

                          app-form-field(:label="$t('company_worker.phone')" field="phone" :errors="errors")
                            b-input(v-model="data.phone")

                          app-form-field(:label="$t('company_worker.mobile_phone')" field="mobilePhone" :errors="errors")
                            b-input(v-model="data.mobilePhone")
                          app-form-field(:label="$t('available_membership')" field="memberships" :errors="errors" required)
                            multiple-resource-select(
                              v-model="data.memberships"
                              resource="member_ships"
                              url="member_ships"
                              :translatable='true'
                            )
                        .column
                          app-form-field(label="Login" field="username" :errors="errors")
                            b-input(v-model="data.username" @focus="isReadOnly = false" :readonly="isReadOnly" autocomplete="off")

                          app-form-field(label="Hasło" field="plainPassword" :errors="errors")
                            b-input(v-model="data.plainPassword" type="password" @focus="isReadOnly = false" :readonly="isReadOnly" autocomplete="off")

                          app-form-field(label="Aktywny" field="isEnabled" :errors="errors")
                            b-checkbox(v-model="data.isEnabled")
                          .column.is-6
                            div.has-text-right
                              b-button(type="is-danger" :loading="loading" @click="sendResetPassword") {{$t('company_worker.change_password')}}

</template>
<script>
import form from "../../../plugins/app/form/mixins/form";
import MultipleResourceSelect from "../../common/components/MultipleResourceSelect";

export default {
  mixins: [form],
  components: {MultipleResourceSelect},
  data() {
    return {
      loading: false,
      isReadOnly: true,
      model: {
        firstName: "",
        lastName: "",
        username: "",
        email: "",
        plainPassword: "",
        phone: "",
        mobilePhone: "",
        isEnabled: true,
        memberships: []
      }
    };
  },
  methods: {
    async sendResetPassword(e) {
      e.preventDefault() // prevent submit

      this.loading = true;
      try {
        await this.$store.dispatch("auth/resetPassword", {email: this.model.email}, false);
        this.$notify("Wysłaliśmy na podany e-mail z instrukcjami resetowania hasła.");
      } catch (e) {
        this.error = e.toString();
      }
      this.loading = false;
    }
  }
}
</script>
