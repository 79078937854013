<template lang="pug">
    b-input(v-model="formatted" @focus="onFocus" @blur="onBlur" :size="size" :disabled="disabled" :custom-class="customClass")
</template>
<script>
export default {
  props: {
    value: Number,
    unit: { type: String, default: "mm" },
    size: { type: String, default: "" },
    disabled: { type: Boolean, default: false },
    customClass: {type:String, default: ""}
  },
  computed: {
    factor() {
      if (this.unit === "mm") {
        return 1;
      }
      if (this.unit === "cm") {
        return 10;
      }
      if (this.unit === "m") {
        return 1000;
      }
      throw new Error("Unrecognized unit type");
    },
    formatted: {
      set(value) {
        if (this.unit === "mm") {
          value = value.replace(",", "");
          value = value.replace(".", "");
        }
        let lastElement = value.substring(value.length - 1);
        const isLastPeriod = lastElement === "," || lastElement === ".";
        if (isLastPeriod) {
          return;
        }
        value = value.replace(",", ".");
        const notANumber = value === "" || isNaN(value);
        const newValue = notANumber
          ? null
          : parseFloat(value.replace(",", ".")) * this.factor;
        this.$emit("input", newValue);
      },
      get() {
        return this.value === null
          ? this.value
          : parseFloat(this.value / this.factor)
              .toString()
              .replace(".", ",");
      }
    }
  },
  methods: {
    onBlur(event) {
      const newValue =
        parseFloat(event.target.value.replace(",", ".")) * this.factor;
      this.$emit("input", newValue);
      this.$emit("blur", newValue);
    },
    onFocus(event) {
      event.target.select();
      this.$emit("focus");
    }
  }
};
</script>
