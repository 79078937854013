<template lang="pug">
  app-view-card(:title="$t('fitters.panel_title')")
    .divider(v-if="hasPermission('FITTER_CREATE')") {{ $t("fitters.panel_title_new") }}
    fitter-form(:companyId="companyId" @success="onAdd" v-if="hasPermission('FITTER_CREATE')")
    .divider {{ $t("fitters.list") }}
    app-simple-table(
      :data="fitters"
      :columns="columns"
      @remove="remove($event)"
      @success="onUpdate"
      resource="fitters"
      editable
      )
</template>
<script>
import { mapActions, mapGetters, mapMutations } from "vuex";
import FitterForm from "@/modules/management/views/components/FitterForm";
import http from "../../../http";
import objectToCollection from "../../../helpers/objectToCollection";

export default {
  components: { FitterForm },
  computed: {
    ...mapGetters("common", ["formatPrice"]),
    ...mapGetters("auth", ["hasPermission"]),
    ...mapGetters("company", [
      "companyId",
      "companyPriceListEntries",
      "currencyCode",
      "fitters"
    ]),
    columns() {
      return [
        { field: "name", label: "name", width: "180px" },
        { field: "surname", label: "surname", width: "180px" },
        { field: "phone", label: "phone", width: "180px" },
        { field: "isEnabled", label: "isEnabled", width: "100px", bool: true }
      ];
    }
  },
  async mounted() {
    try {
      const companyId = this.$store.state.auth.companyId;
      const companyFittersResponse = await http.get(`/companies/${companyId}/fitters`);

      const companyFittersLists = companyFittersResponse.data["hydra:member"];

      this.setFitters(objectToCollection(companyFittersLists));

    } catch (error) {
      // console.error("Error fetching fitters:", error);
    }
  },
  methods: {
    ...mapMutations("company", ["setFitters"]),
    ...mapActions("company", ["removeFitter"]),
    //ten enter trzeba zostawic, wyklada mi sie przez niego caly template

    remove(event) {
      this.$buefy.dialog.confirm({
        message: this.$t("remove_confirm"),
        onConfirm: () => {
          this.removeFitter({
            id: event.id,
            idx: event.idx
          });
        }
      });
    },
    onAdd(data) {
      this.$store.commit("company/addFitter", data);
    },
    onUpdate(data) {
      this.$store.commit("company/updateFitter", data);
    }
  },
  created() {
    this.$store.dispatch("company/init");
  }
};
</script>
