import simpleClone from "@/helpers/simpleClone";

export default function(originalItem) {
  //elementy itemu zamówienia
  let item = simpleClone(originalItem);
  for (let key in item) {
    if (item[key] !== null && typeof item[key] === "object") {
      //elementy pojedynczego elementu
      for (let subitem in item[key]) {
        //usuwa w pojedynczych elementach wszystkie tablice (na 1 poziomie, nie schodzi niżej), w których moga być nadmiarowe ilości danych
        //można dodać również: if typeof item[key][subitem] === "object" i to również usuwać
        if (Array.isArray(item[key][subitem])) {
          delete item[key][subitem];
        }
      }
    }
  }
  return item;
}
