<template lang="pug">
  b-select(
    :loading="loading"
    :value="internalValue"
    @input="emit"
    :placeholder="placeholder"
    :expanded="expanded"
    @fetched="$emit('fetched', $event)"
    :disabled="disabled"
    :required="required"
  )
    option(v-if="nullable" :value="null") {{ placeholder }}
    option(v-for="item in list" :key="item.id" :value="itemValue(item)") {{ item[label] }}
</template>
<script>
import resourceSelectMixin from "@/modules/common/mixins/resourceSelectMixin";

export default {
  mixins: [resourceSelectMixin],
  props: {
    simple: Boolean,
    label: { type: String, default: "name" },
    value: { default: null },
    field: { type: String },
    placeholder: { type: String },
    nullable: Boolean,
    expanded: Boolean,
    disabled:Boolean,
    required:Boolean
  },
  methods: {
    itemValue(item) {
      if (!this.simple) {
        return `/${this.resource}/${item.id}`;
      }
      return item.id;
    },
    emit(event) {
      let obj = event;
      if (this.fullObject) {
        obj = this.list.find(item => item["@id"] === event);
      }
      if(this.field) {
        obj = {
          [this.field]: obj
        }
      }
      this.$emit("input", obj);
    }
  },
  computed: {
    internalValue() {
      if (!this.simple) {
        if (this.value && this.value["@id"]) {
          return this.value["@id"];
        }
        return this.value;
      }
      return this.value;
    }
  }
};
</script>
