import offerTemplateForm from "@/modules/offer/components/OfferTemplateForm";
import t from "@/i18n";

export default {
  list: {
    permissions: ["offer_template_read"],
    actions: [
      {
        label: t.t("new_offer_template"),
        type: "is-primary",
        route: () => ({
          name: "offer_templates_create"
        }),
        permissions: ["offer_template_create"]
      }
    ],
    route: {
      path: "/offer_templates/list",
      name: "offer_templates_list"
    },
    countable: true,
    breadcrumbs: [
      {
        label: () => t.t("offer_templates")
      }
    ],
    fields: [
      { field: "name", type: "text", label: t.t("name"), filter: "text" }
    ],
    resource: "offer_templates",
    url: () => `/offer_templates?properties[]=id&properties[]=name`,
    rowActions: [
      {
        action: "edit",
        permissions: ["offer_template_update"]
      },
      {
        action: "remove",
        permissions: ["offer_template_delete"]
      }
    ],
    deleteErrors: [
      {
        code: 422,
        message: "offer_template_actions.delete_connected_offers"
      }
    ]
  },
  create: {
    permissions: ["offer_template_create"],
    route: {
      path: "/offer_templates/create",
      name: "offer_templates_create"
    },
    breadcrumbs: [
      {
        route: () => ({ name: "offer_templates_list" }),
        label: () => t.t("offer_templates")
      },
      {
        label: () => t.t("new_offer_template")
      }
    ],
    formComponent: offerTemplateForm,
    listRoute: () => ({ name: "offer_templates_list" }),
    updateRoute: ({ object }) => ({
      name: "offer_templates_update",
      params: { id: object.id }
    })
  },
  update: {
    permissions: ["offer_template_update"],
    route: {
      path: "/offer_templates/:id/update",
      name: "offer_templates_update"
    },
    resource: "offer_templates",
    resourceProperties: "properties[]=id&properties[]=name&properties[]=footer",
    breadcrumbs: [
      {
        route: () => ({ name: "offer_templates_list" }),
        label: () => t.t("offer_templates")
      },
      {
        label: ({ compData }) =>
          `${t.t("offer_template_edit")}: "${compData.object.name}"`
      }
    ],
    formComponent: offerTemplateForm,
    listRoute: () => ({ name: "offer_templates_list" })
  }
};
