<template lang="pug">
.has-border-bottom.has-margin-bottom
  b-navbar
    template(slot="brand")
      b-navbar-item(tag="router-link" to="/")
        | SWIZ
    template(slot="start")
    template(slot="end")
      b-navbar-item(tag="div")
        a(href="#" @click.prevent="modal = true")
          b-icon.has-margin-right-small(icon="lock" size="fa-lg")
          span.has-font-size-small {{ $t("data_is_protected") }}
      b-navbar-item(tag="div")
        .buttons(v-if="isLoggedIn")
          span.button.is-primary(title="Zalogowany użytkownik")
            strong {{ fullName }}
          a.button.is-light(@click.prevent="logout" title="Wyloguj")
            b-icon(icon="logout")
  b-modal(:active.sync="modal")
    app-view-modal(:title="$t('data_is_protected')")
      template(#body)
        span(v-html="yourDataIsProtected")
</template>
<script>
import { mapGetters, mapState } from "vuex";

export default {
  data: () => ({ modal: false }),
  computed: {
    ...mapState("auth", ["isLoggedIn"]),
    ...mapGetters("configuration", ["yourDataIsProtected"]),
    ...mapGetters("auth", ["fullName"])
  },
  methods: {
    logout() {
      this.$store.dispatch("auth/logout").then(() => {
        this.$router.push({
          name: "login"
        });
      });
    }
  }
};
</script>
