<template lang="pug">
  .table-container
    table.table.is-bordered.has-cells-centered.is-fullwidth.is-narrow
      thead
        tr
          th(colspan=24) {{ orderName }}
        tr
            th(rowspan=2) {{ $t("ordinal_number") }}
            th(v-if="printType === 'full'" rowspan=2) {{ $t("group") }}
            th(rowspan=2) {{ $t("version") }}
            th(rowspan=2) {{ $t("collection") }}
            th(rowspan=2) {{ $t("fabric") }}
            th(v-if="printType === 'full'" colspan=3) {{ $t("whole_width") }}
            th(rowspan=2 v-if="printType === 'full'") {{ $t("height", { lengthUnit }) }}
            th(rowspan=2 v-if="printType === 'full'") {{ $t("height_glass", { lengthUnit }) }}

            th(rowspan=2) {{ $t("case") }}
            th(rowspan=2) {{ $t("balk") }}
            th(rowspan=2) {{ $t("drive_side") }}
            th(rowspan=2) {{ $t("runners") }}
            th(rowspan=2) {{ $t("fixing") }}
            th(rowspan=2) {{ $t("drive") }}
            //th(rowspan=2) {{ $t("drive_control") }}
            //th(rowspan=2) {{ $t("remote_control") }}
            th(rowspan=2 v-if="printType === 'full'") {{ $t("turn") }}
            th(rowspan=2) {{ $t("additional") }}

            th(rowspan=2) {{ $t("pieces") }}
            th.has-text-right(rowspan=2 v-if="priceType !== 'sales' && printType === 'full'") {{ $t("net_purchase_value", { currencyCode }) }}
            th.has-text-right(rowspan=2 v-if="priceType !== 'purchase' && printType === 'full'") {{ $t("net_sales_value", { currencyCode }) }}
        tr(v-if="printType === 'full'")
            th {{ $t("fabric_shade", { lengthUnit }) }}
            th {{ $t("fabric_width", { lengthUnit }) }}
            th {{ $t("width_glass", { lengthUnit }) }}

      tbody
        template(v-for="(item, index) in this.fabricShadeOrderItems")
          fabric-shade-order-item(
              :item="item"
              :index="index"
              :price-type="priceType"
              :print-type="printType"
            )

      tr
        td(:colspan="printType === 'full' ? 17 : 10" style="border: 0;")
        td {{ $t('sum_up') }}
        td {{ sumValueByParameterInOrderItems("quantity") }}
        td.has-text-right(v-if="priceType !== 'sales' && printType === 'full'") {{ getDecimalValue(sumValueByParameterInOrderItems("netPurchaseValue"))}}
        td.has-text-right(v-if="priceType !== 'purchase' && printType === 'full'") {{ getDecimalValue(sumValueByParameterInOrderItems("netSalesValue")) }}
</template>
<script>

import { mapGetters } from "vuex";
import decimal from "@/helpers/decimal";
import priceFormatter from "../../../../../helpers/priceFormatter";
import FabricShadeOrderItem from "./FabricShadeOrderItem.vue";
import orderNumberCreator from "../../../../../helpers/orderNumberCreator";

export default {
  components: {FabricShadeOrderItem},
  props:{
    fabricShadeOrderItems: {type: Array, default: null, required: true},
    priceType: { default: null, required: true },
    printType: { default: null, required: true }
  },
  computed: {
    ...mapGetters("company", ["currencyCode", "lengthUnit"]),
    ...mapGetters("common", ["formatWeight"]),
    orderName() {
      if (!this.order) {
        return this.$t("order_positions");
      }
      return this.$t("order_positions_for", { name: this.order.name });
    },
    orderNumber() {
      if (this.order && this.order.number) {
        return orderNumberCreator(this.order.number);
      } else {
        return "";
      }
    }
  },
  methods: {
    sumValueByParameterInOrderItems: function(parameter)  {
      return this.fabricShadeOrderItems.reduce((total, externalShutterOrderItem) => (externalShutterOrderItem[parameter]) + total,0)
    },
    formatPriceValue(value) {
      return priceFormatter(value);
    },
    getDecimalValue(value) {
      return decimal(value);
    },
  }
};
</script>
<style>
.table {
  max-height: 200px;
  overflow-y: auto;
}
</style>
