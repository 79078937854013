<template lang="pug">
  app-view-list(:loading="loading")
    template(#breadcrumbs)
      app-view-breadcrumbs(:breadcrumbs="breadcrumbs")

    b-message(type="is-warning") {{ $t('news.alert') }}

    news(v-for="item in list" :key="item.id" :news="item" @image="imageModal = $event" @input="updateNews")

    b-modal(v-model="active")
      .columns(style="width:99%").has-text-centered
        .column
          img(:src="imageModal")
</template>
<script>
import News from "./News";
import {mapGetters} from "vuex";

export default {
  components: {
    News
  },
  props: {
    breadcrumbs: Array
  },
  data() {
    return {
      loading: false,
      imageModal: null
    };
  },
  computed: {
    ...mapGetters("news", [
      "list",
    ]),
    active: {
      set() {
        this.imageModal = null;
      },
      get() {
        return this.imageModal !== null;
      }
    }
  },
  methods: {
    updateNews(news) {
      this.$store.dispatch("news/updateNews", news);
    }
  },
};
</script>
