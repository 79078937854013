import http from "../../http";
import generateProperties from "../../helpers/generateProperties";
import simpleClone from "../../helpers/simpleClone";


const defaultAutomationElementsToFilter = [
    //{value:"global_drive", key:"GlobalDrive"},
    {value: "global_control", key: "GlobalDriveControl"}]
//{value:"global_control_remote", key:"GlobalRemoteControl"}]

const defaultFilters = {
    elements: null,
    text: null,
    manufactures: null,
    driveControlType: null,
    remoteControlType: null,
    remoteControlChannel: null
};

let defaultItem = {
    globalRemoteControl: null,
    //globalRemoteControlQuantity: 1,
    globalDriveControl: null,
    globalDrive: null,
    quantity: 1,
};

export default {
    namespaced: true,
    state: {
        item: simpleClone(defaultItem),
        loading: null,
        productType: null,
        globalDrives: [],
        globalDriveControls: [],
        globalDriveControlRemotes: [],
        defaultAutomationElements: defaultAutomationElementsToFilter,
        filters: simpleClone(defaultFilters)

    },
    getters: {
        item: state => state.item,
        productType: state => state.productType,
        loading: state => state.loading,
        itemGlobalDriveManufactures: state => {
            let availableManufactures = [
                ...state.globalDrives ? state.globalDrives.map(item => item.manufacturer) : [],
                ...state.globalDriveControls ? state.globalDriveControls.map(item => item.manufacturer) : [],
                ...state.globalDriveControlRemotes ? state.globalDriveControlRemotes.map(item => item.manufacturer) : []]

            return [...new Map(availableManufactures.filter((item) => item).map((item) => {
                return [item["@id"], item]
            })).values()];

        },

        itemGlobalDriveControlType: state => {
            let availableManufactures = [
                ...state.globalDriveControls ? state.globalDriveControls.map(item => item.globalDriveControlType) : [],
            ]
            return [...new Map(availableManufactures.filter((item) => item).map((item) => [item["@id"], item])).values()];

        },

        itemGlobalRemoteControlType: state => {
            let availableManufactures = [
                ...state.globalDriveControlRemotes ? state.globalDriveControlRemotes.map(item => item.globalRemoteControlType) : []]

            return [...new Map(availableManufactures.filter((item) => item).map((item) => [item["@id"], item])).values()];
        },

        itemGlobalRemoteControlChannel: state => {
            let availableManufactures = [
                ...state.globalDriveControls ? state.globalDriveControls.map(item => item.globalRemoteControlChannel) : []]

            return [...new Map(availableManufactures.filter((item) => item).map((item) => [item["@id"], item])).values()];

        },

        itemProductElements: state => {

            let {
                elements,
                text,
                manufactures,
                driveControlType,
                remoteControlType,
                remoteControlChannel
            } = state.filters

            let itemElements = [
                ...state.globalDriveControls.filter(
                    item => (elements ? item["@type"] === elements["key"] : true) &&
                        (driveControlType ? driveControlType["@id"] === item.globalDriveControlType["@id"] : true) &&
                        (manufactures ? manufactures["@id"] === item.manufacturer["@id"] : true) &&
                        ((remoteControlChannel && item.globalRemoteControlChannel) ? remoteControlChannel["@id"] === item.globalRemoteControlChannel["@id"] : true)
                ),
                ...state.globalDriveControlRemotes.filter(
                    item => (elements ? item["@type"] === elements["key"] : true) &&
                        (remoteControlType ? remoteControlType["@id"] === item.globalRemoteControlType["@id"] : true) &&
                        ((remoteControlChannel && item.globalRemoteControlChannel) ? remoteControlChannel["@id"] === item.globalRemoteControlChannel["@id"] : true)
                )]

            if (itemElements.length > 0) itemElements = itemElements.filter(element => {
                return element.isEnabled === true
            })
            if (text && itemElements.length > 0) return itemElements.filter(element => {
                return element.symbol.toLowerCase().includes(text.toLowerCase())
            })

            return itemElements
        },
        filters: state => state.filters,
        defaultAutomationElements: state => state.defaultAutomationElements,
        image: () => (object, imageName, extraField = null) => {
            if (extraField) {
                const field = object && object[extraField];
                if (field) {
                    const picture = field[imageName];
                    if (picture) {
                        return picture.contentUrl;
                    }
                    return null;
                }
                return null;
            }
            const picture = object && object[imageName];
            if (picture) {
                return picture.contentUrl;
            }
            return null;
        }

    },

    mutations: {
        startLoading(state) {
            state.loading = true;
        },
        endLoading(state) {
            state.loading = false;
        },
        setGlobalDrives(state, payload) {
            state.globalDrives = payload
        },
        setGlobalDriveControls(state, payload) {
            state.globalDriveControls = payload
        },
        setGlobalRemoteControls(state, payload) {
            state.globalDriveControlRemotes = payload
        },
        setItemGlobalRemoteControl(state, payload) {
            state.item.globalRemoteControl = payload
        },
        setItemGlobalDrive(state, payload) {
            state.item.globalDrive = payload
        },
        setItemGlobalDriveControl(state, payload) {
            state.item.globalDriveControl = payload
        },
        clearItem(state) {
            state.item = simpleClone(defaultItem)
        },
        resetFilters(state) {
            state.filters = simpleClone(defaultFilters)
        },
        setFilterText(state, payload) {
            state.filters.text = payload.value
        },
        setQuantityInOrder(state, quantity) {
            state.item.quantity = quantity;
        },
        setProductType(state, productType) {
            state.productType = productType
        }
    },


    actions: {
        async addElementToItemOrder({commit}, item) {
            const type = item["@type"]
            switch (type) {
                case "GlobalRemoteControl" :
                    commit("setItemGlobalRemoteControl", item);
                    break;
                case "GlobalDrive" :
                    commit("setItemGlobalDrive", item);
                    break;
                case "GlobalDriveControl" :
                    commit("setItemGlobalDriveControl", item);
                    break;
            }
        },
        async setItemProductElements({commit}, elements) {
            commit("setGlobalDriveControls", elements)
        },
        async setQuantity({commit}, quantity) {
            commit("setQuantityInOrder", quantity)
        },
        async setProductType({commit}, productType) {
            commit("setProductType", productType)
        },
        async addItemToOrder({commit, getters, dispatch}) {
            await dispatch(`${getters.productType}/saveAutomationItem`, getters.item, {root: true})
            await commit("clearItem")

        },
        async clearItem({commit}) {
            await commit("clearItem")
        },
        resetFilters({commit}) {
            commit("resetFilters")
        },
        async setFilterSearchText({commit}, payload) {
            commit("setFilterText", payload)
        },
        async init({commit}) {
            commit("startLoading")

            let {data: dataGlobalDriveControls} = await http.get(`/global_drive_controls/?${
                generateProperties(
                    [],
                    ["id", "name", "message", "symbol", "picture", "netRetailPrice", "netWholesalePrice", "manufacturer", "globalDriveControlType", "globalRemoteControlChannel", "isRemoteControl", "description"]
                    , true) +
                generateProperties(
                    ["manufacturer"],
                    ["id", "name"]
                    , true) +
                generateProperties(
                    ["globalDriveControlType"],
                    ["name"]
                    , true)}`);

            // commit("setGlobalDrives", dataGlobalDrives["hydra:member"])
            commit("setGlobalDriveControls", dataGlobalDriveControls["hydra:member"])

            commit("endLoading")
        },

    }
};
