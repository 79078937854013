import router from "../../router";
import NewsList from "./components/NewsList";
import store from "../../store";
import news from "../news/store";

store.registerModule("news", news);

router.addRoutes([
    {
        path: '/news',
        name: 'news_list',
        component: NewsList
    }
])
