<template lang="pug">
    app-form(v-bind="props" v-on="listeners" resource="offers" :allowSave="valuationsExist" :communicate="warning")
        template(#default="{ data, errors }")
            b-tabs
                b-tab-item(:label="$t('general')")
                    .columns
                        .column.is-four-fifths
                            app-form-field(:label="$t('name')" field="name" :errors="errors")
                                b-input(v-model="data.name" expanded)

                            app-form-field(:label="$t('extra_content')" field="customContent" :errors="errors")
                                app-form-editor(v-model="data.customContent")
                    .columns
                        .column
                            .buttons.has-addons
                                a(v-if="data.id" class="button is-info"  @click.prevent="downloadPdf(data.id)") {{ $t("export_to_pdf") }}
                        .column
                b-tab-item(:label="$t('additional_costs_templates_and_valuations')")
                    .columns
                        .column
                            app-form-field(:label="$t('additional_cost_is_visible')" field="additionalCostIsVisible")
                                b-checkbox(v-model="data.additionalCostIsVisible") {{ $t('active') }}
                            br
                            app-form-field( :label="$t('template')" field="offerTemplate" :errors="errors")
                                resource-select(
                                    v-model="data.offerTemplate"
                                    resource="offer_templates"
                                    url="offer_templates"
                                    :placeholder="$t('choose_template')"
                                )
                        .column
                            app-form-field(:label="$t('additional_costs')" field="additional_costs")
                                multiple-resource-select(
                                    v-model="data.additionalCosts"
                                    resource="additional_costs"
                                    url="additional_costs"
                                    extra-props="properties[]=id&properties[]=name&properties[]=cost"
                                    full-object
                                )
                        .column
                            app-form-field(v-if="valuationsExist" :label="$t('valuations')" field="orders" :errors="errors")
                                multiple-resource-select(
                                    v-model="data.orders"
                                    resource="orders"
                                    url="orders"
                                    extra-props="properties[customer][]=firstName&properties[customer][]=lastName&properties[customer][]=companyName&status=valuation"
                                    resource-link="valuations_list"
                                    :resource-link-labels="['name', ['customer', 'firstName'], ['customer', 'lastName'], ['customer', 'companyName']]"
                                )

</template>

<script>
import form from "@/plugins/app/form/mixins/form";
import ResourceSelect from "@/modules/common/components/ResourceSelect";
import MultipleResourceSelect from "@/modules/common/components/MultipleResourceSelect";
import http from "@/http";
import { mapGetters } from "vuex";
import t from "@/i18n";

export default {
  mixins: [form],
  components: {
    ResourceSelect,
    MultipleResourceSelect
  },
  data() {
    return {
      model: {
        name: "",
        customContent: "",
        offerTemplate: null,
        orders: [],
        additionalCosts: [],
        additionalCostIsVisible: true
      },
      valuationsExist: true
    };
  },
  computed: {
    ...mapGetters("auth", ["token"]),
    warning() {
      if (!this.valuationsExist) {
        return t.t("valuation_required");
      } else {
        return " ";
      }
    }
  },
  methods: {
    downloadPdf(id) {
      http
        .get(`api/offers/${id}/pdf`, {
          responseType: "blob",
          headers: {
            Authorization: `Bearer ${this.token}`
          }
        })
        .then(response => {
          let file = new File([response.data], "oferta.pdf", {
            type: "application/pdf"
          });
          let url = URL.createObjectURL(file);
          window.open(url);
        });
    },
    async checkValuationsQuantity() {
      let { data } = await http.get(
        "/orders?properties[]=id&page=1&status=valuation"
      );

      this.valuationsExist = data["hydra:member"].length > 0;
    }
  },
  beforeMount() {
    this.checkValuationsQuantity();
  }
};
</script>
