<template lang="pug">
  b-modal(v-model="isActive" @close="onClose" style="max-width:100%")
    app-view-loadable(:loading="loading")
      app-view-card(:title="$t('settlement_preview')")
        #order.container.print(v-if="this.orders.length > 0")
          .has-text-centered
            .has-margin-bottom
              h1 {{ $t('order_preview') }}
          .columns
            .column.is-4.small-text
              app-panel(:title="$t('order_print.client')")
                app-panel-block() {{ companyData.taxId }}
                app-panel-block() {{ companyData.name }}
                app-panel-block() {{ companyData.streetAndNumber }}
                app-panel-block() {{ companyData.zipCode }} {{ companyData.city }}
                app-panel-block() {{ companyData.phone }}
                app-panel-block() {{ companyData.email }}

          .has-text-centered
            .has-margin-bottom
              table.table.is-bordered.is-fullwidth
                thead
                  tr
                    th {{ $t('order_print.order_date') }}
                    th {{ $t('order_list.header_number') }}
                    th {{ $t('order_list.header_net_margin') }}
                tbody
                  tr(v-for="order in orders")
                    td {{ getOrderDate(order) }}
                    td {{ getOrderNumber(order) }}
                    td {{ formatPriceValue(formatPrice(order.totalNetPurchaseValueBeforeDiscount - order.companyMarginProfit))  }}
                  tr
                    td(colspan=2).has-text-right.has-text-weight-medium  Razem:
                    td.has-text-weight-bold {{ formatPriceValue(formatPrice(totalNetMargin())) }}
          .has-text-right
            p.small-text Wygenerowano {{ new Date().toLocaleString() }}

        .buttons.is-centered
          b-button(@click="printOrder" type="is-primary") {{ $t('print') }}
</template>

<script>
import decimal from "@/helpers/decimal";
import orderNumberCreator from "@/helpers/orderNumberCreator";
import http from "@/http";
import {mapGetters} from "vuex";
import priceFormatter from "../../../helpers/priceFormatter";

export default {
  props: {
    orders: {type: Array, default: () => [], required: true},
    active: {type: Boolean, default: false},
  },

  data() {
    return {
      object: null,
      loading: false,
      isActive: this.active,
      tdStyle: {
        padding: "3px"
      }
    };
  },
  methods: {
    getOrderDate(row) {
      return row.orderDate
    },
    formatPriceValue(value) {
      return priceFormatter(value);
    },
    decimal(val) {
      return decimal(val) || null;
    },
    getOrderNumber(order) {
      return orderNumberCreator(order.id);
    },
    onClose() {
      this.isActive = false;
      this.$emit("close");
    },

    prop(object, field, extraField = null) {
      if (extraField) {
        return object && object[field] && object[field][extraField];
      }
      return object && object[field];
    },
    totalNetMargin() {
      return this.orders.reduce((it, order) => {
        return it + (order.totalNetPurchaseValueBeforeDiscount - order.companyMarginProfit);
      }, 0);
    },
    async settleOrder(order) {
      await http.put(`/orders/${order.id}/settle`, order)
    },
    async printOrder() {
      if (this.orders.length === 0) return;
      // async settle all orders
      await Promise.all(this.orders.map(order => this.settleOrder(order)));
      //refresh orders
      this.$emit("refresh");

      this.$htmlToPaper("order");
      this.active = false;
    }
  },
  computed: {
    ...mapGetters("auth", ["fullName"]),
    ...mapGetters("company", ["companyData"]),
    ...mapGetters("common", ["formatPrice"]),
  },
  watch: {
    active(val) {
      if (val && parseInt(this.orderId)) {
        this.downloadOrder(this.orderId);
      }
      this.isActive = !!val;
    },
    orderId(val) {
      if (
          parseInt(val) &&
          this.isActive &&
          val !== this.orderId &&
          !this.order
      ) {
        this.downloadOrder(val);
      }
    },
    order(val) {
      if (val) {
        this.object = val;
      }
    }
  }
};
</script>
