import simpleClone from "../../../helpers/simpleClone";

export default {
    data() {
        return {
            loading: false,
        };
    },
    computed: {
        orderAfterSum() {
            const orderItems = simpleClone(this.externalShutterOrderItems)
            if(Object.keys(orderItems).length === 0) return []
            return orderItems.filter((item, index, selfArray) =>
                    index === selfArray.findIndex((p) => (
                        p.externalShutter['id'] === item.externalShutter['id'] &&
                        p.externalShutterArmorFabricShadeColorEntry['id'] === item.externalShutterArmorFabricShadeColorEntry['id'] &&
                        p.temporaryExternalBoxColor['id'] === item.temporaryExternalBoxColor['id'] &&
                        p.temporaryInternalBoxColor['id'] === item.temporaryInternalBoxColor['id'] &&
                        p.externalShutterArmorExternalShutterArmorColorEntry['id'] === item.externalShutterArmorExternalShutterArmorColorEntry['id'] &&
                        p.externalShutterPriceList["externalShutterArmor"]['id'] === item.externalShutterPriceList["externalShutterArmor"]['id'] &&
                        p.externalShutterRunnerFabricShadeColorEntry["fabricShadeColor"]['id'] === item.externalShutterRunnerFabricShadeColorEntry["fabricShadeColor"]['id']
                    ))
            );
        },
    },
    methods: {
        checkVirtualVersionNumberAfterSum(item) {
            const orderAfterSum = simpleClone(this.orderAfterSum)
            return orderAfterSum.findIndex((p) => (
                p.externalShutter['id'] === item.externalShutter['id'] &&
                p.externalShutterArmorFabricShadeColorEntry['id'] === item.externalShutterArmorFabricShadeColorEntry['id'] &&
                p.temporaryExternalBoxColor['id'] === item.temporaryExternalBoxColor['id'] &&
                p.temporaryInternalBoxColor['id'] === item.temporaryInternalBoxColor['id'] &&
                p.externalShutterArmorExternalShutterArmorColorEntry['id'] === item.externalShutterArmorExternalShutterArmorColorEntry['id'] &&
                p.externalShutterPriceList["externalShutterArmor"]['id'] === item.externalShutterPriceList["externalShutterArmor"]['id'] &&
                p.externalShutterRunnerFabricShadeColorEntry["fabricShadeColor"]['id'] === item.externalShutterRunnerFabricShadeColorEntry["fabricShadeColor"]['id']
            ))
        },
    },

};
